/**
 * @Description: 工具方法集合
 * @version:
 * @Author: Tom
 * @Date: 2022-11-08 11:02:35
 * @LastEditors: Tom
 * @LastEditTime: 2022-11-08 11:02:35
 */
/**
 * @description: 时间格式化
 * @param {number} duration 时长
 * @param {number} format 格式  1时分秒 2分秒
 * @param {number} dig 保留小数几位
 * @return {string}
 */
export function getTimeString(duration, format = 1, dig = 2) {
  let hour = 0;
  let mintue = 0;
  let second = 0;
  if (duration >= 3600) {
    //取小时整数
    hour = parseInt(duration / 3600);
    if (hour < 10) {
      hour = "0" + hour + ":";
    } else {
      hour = hour + ":";
    }
    //取剩余分钟数
    if (duration % 3600 >= 60) {
      mintue = parseInt((duration % 3600) / 60);

      if (mintue < 10) {
        mintue = "0" + mintue + ":";
      } else {
        mintue = mintue + ":";
      }
    } else {
      mintue = "00:";
    }
    //取秒数，秒数精确到0.01秒
    if (dig == 0) {
      second = Math.trunc(duration % 60);
    } else {
      second = (duration % 60).toFixed(dig);
    }

    if (second < 10) {
      second = "0" + second;
    }
  } else {
    hour = "00:";
    //取分钟整数
    if (duration >= 60) {
      mintue = parseInt(duration / 60);

      if (mintue < 10) {
        mintue = "0" + mintue + ":";
      } else {
        mintue = mintue + ":";
      }
    } else {
      mintue = "00:";
    }
    //取秒数，秒数精确到0.01秒
    if (dig == 0) {
      second = Math.trunc(duration % 60);
    } else {
      second = (duration % 60).toFixed(dig);
    }
    if (second < 10) {
      second = "0" + second;
    }
  }

  if (format === 2) {
    return mintue + second;
  } else {
    return hour + mintue + second;
  }
}

/**
 * @description: 排序
 * @param {number} property 排序键
 * @param {number} type 排序方式 asc  desc
 * @return {string}
 */
export function compare(property, type) {
  return function (a, b) {
    var value1 = a[property];
    var value2 = b[property];
    let ref = value2 - value1;
    if (type === "asc") {
      ref = value1 - value2;
    }
    return ref;
  };
}
/**
 * @description: 中文排序
 * @param {number} property 排序键
 * @param {number} type 排序方式 asc  desc
 * @return {string}
 */
export function compareStr(property, type) {
  return function (a, b) {
    var value1 = a[property];
    var value2 = b[property];
    let ref = 0;
    if (type === "desc") {
      ref = value2.localeCompare(value1, "zh-Hans-CN", {
        sensitivity: "accent",
      });
    } else {
      ref = value1.localeCompare(value2, "zh-Hans-CN", {
        sensitivity: "accent",
      });
    }
    return ref;
  };
}

/**
 * @description: 时间反格式化
 * @param {number} timecode 格式化时间 eg.00:00:44,210 /00:00:44.210
 * @param {number} dig 保留小数几位
 * @return {string}
 */
export function getStringToTime(timecode, dig = 2) {
  let time = timecode.replace(",", ".");
  let uts = time.toString().split(":");
  let hour = parseInt(uts[0]);
  if (isNaN(hour) == true) {
    return NaN;
  }
  let minute = parseInt(uts[1]);
  if (isNaN(minute) == true) {
    return NaN;
  }
  let sec_temp = uts[2].toString().split(".");
  var sec = parseInt(sec_temp[0]);
  if (isNaN(sec) == true) {
    return NaN;
  }
  if (sec_temp.length > 1) {
    var msec = parseInt(sec_temp[1]);
    if (isNaN(msec) == true) {
      console.log(msec);
      return NaN;
    }
  }

  let duration = 3600 * hour + 60 * minute + sec;
  // duration = parseFloat(duration.toFixed(3));
  if (sec_temp.length > 1) {
    duration += "." + sec_temp[1];
  }
  // console.log(duration);
  // console.log(sec_temp);
  return duration;
}

/**
 * @description: 节流
 * @param {number} fn 需要运行function
 * @param {number} wait 运行频率
 * @return {string}
 */
export function throttle(fn, wait) {
  //function函数可以写在其他js里
  let timerOut = null;
  return function () {
    if (!timerOut) {
      timerOut = setTimeout(function () {
        fn.apply(this, arguments);
        timerOut = null;
      }, wait);
    }
  };
}

/**
 * @description: 读速转换
 * @param {number} SpeedProgress  100分制的进度
 * @return {string}
 */
export function SpeedProgressText(SpeedProgress) {
  let speed = 1;
  SpeedProgress = Number(SpeedProgress);
  if (SpeedProgress > 50) {
    speed = SpeedProgress / 50;
  } else if (SpeedProgress < 50) {
    speed = 0.5 + SpeedProgress / 100;
  }
  return speed.toFixed(2) + "x";
}

/**
 *防抖
 * @param func
 * @param wait
 * @returns {(function(): void)|*}
 */
export function debounce(func, wait) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      func.apply(context, args);
    }, wait);
  };
}
