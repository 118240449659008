<!--
 * @Description: TM 管理
 * @version: 
 * @Author: Carful
 * @Date: 2022-11-07 10:09:19
 * @LastEditors: Carful
 * @LastEditTime: 2023-05-25 14:51:13
-->
<template>
  <div class="mainCenter">
    <div class="mainCenterTop titleHeader">
      <div class="titleStyle">
        <div class="titledivStyle"></div>
        <p>{{ $t("TMManagement.title") }}</p>
      </div>
      <div>
        <el-button type="text" @click="addNewmember">
          <div class="svgbtn">
            <!-- headerbtn2 -->
            <svg-icon icon-class="create" class="icon"></svg-icon>
            <p>{{ $t("TMManagement.headerbtn") }}</p>
          </div>
        </el-button>
      </div>
    </div>
    <!-- 筛选 -->
    <div class="contentCenter_tags screenStyle">
      <p>{{ $t("CommonName.screen") }}</p>
      <el-tag
        v-for="tag in tags"
        :key="tag.name"
        closable
        :type="tag.type"
        @close="handleClose(tag)"
      >
        <p
          class="screenp"
          :id="'tag' + tag.id"
          @mouseover="onShowNameTipsMouseenter(tag)"
        >
          <el-tooltip
            class="item"
            effect="dark"
            :content="tag.name"
            placement="top-start"
            :disabled="!tag.showdropdown"
          >
            <span>{{ tag.name }}</span>
          </el-tooltip>
        </p>
      </el-tag>
    </div>
    <div class="mainCenterbtm tableClass">
      <el-table
        :row-style="{
          height: 48 + 'px',
          background: '#393c4e',
          color: '#cbd7e8',
        }"
        :cell-style="{ padding: 0 + 'px' }"
        :header-cell-style="{
          height: 48 + 'px',
          padding: '0px',
          color: '#adb0bc',
          background: '#393c4e',
        }"
        ref="multipleTable"
        :data="
          tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        "
        tooltip-effect="dark"
        style="width: 100%"
        :max-height="tableHeight"
        :default-sort="{ prop: 'create_time', order: 'descending' }"
        @sort-change="sortChange"
      >
        <!-- TM Type -->
        <el-table-column prop="type1" width="160">
          <template slot="header">
            <div class="tableheaderdiv">
              <p class="namep">{{ $t("TMManagement.TMType") }}</p>
              <div class="icondiv">
                <el-dropdown
                  @command="TMTypecommand"
                  trigger="click"
                  placement="bottom-end"
                >
                  <span class="el-dropdown-link">
                    <svg-icon icon-class="Filter" class="iconsize"></svg-icon>
                  </span>
                  <el-dropdown-menu
                    slot="dropdown"
                    class="tabledropdowndiv noshowtriangle"
                  >
                    <el-dropdown-item
                      v-for="item in TMTypedropdownList"
                      :key="item.type"
                      :command="{ name: item.name, value: item.type }"
                    >
                      <span>{{ item.name }}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <div class="tableheader"></div>
              </div>
            </div>
          </template>
          <template slot-scope="scope">
            <div class="menuscopediv">
              {{ scope.row.type1 }}
            </div>
          </template>
        </el-table-column>
        <!-- TM名称 -->
        <el-table-column :min-width="100" prop="name" show-overflow-tooltip>
          <template slot="header">
            <!-- TM 名称 -->
            <div class="tableheaderdiv">
              <p>{{ $t("TMManagement.TMName") }}</p>
              <div class="icondiv">
                <el-popover
                  placement="bottom-end"
                  width="278"
                  v-model="visible"
                  :visible-arrow="false"
                >
                  <div class="popoverDiv">
                    <el-input
                      v-model="popoverInput"
                      :placeholder="$t('CommonName.search')"
                      maxlength="100"
                      @keyup.enter.native="searchdetermineClick"
                    ></el-input>
                    <el-button
                      type="primary"
                      class="setpadding16"
                      @click="searchdetermineClick"
                      >{{ $t("CommonName.determine") }}
                    </el-button>
                  </div>
                  <svg-icon
                    icon-class="Filter"
                    class="iconsize"
                    slot="reference"
                  ></svg-icon>
                </el-popover>

                <div class="tableheader"></div>
              </div>
            </div>
          </template>
          <template slot-scope="scope">
            <div
              style="cursor: pointer; color: #46adff"
              class="overflowEllipsis"
              @click="tovideoDetails(scope.row)"
            >
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>
        <!-- 源语言 -->
        <el-table-column width="185" prop="slang_name" show-overflow-tooltip>
          <template slot="header">
            <div class="tableheaderdiv">
              <p>{{ $t("CommonName.sourcelanguagetable") }}</p>
              <div class="icondiv">
                <el-dropdown
                  @command="sourcelanguagecommand"
                  trigger="click"
                  placement="bottom-end"
                >
                  <span class="el-dropdown-link">
                    <svg-icon icon-class="Filter" class="iconsize"></svg-icon>
                  </span>
                  <el-dropdown-menu
                    slot="dropdown"
                    class="tabledropdowndiv noshowtriangle"
                  >
                    <el-dropdown-item
                      v-for="item in sourcelanguagedropdownList"
                      :key="item.language_id"
                      :command="{ name: item.ename, value: item.language_id }"
                    >
                      <span>{{ item.ename }}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <div class="tableheader"></div>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 目标语言 -->
        <el-table-column width="185" prop="tlang_name" show-overflow-tooltip>
          <template slot="header">
            <div class="tableheaderdiv">
              <p>{{ $t("CommonName.targetlanguagetable") }}</p>
              <div class="icondiv">
                <el-dropdown
                  @command="targetlanguagecommand"
                  trigger="click"
                  placement="bottom-end"
                >
                  <span class="el-dropdown-link">
                    <svg-icon icon-class="Filter" class="iconsize"></svg-icon>
                  </span>
                  <el-dropdown-menu
                    slot="dropdown"
                    class="tabledropdowndiv noshowtriangle"
                  >
                    <el-dropdown-item
                      v-for="item in targetlanguagedropdownList"
                      :key="item.language_id"
                      :command="{ name: item.ename, value: item.language_id }"
                    >
                      <span>{{ item.ename }}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <div class="tableheader"></div>
              </div>
            </div>
          </template>
        </el-table-column>
        <!--  状态-->
        <el-table-column prop="statusName" width="100">
          <template slot="header">
            <div class="tableheaderdiv">
              <p>{{ $t("CommonName.status") }}</p>
              <div class="icondiv">
                <div class="tableheader"></div>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 句段数 -->
        <el-table-column prop="segment" width="100">
          <template slot="header">
            <div class="tableheaderdiv">
              <p>{{ $t("CommonName.Numberofsentencesegments") }}</p>
              <div class="icondiv">
                <div class="tableheader"></div>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 创建时间 -->
        <el-table-column
          prop="create_time"
          sortable="custom"
          :sort-orders="['ascending', 'descending']"
          width="150"
        >
          <template slot="header">
            <span>{{ $t("MemberDetails.createTime") }}</span>
            <span class="tableheaderspan"></span>
          </template>
        </el-table-column>
        <!-- 备注 -->
        <el-table-column prop="comments" show-overflow-tooltip>
          <template slot="header">
            <div class="tableheaderdiv">
              <p>{{ $t("CommonName.remarks") }}</p>
              <div class="icondiv">
                <div class="tableheader"></div>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column width="50" :label="$t('CommonName.operation')">
          <template slot-scope="scope">
            <div class="operationStyle" v-if="scope.row.status == '0'">
              <el-dropdown @command="operationcommandClick">
                <span class="el-dropdown-link">
                  <svg-icon
                    icon-class="MoreOperations"
                    style="color: #cbd7e8"
                  ></svg-icon>
                </span>
                <el-dropdown-menu slot="dropdown" class="dropdowndiv">
                  <el-dropdown-item
                    v-for="item in scope.row.dropdownList"
                    :key="item.command"
                    :command="{ type: item.command, id: scope.row }"
                  >
                    <svg-icon
                      :icon-class="item.icon"
                      style="margin-right: 8px; font-size: 18px"
                    ></svg-icon>
                    <span>{{ item.name }}</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
        <div slot="empty" class="noDataDiv">
          <svg-icon icon-class="NoData" class="noDataicon"></svg-icon>
          <p>{{ $t("CommonName.noData") }}</p>
        </div>
      </el-table>
      <div
        style="margin-top: 20px; float: right; margin-right: 20px"
        v-if="tableData.length > 0"
      >
        <PaginationComVue
          :tableData="tableData"
          :pageSize="pageSize"
          :currentPage="currentPage"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        ></PaginationComVue>
      </div>
      <DialogComVue
        :dialogVisible="dialogVisible"
        @determineClick="determineClick"
        :footerbtnName="$t('CommonName.Sure')"
        :closefooterbtnName="$t('CommonName.Cancel')"
        @changeDialogVisible="changeDialogVisible"
        :width="'888px'"
        :top="'10%'"
        :title="dialogtitle"
        v-if="dialogVisible"
      >
        <div class="dialogContent">
          <!-- 新建 TM -->
          <div v-if="dialogtitle == $t('TMManagement.headerbtn')">
            <el-form
              ref="form"
              :model="form"
              label-position="top"
              class="formStyleClass dialogformStyleClass"
              @keydown.enter.prevent
            >
              <el-form-item :label="$t('TMManagement.TMName1')">
                <el-input
                  v-model="form.name"
                  placeholder=""
                  :maxlength="45"
                ></el-input>
              </el-form-item>
              <!-- <el-form-item :label="$t('TMManagement.TMType')">
                <div class="radioClass">
                  <el-radio-group v-model="form.resource">
                    <el-radio border label="1"
                      >{{ $t("TMManagement.MainTM") }}
                    </el-radio>
                    <el-radio border label="2"
                      >{{ $t("TMManagement.TemporaryTM") }}
                    </el-radio>
                  </el-radio-group>
                </div>
              </el-form-item> -->
              <div class="languageClass">
                <div>
                  <el-form-item :label="$t('CommonName.Sourcelanguage')">
                    <el-select
                      v-model="form.select1"
                      class="selectclass"
                      :placeholder="$t('TMManagement.select')"
                      filterable
                    >
                      <el-option
                        v-for="item in selectList"
                        :key="item.language_id"
                        :label="item.cname"
                        :value="item.language_id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="languageClass_right">
                  <el-form-item :label="$t('CommonName.Targetlanguage')">
                    <el-select
                      v-model="form.select2"
                      multiple
                      collapse-tags
                      class="selectclass"
                      :placeholder="$t('TMManagement.select')"
                      filterable
                    >
                      <el-option
                        v-for="item in selectList"
                        :key="item.language_id"
                        :label="item.cname"
                        :value="item.language_id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <el-form-item :label="$t('TMManagement.Comments')">
                <el-input
                  type="textarea"
                  v-model="form.desc"
                  :maxlength="150"
                  resize="none"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div v-if="dialogtitle == $t('TMManagement.ImportTM')">
            <div class="content">
              <div class="uploadp">
                <p>{{ $t("TMManagement.Uploadfile") }}</p>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('TMManagement.tootip1')"
                  placement="top-start"
                >
                  <svg-icon icon-class="icon_tips" class="icon"></svg-icon>
                </el-tooltip>
                <svg-icon
                  icon-class="presentation"
                  class="verificationIcon"
                  v-if="form1.uploadinput1 == null"
                ></svg-icon>
              </div>
              <el-input
                v-model="form1.uploadinput1"
                class="input-with-select srtUnput"
                @input="form1.uploadinput1 = ''"
              >
                <!-- :action="$uploadURL + '/tm/upload_tm'" -->
                <el-upload
                  slot="append"
                  class="upload-demo"
                  ref="upload"
                  :with-credentials="true"
                  :action="uploadUrl + '/tm/upload_tm'"
                  name="tm_file"
                  :data="uploadData"
                  :before-upload="beforeUpload"
                  :on-change="uploadChange"
                  :on-progress="uploadProgress"
                  :show-file-list="false"
                  :on-success="uploadSuccess"
                  :auto-upload="false"
                  :limit="1"
                >
                  <el-button type="text" @click="AudiotranscriptionClick">
                    <div class="uploadbtn">
                      <svg-icon icon-class="upload" class="icon"></svg-icon>
                      <!-- 上传 -->
                      <span>{{ $t("CommonName.Upload") }}</span>
                    </div>
                  </el-button>
                </el-upload>
              </el-input>
            </div>
            <div class="content_btm">
              <p>{{ $t("TMManagement.importTMTypeTitle") }}</p>
              <div class="radioClass step1Center_left">
                <el-radio-group v-model="form1.resource">
                  <el-radio border label="3"
                    >{{
                      $t("TMManagement.Leaveexistingtranslationunitsunchanged")
                    }}
                  </el-radio>
                  <el-radio border label="2"
                    >{{ $t("TMManagement.Overwriteexistingtranslationunits") }}
                  </el-radio>
                  <el-radio border label="1"
                    >{{ $t("TMManagement.Addnewtranslationunits") }}
                  </el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div v-if="dialogtitle == $t('TMManagement.MergeTitle')">
            <div class="form3_top">
              <p>{{ $t("TMManagement.mergetabletitle") }}</p>
              <el-table
                :row-style="{
                  height: 40 + 'px',
                  background: '#393c4e',
                  color: '#cbd7e8',
                }"
                :cell-style="{ padding: 0 + 'px' }"
                :header-cell-style="{
                  height: 32 + 'px',
                  padding: '0px',
                  color: '#adb0bc',
                  background: '#393c4e',
                }"
                :data="form3.tableData"
                tooltip-effect="dark"
                style="width: 100%; overflow-y: auto"
                max-height="320px"
                border
              >
                <el-table-column
                  prop="filename"
                  :label="$t('TMManagement.TMName1')"
                  width="500"
                >
                  <template slot-scope="scope">
                    <div class="setSelectClass">
                      <el-select
                        v-model="scope.row.name"
                        :placeholder="$t('TMManagement.select')"
                        popper-class="selectclass2"
                        filterable
                        @change="selectChange"
                      >
                        <el-option
                          v-for="item in filenameoptions"
                          :key="item.tm_id"
                          :label="item.name"
                          :value="item"
                        >
                          <div class="selectContentClass">
                            <p class="onep">
                              <span>{{ item.name }}</span>
                            </p>
                            <p>
                              <span>{{ item.cnt }}</span>
                            </p>
                            <p>
                              <span>{{ item.create_time }}</span>
                            </p>
                          </div>
                        </el-option>
                      </el-select>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="cnt"
                  :label="$t('TMManagement.Segments')"
                >
                </el-table-column>
                <el-table-column
                  prop="create_time"
                  :label="$t('TMManagement.CreatedDate')"
                >
                </el-table-column>
              </el-table>
            </div>
            <div class="content_btm">
              <p>{{ $t("TMManagement.Mergeoptions") }}</p>
              <div class="radioClass step1Center_left">
                <el-radio-group v-model="form3.resource">
                  <el-radio border label="1"
                    >{{ $t("TMManagement.Addnewtranslationunits") }}
                  </el-radio>
                  <el-radio border label="2"
                    >{{ $t("TMManagement.Overwriteexistingtranslationunits") }}
                  </el-radio>
                  <el-radio border label="3"
                    >{{
                      $t("TMManagement.Leaveexistingtranslationunitsunchanged")
                    }}
                  </el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
        </div>
      </DialogComVue>
      <div
        class="progressdiv"
        v-if="percentage > 0"
        v-loading.fullscreen.lock="loading"
        element-loading-custom-class="disaplaynoneicon"
        element-loading-background="rgba(0, 0, 0, 0.3)"
      >
        <el-progress
          type="circle"
          :percentage="percentage"
          :stroke-width="10"
        ></el-progress>
      </div>
    </div>
  </div>
</template>
<script>
import {
  onShowNameTipsMouseenter,
  addAndreplaceTags,
} from "@/assets/js/Publicmethods";
import PaginationComVue from "@/components/PaginationCom.vue";
import DialogComVue from "@/components/DialogCom.vue";
import config from "@/config";
import {
  get_tm_list,
  get_language,
  create_tm,
  delete_tm,
  export_tm,
  get_merge_list,
  merge_tm,
  download_tm,
} from "@/utils/https";
import { showLoading, hideLoading } from "@/utils/loading";

export default {
  components: {
    PaginationComVue,
    DialogComVue,
  },
  data() {
    return {
      loading: false,
      percentage: 0,
      uploadData: {},
      rowData: {},
      // 新建
      form: {
        name: "",
        resource: "2",
        desc: "",
        select1: "",
        select2: [],
      },
      // 导入
      form1: {
        uploadinput1: "",
        resource: "3",
      },
      // 合并
      form3: {
        tableData: [
          {
            name: "",
            cnt: "--",
            create_time: "--",
            tm_id: "",
          },
        ],
        resource: "1",
      },
      filenameoptions: [],
      tags: [], //筛选数组
      popoverInput: "", //筛选内部 弹出框 输入
      visible: false,
      dialogVisible: false,
      dialogtitle: "",
      dialogInput: "",
      selectList: [], //语言 数组
      tableData: [],
      // 目标语言
      targetlanguagedropdownList: [],
      // 源语言
      sourcelanguagedropdownList: [],
      // TM Type
      TMTypedropdownList: [
        {
          type: "1",
          name: this.$t("TMManagement.MainTM"),
        },
        {
          type: "2",
          name: this.$t("TMManagement.TemporaryTM"),
        },
      ],
      dropdownList: [
        // {
        //   command: "0",
        //   name: this.$t("CommonName.import"),
        //   icon: "Import",
        // },
        // {
        //   command: "1",
        //   name: this.$t("CommonName.export"),
        //   icon: "export",
        // },
        // {
        //   command: "2",
        //   name: this.$t("CommonName.merge"),
        //   icon: "AdjustmentTime",
        // },
        // {
        //   command: "3",
        //   name: this.$t("CommonName.delete"),
        //   icon: "delete",
        // },
      ],
      older: "create_time",
      sort: "desc",
      pageSize: 20,
      currentPage: 1,
      tableHeight: 0,
      uploadUrl: config.baseUrl.dev,
      defaultSort: { prop: "create_time", order: "descending" },
    };
  },
  mounted() {
    this.get_language();
    hideLoading();
    showLoading(this.$t("CommonName.loadingmessage"));
    //将储存的搜索条件，赋值
    if (
      sessionStorage.getItem("menuIndex") != null &&
      sessionStorage.getItem("menuIndex") == "/ContainerHome/Team/TMManagement"
    ) {
      if (
        sessionStorage.getItem("menuTags") != "" &&
        sessionStorage.getItem("menuTags") != null
      ) {
        let menuTags = JSON.parse(sessionStorage.getItem("menuTags"));
        if (menuTags.currentPage != undefined) {
          this.currentPage = menuTags.currentPage;
        }
        if (menuTags.pageSize != undefined) {
          this.pageSize = menuTags.pageSize;
        }
        if (menuTags.older != undefined) {
          this.older = menuTags.older;
          this.defaultSort.prop = this.older;
        }
        if (menuTags.sort != undefined) {
          this.sort = menuTags.sort;
          this.defaultSort.order =
            this.sort == "asc" ? "ascending" : "descending";
        }
        if (menuTags.tags != undefined) {
          this.tags = menuTags.tags;
        }
      }
    }
    this.get_tm_list();
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - 300;
    });
    window.onresize = () => {
      this.tableHeight = window.innerHeight - 300;
    };
  },
  methods: {
    /**
     * 获取语言接口
     */
    get_language() {
      get_language({
        action: "get_language",
        lang:
          sessionStorage.getItem("lang") == "cn" ||
          sessionStorage.getItem("lang") == null
            ? 1
            : 2,
      }).then((res) => {
        if (res) {
          this.selectList = res.list;
          this.sourcelanguagedropdownList = res.list;
          this.targetlanguagedropdownList = res.list;
        }
      });
    },
    /**
     * 获取 表格数据
     */
    get_tm_list() {
      let data = { action: "get_tm_list" };
      if (this.older != "" && this.sort != "") {
        data["older"] = this.older;
        data["sort"] = this.sort;
      }
      this.tags.forEach((item, index) => {
        data[item.key] = item.value;
      });
      return get_tm_list(data).then((res) => {
        if (res) {
          // console.log(res.list);
          if (res.list.length > 0) {
            res.list.forEach((i) => {
              if (i.type == "1") {
                i.type1 = this.$t("TMManagement.MainTM");
              } else {
                i.type1 = this.$t("TMManagement.TemporaryTM");
              }
              if (i.download !== "") {
                i.dropdownList = [
                  {
                    command: "0",
                    name: this.$t("CommonName.import"),
                    icon: "Import",
                  },
                  {
                    command: "1",
                    name: this.$t("CommonName.export"),
                    icon: "export",
                  },
                  {
                    command: "4",
                    name: this.$t("CommonName.download"),
                    icon: "download",
                  },
                  // {
                  //   command: "2",
                  //   name: this.$t("CommonName.merge"),
                  //   icon: "AdjustmentTime",
                  // },
                  {
                    command: "3",
                    name: this.$t("CommonName.delete"),
                    icon: "delete",
                  },
                ];
              } else {
                i.dropdownList = [
                  {
                    command: "0",
                    name: this.$t("CommonName.import"),
                    icon: "Import",
                  },
                  {
                    command: "1",
                    name: this.$t("CommonName.export"),
                    icon: "export",
                  },
                  // {
                  //   command: "2",
                  //   name: this.$t("CommonName.merge"),
                  //   icon: "AdjustmentTime",
                  // },
                  {
                    command: "3",
                    name: this.$t("CommonName.delete"),
                    icon: "delete",
                  },
                ];
              }
              if (i.status == "0") {
                i.statusName = this.$t("TMManagement.statusName1");
              } else if (i.status == "10") {
                i.statusName = this.$t("TMManagement.statusName2");
              } else if (i.status == "20") {
                i.statusName = this.$t("TMManagement.statusName3");
              } else {
                i.statusName = this.$t("TMManagement.statusName4");
              }
            });
          }
          this.tableData = res.list;
          hideLoading();
        }
      });
    },
    sortChange(column) {
      // console.log(column);
      showLoading(this.$t("CommonName.loadingmessage"));
      if (column.order == "ascending") {
        this.older = column.prop;
        this.sort = "asc";
      } else {
        this.older = column.prop;
        this.sort = "desc";
      }
      this.currentPage = 1;
      this.handleMenuTags();
      this.get_tm_list();
    },
    /**
     *
     * @param {*删除TM} data
     */
    delete_tm(data) {
      delete_tm({
        action: "delete_tm",
        tm_id: data,
      }).then((res) => {
        if (!res) {
          return;
        }
        sessionStorage.setItem("menuTags", "");
        this.currentPage = 1;
        this.pageSize = 20;
        this.older = "create_time";
        this.defaultSort = { prop: "create_time", order: "descending" };
        this.sort = "desc";
        this.tags = [];

        this.get_tm_list().then(() => {
          this.$messages("success", this.$t("CommonName.successmessage2"));
        });
      });
    },
    /**
     *
     * @param {*导出TM} id
     */
    export_tm(id) {
      export_tm({
        action: "export_tm",
        tm_id: id,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.$messages("success", this.$t("TMManagement.successmessage2"));
      });
    },
    /**
     *
     * @param {下载TM} id
     */
    download_tm(id) {
      download_tm({
        action: "download_tm",
        tm_id: id,
      }).then((res) => {
        if (!res) {
          return;
        }
        window.location.href = res.path;
      });
    },
    /**
     *
     * @param {*获取可合并TM列表} id
     */
    get_merge_list(id) {
      return get_merge_list({
        action: "get_merge_list",
        tm_id: id,
      }).then((res) => {
        if (!res) {
          return;
        }
        // console.log(res);
        this.filenameoptions = res.list;
        this.dialogtitle = this.$t("TMManagement.MergeTitle");
        this.dialogVisible = true;
        hideLoading();
      });
    },
    /**
     *
     * @param {合并TM} item
     */
    merge_tm(item) {
      merge_tm({
        action: "merge_tm",
        source_tm_id: this.rowData.tm_id,
        target_tm_id: item.tableData[0].tm_id,
        merge_type: item.resource,
      }).then((res) => {
        if (!res) {
          hideLoading();
          return;
        }
        this.get_tm_list().then(() => {
          this.$messages("success", this.$t("TMManagement.successmessage4"));
          this.dialogVisible = false;
        });
      });
    },
    /**
     * 弹窗确认按钮
     */
    determineClick() {
      // 创建 TM
      if (this.dialogtitle == this.$t("TMManagement.headerbtn")) {
        if (this.form.name.trim() == "") {
          this.$messages("warning", this.$t("TMManagement.warningmessage1"));
          return;
        }

        if (!this.form.select1 || this.form.select2.length == 0) {
          this.$messages("warning", this.$t("CommonName.warningmessage1"));
          return;
        }
        if (this.form.select2.includes(this.form.select1)) {
          this.$messages("warning", this.$t("TMManagement.warningmessage2"));
          return;
        }
        showLoading(this.$t("CommonName.loadingmessage"));
        create_tm({
          action: "create_tm",
          name: this.form.name.trim(),
          slang: this.form.select1,
          tlangs: this.form.select2,
          type: this.form.resource,
          comments: this.form.desc,
        }).then((res) => {
          if (res) {
            sessionStorage.setItem("menuTags", "");
            this.currentPage = 1;
            this.pageSize = 20;
            this.older = "create_time";
            this.defaultSort = { prop: "create_time", order: "descending" };
            this.sort = "desc";
            this.tags = [];

            this.get_tm_list().then(() => {
              this.$messages("success", this.$t("CommonName.successmessage1"));
              this.dialogVisible = false;
            });
          }
        });
        //  导入TM
      } else if (this.dialogtitle == this.$t("TMManagement.ImportTM")) {
        if (!this.form1.uploadinput1) {
          this.$messages("warning", this.$t("toolset.uploadwarningmessage4"));
          return;
        }
        this.uploadData = {
          tm_type: this.form1.resource,
          tm_id: this.rowData.tm_id,
        };
        this.$nextTick(() => {
          this.$refs.upload.submit();
        });
      } else {
        // console.log(this.form3);
        if (!this.form3.tableData[0].tm_id) {
          this.$messages("warning", this.$t("CommonName.selectMessage"));
          return;
        }
        showLoading(this.$t("CommonName.loadingmessage"));
        this.merge_tm(this.form3);
      }
    },
    /**
     *
     * @param {*弹窗关闭按钮} val
     */
    changeDialogVisible(val) {
      this.dialogVisible = val;
      this.form3 = {
        tableData: [
          {
            name: "",
            cnt: "--",
            create_time: "--",
            tm_id: "",
          },
        ],
        resource: "1",
      };
      this.form = {
        name: "",
        resource: "2",
        desc: "",
        select1: "",
        select2: "",
      };
      // 导入
      this.form1 = {
        uploadinput1: "",
        resource: "1",
      };
    },
    /**
     *
     * @param {*表格操作 下拉框} command
     */
    operationcommandClick(command) {
      // console.log(command);
      switch (command.type) {
        case "0":
          this.form1 = {
            uploadinput1: "",
            resource: "3",
          };
          this.dialogtitle = this.$t("TMManagement.ImportTM");
          this.dialogVisible = true;
          this.rowData = command.id;
          break;
        case "2":
          // 合并 TM
          this.form3 = {
            tableData: [
              {
                name: "",
                cnt: "--",
                create_time: "--",
                tm_id: "",
              },
            ],
            resource: "1",
          };
          this.rowData = command.id;
          showLoading(this.$t("CommonName.loadingmessage"));
          this.get_merge_list(command.id.tm_id);
          break;
        case "3":
          // console.log(command.id);
          this.$confirm(
            this.$t("CommonName.deleteMessage"),
            this.$t("CommonName.deleteMessageTitle"),
            {
              confirmButtonText: this.$t("CommonName.deleteMessagebutton"),
              cancelButtonText: this.$t("CommonName.deleteMessageClosebutton"),
              closeOnClickModal: false,
              cancelButtonClass: "closeButton",
            }
          )
            .then(() => {
              showLoading(this.$t("CommonName.loadingmessage"));
              this.delete_tm(command.id.tm_id);
            })
            .catch(() => {});

          break;
        case "4":
          this.download_tm(command.id.tm_id);
          // var downloada = document.createElement("a");
          // downloada.href = command.id.download;
          // downloada.click();
          // window.location.href = command.id.download; //下载
          break;
        default:
          var url = this.uploadUrl + "/tm/export_tm?tm_id=" + command.id.tm_id;
          window.open(url);
          this.dialogVisible = false;
          break;
      }
    },
    // =============================上传===========================================
    AudiotranscriptionClick() {
      this.form1.uploadinput1 = "";
      let uploadFilesArr = this.$refs["upload"].uploadFiles; //上传字幕文件
      if (uploadFilesArr.length == 0) {
        return;
      } else {
        this.$refs["upload"].uploadFiles = [];
      }
    },
    beforeUpload() {},
    /**
     *
     * @param {*文件上传 状态变化} file
     */
    uploadChange(file) {
      if (!file) return;
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg.toLowerCase() === "tmx";
      const isLt500M = file.size / 1024 / 1024 < 100;
      // if (!extension) {
      //   this.form1.uploadinput1 = "";
      //   this.$refs.upload.clearFiles();
      //   this.$messages("warning", this.$t("TMManagement.warningmessage4"));
      //   return false;
      // } else
      if (!isLt500M) {
        this.form1.uploadinput1 = "";
        this.$refs.upload.clearFiles();
        this.$messages("warning", this.$t("toolset.uploadwarningmessage2"));
        return false;
      } else {
        this.form1.uploadinput1 = file.name;
        return true;
      }
    },
    /**
     *
     * @param {*上传成功时} response
     * @param {*} file
     */
    uploadSuccess(response, file) {
      // console.log(response, file);
      if (response.result == "SUCCESS") {
        this.get_tm_list().then(() => {
          this.$messages("success", this.$t("TMManagement.successmessage3"));
          this.percentage = 0;
          this.loading = false;
          this.dialogVisible = false;
          this.form1 = {
            uploadinput1: "",
            resource: "1",
          };
        });
      } else if (response.result == "LOGIN_FAILED") {
        this.percentage = 0;
        this.loading = false;
        this.$messages("warning", this.$t("CommonName.warningmessage4"));
        this.$router.push("/Login");
        this.$Cookie.remove("token_code");
        sessionStorage.clear();
      } else {
        this.percentage = 0;
        this.loading = false;
        this.$messages("error", response.msg);
        this.dialogVisible = false;
      }
    },
    /**
     *
     * @param {*上传过程中} file
     */
    uploadProgress(file) {
      // console.log(file);
      // showLoading();
      if (file.percent > 0) {
        this.percentage = Number(file.percent.toFixed());
        this.loading = true;
      }
    },
    /**
     * 新建 TM
     */
    addNewmember() {
      this.form = {
        name: "",
        resource: "2",
        desc: "",
        select1: "",
        select2: "",
      };
      this.dialogtitle = this.$t("TMManagement.headerbtn");
      this.dialogVisible = true;
    },
    /**
     *
     * @param {*下拉框选中} item
     */
    selectChange(item) {
      // console.log(item);
      let a = this.form3.tableData;
      a.forEach((i) => {
        i.tm_id = item.tm_id;
        i.name = item.name;
        i.cnt = item.cnt;
        i.create_time = item.create_time;
      });
      this.form3.tableData = a;
    },
    /**
     *
     * @param {超出溢出 显示} i
     */
    onShowNameTipsMouseenter(i) {
      onShowNameTipsMouseenter(i);
    },
    /**
     *
     * @param {*筛选 tab 删除} tag
     */
    handleClose(tag) {
      this.tags.splice(this.tags.indexOf(tag), 1);
      this.currentPage = 1;
      this.handleMenuTags();
      this.get_tm_list();
    },
    /**
     * 表格表头 名称 筛选 按钮
     */
    searchdetermineClick() {
      if (this.popoverInput) {
        this.tags = addAndreplaceTags(
          this.$t("TMManagement.TMName"),
          this.popoverInput,
          this.tags,
          "name",
          this.popoverInput
        );
      }
      this.currentPage = 1;
      this.handleMenuTags();
      this.get_tm_list();
      this.visible = false;
      this.popoverInput = "";
    },
    /**
     *
     * @param {*TM Type 下拉筛选} command
     */
    TMTypecommand(command) {
      this.tags = addAndreplaceTags(
        this.$t("TMManagement.TMType"),
        command.name,
        this.tags,
        "type",
        command.value
      );
      this.currentPage = 1;
      this.handleMenuTags();
      this.get_tm_list();
    },
    /**
     *
     * @param {*目标语言} command
     */
    targetlanguagecommand(command) {
      this.tags = addAndreplaceTags(
        this.$t("CommonName.targetlanguagetable"),
        command.name,
        this.tags,
        "tlang_id",
        command.value
      );
      this.currentPage = 1;
      this.handleMenuTags();
      this.get_tm_list();
    },
    /**
     *
     * @param {*源语言} command
     */
    sourcelanguagecommand(command) {
      this.tags = addAndreplaceTags(
        this.$t("CommonName.sourcelanguagetable"),
        command.name,
        this.tags,
        "slang_id",
        command.value
      );
      this.currentPage = 1;
      this.handleMenuTags();
      this.get_tm_list();
    },
    /**
     *
     * @param {点击表格 项目名称 进入项目详情页面} row
     */
    tovideoDetails(row) {
      this.$router.push({
        name: "TMManagementDetails",
        query: {
          id: row.tm_id,
        },
      });
    },
    /**
     * 分页器
     * @param {} val 页数
     */
    handleSizeChange(val) {
      this.pageSize = val;
      this.handleMenuTags();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.handleMenuTags();
    },
    handleMenuTags() {
      let menuTags = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        older: this.older,
        sort: this.sort,
        tags: this.tags,
      };
      sessionStorage.setItem("menuTags", JSON.stringify(menuTags));
    },
  },
};
</script>
<style scoped lang="scss">
.mainCenter {
  height: calc(100% - 38px);
  width: 100%;
  box-sizing: border-box;

  .mainCenterbtm {
    height: calc(100% - 76px);
  }
}

.mainCenterTop {
  // float: right;
  padding: 14px 24px 14px 0;

  .headerbtn1 {
    margin-right: 15px;
  }
}

.tableBtnClass {
  display: flex;

  div {
    width: 50%;
  }
}

.menuscopediv {
  margin-left: 14px;
}

.namep {
  margin-left: 14px;
}

.dialogContent {
  margin: 24px;

  p {
    color: #adb0bc;
    font-size: 14px;
    margin-bottom: 6px;
  }

  ::v-deep .el-input__inner {
    width: 840px;
  }

  .content {
    p {
      margin-bottom: 0;
    }

    .uploadp {
      display: flex;
      align-items: center;
      margin-bottom: 6px;

      .icon {
        cursor: pointer;
        margin-left: 6px;
        color: #adb0bc;
      }
    }

    .srtUnput {
      ::v-deep .el-input__inner {
        width: 755px !important;
      }

      ::v-deep .el-input-group__append,
      .el-input-group__prepend {
        width: 84px;
        background: none;
        border: 1px solid #7d7f8b;
        border-left: none;
        text-align: center;
        padding: 0;
      }

      .icon {
        margin-right: 6px;
      }
    }
  }

  .content_btm {
    margin-top: 16px;

    p {
      margin-bottom: 0;
    }
  }
}

.dropdowndiv {
  ::v-deep .popper__arrow {
    left: 80px !important;
  }
}

.dialogformStyleClass {
  .languageClass {
    display: flex;

    .selectclass {
      ::v-deep .el-input__inner {
        width: 400px !important;
      }

      ::v-deep .el-input__icon {
        line-height: 32px;
      }
    }

    .languageClass_right {
      margin-left: 40px;
    }
  }
}

.step1Center_left {
  .el-radio-button__inner,
  .el-radio-group {
    display: flex;
    flex-direction: column;
  }

  .el-radio.is-bordered {
    width: 400px;
    margin-top: 6px;
  }
}

.form3_top {
  .setSelectClass {
    ::v-deep .el-input__inner {
      width: 480px !important;
    }

    ::v-deep .el-input__icon {
      line-height: 32px;
    }
  }
}

.tabledropdowndiv {
  max-height: 210px;
  overflow-y: auto;
}
</style>
