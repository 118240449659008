<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    append-to-body
    :width="'888px'"
    :top="'3%'"
    center
    :close-on-click-modal="false"
    @close="closeDialog"
  >
    <!--    第一步-->
    <div v-if="step === 1" class="step1Center">
      <div class="step1Center_top">
        <div class="radioClass step1Center_left">
          <div class="verification">
            <!-- Task workflow -->
            <p>{{ $t("CommonName.Taskworkflow.name") }}</p>
            <svg-icon
              icon-class="presentation"
              class="verificationIcon"
              v-if="form.radio1 === '-1'"
            ></svg-icon>
          </div>
          <el-radio-group v-model="form.radio1" @change="radiochange1">
            <el-radio
              v-for="i in radioList1"
              :key="i.label"
              :label="i.label"
              border
              >{{ i.name }}</el-radio
            >
          </el-radio-group>
        </div>
        <div class="radioClass step1Center_right">
          <div class="verification">
            <!-- PreprocessOptions -->
            <p>{{ $t("CommonName.PreprocessOptions.name") }}</p>
            <svg-icon
              icon-class="presentation"
              class="verificationIcon"
              v-if="form.radio2 === '-1'"
            ></svg-icon>
          </div>
          <el-radio-group v-model="form.radio2">
            <el-radio
              v-for="i in radioList2"
              :key="i.label"
              :label="i.label"
              border
              >{{ i.name }}</el-radio
            >
          </el-radio-group>
        </div>
      </div>
      <div
        class="step1Center_center radioClass"
        v-if="form.radio2 === '0'"
        v-show="info.role === 3"
      >
        <!-- "ASR Engine" -->
        <p>{{ $t("CommonName.ASREngine.name") }}</p>
        <el-radio-group v-model="form.radio3">
          <el-radio
            v-for="i in radioList3"
            :key="i.label"
            :label="i.label"
            :disabled="i.disabled"
            border
            >{{ i.name }}</el-radio
          >
        </el-radio-group>
      </div>
      <div class="step1Center_bottom">
        <div class="step1Center_left">
          <div class="verification">
            <!-- Source language-->
            <p>{{ $t("CommonName.Sourcelanguage") }}</p>
            <svg-icon
              icon-class="presentation"
              class="verificationIcon"
              v-if="form.select1 == null"
            ></svg-icon>
          </div>
          <el-select v-model="form.select1" class="selectclass" filterable>
            <el-option
              v-for="item in setLanguage"
              :key="item.language_id"
              :label="item.cname"
              :value="item.language_id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="step1Center_right" v-if="form.radio1 !== '0'">
          <div class="verification">
            <!-- Target language-->
            <p>{{ $t("CommonName.Targetlanguage") }}</p>
            <svg-icon
              icon-class="presentation"
              class="verificationIcon"
              v-if="form.select2 == null"
            ></svg-icon>
          </div>
          <el-select v-model="form.select2" class="selectclass" filterable>
            <el-option
              v-for="item in languageoptions"
              :key="item.language_id"
              :label="item.cname"
              :value="item.language_id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div
        class="step1Center_center checkboxClass"
        v-show="['1', '2', '3', '4'].includes(form.radio1)"
      >
        <!-- "AIT" -->
        <el-checkbox v-model="AITCheck" border style="width: 400px">{{
          $t("CommonName.AITText")
        }}</el-checkbox>
      </div>
    </div>
    <div v-if="step === 2" class="step2Center">
      <div class="radioClass content_1">
        <!--        多媒体文件选择-->
        <p>{{ $t("ExternalCreateTask.Sourceofmediafile.name") }}</p>
        <el-radio-group v-model="form.radio">
          <el-radio
            v-for="i in radioList"
            :key="i.label"
            :label="i.label"
            border
            >{{ i.name }}</el-radio
          >
        </el-radio-group>
      </div>
      <div class="content_center_1">
        <div class="uploadp">
          <p>{{ $t("PersonalEndLanguage.uploadFile") }}</p>
          <!--          <el-tooltip placement="top-start">-->
          <!--            <div slot="content">-->
          <!--              {{ $t("ProjectManagement.changeVideo4") }}<br />-->
          <!--              {{ $t("ProjectManagement.changeVideo3") }}<br />-->
          <!--              {{ $t("ProjectManagement.changeVideo6") }}<br />-->
          <!--              {{-->
          <!--                form.radio2 == "1" ? $t("ProjectManagement.changeVideo5") : ""-->
          <!--              }}-->
          <!--            </div>-->
          <!--            <svg-icon-->
          <!--              icon-class="presentation"-->
          <!--              class="icon"-->
          <!--              style="cursor: pointer"-->
          <!--            ></svg-icon>-->
          <!--          </el-tooltip>-->

          <svg-icon
            icon-class="presentation"
            class="verificationIcon"
            v-if="form.uploadinput1 == null"
          ></svg-icon>
        </div>
        <div class="tag_left_third">
          <el-upload
            class="upload-demo"
            drag
            ref="upload"
            :with-credentials="true"
            :action="$uploadURL + '/videojob/gateway'"
            :on-change="hardSubtitleRecognition_change_third"
            :on-exceed="hardSubtitleRecognition_Exceed_third"
            :auto-upload="false"
            :show-file-list="false"
            :limit="30"
            :file-list="form.hardSubtitleRecognition_fileList"
            multiple
          >
            <div
              class="uploaddiv"
              slot="trigger"
              @mouseenter="AudiotranscriptionClick_third"
            >
              <div
                v-if="form.hardSubtitleRecognition_fileList.length > 0"
                class="filelist_div"
              >
                <div
                  class="upload_table_div"
                  @click="
                    (e) => {
                      return e.stopPropagation();
                    }
                  "
                >
                  <div class="videoDatanamediv_header videoDatanamediv_header1">
                    <p style="" class="first_p">
                      <!--                              视频标题-->
                      {{ $t("PersonalEndLanguage.videoTitle") }}
                    </p>
                    <!--                    <p style="" class="last_p">-->
                    <!--                      &lt;!&ndash;                              源语言&ndash;&gt;-->
                    <!--                      {{ $t("MyFiles.sourceLanguage") }}-->
                    <!--                    </p>-->
                  </div>
                  <div class="videoDatanamediv_body_list">
                    <div
                      class="videoDatanamediv_body"
                      v-for="item in form.hardSubtitleRecognition_fileList"
                      :key="item.id"
                    >
                      <div class="left_div left_div1">
                        <div class="svgdivs">
                          <svg-icon icon-class="video" class="icon"></svg-icon>
                        </div>
                        <div
                          class="body_p"
                          :id="'tag' + item.id"
                          @mouseover="onShowNameTipsMouseenter(item)"
                        >
                          <el-tooltip
                            class="item"
                            effect="dark"
                            :content="item.name"
                            placement="top-start"
                            :disabled="!item.showdropdown"
                            :visible-arrow="false"
                          >
                            <span>{{ item.name }}</span>
                          </el-tooltip>
                        </div>
                      </div>
                      <!--                      <div class="right_select">-->
                      <!--                        <el-select-->
                      <!--                          v-model="item.videoLanguage"-->
                      <!--                          filterable-->
                      <!--                          :placeholder="-->
                      <!--                            $t('PersonalEndLanguage.PleaseselectSRTlanguage')-->
                      <!--                          "-->
                      <!--                          class="selectclass"-->
                      <!--                          @change="-->
                      <!--                            () => {-->
                      <!--                              $forceUpdate();-->
                      <!--                            }-->
                      <!--                          "-->
                      <!--                        >-->
                      <!--                          <el-option-->
                      <!--                            v-for="items in setLanguage"-->
                      <!--                            :key="items.language_id"-->
                      <!--                            :label="items.cname"-->
                      <!--                            :value="items.language_id"-->
                      <!--                          >-->
                      <!--                          </el-option>-->
                      <!--                        </el-select>-->
                      <!--                      </div>-->
                      <div class="last_delete last_delete1">
                        <el-button
                          type="text"
                          @click="
                            hardSubtitleRecognitionList_Delete_Click(
                              $event,
                              item
                            )
                          "
                        >
                          <svg-icon icon-class="delete" class="icon"></svg-icon>
                        </el-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="p_div">
                  <i class="el-icon-plus"></i>
                  <p style="color: #2f9efd">
                    <!--                            点击选择或拖动视频到这里-->
                    {{ $t("PersonalEndLanguage.DragVideo") }}
                  </p>
                </div>
              </div>
              <div v-else>
                <i class="el-icon-plus"></i>
                <p class="topp">
                  {{ $t("PersonalEndLanguage.DragVideo") }}
                </p>
                <p class="botp">
                  {{
                    $t("PersonalEndLanguage.SupportsMP4formatvideo") +
                    "≤" +
                    info.video_dubbing_filesize_limit +
                    "GB，" +
                    $t("PersonalEndLanguage.duration") +
                    "≤"
                  }}
                  <span>{{ info.video_dubbing_duration_limit }}</span>
                  {{
                    $t("PersonalEndLanguage.minute") +
                    $t("PersonalEndLanguage.moreLangth2")
                  }}
                </p>
              </div>
            </div>
          </el-upload>
        </div>
      </div>
      <!-- Select project  下拉框-->
      <div
        class="radio_content_2"
        v-if="form.radio === '0'"
        v-show="info.role === '3'"
      >
        <p>{{ $t("BatchCreationTask.OSSMode") }}</p>
        <el-select v-model="form.select3" class="selectclass" placeholder="">
          <el-option
            v-for="item in selectList3"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <!-- =========== Speech transcription deadline  step2time1,2-->
      <div
        class="content_center_footer"
        v-if="['0', '1', '4'].includes(form.radio1)"
      >
        <div class="content__footer_left">
          <p>{{ $t("CommonName.Speechtranscriptiondeadline") }}</p>
          <el-date-picker
            v-model="form.step2time1"
            type="datetime"
            :format="time_format"
            value-format="yyyy-MM-dd HH:mm"
            class="selectclass"
          >
          </el-date-picker>
        </div>
        <!--         转写任务处理人 -->
        <div class="content__footer_right">
          <p>
            {{
              $t("ExternalCreateTask.Taskprocessor") +
              " " +
              $t("CommonName.optional")
            }}
          </p>
          <el-select
            v-model="form.select8"
            filterable
            clearable
            class="selectclass"
          >
            <el-option
              v-for="item in select2List"
              :key="item.member_id"
              :label="item.nickname"
              :value="item.member_id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div
        class="content_center_footer"
        v-if="['1', '2', '3', '4'].includes(form.radio1)"
      >
        <div class="content__footer_left">
          <!-- Subtitle translation deadline -->
          <p>{{ $t("CommonName.Subtitletranslationdeadline") }}</p>
          <el-date-picker
            v-model="form.step2time2"
            type="datetime"
            :format="time_format"
            value-format="yyyy-MM-dd HH:mm"
            class="selectclass"
          >
          </el-date-picker>
        </div>
        <!-- 翻译任务处理人 -->
        <div class="content__footer_right">
          <p>
            {{
              $t("ExternalCreateTask.Translator") +
              " " +
              $t("CommonName.optional")
            }}
          </p>
          <el-select
            v-model="form.select9"
            filterable
            clearable
            class="selectclass"
          >
            <el-option
              v-for="item in select2List"
              :key="item.member_id"
              :label="item.nickname"
              :value="item.member_id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <!-----------------------------Subtitle Review Deadline------step2time3  -->
      <div
        v-if="['3', '4'].includes(form.radio1)"
        class="content_center_footer"
      >
        <div class="content__footer_left">
          <!-- Subtitle review deadline-->
          <div class="verification">
            <p>{{ $t("CommonName.Subtitlereviewdeadline") }}</p>
            <svg-icon
              icon-class="presentation"
              class="verificationIcon"
              v-if="form.step2time3 == null"
            ></svg-icon>
          </div>
          <el-date-picker
            v-model="form.step2time3"
            type="datetime"
            :format="time_format"
            value-format="yyyy-MM-dd HH:mm"
            class="selectclass"
          >
          </el-date-picker>
        </div>
        <!-- 校对任务处理人 -->
        <div class="content__footer_right">
          <p>
            {{
              $t("ExternalCreateTask.ProofreadTaskHandler") +
              " " +
              $t("CommonName.optional")
            }}
          </p>
          <el-select
            v-model="form.select10"
            filterable
            clearable
            class="selectclass"
          >
            <el-option
              v-for="item in select2List"
              :key="item.member_id"
              :label="item.nickname"
              :value="item.member_id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div
        class="content_center_footer"
        v-if="['1', '2', '3', '4'].includes(form.radio1)"
      >
        <!-- Temp TM T(optional)           select7 -->
        <div class="content__footer_left">
          <div class="verification">
            <p>{{ $t("CommonName.TempTMToptional") }}</p>
            <svg-icon
              icon-class="presentation"
              class="verificationIcon"
              v-if="form.select6 == null"
            ></svg-icon>
          </div>
          <el-select v-model="form.select6" class="selectclass">
            <el-option
              v-for="item in select6List"
              :key="item.tm_id"
              :label="item.name"
              :value="item.tm_id"
            >
            </el-option>
          </el-select>
        </div>
        <!-- Temp TM E(optional)           select7 -->
        <div
          class="content__footer_right"
          v-if="['3', '4'].includes(form.radio1)"
        >
          <div class="verification">
            <p>{{ $t("CommonName.TempTMEoptional") }}</p>
            <svg-icon
              icon-class="presentation"
              class="verificationIcon"
              v-if="form.select7 == null"
            ></svg-icon>
          </div>
          <el-select v-model="form.select7" class="selectclass">
            <el-option
              v-for="item in select6List"
              :key="item.tm_id"
              :label="item.name"
              :value="item.tm_id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer" style="margin-top: 24px">
      <div class="Previousbtn" v-if="step !== 1">
        <el-button @click="PreviousClick" type="info" class="setpadding16">
          <div class="btnicon">
            <svg-icon icon-class="Aright" class="icon"></svg-icon>
            <p class="footerleftbuttonp">{{ $t("CommonName.Previous") }}</p>
          </div>
        </el-button>
      </div>
      <div>
        <!--        取消-->
        <el-button @click="closeDialog" type="info" class="setpadding23"
          ><p class="footerleftbuttonp">
            {{ $t("CommonName.Cancel") }}
          </p></el-button
        >
        <!--        下一步-->
        <el-button type="primary" @click="NextClick" class="setpadding23">
          <p class="footerrightbuttonp2" style="">
            {{ $t("CommonName.Next") }}
          </p>
        </el-button>
      </div>
    </div>

    <!--      硬字幕识别-->
    <el-dialog
      :visible.sync="innerVisible"
      @close="closeinnerVisible(1)"
      custom-class="innerDialogClass"
      append-to-body
      width="888px"
      top="5%"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true"
      v-if="innerVisible"
    >
      <div slot="title" class="dialogTitle_div overflowEllipsis">
        {{ dialogtitle }}
      </div>
      <div class="innerDialog_div">
        <!--                      错误示例-->
        <div class="innerDialog_div_top_p">
          <p>
            {{ $t("PersonalEndLanguage.warningmessage35") }}
            <el-popover
              placement="bottom-end"
              trigger="hover"
              :visible-arrow="false"
              popper-class="innerDialogPopover"
            >
              <div class="innerDialogPopover_div">
                <div>
                  <p>
                    <svg-icon icon-class="icon_No" class="cuoWu"></svg-icon
                    >{{ $t("PersonalEndLanguage.ViewExamples1") }}
                  </p>
                  <el-image
                    :src="require('@/assets/imgs/cuoWu_shiLi.png')"
                    fit="fill"
                  ></el-image>
                  <p class="btmp" style="word-break: break-word">
                    {{ $t("PersonalEndLanguage.ViewExamples3") }}
                  </p>
                </div>
                <div>
                  <p>
                    <svg-icon icon-class="Yes" class="zhengQue"></svg-icon>
                    {{ $t("PersonalEndLanguage.ViewExamples2") }}
                  </p>
                  <el-image
                    :src="require('@/assets/imgs/zhengQue_shiLi.png')"
                    fit="fill"
                  ></el-image>
                  <p class="btmp" style="word-break: break-word">
                    {{ $t("PersonalEndLanguage.ViewExamples4") }}
                  </p>
                </div>
              </div>
              <el-button slot="reference" type="text">{{
                $t("PersonalEndLanguage.ViewExamples")
              }}</el-button>
            </el-popover>
          </p>
        </div>
        <!-- 视频展示 -->
        <div class="innerDialog_video_div" id="innerDialog_video_div">
          <video
            class="innerDialog_video_class"
            id="dialogVideo"
            ref="videoref"
            :src="videoUrl"
            type="video/mp4"
            oncontextmenu="return false"
          >
            <!-- <source /> -->
          </video>
          <canvas id="canvas" :width="1" :height="360"></canvas>
        </div>
        <!--        滑块-->
        <div class="sliderClass">
          <el-slider
            v-model="slidervalue"
            :show-tooltip="true"
            :step="step"
            @change="sliderInput"
            :max="silderMax"
            :marks="marks"
            :format-tooltip="formatTooltip"
          ></el-slider>
        </div>
        <div class="left_videobom_div left_videobom_div_play">
          <div class="left"></div>
          <div class="center">
            <!-- 回退 -->
            <div @click="rew" class="btndiv">
              <!-- ! todo -->
              <svg-icon icon-class="VideoBack" class="svgiconclass"></svg-icon>
            </div>
            <!-- 开始时间 -->
            <p>{{ videoStartTime }}</p>
            <!-- 暂停播放按钮 -->

            <div @click="playvideo" v-if="player" class="btndiv" id="step-5">
              <svg-icon icon-class="VideoPlay" class="svgiconclass"></svg-icon>
            </div>
            <div @click="closevideo" v-else class="btndiv" id="step-5">
              <svg-icon icon-class="VideoPause" class="svgiconclass"></svg-icon>
            </div>

            <!-- 结束时间 -->
            <p>{{ videolangtime }}</p>
            <!-- 前进 -->
            <div @click="speek" class="btndiv">
              <!-- ! todo -->
              <svg-icon
                icon-class="VideoForward"
                class="svgiconclass"
              ></svg-icon>
            </div>
          </div>
          <div class="right">
            <!-- 播放倍数 -->
            <!-- <el-dropdown @command="handleCommand" placement="top" trigger="click">
            <p
              class="el-dropdown-link"
              style="text-align: end; cursor: pointer"
            >
              {{ ds + "X" }}
            </p>
            <el-dropdown-menu slot="dropdown" :append-to-body="false">
              <el-dropdown-item command="0.5">0.5X</el-dropdown-item>
              <el-dropdown-item command="0.75">0.75X</el-dropdown-item>
              <el-dropdown-item command="1">1X</el-dropdown-item>
              <el-dropdown-item command="1.25">1.25X</el-dropdown-item>
              <el-dropdown-item command="1.5">1.5X</el-dropdown-item>
              <el-dropdown-item command="1.75">1.75X</el-dropdown-item>
              <el-dropdown-item command="2">2X</el-dropdown-item>
              <el-dropdown-item command="2.5">2.5X</el-dropdown-item>
              <el-dropdown-item command="3">3X</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
          </div>
        </div>
        <!--         时间-->
        <div class="time_div">
          <div class="start_time_div">
            <div>
              <!--                片头时间点-->
              <span>{{ $t("PersonalEndLanguage.openingTime") }}</span>
              <el-input
                v-model="startTime"
                placeholder="00:00:00"
                clearable
                suffix-icon="el-icon-time"
                @clear="clearInput(0)"
                @blur="inputBlur(0)"
              >
              </el-input>
            </div>
            <div
              class="icon_div"
              @click="timeIconClick(0, videoDom.currentTime)"
            >
              <i class="el-icon-location icon"></i>
            </div>
          </div>
          <div class="end_time_div">
            <div>
              <!--                片尾时间点-->
              <span>{{ $t("PersonalEndLanguage.endingTime") }}</span>
              <el-input
                v-model="endTime"
                placeholder="00:00:00"
                clearable
                suffix-icon="el-icon-time"
                @clear="clearInput(1)"
                @blur="inputBlur(1)"
              >
              </el-input>
            </div>
            <div
              class="icon_div"
              @click="timeIconClick(1, videoDom.currentTime)"
            >
              <i class="el-icon-location icon1"></i>
            </div>
          </div>
        </div>
        <p class="tips_p">
          <!--          您可以手动选择要识别的片头时间点和片尾时间，选择后将仅对所选时间范围内进行硬字幕识别。-->
          {{ $t("PersonalEndLanguage.ViewExamples7") }}
        </p>
      </div>
      <!--      底部 按钮-->
      <div slot="footer" class="dialog-footer" style="margin-top: 24px">
        <el-button
          type="info"
          @click="closeinnerVisible(2)"
          class="setpadding23"
          >{{
            form.hardSubtitleRecognition_fileList.length > 1
              ? row_id === 0
                ? $t("CommonName.Previous")
                : $t("CommonName.Prev")
              : $t("CommonName.Previous")
          }}</el-button
        >
        <el-button
          type="primary"
          @click="SureClick"
          class="setpadding23"
          :loading="buttonLoading"
          >{{
            form.hardSubtitleRecognition_fileList.length > 1
              ? row_id + 1 === form.hardSubtitleRecognition_fileList.length
                ? $t("CommonName.OK")
                : $t("CommonName.Nexts")
              : $t("CommonName.OK")
          }}</el-button
        >
      </div>
      <div
        class="progressdiv"
        v-if="
          form.hardSubtitleRecognition_fileList.length > 0 &&
          totalProgress > 0 &&
          totalProgress < 100
        "
        v-loading.fullscreen.lock="loading"
        element-loading-custom-class="disaplaynoneicon"
        element-loading-background="rgba(0, 0, 0, 0.2)"
      >
        <el-progress
          type="circle"
          :percentage="totalProgress"
          :stroke-width="10"
        ></el-progress>
      </div>
    </el-dialog>
  </el-dialog>
</template>
<script>
import { hideLoading, showLoading } from "@/utils/loading";
import {
  get_tm_list, //获取TM列表
  get_oss_list,
  get_ocr_last_job,
  create_job_file_ocr,
  create_video_dubbing_job,
  get_member_list,
} from "@/utils/https";
import { getCurrentTime, TimeHandle } from "@/api/setTime";
import { onShowNameTipsMouseenter } from "@/assets/js/Publicmethods";
import { fabric } from "fabric";
import { getTimeString } from "@/utils/tools";
export default {
  props: {
    showdialog: {
      type: Boolean,
      default: false,
    },
    languageoptions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    /**
     * 过滤 硬字幕识别 源语言
     * @returns {*[]}
     */
    setLanguage() {
      return this.languageoptions.filter((i) => {
        return this.HardlanguageList.includes(i.language_id);
      });
    },
    /**
     * 上传 进度
     * @returns {number}
     */
    totalProgress() {
      const total = this.form.hardSubtitleRecognition_fileList.reduce(
        (acc, progressobj) => acc + progressobj.percentage,
        0
      );
      const total1 = this.form.hardSubtitleRecognition_fileList.reduce(
        (acc, progressobj) => acc + progressobj.size,
        0
      );
      // console.log(Math.floor((total / total1) * 100), "111111");
      return Math.floor((total / total1) * 100);
    },
  },
  data() {
    return {
      innerVisible: false, //内部弹窗 开关
      dialogtitle: "", //内部弹窗 文件名称
      buttonLoading: false, // 内部弹窗 确定创建任务 按钮loading
      row_id: 0, //当前 展示得 视频
      info: {}, // 获取得 账号信息
      loading: false, //点击上传 获取上传进度 loading
      dialogVisible: false,
      title: "", //弹窗名称
      step: 1, //步骤
      project_id: "", // 任务id
      form: {
        radio: "0", //Source of Media File
        radio1: "", //Task workflow
        radio2: "1", //Preprocess Options
        radio3: "1", //ASR Engine
        uploadinput1: "", //Upload Media Zip File
        select1: "", //Source language
        select2: "", //Target language
        select3: "", //OSSMode
        select5: "", //Main TM (optional)
        select6: "", //Temp TM T(optional)
        select7: "", //Temp TM T(optional)
        select8: "", //转写任务处理人 转写任务执行人id
        select9: "", //翻译任务处理人 翻译任务执行人id
        select10: "", //校对任务处理人 校对任务执行人id
        step2time1: "", //Speech transcription deadline
        step2time2: "", //Subtitle translation deadline
        step2time3: "", //Subtitle Review Deadline
        hardSubtitleRecognition_fileList: [],
      },
      //ASR Engine
      radioList3: [
        {
          label: "1",
          name: this.$t("CommonName.ASREngine.label0"),
          disabled: false,
        },
        {
          label: "2",
          name: this.$t("CommonName.ASREngine.label1"),
          disabled: false,
        },
        {
          label: "3",
          name: this.$t("CommonName.ASREngine.label2"),
          disabled: false,
        },
      ],
      // 选项
      radioList2: [
        {
          label: "1",
          name: this.$t("CommonName.PreprocessOptions.label4"),
          disabled: false,
        },
      ],
      // 任务流程
      radioList1: [
        { label: "0", name: this.$t("CommonName.Taskworkflow.label0") },
        { label: "1", name: this.$t("CommonName.Taskworkflow.label1") },
        { label: "4", name: this.$t("CommonName.Taskworkflow.label4") },
      ],
      // 上传新文件
      radioList: [
        {
          label: "0",
          name: this.$t("ExternalCreateTask.Sourceofmediafile.label0"),
        },
      ],
      selectList3: [], //OSSMode
      select5List: [], //主TM
      select6List: [], //临时TM
      time_format:
        sessionStorage.getItem("lang") == "en"
          ? "MMM dd yyyy HH:mm"
          : "yyyy-MM-dd HH:mm",
      // 硬字幕识别 所需参数
      videoWidth: 0, //获取视频 原始高度
      videoUrl: "", //内部弹窗 中的视频
      videoHeight: 0, //获取视频原始 高度
      startTime: "", //片头时间点
      endTime: "", //片尾时间点
      old_time: "", //记录 片头时间
      old_time1: "", //记录 片尾事件
      Sliderstep: 0.01, //步长
      silderMax: 0, //总步长
      marks: {}, // 标记 片头时间点 片尾时间点
      canvas: null, //canvas
      rect: null, //创建矩形
      videoDom: {}, //创建 视频对象
      slidervalue: 0, //当前步
      formatmessage: true, //视频配音 上传文件 格式错误 提示标识
      sizemessage: true, //视频配音 上传文件 大小错误 提示标识
      langMessage: true, //视频配音 上传文件 名称长短 提示标识
      chunkSize: 20 * 1024 * 1024, // 每个分片大小为2MB
      select2List: [], // 成员列表 转写 翻译 校对 处理人
      HardlanguageList: [
        "2",
        "1",
        "3",
        "8",
        "9",
        "5",
        "11",
        "13",
        "15",
        "17",
        "24",
        "25",
      ], //硬字幕识别支持的源语言
      AITCheck: false,
      videoStartTime: "00:00:00.00",
      videolangtime: "00:00:00.00", //总时长
      player: true,
      ds: 1.0, // 倍数
    };
  },
  mounted() {
    this.dialogVisible = this.showdialog;
    this.title = this.$t("BatchCreateHardSubtitleRecognitionTasks.title1");
    this.info = JSON.parse(sessionStorage.getItem("info"));
    this.project_id = this.$route.query.id;
    window.addEventListener("keydown", this.windowkeydown, true);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.windowkeydown, true);
  },
  methods: {
    /**
     *
     * @param {*键盘 按下事件} e
     */
    windowkeydown(e) {
      const nodeName = e.target.nodeName;
      //空格
      if (e.keyCode == 0 || e.keyCode == 32) {
        if (
          (nodeName && nodeName.toUpperCase() == "INPUT") ||
          (nodeName && nodeName.toUpperCase() == "TEXTAREA")
        ) {
          return;
        }
        e.preventDefault();
        if (this.player) {
          this.playvideo();
        } else {
          this.closevideo();
        }
      }
    },
    // 弹窗 food 按钮方法
    /**
     * 关闭弹窗
     */
    closeDialog() {
      this.dialogVisible = false;
      this.$emit("claseHardSubtitleRecognitiondialog", this.dialogVisible);
    },
    /**
     * 上一步
     * @constructor
     */
    PreviousClick() {
      if (this.step === 3) {
        this.step = 2;
        this.title = this.$t("ExternalCreateTask.title3");
      } else if (this.step === 2) {
        this.step = 1;
        this.title = this.$t("BatchCreateHardSubtitleRecognitionTasks.title1");
      }
    },
    /**
     * 下一步
     * @constructor
     */
    NextClick() {
      if (this.step === 1) {
        this.form.radio1 = this.form.radio1 || "-1";
        this.form.select1 = this.form.select1 || null;
        if (this.form.radio1 === "-1") {
          this.$messages("warning", this.$t("CommonName.selectMessage"));
          return;
        }
        if (this.form.radio1 !== "0") {
          if (!this.form.select1 || !this.form.select2) {
            this.$messages("warning", this.$t("CommonName.selectMessage"));
            return;
          }
          if (this.form.select1 === this.form.select2) {
            this.$messages("warning", this.$t("CommonName.selectMessage1"));
            return;
          }
        } else {
          if (!this.form.select1) {
            this.$messages("warning", this.$t("CommonName.selectMessage"));
            return;
          }
        }

        this.title = this.$t("ExternalCreateTask.title3");
        this.get_oss_list();
        this.form.select5 = "";
        this.form.select6 = "";
        this.form.select7 = "";
        this.form.step2time1 = getCurrentTime("0");
        this.form.step2time2 = getCurrentTime("1");
        this.form.step2time3 = getCurrentTime("2");
        this.get_member_list(2);
        if (this.form.radio1 !== "0") {
          this.get_tm_list("2");
        }
        this.step = 2;
      } else if (this.step === 2) {
        let video = null;
        if (this.form.hardSubtitleRecognition_fileList.length === 0) {
          this.$messages("warning", this.$t("CommonName.warningmessage5"));
          return;
        }
        // if (
        //   this.form.hardSubtitleRecognition_fileList.some((obj) => {
        //     return (
        //       obj.videoLanguage === null ||
        //       obj.videoLanguage === undefined ||
        //       obj.videoLanguage === ""
        //     );
        //   })
        // ) {
        //   this.$messages(
        //     "warning",
        //     this.$t("PersonalEndLanguage.warningmessage34")
        //   );
        //   return;
        // }
        this.form.hardSubtitleRecognition_fileList.forEach((i, index) => {
          i.videoHeight = 0;
          i.videoWidth = 0;
          i.slidervalue = 0;
          i.startTime = "";
          i.endTime = "";
          i.marks = {};
          i.rect = null;
          i.canvas = null;
          i.position = {};
          i.new_id = index;
        });
        showLoading(this.$t("PersonalEndLanguage.loadingMessage"));
        get_ocr_last_job({ action: "get_ocr_last_job" }).then((res) => {
          if (res) {
            this.initHardSubtitleRecognition();
            this.form.hardSubtitleRecognition_fileList.forEach((i) => {
              i.position = res.position;
            });
            // this.hardSubtitleRecognition_fileList[0].position = res.position;
            this.innerVisible = true;
            this.dialogtitle =
              this.form.hardSubtitleRecognition_fileList[0].name;
            this.videoUrl = URL.createObjectURL(
              this.form.hardSubtitleRecognition_fileList[0].raw
            );
            // video = document.createElement("video");
            // video.src = this.videoUrl;
            // document.body.appendChild(video);
            // video.onloadedmetadata = () => {
            //   this.videoHeight = video.videoHeight;
            //   this.videoWidth = video.videoWidth;
            // };
            // document.body.removeChild(video);
            // console.log(window.devicePixelRatio, " * window.screen.availHeight");
            this.windowdev = window.devicePixelRatio;
            // console.log(this.windowdev);
            this.initVideo(
              this.form.hardSubtitleRecognition_fileList[0].position
            );
            hideLoading();
          }
        });
      }
    },
    /**
     * 初始化 硬字幕识别 弹窗内容
     * @param data
     */
    initHardSubtitleRecognition() {
      this.videoHeight = 0;
      this.videoWidth = 0;
      this.slidervalue = 0;
      this.startTime = "";
      this.endTime = "";
      this.marks = {};
      this.rect = null;
      this.canvas = null;
    },
    /**
     * 时间转换
     * @param time
     * @returns {string}
     */
    formatTime(time) {
      const milliseconds = parseInt(time * 1000);
      const date = new Date(milliseconds);
      const hours = date.getUTCHours().toString().padStart(2, "0");
      const minutes = date.getUTCMinutes().toString().padStart(2, "0");
      const seconds = date.getUTCSeconds().toString().padStart(2, "0");
      return `${hours}:${minutes}:${seconds}`;
    },
    /**
     *
     * @param time
     * @returns {string}
     */
    formattedTime(time) {
      const [hours, minutes, seconds] = time.split(":").map(Number);
      const totalSeconds = hours * 3600 + minutes * 60 + seconds;
      return totalSeconds; // 返回总秒数
    },
    /**
     * 初始化 视频
     * @param data
     */
    initVideo(obj, row = {}) {
      this.$nextTick(() => {
        // this.videoDom = null;
        this.videoDom = document.getElementById("dialogVideo"); // 获取视频dom元素
        var v = this.$refs.videoref;
        let isCurrentMetadataLoaded = false;
        this.videoDom.addEventListener("loadedmetadata", () => {
          if (!isCurrentMetadataLoaded) {
            this.marks = {};
            this.silderMax = this.videoDom.duration;
            this.videoWidth = Number(
              (
                this.videoDom.videoWidth /
                Number((this.videoDom.videoHeight / v.clientHeight).toFixed(2))
              ).toFixed(2)
            );
            this.videoHeight = v.clientHeight;
            console.log(this.videoHeight, "this.videoHeight", this.videoWidth);
            console.log(row.id, 1111);
            if (row.id) {
              if (Object.keys(row.marks).length === 0) {
                this.marks[this.silderMax] = this.setObjData(2, this.silderMax);
              } else {
                this.marks = row.marks;
              }
              if (!row.endTime) {
                this.endTime = this.formatTime(this.silderMax);
              } else {
                this.endTime = row.endTime;
              }
              this.startTime = row.startTime;
            } else {
              this.startTime = "";
              this.endTime = this.formatTime(this.silderMax);
              this.marks[this.silderMax] = this.setObjData(2, this.silderMax);
            }
            this.marks = Object.assign({}, this.marks);
            this.initCanvas(obj); //创建canvas
            isCurrentMetadataLoaded = true;
          }
        });
        this.videoDom.ontimeupdate = () => {
          // 播放走动时，实时获取播放时间
          this.videoStartTime = TimeHandle(this.videoDom.currentTime);
          this.slidervalue = this.videoDom.currentTime;
        };
        // 播放 结束
        this.videoDom.addEventListener("ended", () => {
          this.player = true;
        });
        this.videoDom.addEventListener("error", () => {
          this.$messages("warning", this.$t("CommonName.videoWarning"));
        });
      });
    },
    /**
     *
     * @param {* 回退} data
     */
    rew() {
      this.videoDom.currentTime = this.videoDom.currentTime - 0.1;
      this.videoStartTime = TimeHandle(this.videoDom.currentTime);
    },
    /**
     *
     * @param {*播放} data
     */
    playvideo() {
      this.player = false;
      this.videoDom.play();
    },
    /**
     *
     * @param {* 暂停播放} data
     */
    closevideo() {
      this.player = true;
      this.videoDom.pause();
    },
    /**
     *
     * @param {* 快进} data
     */
    speek() {
      if (this.videoDom.currentTime == this.videoDom.duration) {
        return;
      }
      this.videoDom.currentTime = this.videoDom.currentTime + 0.1;
      this.videoStartTime = TimeHandle(this.videoDom.currentTime);
    },
    /**
     *
     * @param {* 调整播放速度} command
     */
    handleCommand(command) {
      this.ds = command;
      this.videoDom.playbackRate = command;
    },
    initCanvas(obj, row) {
      if (this.canvas) {
        this.canvas.setWidth(1);
        this.canvas.dispose();
        this.canvas = null;
      }
      this.canvas = new fabric.Canvas("canvas");
      this.canvas.setWidth(this.videoDom.clientWidth);
      this.canvas.wrapperEl.style.position = "absolute";
      // if (this.windowdev > 1) {
      //   this.canvas.clear();
      // }
      console.log(obj, "obj");
      this.createRect(obj);
    },
    /**
     *  创建 canvas 矩形
     * @param obj
     */
    createRect(obj) {
      let left = 0;
      let videoDom = {};
      let option = {
        top: 0,
        left: 0,
        width: 0,
        height: 0,
        // fill: "transparent", // 填充色：透明
        fill: "blue", // 填充色：透明
        // fill: "rgba(255,255,255,0.1)",
        stroke: "#46adff", // 边框颜色：黑色
        strokeWidth: 0.5, // 边框宽度
        // strokeUniform: true,
        cornerSize: 5,
        cornerColor: "#fff",
        lockRotation: true,
        strokeDashArray: [5, 7],
      };
      videoDom = this.videoDom;
      if (+obj.position_w + +obj.position_x > videoDom.clientWidth) {
        left = this.canvas.width / 2 - this.canvas.width / 4;
      }
      option.top = Object.values(obj).every((i) => +i == 0)
        ? this.canvas.height - 100
        : Number(obj.position_y);
      option.left = Object.values(obj).every((i) => +i == 0)
        ? this.canvas.width / 2 - this.canvas.width / 4
        : +obj.position_w + +obj.position_x > videoDom.clientWidth
        ? this.canvas.width / 2 - this.canvas.width / 4
        : Number(obj.position_x);
      option.width = Object.values(obj).every((i) => +i == 0)
        ? this.canvas.width / 2
        : +obj.position_w > videoDom.clientWidth
        ? this.canvas.width / 2
        : Number(obj.position_w);
      option.height = Object.values(obj).every((i) => +i == 0)
        ? 40
        : Number(obj.position_h);
      console.log("option", option);
      // 矩形对象
      this.rect = new fabric.Rect(option);
      this.canvas.add(this.rect);
      const mask = new fabric.Rect({
        left: 0,
        top: 0,
        width: this.canvas.width,
        height: this.canvas.height,
        // backgroundVpt: false,
        // height: 360,
        fill: "rgba(0,0,0,0.4)",
        // fill: "rgba(255,255,255,0.5)",
        selectable: false,
      });

      // 将矩形添加到画布上

      this.canvas.add(mask);
      // mask.globalCompositeOperation = "source-over";
      // mask.globalCompositeOperation = "destination-out";
      this.rect.globalCompositeOperation = "destination-out";
      // this.rect.globalCompositeOperation = "source-over";
      this.canvas.bringToFront(this.rect);
      this.canvas.setActiveObject(this.rect);
      this.canvas.selection = true; // 允许框选
      this.canvas.selectionColor = "rgba(100, 100, 255, 0.3)"; // 选框填充色：半透明的蓝色
      this.canvas.selectionBorderColor = "rgba(255, 255, 255, 0.3)"; // 选框边框颜色：半透明灰色
      this.canvas.skipTargetFind = false; // 允许选中
      this.canvas.on("selection:cleared", () => {
        this.canvas.setActiveObject(this.rect);
      });
      // 监听 矩形移动事件
      this.canvas.off("object:moving").on("object:moving", (e) => {
        this.isMoveLeft = true;
        this.isMoveTop = true;
        var target = e.target;
        var canvasWidth = this.canvas.width;
        var canvasHeight = this.canvas.height;
        var left = target.left;
        var top = target.top;
        var maxLeft = canvasWidth - target.width * target.scaleX;
        var maxTop = canvasHeight - target.height * target.scaleY;
        if (left < 0) {
          target.left = 3;
        } else if (left > maxLeft) {
          target.left = maxLeft - 3;
        }
        if (top < 0) {
          target.top = 3;
        } else if (top > maxTop) {
          target.top = maxTop - 3;
        }
      });
      this.rect.off("scaling").on("scaling", (e) => {
        var rect = e.transform.target;
        // 获取矩形的当前位置和大小
        var left = rect.left;
        var top = rect.top;
        var width = rect.width * rect.scaleX;
        var height = rect.height * rect.scaleY;
        // 获取画布的尺寸
        var canvasWidth = this.canvas.width;
        var canvasHeight = this.canvas.height;

        // 计算矩形边界
        var right = left + width;
        var bottom = top + height;
        // 检查是否超出画布边界，如果是，则限制大小

        if (height < 10) {
          rect.scaleY = 10 / rect.height;
        }
        if (width < 10) {
          rect.scaleX = 10 / rect.width;
        }
        if (left > 0 && right < canvasWidth) {
          this.isMoveLeft = true;
        }
        if (top > 0 && bottom < canvasHeight) {
          this.isMoveTop = true;
        }
        if (left < 0) {
          rect.left = 3;
          if (this.isMoveLeft) {
            rect.scaleX = (width - 3 + left) / rect.width;
            this.maxScaleX = rect.scaleX;
            this.isMoveLeft = false;
          } else {
            // console.log(this.maxScaleX, "maxScaleX");
            rect.scaleX = this.maxScaleX;
          }
        }

        if (right > canvasWidth) {
          rect.scaleX = (canvasWidth - 5 - left) / rect.width;
        }
        if (top < 0) {
          rect.top = 3;
          if (this.isMoveTop) {
            rect.scaleY = (height - 3 + top) / rect.height;
            this.maxScaleY = rect.scaleY;
            this.isMoveTop = false;
          } else {
            rect.scaleY = this.maxScaleY;
          }
        }
        if (bottom > canvasHeight) {
          rect.scaleY = (canvasHeight - 3 - top) / rect.height;
        }
      });
    },

    //   发送 请求 获取数据
    /**
     *
     * @param {*获取OSS节点列表信息} data
     */
    get_oss_list() {
      get_oss_list({
        action: "get_oss_list",
      }).then((res) => {
        if (!res) {
          return;
        }
        res.list.map((i) => {
          if (i.default == true) {
            this.form.select3 = i.id;
          }
        });
        this.selectList3 = res.list;
      });
    },
    /**
     *
     * @param {*获取TM列表} data
     */
    get_tm_list(data) {
      get_tm_list({
        action: "get_tm_list",
        type: data, //1主TM，2临时TM
        slang_id: this.form.select1,
        tlang_id: this.form.radio1 !== "0" ? this.form.select2 : "",
      }).then((res) => {
        if (!res) {
          return;
        }
        if (data == "1") {
          this.select5List = res.list;
        } else {
          this.select6List = res.list;
        }
      });
    },
    /**
     *
     * @param {*获取成员列表信息} data
     */
    get_member_list(data = 2) {
      get_member_list({
        action: "get_member_list",
        team_user_status: data,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.select2List = res.list;
      });
    },
    //   其他 方法
    /**
     * 溢出隐藏 划上显示
     * @param i
     */
    onShowNameTipsMouseenter(i) {
      this.$forceUpdate();
      onShowNameTipsMouseenter(i);
    },
    /**
     * 任务流程 切换
     * @param val
     */
    radiochange1(val) {
      if (val === "0") {
        this.form.select2 = "";
      }
    },
    /**
     * 设置批量上传 数据 上传之前 判断是否满足条件
     * @param file
     * @param arr
     * @returns {*|boolean}
     */
    setUploadListData_change(file, arr) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isLt2M =
        file.size / 1024 / 1024 / 1024 <
        parseFloat(this.info.video_dubbing_filesize_limit);
      const extension = testmsg.toLowerCase() === "mp4";
      if (!extension) {
        if (this.formatmessage) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage7")
          );
          this.formatmessage = false;
        }
        this.$refs.upload.clearFiles();
        return false;
      } else if (!isLt2M) {
        if (this.sizemessage) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage18") +
              this.info.video_dubbing_filesize_limit +
              "GB"
          );
          this.sizemessage = false;
        }
        this.$refs.upload.clearFiles();
        return false;
      } else if (file.name.length > 80) {
        if (this.langMessage) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage36")
          );
          this.langMessage = false;
        }
        this.$refs.upload.clearFiles();
        return false;
      } else {
        file.id = Math.random() + ".mp4";
        file.showdropdown = false;
        file.srtinput = "";
        file.videoLanguage = "";
        file.srturl = "";
        file.srttime = "";
        file.totalChunks = 0;
        file.currentChunk = 0;
        file.percentage = 0;
        file.independence_percentage = 0;
        arr.push(file);
        arr.sort((a, b) => {
          let nameA = a.name.toLowerCase();
          let nameB = b.name.toLowerCase();
          return nameA.localeCompare(nameB);
        });
      }
      return arr;
    },
    /**
     * 硬字幕识别 上传之前 验证是否满足上传条件  change 事件
     * @param file
     * @param fileList
     */
    hardSubtitleRecognition_change_third(file, fileList) {
      if (!fileList || !file) return;
      this.setUploadListData_change(
        file,
        this.form.hardSubtitleRecognition_fileList
      );
    },
    /**
     * 上传进度
     * @param file
     */
    uploadProgress(file) {
      console.log(file, "uploadProgress");
    },
    /**
     *  上传成功
     * @param response
     * @param file
     */
    uploadSuccess(response, file) {
      if (response.result == "SUCCESS") {
        console.log(response, "response");
      }
    },
    /**
     * 硬字幕识别 上传超出 数量限制 事件
     * @param filelist
     */
    hardSubtitleRecognition_Exceed_third(filelist) {
      filelist = this.form.hardSubtitleRecognition_fileList;
      this.$messages(
        "warning",
        this.$t("PersonalEndLanguage.warningmessage11")
      );
    },
    /**
     *  视频配音 多文件上传时 限制
     * @constructor
     */
    AudiotranscriptionClick_third() {
      this.formatmessage = true;
      this.sizemessage = true;
      this.langMessage = true;
    },
    /**
     * 硬字幕识别 删除 上传列表中的某条
     * @param e
     * @param item
     */
    hardSubtitleRecognitionList_Delete_Click(e, item) {
      this.form.hardSubtitleRecognition_fileList =
        this.setUploadListData_delete(
          this.form.hardSubtitleRecognition_fileList,
          item
        );
      e.stopPropagation();
    },
    /**
     * 设置批量上传 数据 删除
     * @param arr
     * @returns {*}
     */
    setUploadListData_delete(arr, item) {
      arr.map((i, index) => {
        if (i.id == item.id) {
          arr.splice(index, 1);
        }
      });
      return arr;
    },
    /**
     * 滑块滑动  修改视频时间
     * @param slider
     */
    sliderInput(slider) {
      this.slidervalue = slider;
      this.videoDom.currentTime = slider;
    },
    /**
     * 格式化显示 时间
     * @param val
     */
    formatTooltip(val) {
      return getTimeString(val, 1, 2).replace(".", ",");
    },
    /**
     * 清除 输入框里得内容
     * @param data
     */
    clearInput(data) {
      for (let Key in this.marks) {
        if (data == 0) {
          if (this.marks[Key].id == 1) {
            delete this.marks[Key];
          }
          this.old_time = "";
        } else {
          if (this.marks[Key].id == 2) {
            delete this.marks[Key];
          }
          this.old_time1 = "";
        }
      }
      this.marks = Object.assign({}, this.marks);
    },
    /**
     *  片头片尾 时间点 失去焦点
     * @param data
     */
    inputBlur(data) {
      let timeRegExp = /^([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/;
      if (data == 0) {
        if (!this.startTime) {
          return;
        }
        //判断时间格式是否正确的正则表达式
        if (!timeRegExp.test(this.startTime)) {
          this.$messages(
            "warning",
            this.$t("OverwriteOperation.warningmessage1")
          );
          return;
        }

        let time = this.formattedTime(this.startTime);
        if (this.endTime) {
          let time1 = this.formattedTime(this.endTime);
          if (time >= time1) {
            this.old_time = this.startTime;
            this.startTime = "";
            for (const marks in this.marks) {
              if (this.marks[marks].id == 1) {
                delete this.marks[marks];
              }
            }
            this.marks = Object.assign({}, this.marks);
            return;
          }
        }
        if (time > this.silderMax) {
          this.old_time = this.startTime;
          this.startTime = "";

          for (const marks in this.marks) {
            if (this.marks[marks].id == 1) {
              delete this.marks[marks];
            }
          }
          this.marks = Object.assign({}, this.marks);
          this.$messages("warning", this.$t("PersonalEndLanguage.timeMessage"));
          return;
        }
        this.old_time = this.startTime;
        this.timeIconClick(data, time);
      } else {
        if (!this.endTime) {
          return;
        }
        if (!timeRegExp.test(this.endTime)) {
          this.$messages(
            "warning",
            this.$t("OverwriteOperation.warningmessage1")
          );
          return;
        }
        let time = this.formattedTime(this.endTime);
        if (this.startTime) {
          let time1 = this.formattedTime(this.startTime);
          if (time <= time1) {
            this.old_time1 = this.endTime;
            this.endTime = "";
            for (const marks in this.marks) {
              if (this.marks[marks].id == 2) {
                delete this.marks[marks];
              }
            }
            this.marks = Object.assign({}, this.marks);
            return;
          }
        }
        if (time > this.silderMax) {
          this.old_time1 = this.endTime;
          this.endTime = "";
          for (const marks in this.marks) {
            if (this.marks[marks].id == 2) {
              delete this.marks[marks];
            }
          }
          this.marks = Object.assign({}, this.marks);
          this.$messages("warning", this.$t("PersonalEndLanguage.timeMessage"));
          return;
        }
        this.old_time1 = this.endTime;
        this.timeIconClick(data, time);
      }
    },
    /**
     * 点击 片头片尾 按钮  获取当前时间 赋值片头片尾时间
     * @param data 0：片头 1：片尾
     * @constructor
     */
    timeIconClick(data, time) {
      if (data == 0) {
        if (Object.keys(this.marks).length !== 0) {
          let obj = {};
          for (let Key in this.marks) {
            if (this.marks[Key].id === 2) {
              obj = this.marks[Key];
            }
          }
          if (time >= obj.time) {
            return;
          }

          for (let Key in this.marks) {
            if (this.marks[Key].id === 1) {
              delete this.marks[Key];
              this.marks[time] = this.setObjData(1, time);
            } else {
              if (this.marks[Key].id === 2) {
                if (time >= this.marks[Key].time) {
                  return;
                }
              }
              this.marks[time] = this.setObjData(1, time);
            }
          }
        } else {
          this.marks[time] = this.setObjData(1, time);
        }
        this.startTime = this.formatTime(time);
        this.marks = Object.assign({}, this.marks);
      } else {
        if (Object.keys(this.marks).length !== 0) {
          let obj = {};
          for (let Key in this.marks) {
            if (this.marks[Key].id === 1) {
              obj = this.marks[Key];
            }
          }
          if (time <= obj.time) {
            return;
          }
          for (let Key in this.marks) {
            if (this.marks[Key].id === 2) {
              delete this.marks[Key];
              this.marks[time] = this.setObjData(2, time);
            } else {
              if (this.marks[Key].id === 1) {
                if (time <= this.marks[Key].time) {
                  return;
                }
              }
              this.marks[time] = this.setObjData(2, time);
            }
          }
        } else {
          this.marks[time] = this.setObjData(2, time);
        }
        this.endTime = this.formatTime(time);
        this.marks = Object.assign({}, this.marks);
      }
    },
    /**
     * 往对象中添加 属性值
     * @param id
     */
    setObjData(id, time) {
      return {
        id: id,
        time: time,
        label: this.$createElement("i", {
          class: {
            "el-icon-location": true,
          },
          style: {
            transform: "rotate(180deg)",
            color: id === 1 ? "#98FF6C" : "#FFB655",
          },
        }),
      };
    },
    /**
     * 内部弹窗 关闭
     * @param data
     * @constructor
     */
    closeinnerVisible(data) {
      this.closevideo();
      // 上一步
      if (data === 1) {
        this.row_id = 0;
        this.innerVisible = false;
        this.closeCanvas();
      } else {
        if (this.form.hardSubtitleRecognition_fileList.length === 1) {
          this.row_id = 0;
          this.innerVisible = false;
          this.closeCanvas();
        } else {
          if (this.row_id - 1 < 0) {
            this.row_id = 0;
            this.innerVisible = false;
            this.closeCanvas();
            return;
          }
          // 上一个
          // 当最后一个为 列表的最后一个时 点击上一步 赋值开始结束时间标点
          // if (
          //   this.form.hardSubtitleRecognition_fileList[this.row_id].new_id ===
          //   this.form.hardSubtitleRecognition_fileList[
          //     this.form.hardSubtitleRecognition_fileList.length - 1
          //   ].new_id
          // ) {
          this.form.hardSubtitleRecognition_fileList[this.row_id].marks =
            this.marks;
          this.form.hardSubtitleRecognition_fileList[this.row_id].startTime =
            this.startTime;
          this.form.hardSubtitleRecognition_fileList[this.row_id].endTime =
            this.endTime;
          this.form.hardSubtitleRecognition_fileList[this.row_id].rect =
            this.rect;
          this.form.hardSubtitleRecognition_fileList[this.row_id].position =
            this.setPositions(
              this.form.hardSubtitleRecognition_fileList[this.row_id]
            );
          // }
          this.canvas.setWidth(1);
          this.canvas.dispose();
          let video = null;
          this.videoUrl = "";
          this.initHardSubtitleRecognition();
          if (this.form.hardSubtitleRecognition_fileList[this.row_id - 1]) {
            this.dialogtitle =
              this.form.hardSubtitleRecognition_fileList[this.row_id - 1].name;

            this.videoUrl = URL.createObjectURL(
              this.form.hardSubtitleRecognition_fileList[this.row_id - 1].raw
            );
            this.videoDom.removeEventListener("loadedmetadata", () => {});
            // video = document.createElement("video");
            // video.src = this.videoUrl;
            // document.body.appendChild(video);
            // video.onloadedmetadata = () => {
            //   this.videoHeight = video.videoHeight;
            //   this.videoWidth = video.videoWidth;
            // };
            // document.body.removeChild(video);
            this.windowdev = window.devicePixelRatio;
            let position =
              this.form.hardSubtitleRecognition_fileList[this.row_id - 1]
                .position;

            // 创建 video 与 canvas
            this.initVideo(
              position,
              this.form.hardSubtitleRecognition_fileList[this.row_id - 1]
            );
            this.row_id--;
          }
        }
      }
    },
    /**
     * 设置 矩形
     * @param obj
     */
    setPositions(obj) {
      let position = {};
      position.position_h = obj.rect.height * obj.rect.scaleY;
      position.position_w = obj.rect.width * obj.rect.scaleX;
      position.position_x = obj.rect.left;
      position.position_y = obj.rect.top;
      return position;
    },
    /**
     * 内部中得内部 弹窗 确定 按钮
     * @constructor
     */
    SureClick() {
      this.closevideo();
      let timeRegExp = /^([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/;
      if (this.startTime && !timeRegExp.test(this.startTime)) {
        return;
      }
      if (this.endTime && !timeRegExp.test(this.endTime)) {
        return;
      }
      let startTime = this.formattedTime(this.old_time);
      let endTime = this.formattedTime(this.old_time1);
      if (startTime && endTime) {
        if (startTime >= endTime) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.timeMessage1")
          );
          return;
        }
      }
      // console.log(startTime, this.silderMax);
      if (startTime > this.silderMax) {
        this.old_time = "";
        return;
      }

      if (endTime > this.silderMax) {
        this.old_time1 = "";
        return;
      }
      let obj = this.form.hardSubtitleRecognition_fileList[this.row_id];
      obj.endTime = this.endTime;
      obj.startTime = this.startTime;
      obj.marks = this.marks;
      obj.silderMax = this.silderMax;
      obj.rect = this.rect;
      obj.videoHeight = this.videoHeight;
      obj.videoWidth = this.videoWidth;
      obj.position = this.setPositions(obj);
      if (
        this.row_id + 1 ===
        this.form.hardSubtitleRecognition_fileList.length
      ) {
        // 创建任务
        this.buttonLoading = true;
        this.form.hardSubtitleRecognition_fileList.forEach((i) => {
          this.uploadList(i);
        });
        return;
      }
      // 下一个
      this.canvas.setWidth(1);
      this.canvas.dispose();
      let video = null;
      this.videoUrl = "";
      this.initHardSubtitleRecognition();
      if (this.form.hardSubtitleRecognition_fileList[this.row_id + 1]) {
        this.dialogtitle =
          this.form.hardSubtitleRecognition_fileList[this.row_id + 1].name;
        this.videoUrl = URL.createObjectURL(
          this.form.hardSubtitleRecognition_fileList[this.row_id + 1].raw
        );
        this.videoDom.removeEventListener("loadedmetadata", () => {});
        // video = document.createElement("video");
        // video.src = this.videoUrl;
        // document.body.appendChild(video);
        // video.onloadedmetadata = () => {
        //   this.videoHeight = video.videoHeight;
        //   this.videoWidth = video.videoWidth;
        // };
        // document.body.removeChild(video);
        this.windowdev = window.devicePixelRatio;
        let position = {};
        if (!this.form.hardSubtitleRecognition_fileList[this.row_id + 1].rect) {
          position = this.setPositions(
            this.form.hardSubtitleRecognition_fileList[this.row_id]
          );
        } else {
          position =
            this.form.hardSubtitleRecognition_fileList[this.row_id + 1]
              .position;
        }
        this.initVideo(
          position,
          this.form.hardSubtitleRecognition_fileList[this.row_id + 1]
        );
        this.row_id++;
      }
    },
    /**
     * 关闭遮罩
     * @param
     */
    closeCanvas() {
      this.canvas.setWidth(1);
      this.canvas.dispose();
      this.canvas = null;
      this.rect = null;
    },
    /**
     * 设置 this.form.radio1
     * @param data
     */
    setRadio1(data) {
      // " 014";
      let radio = "";
      radio = String(+data + 1);
      if (radio === "2") {
        radio = "3";
      }
      return radio;
    },
    /**
     * 上传文件 创建任务
     * @param obj
     */
    uploadList(obj) {
      obj.totalChunks = Math.ceil(obj.size / this.chunkSize);
      const start = obj.currentChunk * this.chunkSize;
      const end = Math.min(start + this.chunkSize, obj.raw.size);
      const chunk = obj.raw.slice(start, end);
      const formData = new FormData();
      formData.append("file", chunk);
      formData.append("totalChunks", obj.totalChunks);
      formData.append("currentChunk", obj.currentChunk);
      formData.append("media_file_name", obj.id);
      // 使用XMLHttpRequest手动上传分片
      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        `${this.$uploadURL}/videojob/fragment_upload_media_file`,
        true
      );
      xhr.withCredentials = true;
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const uploaded = start + event.loaded; // 已上传的大小
          const total = obj.size; // 文件总大小
          obj.independence_percentage = Math.floor((uploaded / total) * 100);
          obj.percentage = uploaded;
          this.loading = true;
        }
      };
      xhr.onerror = (error) => {
        this.$messages("error", this.$t("PersonalEndLanguage.errormessage"));
        obj.currentChunk = 0;
        obj.percentage = 0;
        this.percentage = 0;
        this.loading = false;
        this.buttonLoading = false;
        this.dialogAIVisible = false;
        this.dialogVisible = false;
        this.innerVisible = false;
      };
      xhr.onload = () => {
        if (xhr.status === 200) {
          obj.currentChunk++;
          if (obj.currentChunk < obj.totalChunks) {
            // 继续上传下一个分片
            this.uploadList(obj);
          } else {
            let res = JSON.parse(xhr.response);
            // console.log(res.savename, "文件上传完成！");
            const isAll = this.form.hardSubtitleRecognition_fileList.every(
              (obj) => obj.independence_percentage === 100
            );
            if (isAll) {
              this.loading = false;
              showLoading(this.$t("PersonalEndLanguage.loadingMessage"));
            }

            // obj.currentChunk = 0;
            // this.percentage = 0;
            // obj.percentage = 0;
            // this.loading = false;
            // console.log(id, res.savename);
            // showLoading("文件已上传完毕，正在解析中... ");
            if (res && res.savename !== "") {
              let num = obj.videoHeight / 360;
              let x = Math.ceil(obj.rect.left * num);
              let y = Math.ceil(obj.rect.top * num);
              let width = Math.ceil(obj.rect.width * obj.rect.scaleX * num);
              let height = Math.ceil(obj.rect.height * obj.rect.scaleY * num);
              create_job_file_ocr({
                action: "create_job_file_ocr",
                project_id: this.project_id,
                task_type: this.setRadio1(this.form.radio1), //	任务类型，1转写、2翻译、3转写+翻译，4翻译+校审，5转写+翻译+校审
                slang: this.form.select1,
                tlang: this.form.select2,
                mt_value: this.form.select5, //	主tm
                tt_value: this.form.select6, //临时tm
                te_value: this.form.select7, //临时tm review
                job_name: obj.name,
                savename: res.savename,
                oss_mode_type: this.form.radio == "0" ? this.form.select3 : "",
                date_dead_stt: ["1", "4"].includes(this.form.radio1)
                  ? this.form.step2time1
                  : "", //转写预计交付时间
                date_dead_tra: ["1", "4"].includes(this.form.radio1)
                  ? this.form.step2time2
                  : "", //翻译预计交付时间 按照任务类型 没有该节点就传空
                date_dead_rev: ["4"].includes(this.form.radio1)
                  ? this.form.step2time3
                  : "", //校审预计交付时间 按照任务类型 没有该节点就传空
                user_stt: this.form.select8, //转写任务执行人id
                user_tra: this.form.select9, //翻译任务执行人id
                user_rev: this.form.select10, //	校对任务执行人id
                position_x: x,
                position_y: y,
                position_w: width,
                position_h: height,
                position_original_x: obj.rect.left,
                position_original_y: obj.rect.top,
                position_original_w: obj.rect.width * obj.rect.scaleX,
                position_original_h: obj.rect.height * obj.rect.scaleY,
                time_start: obj.startTime ? obj.startTime : "",
                time_end: obj.endTime
                  ? obj.endTime
                  : obj.formatTime(obj.silderMax),
                original_width: obj.videoWidth,
                original_height: obj.videoHeight,
                ait_mode: this.AITCheck ? 1 : 0,
              })
                .then((res) => {
                  if (!res) {
                    this.buttonLoading = false;
                    return;
                  }
                  if (res.result == "SUCCESS") {
                    const isAll =
                      this.form.hardSubtitleRecognition_fileList.every(
                        (obj) => obj.independence_percentage === 100
                      );
                    if (isAll) {
                      hideLoading();
                      this.dialogVisible = false;
                      this.buttonLoading = false;
                      this.innerVisible = false;
                      this.$emit(
                        "CreatHardSubtitleRecognitionTasks",
                        this.dialogVisible
                      );
                    }
                  } else if (res.result == "LOGIN_FAILED") {
                    this.otherResult();
                  } else if (
                    res.result == "QUOTE_FAILED" ||
                    res.result == "SPACE_FAILED"
                  ) {
                    this.otherResult2(res);
                  } else {
                    this.otherResult1(res);
                  }
                })
                .catch((err) => {
                  this.$messages("error", err.msg);
                  this.dialogVisible = false;
                  this.buttonLoading = false;
                  this.innerVisible = false;
                  this.loading = false;
                });
            }
          }
        }
      };
      xhr.send(formData);
    },
  },
};
</script>
<style scoped lang="scss">
@import "./AllDialogradio.scss";
.step1Center {
  margin: 4px 24px 24px 24px;
  p {
    color: #adb0bc;
    font-size: 14px;
    margin-top: 20px;
  }
  .el-radio.is-bordered.is-disabled {
    border-color: #5b5d6c;
  }
  ::v-deep .el-radio__input.is-disabled + span.el-radio__label {
    color: #595d69 !important;
  }
  ::v-deep .el-radio__input.is-disabled .el-radio__inner {
    background: none;
    border-color: #595d69;
  }
  .step1Center_top {
    display: flex;
    .step1Center_left {
      .el-radio-button__inner,
      .el-radio-group {
        display: flex;
        flex-direction: column;
      }
      .el-radio.is-bordered {
        width: 400px;
        margin-top: 6px;
      }
    }
    .step1Center_right {
      margin-left: 40px;
      .el-radio-button__inner,
      .el-radio-group {
        display: flex;
        flex-direction: column;
      }
      .el-radio.is-bordered {
        width: 400px;
        margin-top: 6px;
      }
    }
  }

  .step1Center_center {
    .el-radio.is-bordered {
      width: 210px;
      margin-top: 6px;
    }
  }
  .step1Center_bottom {
    display: flex;
    .step1Center_right {
      margin-left: 40px;
    }
  }
}
.step2Center {
  margin: 4px 24px 24px 24px;
  p {
    color: #adb0bc;
    font-size: 14px;
    margin-top: 20px;
  }
  .content_1 {
    .el-radio.is-bordered {
      width: 200px;
      margin-top: 6px;
    }
  }
  .tag_left_third {
    margin-top: 6px;
    ::v-deep .el-upload-dragger {
      width: 840px !important;
      height: 200px !important;
    }
    .svgdivs {
      width: 48px;
      height: 48px;
      background-color: #474e61;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        width: 33px;
        height: 33px;
        color: #ffffff;
      }
    }

    .uploaddiv {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .el-icon-plus {
        font-size: 15.66px;
        color: #46adff;
      }

      .filelist_div {
        width: 100%;
        height: 100%;

        .upload_table_div {
          .widthClass {
            width: 240px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          height: 170px;
          width: 100%;

          .videoDatanamediv_header {
            display: flex;
            //padding-top: 15px;

            p {
              color: #adb0bc;
              font-size: 12px;
              width: 240px;
              text-align: left;
            }
          }
          .videoDatanamediv_header1 {
            p {
              margin-top: 6px;
            }
            .first_p {
              width: 500px;
              padding-left: 15px;
              box-sizing: border-box;
            }
            .last_p {
              width: calc(100% - 500px);
              padding-left: 30px;
              box-sizing: border-box;
            }
          }
          .videoDatanamediv_body_list {
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow-y: auto;
            height: calc(100% - 34px);

            .videoDatanamediv_body {
              display: flex;
              align-items: center;
              width: 820px;
              height: 48px;
              background: #474e61;
              border-radius: 4px;
              margin-bottom: 3px;

              // .svgdivs {
              //   //margin-left: 17px
              // }
              .left_div1 {
                width: 93%;
                .body_p {
                  width: 700px !important;
                  text-align: left;
                }
              }

              .last_delete1 {
                width: calc(100% - 500px - 240px) !important;
              }

              .left_div {
                @extend .widthClass;

                .body_p {
                  color: #ffffff;
                  font-size: 14px;
                  width: 220px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }

              .center_input {
                @extend .widthClass;
                margin-left: 20px;

                ::v-deep .el-input__inner {
                  width: 170px !important;
                }

                //::v-deep .el-input__suffix {
                //  right: 40px !important;
                //  top: 1px;
                //}
                ::v-deep .el-input-group__append,
                .el-input-group__prepend {
                  // right: 10px;
                  width: 70px;
                  background: #393c4e;
                  border: 1px solid #7d7f8b;
                  border-left: none;
                  text-align: center;
                  padding: 0;
                }
              }

              .right_select {
                @extend .widthClass;
                margin-left: 20px;

                .selectclass {
                  ::v-deep .el-input__inner {
                    width: 240px !important;
                  }
                }
              }

              .last_delete {
                width: 7%;
                .icon {
                  width: 18px;
                  height: 18px;
                }
              }
            }
          }
        }

        .p_div {
          display: flex;
          justify-content: center;
          align-items: center;
          height: calc(100% - 170px);

          p {
            margin-left: 10px;
            margin-top: 0px;
          }
        }
      }
    }
    .topp {
      color: #46adff;
      font-size: 14px;
      margin-top: 11.9px;
    }

    .botp {
      color: #787e83;
      font-size: 10px;
      margin-top: 8px;
      text-align: center;
    }
    ::v-deep .el-upload-dragger {
      background-color: inherit !important;
      width: 400px;
    }
  }
}
</style>
