<template>
  <div class="conten_div">
    <el-row v-for="i in activeUsers" :key="i.id" class="lirow">
      <el-col :span="10" class="li_col">
        <div class="col_div">
          <div class="key_div" :class="i.class ? i.class : ''">
            {{ i.key }}
          </div>
          <div class="center_div" v-if="i.key1">{{ " + " }}</div>
          <div class="key_div" v-if="i.key1">{{ i.key1 }}</div>
          <div class="center_div" v-if="i.key2">{{ " + " }}</div>
          <div class="key_div" v-if="i.key2">{{ i.key2 }}</div>
        </div>
      </el-col>
      <el-col :span="14" class="li_bottom_div">
        <p>{{ i.value }}</p>
        <p v-if="i.value1">{{ i.value1 }}</p>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  props: {
    canChange: {
      type: Boolean,
      default: true,
    },
    showwhat: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    activeUsers: function () {
      return this.contentData.filter(function (user) {
        return user.show; //返回Show=true的项，添加到activeUsers数组
      });
    },
  },
  data() {
    return {
      contentData: [
        {
          id: "0",
          key: this.canChange ? this.$t("Help.Spacebar") : "空格键",
          value: this.canChange ? this.$t("Help.SpacebarVal") : "播放&暂停",
          class: "spaceclass",
          show: [1, 2, 3, 4, 5].includes(this.showwhat) ? true : false,
        },
        {
          id: "1",
          key: "Ctrl",
          key1: "↑",
          value: this.canChange
            ? this.$t("Help.Gotolast")
            : "转至上一句段（非编辑状态）",
          show: [1, 2, 3, 4, 5].includes(this.showwhat) ? true : false,
        },
        {
          id: "2",
          key: "Ctrl",
          key1: "↓",
          value: this.canChange
            ? this.$t("Help.Gotonext")
            : "转至上一句段（非编辑状态）",
          show: [1, 2, 3, 4, 5].includes(this.showwhat) ? true : false,
        },
        {
          id: "3",
          key: "Ctrl",
          key1: "Enter",
          value: this.canChange
            ? this.$t("Help.SaveNext")
            : "保存并跳转一下句段（编辑状态）",
          show: [1, 2, 3, 4, 5].includes(this.showwhat) ? true : false,
        },
        {
          id: "4",
          key: "F8",
          value: this.canChange ? this.$t("Help.Playcurrent") : "播放当前句",
          show: [1, 2, 3, 4, 5].includes(this.showwhat) ? true : false,
        },
        {
          id: "5",
          key: "Ctrl",
          key1: "Delete",
          value: this.canChange
            ? this.$t("Help.Deleteselected")
            : "删除选中句段",
          show: [1, 2, 3, 4, 5].includes(this.showwhat) ? true : false,
        },
        {
          id: "6",
          key: "Ctrl",
          key1: "M",
          value: this.canChange
            ? this.$t("Help.MachineTranslation")
            : "机器翻译",
          show: [1, 3, 4].includes(this.showwhat) ? true : false,
        },
        {
          id: "7",
          key: "W",
          value: this.canChange
            ? this.$t("Help.Copycurrenttimestamp")
            : "复制播放时间到平移时间轴",
          show: [2, 4, 5].includes(this.showwhat) ? true : false,
        },
        {
          id: "8",
          key: "Alt",
          key1: "S",
          value: this.canChange
            ? this.$t("Help.Splitsegment")
            : "切分句段（编辑状态）",
          show: [1, 2, 3, 4, 5].includes(this.showwhat) ? true : false,
        },
        {
          id: "9",
          key: "J",
          value: this.canChange
            ? this.$t("Help.Creattimecodes")
            : "长按J快捷添加句段（非编辑状态）",
          value1: this.canChange
            ? this.$t("Help.Creattimecodes1")
            : "按下记录开始时间，抬起记录结束时间并生成句段",
          show: [1, 2, 3, 4, 5].includes(this.showwhat) ? true : false,
        },
        {
          id: "10",
          key: "S",
          value: this.canChange
            ? this.$t("Help.Setstarttime")
            : "设置时间轴开始时间（选中句段状态）",
          show: [1, 2, 3, 4, 5].includes(this.showwhat) ? true : false,
        },
        {
          id: "11",
          key: "E",
          value: this.canChange
            ? this.$t("Help.Setendtime")
            : "设置时间轴结束时间（选中句段状态）",
          show: [1, 2, 3, 4, 5].includes(this.showwhat) ? true : false,
        },
        {
          id: "12",
          key: "Ctrl",
          key1: "B",
          value: this.canChange ? this.$t("SubtitleStyle.Bold") : "加粗",
          show: true,
        },
        {
          id: "13",
          key: "Ctrl",
          key1: "I",
          value: this.canChange ? this.$t("SubtitleStyle.Italic") : "斜体",
          show: true,
        },
        {
          id: "14",
          key: "Alt",
          key1: "U",
          value: this.canChange ? this.$t("SubtitleStyle.Underline") : "下划线",
          show: false,
        },
        {
          id: "15",
          key: "Alt",
          key1: "N",
          value: this.canChange
            ? this.$t("SubtitleStyle.MusicSign")
            : "小音乐符号",
          show: false,
        },
        {
          id: "16",
          key: "Alt",
          key1: "M",
          value: this.canChange
            ? this.$t("SubtitleStyle.MusicSign1")
            : "大音乐符号",
          show: false,
        },
        {
          id: "17",
          key: "Alt",
          key1: "Shift",
          key2: "N",
          value: this.canChange
            ? this.$t("SubtitleStyle.MusicSignwithSpace")
            : "小音乐符号带空格",
          show: false,
        },
        {
          id: "18",
          key: "Alt",
          key1: "Shift",
          key2: "M",
          value: this.canChange
            ? this.$t("SubtitleStyle.MusicSignwithSpace1")
            : "大音乐符号带空格",
          show: false,
        },
        {
          id: "19",
          key: "Alt",
          key1: "W",
          value: this.canChange ? this.$t("SubtitleStyle.text") : "激活译文",
          show: [3, 4].includes(this.showwhat) ? true : false,
        },
        {
          id: "20",
          key: "Alt",
          key1: "1-5",
          value: this.canChange
            ? this.$t("SubtitleStyle.text1")
            : "设置错误类型",
          show: [4, 5].includes(this.showwhat) ? true : false,
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.conten_div {
  width: 100%;
  height: 450px;
  overflow-y: auto;

  .lirow {
    margin-bottom: 14px;
    width: 100%;

    .li_col {
      //   display: flex;
      //   justify-content: center;

      .col_div {
        display: flex;
        margin-left: 60px;

        .center_div {
          margin: 0 6px;
          height: 16px;
          line-height: 16px;
          color: #9ad2ff;
        }

        .key_div {
          height: 16px;
          line-height: 16px;
          font-size: 12px;
          color: #9ad2ff;
          padding: 0 6px;
          border: 1px solid;
          border-color: #9ad2ff;
          border-radius: 2px;
        }

        .spaceclass {
          padding: 0px;
          text-align: center;
          width: 99px;
        }
      }
    }

    .li_bottom_div {
      white-space: pre-line !important;
      word-wrap: break-word !important;
      word-break: break-word !important;
      color: #ffffff;
      font-size: 14px;
    }
  }
}
</style>
