<!--
 * @Description: 服务明细
 * @version: 
 * @Author: Carful
 * @Date: 2022-12-12 15:11:24
 * @LastEditors: Carful
 * @LastEditTime: 2022-12-13 10:00:16
-->
<template>
  <div class="contentdiv">
    <div class="titlediv">
      <p>
        <el-button type="text" class="" @click="BackPrevious">
          <svg-icon icon-class="BackPrevious" class="svgClass"></svg-icon>
        </el-button>
        <!--        服务明细-->
        <span class="spanClass">{{
          $t("PersonalCenter.ServiceDetailsText")
        }}</span>
      </p>
    </div>
    <div class="content">
      <div class="content_time">
        <el-date-picker
          v-model="time"
          type="daterange"
          :range-separator="$t('MemberDetails.datetime2')"
          :start-placeholder="$t('MemberDetails.datetime1')"
          :end-placeholder="$t('MemberDetails.datetime3')"
          :editable="false"
          @change="daterangeChange"
          unlink-panels
        >
        </el-date-picker>
      </div>
      <div
        class="tableborderClass tableClass borderTableStyle"
        :class="tableData.length == 0 ? 'tablebtmborder' : ''"
      >
        <el-table
          :row-style="{
            height: 40 + 'px',
            background: '#393c4e',
            color: '#ffffff',
          }"
          :cell-style="{ padding: 0 + 'px' }"
          :header-cell-style="{
            height: 40 + 'px',
            padding: '0px',
            color: '#adb0bc',
            background: '#454758',
          }"
          :data="
            tableData.slice(
              (currentPage - 1) * pageSize,
              currentPage * pageSize
            )
          "
          tooltip-effect="dark"
          style="width: 100%"
          max-height="100%"
          border
        >
          <div slot="empty" class="noDataDiv">
            <svg-icon icon-class="NoData" class="noDataicon"></svg-icon>
            <p>{{ $t("CommonName.noData") }}</p>
          </div>
          <!--邀请时间  -->
          <el-table-column
            prop="create_time"
            :label="$t('PersonalCenter.ServiceDetailsText1')"
            width="189"
          >
          </el-table-column>
          <!-- 账户 ID -->
          <el-table-column
            prop="content"
            :label="$t('PersonalCenter.ServiceDetailsText2')"
          ></el-table-column>
          <el-table-column
            prop="action"
            :label="$t('PersonalCenter.ServiceDetailsText3')"
            width="340"
          >
          </el-table-column>
        </el-table>
      </div>
      <div
        style="margin-top: 20px; float: right; margin-right: 20px"
        v-if="tableData.length > 0"
      >
        <PaginationComVue
          :tableData="tableData"
          :pageSize="pageSize"
          :currentPage="currentPage"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        ></PaginationComVue>
      </div>
    </div>
  </div>
</template>
<script>
import { get_user_quota_log } from "@/utils/https";
import { timestampToTime } from "@/api/setTime";
import PaginationComVue from "@/components/PaginationCom.vue";

export default {
  components: {
    PaginationComVue,
  },
  created() {
    // 变换
    this.$emit("header", false, false, true, true, true);
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
    this.time = [timestampToTime(start), timestampToTime(end)];
  },
  data() {
    return {
      time: [],
      maxHeight: 700,
      tableData: [],
      pageSize: 20,
      currentPage: 1,
    };
  },
  methods: {
    get_user_quota_list() {
      let start = "";
      let end = "";
      if (this.time !== null && this.time.length == 2) {
        start = this.time[0];
        end = this.time[1];
      }
      get_user_quota_log({
        action: "get_user_quota_log",
        start,
        end,
      }).then((res) => {
        console.log(res);
        if (!res) {
          return;
        }
        this.tableData = res.list;
      });
    },
    daterangeChange(time) {
      // console.log(time);
      console.log(this.time);
      this.get_user_quota_list();
      // console.log(maxDate);
      // console.log(minDate);
    },
    BackPrevious() {
      this.$router.go(-1);
    },
    /**
     * 分页器
     * @param {} val 页数
     */
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
  },
  mounted() {
    this.get_user_quota_list();
  },
};
</script>
<style lang="scss" scoped>
.content {
  .content_time {
    display: flex;
    justify-content: flex-start;
    margin: 0 0 16px 0;
    padding: 24px 24px 0;
  }

  .tableClass {
    height: calc(100% - 184px);
    padding: 0 24px;
    // overflow-y: auto;
  }
}
</style>
