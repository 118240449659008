<!--
 * @Description: 工具箱
 * @version: 
 * @Author: Carful
 * @Date: 2022-11-07 10:12:45
 * @LastEditors: Carful
 * @LastEditTime: 2023-02-24 11:35:00
-->
<template>
  <div class="mainCenter">
    <div class="mainCenterTop titleHeader">
      <div class="titleStyle">
        <div class="titledivStyle"></div>
        <p>{{ $t("toolset.title") }}</p>
      </div>
    </div>
    <div class="mainCenterbtm">
      <div
        v-for="i in toolsetList"
        :key="i.id"
        class="toolseAlldiv"
        @click="clicktoolse(i)"
      >
        <div class="toolsediv">
          <svg-icon :icon-class="i.icon" class="icon"></svg-icon>
          <p>{{ i.name }}</p>
        </div>
      </div>
    </div>
    <DialogComVue
      :dialogVisible="dialogVisible"
      @changeDialogVisible="changeDialogVisible"
      @determineClick="determineClick"
      :footerbtnName="$t('CommonName.OK')"
      :closefooterbtnName="$t('CommonName.Cancel')"
      :width="'888px'"
      :top="'5%'"
      :showTitle="showTitle"
      :title="dialogtitle"
      :showfooter="showfooter"
      v-if="dialogVisible"
    >
      <!-- 弹出框头部 -->
      <template
        v-slot:title
        v-if="dialogtitle == $t('toolset.toolsetList.name16')"
      >
        <div
          class="slottitle"
          v-if="dialogtitle == $t('toolset.toolsetList.name16')"
        >
          <p>{{ dialogtitle }}</p>
          <!-- 分享检查工具 -->
          <el-button type="text" @click="ShareCheckingTool()"
            >{{ $t("toolset.name16Text5") }}
          </el-button>
        </div>
      </template>
      <div class="dialogContent">
        <div
          v-if="
            dialogtitle === $t('toolset.toolsetList.name0') ||
            dialogtitle === $t('toolset.toolsetList.name2') ||
            dialogtitle === $t('toolset.toolsetList.name3') ||
            dialogtitle === $t('toolset.toolsetList.name6') ||
            dialogtitle === $t('toolset.toolsetList.name12') ||
            dialogtitle === $t('toolset.toolsetList.name13') ||
            dialogtitle === $t('toolset.toolsetList.name14') ||
            dialogtitle === $t('toolset.toolsetList.name17')
          "
        >
          <div class="content">
            <!--            时间轴排序-->
            <div
              class="uploadp"
              v-if="dialogtitle === $t('toolset.toolsetList.name0')"
            >
              <p>{{ $t("toolset.name0dialogtitle") }}</p>
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('toolset.name0dialogtooltip')"
                placement="top-start"
              >
                <svg-icon icon-class="icon_tips" class="icon"></svg-icon>
              </el-tooltip>
              <!-- <svg-icon
                icon-class="presentation"
                class="verificationIcon"
                v-if="form.uploadinput1 == null"
              ></svg-icon> -->
            </div>
            <!--            .xlsx -> .srt转换器-->
            <div
              class="uploadp"
              v-if="dialogtitle === $t('toolset.toolsetList.name2')"
            >
              <p>{{ $t("toolset.UploadExcelFile") }}</p>

              <!-- <svg-icon
                icon-class="presentation"
                class="verificationIcon"
                v-if="form.uploadinput1 == null"
              ></svg-icon> -->
            </div>
            <!--            .srt -> UTF-8转换器-->
            <div
              class="uploadp"
              v-if="
                dialogtitle === $t('toolset.toolsetList.name3') ||
                dialogtitle === $t('toolset.toolsetList.name12') ||
                dialogtitle === $t('toolset.toolsetList.name13') ||
                dialogtitle === $t('toolset.toolsetList.name14') ||
                dialogtitle === $t('toolset.toolsetList.name17')
              "
            >
              <p>{{ $t("toolset.Uploadfilezip") }}</p>

              <!-- <svg-icon
                icon-class="presentation"
                class="verificationIcon"
                v-if="form.uploadinput1 == null"
              ></svg-icon> -->
            </div>
            <!--            调换语言顺序-->
            <div
              class="uploadp"
              v-if="dialogtitle === $t('toolset.toolsetList.name6')"
            >
              <p>{{ $t("toolset.Uploadfilesrt") }}</p>

              <!-- <svg-icon
                icon-class="presentation"
                class="verificationIcon"
                v-if="form.uploadinput1 == null"
              ></svg-icon> -->
            </div>
            <el-input
              v-model="form.uploadinput1"
              class="input-with-select srtUnput"
              @input="form.uploadinput1 = ''"
              :disabled="true"
            >
              <el-upload
                slot="append"
                class="upload-demo"
                ref="upload"
                :action="$uploadURL + '/other/gateway'"
                :data="uploadData"
                :on-change="uploadChange"
                :show-file-list="false"
                :on-success="uploadSuccess"
                :on-progress="uploadProgress"
                :auto-upload="false"
                :with-credentials="true"
                :limit="1"
              >
                <el-button
                  type="text"
                  @click="updateNewfile('upload', false, '1')"
                >
                  <div class="uploadbtn">
                    <svg-icon icon-class="upload" class="icon"></svg-icon>
                    <!-- 上传 -->
                    <span>{{ $t("CommonName.Upload") }}</span>
                  </div>
                </el-button>
              </el-upload>
            </el-input>
            <div
              v-if="dialogtitle === $t('toolset.toolsetList.name13')"
              style="margin-top: 24px; color: #fff"
            >
              导入zip内包含相同剧集的单语原文srt文件和单语译文srt文件，可导出以原文srt文件的时间轴为准的双语srt、双语Excel、单语译文srt。
              导入zip内文件的命名规则：xxxx_source.srt 和 xxxx_target.srt
              表示原文srt与译文srt
            </div>
            <div
              v-if="dialogtitle === $t('toolset.toolsetList.name17')"
              style="margin-top: 24px; color: #fff"
            >
              导入zip内包含相同剧集的单语原文srt文件和单语译文srt文件，可导出以原文srt文件的时间轴为准的双语srt、双语Excel、单语译文srt。
              导入zip内文件的命名规则：xxxx_source.srt 和 xxxx_target.srt
              表示原文srt与译文srt
              <br />特别版更新：先将译文中画面字提取出来，将剩余内容与原文进行合并，合并后的新句段内容再与画面字内容进行合并。
            </div>
          </div>
        </div>
        <!--        合成双语字幕/Combine Documents-->
        <div
          v-if="
            dialogtitle === $t('toolset.toolsetList.name1') ||
            dialogtitle === $t('toolset.toolsetList.name7')
          "
          class="toolsetListname1"
        >
          <div
            class="content_btm"
            v-if="dialogtitle == $t('toolset.toolsetList.name1')"
          >
            <p>{{ $t("toolset.Importoptions") }}</p>
            <div class="radioClass">
              <el-radio-group v-model="form1.resource">
                <el-radio border label="1"
                  >{{ $t("toolset.SourceSRT") }}
                </el-radio>
                <el-radio border label="2"
                  >{{ $t("toolset.BilingualSRT") }}
                </el-radio>
              </el-radio-group>
            </div>
          </div>
          <div
            class="content_btm_1"
            v-if="dialogtitle === $t('toolset.toolsetList.name7')"
          >
            <p>{{ $t("CommonName.Orderoftextsinsubtitlefile.name") }}</p>
            <div class="radioClass">
              <el-radio-group v-model="form1.resource">
                <el-radio border label="1"
                  >{{ $t("CommonName.Orderoftextsinsubtitlefile.label0") }}
                </el-radio>
                <el-radio border label="2"
                  >{{ $t("CommonName.Orderoftextsinsubtitlefile.label1") }}
                </el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="content">
            <div class="uploadp">
              <p>{{ $t("toolset.Uploadsourcefile") }}</p>

              <!-- <svg-icon
                icon-class="presentation"
                class="verificationIcon"
                v-if="form.uploadinput1 == null"
              ></svg-icon> -->
            </div>
            <el-input
              v-model="form1.uploadinput1"
              class="input-with-select srtUnput"
              @input="form1.uploadinput1 = ''"
              :disabled="true"
            >
              <el-upload
                slot="append"
                class="upload-demo"
                ref="srtupload"
                :data="
                  TOOLSETID === '1'
                    ? { action: 'upload_time_source_srt_file' }
                    : { action: 'upload_compose_source_srt_file' }
                "
                :action="$uploadURL + '/other/gateway'"
                :before-upload="beforeUpload"
                :show-file-list="false"
                :on-success="uploadSuccess"
                :on-progress="uploadProgress"
                :auto-upload="true"
                :with-credentials="true"
                :limit="1"
              >
                <el-button
                  type="text"
                  @click="updateNewfile('srtupload', true, '2')"
                >
                  <div class="uploadbtn">
                    <svg-icon icon-class="upload" class="icon"></svg-icon>
                    <!-- 上传 -->
                    <span>{{ $t("CommonName.Upload") }}</span>
                  </div>
                </el-button>
              </el-upload>
            </el-input>
          </div>
          <div class="content">
            <div class="uploadp">
              <p v-if="dialogtitle === $t('toolset.toolsetList.name1')">
                {{ $t("toolset.Uploadreplacefile") }}
              </p>
              <p v-if="dialogtitle === $t('toolset.toolsetList.name7')">
                {{ $t("toolset.Uploadtargetfile") }}
              </p>
              <!-- <svg-icon
                icon-class="presentation"
                class="verificationIcon"
                v-if="form.uploadinput1 == null"
              ></svg-icon> -->
            </div>
            <el-input
              v-model="form1.uploadinput2"
              class="input-with-select srtUnput"
              @input="form1.uploadinput2 = ''"
              :disabled="true"
            >
              <el-upload
                slot="append"
                class="upload-demo"
                ref="upload"
                :data="uploadData"
                :action="$uploadURL + '/other/gateway'"
                :on-change="uploadChange"
                :show-file-list="false"
                :on-success="uploadSuccess"
                :on-progress="uploadProgress"
                :auto-upload="false"
                :with-credentials="true"
                :limit="1"
              >
                <el-button
                  type="text"
                  @click="updateNewfile('upload', false, '3')"
                >
                  <div class="uploadbtn">
                    <svg-icon icon-class="upload" class="icon"></svg-icon>
                    <!-- 上传 -->
                    <span>{{ $t("CommonName.Upload") }}</span>
                  </div>
                </el-button>
              </el-upload>
            </el-input>
          </div>
        </div>
        <!--        全局检查-->
        <div
          v-if="dialogtitle === $t('toolset.toolsetList.name4')"
          class="name4form4"
        >
          <div class="top_p">
            <p>{{ $t("toolset.Errortype") }}</p>
            <div class="checkboxClass">
              <el-checkbox-group v-model="form4.type">
                <div>
                  <el-checkbox
                    v-for="i in checkboxList"
                    :label="i.id"
                    :key="i.id"
                    name="type"
                    border
                  >
                    <div class="checkboxinputdiv">
                      <p>{{ i.label }}</p>
                      <div
                        v-if="i.showInput"
                        class="checkboxinput disabledInputClass"
                      >
                        <div style="margin-left: 20px">
                          <el-input
                            v-model="i.input"
                            :disabled="!form4.type.includes(i.id)"
                          ></el-input>
                        </div>
                      </div>
                    </div>
                  </el-checkbox>
                </div>
              </el-checkbox-group>
            </div>
          </div>
          <div class="content">
            <div class="uploadp">
              <p>{{ $t("toolset.name0dialogtitle") }}</p>
            </div>
            <el-input
              v-model="form.uploadinput1"
              class="input-with-select srtUnput"
              @input="form.uploadinput1 = ''"
              :disabled="true"
            >
              <el-upload
                slot="append"
                class="upload-demo"
                ref="upload"
                :data="uploadData"
                :action="$uploadURL + '/other/gateway'"
                :on-change="uploadChange"
                :show-file-list="false"
                :on-success="uploadSuccess"
                :on-progress="uploadProgress"
                :auto-upload="false"
                :with-credentials="true"
                :limit="1"
              >
                <el-button
                  type="text"
                  @click="updateNewfile('upload', false, '1')"
                >
                  <div class="uploadbtn">
                    <svg-icon icon-class="upload" class="icon"></svg-icon>
                    <!-- 上传 -->
                    <span>{{ $t("CommonName.Upload") }}</span>
                  </div>
                </el-button>
              </el-upload>
            </el-input>
          </div>
        </div>
        <!--        Words-->
        <div
          v-if="dialogtitle === $t('toolset.toolsetList.name5')"
          class="name5form5"
        >
          <div class="content_top">
            <div class="content_top_1">
              <p>{{ $t("CommonName.Sourcelanguage") }}</p>
              <el-select
                v-model="form5.select"
                class="selectclass"
                placeholder=""
              >
                <el-option
                  v-for="item in selectList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div>
              <p>&nbsp;</p>
              <el-button type="text" @click="AddWordsClick">
                <div class="svgbtn">
                  <svg-icon icon-class="create" class="icon"></svg-icon>
                  <p>{{ $t("toolset.AddWords") }}</p>
                </div>
              </el-button>
            </div>
          </div>
          <div class="tableborderClass tableClass borderTableStyle">
            <el-table
              :row-style="{
                height: 40 + 'px',
                background: '#393c4e',
                color: '#ffffff',
              }"
              :cell-style="{ padding: 0 + 'px' }"
              :header-cell-style="{
                height: 32 + 'px',
                padding: '0px',
                color: '#adb0bc',
                background: '#454758',
              }"
              :data="form5.tableData"
              tooltip-effect="dark"
              style="width: 100%"
              :max-height="maxHeight"
              border
            >
              <el-table-column width="80" label="#" type="index">
              </el-table-column>
              <el-table-column prop="Word" :label="$t('toolset.Word')">
              </el-table-column>
              <el-table-column width="50" align="center">
                <template slot-scope="scope">
                  <el-button type="text" @click="deleteClick(scope.row)">
                    <svg-icon icon-class="delete" class="deleteIcon"></svg-icon>
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!--        srt拆分-->
        <div
          v-if="dialogtitle === $t('toolset.toolsetList.name8')"
          class="name8form8"
        >
          <div class="name8form8_top">
            <div class="name8form8_top_left">
              <!--                分割选项-->
              <p class="title_p">{{ $t("toolset.srtText3") }}</p>
              <div class="radioClass">
                <el-radio-group v-model="form8.radio" @input="form8RadioChange">
                  <el-radio label="1">{{ $t("toolset.srtText") }}</el-radio>
                  <el-radio label="2">{{ $t("toolset.srtText1") }}</el-radio>
                  <el-radio label="3">{{ $t("toolset.srtText2") }}</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="name8form8_top_center inputNumber disabledInputClass">
              <!--              相等部分的数量-->
              <p class="title_p">{{ $t("toolset.srtText4") }}</p>
              <el-input-number
                v-model="form8.input"
                controls-position="right"
                :min="2"
                :max="
                  form8.rows_count < 4 ? 0 : Math.floor(form8.rows_count / 2)
                "
                :disabled="!this.form8.uploadinput ? true : false"
                @change="form8inputchange"
                @blur="form8inputBuild"
              ></el-input-number>
              <!--              <el-input-->
              <!--                v-model="form8.input"-->
              <!--                type="number"-->
              <!--                min="2"-->
              <!--                :max="-->
              <!--                  form8.rows_count < 4 ? 0 : Math.floor(form8.rows_count / 2)-->
              <!--                "-->
              <!--                @blur="form8inputBuild"-->
              <!--                :disabled="!this.form8.uploadinput ? true : false"-->
              <!--              ></el-input>-->
            </div>
            <div class="name8form8_top_right">
              <!--          字幕信息    -->
              <p class="title_p">{{ $t("toolset.srtText5") }}</p>
              <div>
                <p>{{ $t("toolset.srtText6") + form8.rows_count }}</p>
                <p>{{ $t("toolset.srtText7") + form8.word_count }}</p>
              </div>
            </div>
          </div>
          <div class="name8form8_center content">
            <p class="title_p">{{ $t("toolset.srtText8") }}</p>
            <el-input
              v-model="form8.uploadinput"
              class="input-with-select srtUnput"
              @input="form8.uploadinput = ''"
              :disabled="true"
            >
              <el-upload
                slot="append"
                class="upload-demo"
                ref="srtupload"
                :data="{
                  action: 'upload_split_srt',
                  type: 1,
                  count: this.form8.input,
                  mode: this.form8.radio,
                  is_upload: 1,
                }"
                :action="$uploadURL + '/other/gateway'"
                :before-upload="beforeUpload"
                :show-file-list="false"
                :on-success="uploadSuccess"
                :on-progress="uploadProgress"
                :auto-upload="true"
                :with-credentials="true"
                :limit="1"
              >
                <el-button
                  type="text"
                  @click="updateNewfile('srtupload', true, '8')"
                >
                  <div class="uploadbtn">
                    <svg-icon icon-class="upload" class="icon"></svg-icon>
                    <!-- 上传 -->
                    <span>{{ $t("CommonName.Upload") }}</span>
                  </div>
                </el-button>
              </el-upload>
            </el-input>
          </div>
          <div class="name8form8_btm">
            <p class="title_p">{{ $t("toolset.srtText9") }}</p>
            <div class="tableClass">
              <el-table
                :row-style="{
                  height: 30 + 'px',
                  background: '#393c4e',
                  color: '#cbd7e8',
                }"
                :cell-style="{ padding: 0 + 'px' }"
                :header-cell-style="{
                  height: 30 + 'px',
                  padding: '0px',
                  color: '#adb0bc',
                  background: '#393c4e',
                }"
                ref="multipleTable"
                :data="form8.tableData"
                tooltip-effect="dark"
                style="width: 100%"
                max-height="200"
              >
                <el-table-column
                  prop="rows_count"
                  :label="$t('toolset.srtText')"
                  width="150"
                ></el-table-column>
                <el-table-column
                  prop="word_count"
                  :label="$t('toolset.srtText1')"
                  width="150"
                ></el-table-column>
                <el-table-column
                  prop="file_name"
                  :label="$t('toolset.srtText10')"
                  show-overflow-tooltip
                ></el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <!--        srt合并-->
        <div
          v-if="dialogtitle === $t('toolset.toolsetList.name9')"
          class="name9form9"
        >
          <div class="top_div">
            <div class="top_div_left">
              <el-upload
                class="upload-demo"
                drag
                ref="upload"
                :with-credentials="true"
                :action="$uploadURL + '/other/gateway'"
                :data="{
                  action: 'upload_merge_srt',
                }"
                :before-upload="merge_beforeUpload"
                :on-exceed="merge_uploadExceed"
                :on-progress="merge_uploadProgress"
                :on-success="merge_uploadSuccess"
                :auto-upload="true"
                :show-file-list="false"
                :limit="50"
                :file-list="fileList"
                multiple
              >
                <div
                  class="uploaddiv"
                  slot="trigger"
                  @click="merge_updateNewfile"
                >
                  <div v-if="fileList.length > 0" class="filelist_div">
                    <div
                      class="upload_table_div"
                      @click="
                        (e) => {
                          return e.stopPropagation();
                        }
                      "
                    >
                      <div class="tableClass">
                        <el-table
                          :row-style="{
                            height: 30 + 'px',
                            background: '#393c4e',
                            color: '#cbd7e8',
                          }"
                          :cell-style="{ padding: 0 + 'px' }"
                          :header-cell-style="{
                            height: 30 + 'px',
                            padding: '0px',
                            color: '#adb0bc',
                            background: '#393c4e',
                          }"
                          ref="multipleTable"
                          :data="fileList"
                          tooltip-effect="dark"
                          style="width: 100%"
                          max-height="250"
                        >
                          <el-table-column
                            prop="rows_count"
                            :label="$t('toolset.srtText11')"
                            width="100"
                          ></el-table-column>
                          <el-table-column
                            prop="min_time"
                            :label="$t('toolset.srtText12')"
                            width="120"
                          ></el-table-column>
                          <el-table-column
                            prop="max_time"
                            :label="$t('toolset.srtText13')"
                            width="120"
                          ></el-table-column>
                          <el-table-column
                            prop="filename"
                            :label="$t('toolset.srtText10')"
                            show-overflow-tooltip
                          ></el-table-column>
                          <el-table-column width="50">
                            <template slot-scope="scope">
                              <el-button
                                type="text"
                                @click="
                                  UploadListDeleteClick($event, scope.row)
                                "
                              >
                                <svg-icon
                                  icon-class="delete"
                                  class="icon"
                                ></svg-icon>
                              </el-button>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                    </div>
                    <div class="p_div">
                      <i class="el-icon-plus"></i>
                      <p style="color: #2f9efd">
                        <!--                            点击选择或拖动视频到这里-->
                        {{ $t("PersonalEndLanguage.DragVideo1") }}
                      </p>
                    </div>
                  </div>
                  <div v-else>
                    <i class="el-icon-plus"></i>
                    <p class="topp">
                      {{ $t("PersonalEndLanguage.DragVideo1") }}
                    </p>
                    <p class="botp"></p>
                  </div>
                </div>
              </el-upload>
            </div>
            <div class="top_div_right">
              <!--              清空-->
              <el-button
                type="primary"
                class="setpadding16"
                @click="clearFileList"
                >{{ $t("toolset.srtText14") }}
              </el-button>
            </div>
          </div>
          <div class="btm_div">
            <div class="btm_div_left">
              <div class="radioClass">
                <el-radio-group v-model="form9.radio">
                  <el-radio label="1">{{ $t("toolset.srtText15") }}</el-radio>
                  <el-radio label="2">{{ $t("toolset.srtText16") }}</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="btm_div_right inputNumber disabledInputClass">
              <p>
                {{ $t("toolset.srtText17") }}
              </p>
              <!--              <el-input-number-->
              <!--                v-model="form9.input"-->
              <!--                type="number"-->
              <!--                :min="0"-->
              <!--                :max="100000"-->
              <!--                :disabled="form9.radio == '1'"-->
              <!--                @change="-->
              <!--                  form9.input > 100000 ? (form9.input = 100000) : form9.input-->
              <!--                "-->
              <!--                controls-position="right"-->
              <!--              ></el-input-number>-->
              <el-input
                v-model="form9.input"
                type="number"
                min="0"
                max="100000"
                :disabled="form9.radio == '1'"
                @input="
                  form9.input > 100000 ? (form9.input = 100000) : form9.input
                "
              ></el-input>
            </div>
          </div>
        </div>
        <!--        SRT转ASS-->
        <div
          v-if="dialogtitle === $t('toolset.toolsetList.name11')"
          class="name10form10"
        >
          <div class="name10form10_top">
            <p class="title_p">{{ $t("toolset.ASS") }}</p>
            <el-input
              type="textarea"
              resize="none"
              v-model="form10.textarea"
              :placeholder="$t('toolset.ASS2')"
              maxlength="500"
            ></el-input>
          </div>
          <div class="content">
            <div class="uploadp">
              <p>{{ $t("toolset.name0dialogtitle") }}</p>
            </div>
            <el-input
              v-model="form.uploadinput1"
              class="input-with-select srtUnput"
              @input="form.uploadinput1 = ''"
              :disabled="true"
            >
              <el-upload
                slot="append"
                class="upload-demo"
                ref="upload"
                :data="uploadData"
                :action="$uploadURL + '/other/gateway'"
                :on-change="uploadChange"
                :show-file-list="false"
                :on-success="uploadSuccess"
                :on-progress="uploadProgress"
                :auto-upload="false"
                :with-credentials="true"
                :limit="1"
              >
                <el-button
                  type="text"
                  @click="updateNewfile('upload', false, '1')"
                >
                  <div class="uploadbtn">
                    <svg-icon icon-class="upload" class="icon"></svg-icon>
                    <!-- 上传 -->
                    <span>{{ $t("CommonName.Upload") }}</span>
                  </div>
                </el-button>
              </el-upload>
            </el-input>
          </div>
        </div>
        <!--        视频转换 -->
        <div
          v-if="dialogtitle === $t('toolset.toolsetList.name15')"
          class="name11form11"
        >
          <el-input
            :placeholder="$t('toolset.name15Text')"
            v-model="form11Input"
          ></el-input>
        </div>
        <!--        检查-->
        <div v-if="false" class="toolsetListname1">
          <!--        源语言-->
          <div class="content_btm_1">
            <p>{{ $t("toolset.name16Text") }}</p>
            <div class="radioClass">
              <el-select
                v-model="form16.select1"
                class="selectclass16"
                @change="changeselectLanguage1"
                :placeholder="$t('CommonName.Pleaseselect')"
                filterable
              >
                <el-option
                  v-for="item in languageObj.selectLanguage1"
                  :key="item.language_id"
                  :label="item.cname"
                  :value="item.language_id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <!--        目标语言-->
          <div class="content">
            <div class="uploadp">
              <p>{{ $t("toolset.name16Text1") }}</p>
            </div>
            <div class="radioClass">
              <el-select
                v-model="form16.select2"
                class="selectclass16"
                @change="changeselectLanguage2"
                :placeholder="$t('CommonName.Pleaseselect')"
                filterable
              >
                <el-option
                  v-for="item in languageObj.selectLanguage2"
                  :key="item.language_id"
                  :label="item.cname"
                  :value="item.language_id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <!--        术语表-->
          <div class="content">
            <div class="uploadp">
              <p>{{ $t("toolset.name16Text2") }}</p>
            </div>
            <div class="radioClass">
              <el-select
                v-model="form16.select3"
                class="selectclass16"
                :placeholder="$t('CommonName.Pleaseselect')"
                filterable
              >
                <el-option
                  v-for="item in TermList"
                  :key="item.term_set_id"
                  :label="item.name"
                  :value="item.term_set_id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <!--        字数上线与符号检查-->
          <div class="content">
            <div class="uploadp">
              <div class="AverageClass">
                <p>{{ $t("toolset.name16Text3") }}</p>
                <p>{{ $t("toolset.name16Text4") }}</p>
              </div>
            </div>
            <div class="radioClass">
              <div class="AverageClass">
                <el-input v-model.number="form16.input"></el-input>
                <el-input v-model="form16.input1"></el-input>
              </div>
            </div>
          </div>
          <div class="content">
            <div class="uploadp">
              <p>{{ $t("toolset.name16Text8") }}(.zip)</p>

              <!-- <svg-icon
                icon-class="presentation"
                class="verificationIcon"
                v-if="form.uploadinput1 == null"
              ></svg-icon> -->
            </div>
            <el-input
              v-model="form16.uploadinput1"
              class="input-with-select srtUnput"
              @input="form16.uploadinput1 = ''"
              :disabled="true"
            >
              <el-upload
                slot="append"
                class="upload-demo"
                ref="upload1"
                :action="$uploadURL + '/other/upload_zip_file'"
                name="zip_file"
                :before-upload="beforeUploadZip"
                :show-file-list="false"
                :on-success="uploadSuccess"
                :on-progress="uploadProgress"
                :auto-upload="true"
                :with-credentials="true"
                :limit="1"
              >
                <el-button
                  type="text"
                  @click="updateNewfile('upload1', false, '16')"
                >
                  <div class="uploadbtn">
                    <svg-icon icon-class="upload" class="icon"></svg-icon>
                    <!-- 上传 -->
                    <span>{{ $t("CommonName.Upload") }}</span>
                  </div>
                </el-button>
              </el-upload>
            </el-input>
          </div>
          <div class="content">
            <div class="uploadp">
              <p>{{ $t("toolset.name16Text9") }}(.zip)</p>
            </div>
            <el-input
              v-model="form16.uploadinput2"
              class="input-with-select srtUnput"
              @input="form16.uploadinput2 = ''"
              :disabled="true"
            >
              <el-upload
                slot="append"
                class="upload-demo"
                ref="upload"
                :data="uploadData"
                :action="$uploadURL + '/other/upload_zip_file'"
                name="zip_file"
                :before-upload="beforeUploadZip"
                :show-file-list="false"
                :on-success="uploadSuccess1"
                :on-progress="uploadProgress"
                :auto-upload="true"
                :with-credentials="true"
                :limit="1"
              >
                <el-button
                  type="text"
                  @click="updateNewfile('upload', false, '15')"
                >
                  <div class="uploadbtn">
                    <svg-icon icon-class="upload" class="icon"></svg-icon>
                    <!-- 上传 -->
                    <span>{{ $t("CommonName.Upload") }}</span>
                  </div>
                </el-button>
              </el-upload>
            </el-input>
          </div>
        </div>
        <!--       导入excel与srt获得srt -->
        <div
          v-if="dialogtitle === $t('toolset.toolsetList.name18')"
          class="toolsetListname1"
        >
          <div class="content_btm_1">
            <div class="radioClass">
              <el-radio-group v-model="form18.type">
                <el-radio border label="1">{{ "Amy" }} </el-radio>
                <el-radio border label="2">{{ "Emily" }} </el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="content">
            <div class="uploadp">
              <p>{{ $t("toolset.UploadExcelFile") }}</p>
            </div>
            <el-input
              v-model="form18.uploadinput2"
              class="input-with-select srtUnput"
              @input="form18.uploadinput2 = ''"
              :disabled="true"
            >
              <el-upload
                slot="append"
                class="upload-demo"
                ref="srtupload"
                :action="$uploadURL + '/other/upload_zip_file'"
                :before-upload="beforeUploadExcel"
                :show-file-list="false"
                :on-success="uploadSuccess"
                :on-progress="uploadProgress"
                :auto-upload="true"
                :with-credentials="true"
                :limit="1"
                name="zip_file"
              >
                <el-button
                  type="text"
                  @click="updateNewfile('srtupload', true, '18-2')"
                >
                  <div class="uploadbtn">
                    <svg-icon icon-class="upload" class="icon"></svg-icon>
                    <!-- 上传 -->
                    <span>{{ $t("CommonName.Upload") }}</span>
                  </div>
                </el-button>
              </el-upload>
            </el-input>
          </div>
          <div class="content">
            <div class="uploadp">
              <p>
                {{ $t("toolset.SourceSRT") }}
              </p>
              <!-- <svg-icon
                icon-class="presentation"
                class="verificationIcon"
                v-if="form.uploadinput1 == null"
              ></svg-icon> -->
            </div>
            <el-input
              v-model="form18.uploadinput1"
              class="input-with-select srtUnput"
              @input="form18.uploadinput1 = ''"
              :disabled="true"
            >
              <el-upload
                slot="append"
                class="upload-demo"
                ref="upload"
                :action="$uploadURL + '/other/upload_zip_file'"
                :before-upload="beforeUpload"
                :show-file-list="false"
                :on-success="uploadSuccess1"
                :on-progress="uploadProgress"
                :auto-upload="true"
                :with-credentials="true"
                :limit="1"
                name="zip_file"
              >
                <el-button
                  type="text"
                  @click="updateNewfile('upload', false, '18-1')"
                >
                  <div class="uploadbtn">
                    <svg-icon icon-class="upload" class="icon"></svg-icon>
                    <!-- 上传 -->
                    <span>{{ $t("CommonName.Upload") }}</span>
                  </div>
                </el-button>
              </el-upload>
            </el-input>
          </div>
        </div>
        <!--        <div-->
        <!--          v-if="dialogtitle === $t('toolset.toolsetList.name12')"-->
        <!--          class="name11form11"-->
        <!--        >-->
        <!--          <div class="content">-->
        <!--            <div class="uploadp">-->
        <!--              <p>{{ $t("toolset.Excel") }}</p>-->
        <!--            </div>-->
        <!--            <el-input-->
        <!--              v-model="form.uploadinput1"-->
        <!--              class="input-with-select srtUnput"-->
        <!--              @input="form.uploadinput1 = ''"-->
        <!--              :disabled="true"-->
        <!--            >-->
        <!--              <el-upload-->
        <!--                slot="append"-->
        <!--                class="upload-demo"-->
        <!--                ref="upload"-->
        <!--                :data="uploadData"-->
        <!--                :action="$uploadURL + '/other/gateway'"-->
        <!--                :on-change="uploadChange"-->
        <!--                :show-file-list="false"-->
        <!--                :on-success="uploadSuccess"-->
        <!--                :on-progress="uploadProgress"-->
        <!--                :auto-upload="false"-->
        <!--                :with-credentials="true"-->
        <!--                :limit="1"-->
        <!--              >-->
        <!--                <el-button-->
        <!--                  type="text"-->
        <!--                  @click="updateNewfile('upload', false, '1')"-->
        <!--                >-->
        <!--                  <div class="uploadbtn">-->
        <!--                    <svg-icon icon-class="upload" class="icon"></svg-icon>-->
        <!--                    &lt;!&ndash; 上传 &ndash;&gt;-->
        <!--                    <span>{{ $t("CommonName.Upload") }}</span>-->
        <!--                  </div>-->
        <!--                </el-button>-->
        <!--              </el-upload>-->
        <!--            </el-input>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
      <el-dialog
        width="888px"
        top="10%"
        :title="$t('toolset.AddWords')"
        :visible.sync="innerVisible"
        center
        append-to-body
        v-if="innerVisible"
      >
        <div class="innerVisiblecentent">
          <div class="content_top_1">
            <p>{{ $t("CommonName.Sourcelanguage") }}</p>
            <el-select
              v-model="innerVisibledia.select"
              class="selectclass"
              placeholder=""
            >
              <el-option
                v-for="item in selectList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="content_top_1" style="margin-top: 16px">
            <p>{{ $t("toolset.Word") }}</p>
            <el-input
              v-model="innerVisibledia.input"
              class="content_top_1_input"
            ></el-input>
          </div>
        </div>
        <div slot="footer" class="dialog-footer" style="margin-top: 24px">
          <el-button @click="closeinnerVisible" type="info" class="setpadding23"
            ><p class="footerleftbuttonp">
              {{ $t("CommonName.Cancel") }}
            </p></el-button
          >
          <el-button type="primary" @click="AddWords" class="setpadding23">
            <p class="footerrightbuttonp2" style="">
              {{ $t("toolset.confirm") }}
            </p>
          </el-button>
        </div>
      </el-dialog>
    </DialogComVue>
    <div
      class="progressdiv"
      v-if="percentage > 0"
      v-loading.fullscreen.lock="loading"
      element-loading-custom-class="disaplaynoneicon"
      element-loading-background="rgba(0, 0, 0, 0.3)"
    >
      <el-progress
        type="circle"
        :percentage="percentage"
        :stroke-width="10"
      ></el-progress>
    </div>
    <!--    <div-->
    <!--      class="progressdiv"-->
    <!--      v-if="-->
    <!--        this.fileList.length > 0 && totalProgress > 0 && totalProgress < 100-->
    <!--      "-->
    <!--      v-loading.fullscreen.lock="loading"-->
    <!--      element-loading-custom-class="disaplaynoneicon"-->
    <!--      element-loading-background="rgba(0, 0, 0, 0.3)"-->
    <!--    >-->
    <!--      <el-progress-->
    <!--        type="circle"-->
    <!--        :percentage="totalProgress"-->
    <!--        :stroke-width="10"-->
    <!--      ></el-progress>-->
    <!--    </div>-->
  </div>
</template>
<script>
import DialogComVue from "@/components/DialogCom.vue";
import {
  upload_split_srt,
  merge_srt,
  get_language,
  get_termfile_list,
} from "@/utils/https";
import { hideLoading, showLoading } from "@/utils/loading";
import input from "element-ui/packages/input";
import radio from "element-ui/packages/radio";
import config from "@/config";
export default {
  computed: {
    radio() {
      return radio;
    },
    input() {
      return input;
    },
    // totalProgress() {
    //   // console.log(this.fileList);
    //   const total = this.fileList.reduce(
    //     (acc, progressobj) => acc + progressobj.percentage,
    //     0
    //   );
    //   const total1 = this.fileList.reduce(
    //     (acc, progressobj) => acc + progressobj.size,
    //     0
    //   );
    //   console.log(total, total1);
    //   return Math.floor((total / total1) * 100);
    // },
  },
  components: {
    DialogComVue,
  },
  data() {
    return {
      form11Input: "",
      formatmessage: true, //上传文件 格式错误 提示标识
      sizemessage: true, //上传文件 大小错误 提示标识
      fileList: [], //set 合并 批量上传 数组
      dialogVisible: false,
      showTitle: false, //是否自定义 头部
      innerVisible: false,
      dialogtitle: "",
      TOOLSETID: "", //上传时 对应id
      uploadData: {}, //上传 另外参数
      loading: false,
      percentage: 0,
      showfooter: true,
      maxHeight: 200,
      // Segments Sorting
      form: {
        uploadinput1: "",
      },
      // Combine Documents
      form1: {
        resource: "1",
        uploadinput2: "",
        uploadinput1: "",
      },
      clickCombineDocuments: false,
      CombineDocumentsInfo: {},
      form4: {
        type: [],
      },
      innerVisibledia: {
        select: "0",
        input: "",
      },
      // id 4 添加选项
      checkboxList: [],
      // Words
      form5: {
        select: "0",
        tableData: [
          {
            Word: "zane6",
          },
          {
            Word: "zane6",
          },
          {
            Word: "zane6",
          },
          {
            Word: "zane6",
          },
          {
            Word: "zane6",
          },
        ],
      },
      selectList: [
        {
          id: "0",
          name: "English (United States)",
        },
      ],
      toolsetList: [],
      form8: {
        radio: "1",
        input: 2,
        uploadinput: "",
        tableData: [],
        rows_count: "0",
        word_count: "0",
        savename: "",
      },
      form9: {
        radio: "1",
        input: 0,
      },
      form10: {
        textarea: "",
      },
      form16: {
        select1: "", //Source language
        select2: "", //Target language
        select3: "", //术语表
        input: 16,
        input1: "",
        uploadinput1: "",
        source_path: "",
        uploadinput2: "",
        trans_path: "",
      },
      languageoptions: [], //所有 语言  数据
      languageObj: {
        //源语言 目标语言 创建任务时 互斥 关系
        selectLanguage1: [],
        selectLanguage2: [],
      },
      TermList: [], //术语表列表
      mediaUrl: config.mediaUrl.dev, //地址
      form18: {
        uploadinput2: "",
        excel_path: "",
        uploadinput1: "",
        srt_path: "",
        type: "1",
      },
    };
  },
  methods: {
    /**
     * 获取语言接口
     */
    get_language() {
      get_language({
        action: "get_language",
        lang:
          sessionStorage.getItem("lang") == "cn" ||
          sessionStorage.getItem("lang") == null
            ? 1
            : 2,
      }).then((res) => {
        if (res) {
          this.languageoptions = res.list;
          this.languageObj.selectLanguage1 = res.list;
          this.languageObj.selectLanguage2 = res.list;
        }
      });
    },
    /**
     *
     * @param {* 源语言 下拉选 语言 与目标语言 互斥} select
     */
    changeselectLanguage1(select) {
      let arr = JSON.parse(JSON.stringify(this.languageoptions));
      this.languageObj.selectLanguage2 = arr.filter(
        (i) => i.language_id !== select
      );
      this.form16.select3 = "";
      this.getTermList(select);
      // console.log(select, "select1");
    },
    /**
     *
     * @param {*目标语言 下拉选 语言 与源语言 互斥} select
     */
    changeselectLanguage2(select) {
      let arr = JSON.parse(JSON.stringify(this.languageoptions));
      this.languageObj.selectLanguage1 = arr.filter(
        (i) => i.language_id !== select
      );
    },
    /**
     *  分项检查工具
     *
     */
    ShareCheckingTool() {
      if (
        !this.form16.select1 ||
        !this.form16.select2 ||
        !this.form16.select3
      ) {
        this.$messages("warning", this.$t("CommonName.selectMessage"));
        return;
      }
      if (this.form16.input == "") {
        this.$messages("warning", this.$t("toolset.name16Text6"));
        return;
      }
      showLoading(this.$t("CommonName.loadingmessage"));
      upload_split_srt({
        action: "get_check_files_error_id",
        term_set_id: this.form16.select3,
        slang_id: this.form16.select1,
        tlang_id: this.form16.select2,
        char_limit: this.form16.input,
        trans_character: this.form16.input1,
      }).then((res) => {
        if (!res) {
          hideLoading();
          return;
        }
        let input = document.createElement("textarea"); // js创建一个input输入框
        input.value =
          this.mediaUrl +
          "ContainerHome/Team/HoldAll/share_check?cid=" +
          res.cid; // 将需要复制的文本赋值到创建的input输入框中
        document.body.appendChild(input); // 将输入框暂时创建到实例里面
        input.select(); // 选中输入框中的内容
        document.execCommand("Copy"); // 执行复制操作
        document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
        this.$messages("success", this.$t("toolset.name16Text10"));
        hideLoading();
      });
    },
    /**
     *  获取术语表
     */
    getTermList(slang_id) {
      get_termfile_list({
        action: "get_term_setlist",
        slang_id: slang_id,
      }).then((res) => {
        if (res) {
          this.TermList = res.list;
        }
      });
    },
    //检查数据验证
    checkingToolData() {
      if (
        !this.form16.select1 ||
        !this.form16.select2 ||
        !this.form16.select3
      ) {
        this.$messages("warning", this.$t("CommonName.selectMessage"));
        return;
      }
      if (this.form16.input == "") {
        this.$messages("warning", this.$t("toolset.name16Text6"));
        return;
      }
      if (!this.form16.uploadinput1 || !this.form16.uploadinput2) {
        this.$messages("warning", this.$t("CommonName.warningmessage5"));
        return;
      }
      showLoading(this.$t("CommonName.loadingmessage"));
      upload_split_srt({
        action: "check_files_error",
        term_set_id: this.form16.select3,
        slang_id: this.form16.select1,
        tlang_id: this.form16.select2,
        char_limit: this.form16.input,
        trans_character: this.form16.input1,
        source_path: this.form16.source_path,
        trans_path: this.form16.trans_path,
      }).then((res) => {
        if (!res) {
          hideLoading();
          return;
        }

        window.open(res.path, "_blank");
        this.dialogVisible = false;
        hideLoading();
      });
    },
    //导入Excel、SRT 并导出SRT
    ExcelAndSrtToSrt() {
      console.log(this.form18);
      if (!this.form18.uploadinput1 || !this.form18.uploadinput2) {
        this.$messages("warning", this.$t("toolset.uploadwarningmessage4"));
        return;
      }
      if (!this.form18.excel_path || !this.form18.srt_path) {
        this.$messages("warning", this.$t("toolset.uploadwarningmessage4"));
        return;
      }
      showLoading(this.$t("CommonName.loadingmessage"));
      upload_split_srt({
        action: "merge_excel_srt",
        excel_path: this.form18.excel_path,
        srt_path: this.form18.srt_path,
        type: this.form18.type,
      }).then((res) => {
        if (!res) {
          hideLoading();
          return;
        }
        window.open(res.url, "_blank");
        this.dialogVisible = false;
        hideLoading();
      });
    },
    /**
     * 分割srt文件
     * @param data
     */
    upload_split_srt(data, flag) {
      upload_split_srt(data)
        .then((res) => {
          if (res) {
            if (flag == 1) {
              this.form8.tableData = res.data;
            } else {
              window.open(res.url, "_blank");
              this.initData();
              this.percentage = 0;
              this.loading = false;
              this.dialogVisible = false;
            }
            hideLoading();
          }
          hideLoading();
        })
        .catch((err) => {
          this.$messages("error", err.msg);
          hideLoading();
        });
    },
    /**
     * 合并srt文件
     * @param data
     */
    merge_srt(data) {
      merge_srt(data)
        .then((res) => {
          if (res) {
            window.open(res.url, "_blank");
            this.initData();
            this.percentage = 0;
            this.loading = false;
            this.dialogVisible = false;
            this.fileList = [];
            hideLoading();
          }
        })
        .catch((err) => {
          this.$messages("error", err.msg);
          hideLoading();
        });
    },
    /**
     *
     * @param currentValue
     * @param oldValue
     */
    form8inputchange(currentValue, oldValue) {
      if (!currentValue) {
        return;
      }
      if (!this.form8.uploadinput) {
        return;
      }
      // showLoading(this.$t("CommonName.loadingmessage"));
      this.upload_split_srt(
        {
          action: "upload_split_srt",
          type: 1,
          count: this.form8.input,
          mode: this.form8.radio,
          is_upload: 0,
          savename: this.form8.savename,
          file_name: this.form8.uploadinput,
        },
        1
      );
    },
    /**
     *切分量 输入框 失去焦点时 触发接口调用
     * @param data
     */
    form8inputBuild() {
      if (!this.form8.input) {
        this.form8.input = 2;
      }
    },
    /**
     * 分割选项 选择
     * @param radio
     */
    form8RadioChange(radio) {
      if (!this.form8.uploadinput) {
        return;
      }
      showLoading(this.$t("CommonName.loadingmessage"));
      this.upload_split_srt(
        {
          action: "upload_split_srt",
          type: 1,
          count: this.form8.input,
          mode: radio,
          is_upload: 0,
          savename: this.form8.savename,
          file_name: this.form8.uploadinput,
        },
        1
      );
    },
    /**
     * 点击重新上传
     */
    updateNewfile(ref, flag, data) {
      this.clickCombineDocuments = !!flag;
      if (data === "1") {
        this.form.uploadinput1 = "";
      } else if (data === "2") {
        this.form1.uploadinput1 = "";
      } else if (data === "16") {
        this.form16.uploadinput1 = "";
      } else if (data === "15") {
        this.form16.uploadinput2 = "";
      } else if (data === "8") {
        this.form8 = {
          radio: "1",
          input: "2",
          uploadinput: "",
          tableData: [],
          rows_count: "0",
          word_count: "0",
          savename: "",
        };
      } else if (data === "18-1") {
        this.form18.uploadinput1 = "";
      } else if (data === "18-2") {
        this.form18.uploadinput2 = "";
      } else {
        this.form1.uploadinput2 = "";
      }
      let uploadFilesArr = this.$refs[ref].uploadFiles; //上传字幕文件
      if (uploadFilesArr.length === 0) {
        return;
      } else {
        this.$refs[ref].uploadFiles = [];
      }
    },
    /**
     * 初始化数据
     */
    initData() {
      this.form11Input = "";
      this.form = {
        uploadinput1: "",
      };
      this.form4 = {
        type: [],
      };
      this.checkboxList = [
        {
          id: "error_type_0",
          label: this.$t("toolset.Crossovertime"),
          showInput: false,
        },
        {
          id: "error_type_1",
          label: this.$t("toolset.ContainsEnglish"),
          showInput: false,
        },
        {
          id: "error_type_2",
          label: this.$t("toolset.Containsnumbers"),
          showInput: false,
        },
        {
          id: "error_type_3",
          label: this.$t("toolset.Emptysgement"),
          showInput: false,
        },
        {
          id: "error_type_4",
          label: this.$t("toolset.Morethanrows"),
          showInput: true,
          input: "2",
          disabled: true,
        },
        {
          id: "error_type_5",
          label: this.$t("toolset.Morethancharssinglelength"),
          showInput: true,
          input: "35",
          disabled: true,
        },
        {
          id: "error_type_6",
          label: this.$t("toolset.Durationlessthan"),
          showInput: true,
          input: "0",
          disabled: true,
        },
        {
          id: "error_type_7",
          label: this.$t("toolset.Morethancharstotallength"),
          showInput: true,
          input: "70",
          disabled: true,
        },
      ];
      this.form1 = {
        resource: "1",
        uploadinput2: "",
        uploadinput1: "",
      };
      this.CombineDocumentsInfo = {};
      this.clickCombineDocuments = false;
      this.form8 = {
        radio: "1",
        input: "2",
        uploadinput: "",
        tableData: [],
        rows_count: "0",
        word_count: "0",
        savename: "",
      };
      this.form9 = {
        radio: "1",
        input: 0,
      };
      this.form10 = {
        textarea: "",
      };
      this.fileList = [];
      this.form18 = {
        uploadinput2: "",
        excel_path: "",
        uploadinput1: "",
        srt_path: "",
        type: "1",
      };
    },
    /**
     *
     * @param {*关闭弹窗} val
     */
    changeDialogVisible(val) {
      this.dialogVisible = val;
      this.initData();
    },
    /**
     * 弹窗 确定 按钮
     */
    determineClick() {
      // 检查
      if (this.TOOLSETID === "16") {
        this.checkingToolData();
        return;
      }
      // 导入Excel、SRT 并导出SRT
      if (this.TOOLSETID === "18") {
        this.ExcelAndSrtToSrt();
        return;
      }
      // 视频转换
      if (this.TOOLSETID === "15") {
        showLoading(this.$t("CommonName.loadingmessage"));
        upload_split_srt({
          action: "check_video_change",
          email: this.form11Input,
        }).then((res) => {
          if (!res) {
            hideLoading();
            return;
          }
          this.initData();
          this.dialogVisible = false;
          this.$messages("success", this.$t("toolset.name15Text1"));
          hideLoading();
        });
      }
      if (this.TOOLSETID === "11") {
        if (!this.form10.textarea) {
          this.$messages("warning", this.$t("toolset.ASS1"));
          return;
        }
      }
      if (
        ["0", "3", "4", "6", "11", "12", "13", "14", "17"].includes(
          this.TOOLSETID
        )
      ) {
        if (!this.form.uploadinput1) {
          this.$messages("warning", this.$t("toolset.uploadwarningmessage4"));
          return;
        }
      }
      if (["1", "7"].includes(this.TOOLSETID)) {
        if (!this.form1.uploadinput1 || !this.form1.uploadinput2) {
          this.$messages("warning", this.$t("toolset.uploadwarningmessage4"));
          return;
        }
      }
      switch (this.TOOLSETID) {
        case "0":
          this.uploadData = {
            action: "upload_srt_file",
          };
          break;
        case "1":
          this.uploadData = {
            action: "upload_time_replace_srt_file",
            savename: this.CombineDocumentsInfo.savename,
            name: this.CombineDocumentsInfo.name,
            time_axis_type: this.form1.resource,
          };
          break;
        case "2":
          this.uploadData = {
            action: "upload_excel_srt_zip_file",
          };
          break;
        case "3":
          this.uploadData = {
            action: "upload_utf_srt_file",
          };
          break;
        case "4":
          this.uploadData = this.setform4Datas(this.form4.type);
          break;
        case "6":
          this.uploadData = {
            action: "upload_exchange_srt_file",
          };
          break;
        case "7":
          this.uploadData = {
            action: "upload_compose_target_srt_file",
            savename: this.CombineDocumentsInfo.savename,
            name: this.CombineDocumentsInfo.name,
            type: this.form1.resource,
          };
          break;
        case "8":
          if (!this.form8.uploadinput) {
            return;
          }
          showLoading(this.$t("CommonName.loadingmessage"));
          this.upload_split_srt(
            {
              action: "upload_split_srt",
              type: 2,
              count: this.form8.input,
              mode: this.form8.radio,
              is_upload: 0,
              savename: this.form8.savename,
              file_name: this.form8.uploadinput,
            },
            2
          );
          break;
        case "9":
          if (this.fileList.length === 0) {
            return;
          }
          showLoading(this.$t("CommonName.loadingmessage"));
          this.merge_srt({
            action: "merge_srt", //方法名
            files: JSON.stringify(this.fileList),
            mode: this.form9.radio,
            millisecond: this.form9.input,
          });
          break;
        case "11":
          this.uploadData = {
            action: "srt_to_ass",
            ass_content: this.form10.textarea,
          };
          break;
        case "12":
          this.uploadData = {
            action: "upload_excel_srt_zip_file",
          };
          break;
        case "13":
          this.uploadData = {
            action: "upload_merge_srt_zip_file",
          };
          // console.log(11111);
          break;
        case "14":
          this.uploadData = {
            action: "upload_bilingual_srt_zip_file",
          };
          // console.log(11111);
          break;
        case "17":
          this.uploadData = {
            action: "upload_merge_srt_zip_file_hua",
          };
          // console.log(11111);
          break;
        default:
          break;
      }
      if (
        this.TOOLSETID !== "8" &&
        this.TOOLSETID !== "9" &&
        this.TOOLSETID !== "15"
      ) {
        this.$nextTick(() => {
          this.$refs.upload.submit();
        });
      }
    },
    /**
     *
     * @param {处理 form4 数据} list
     */
    setform4Datas(list) {
      let uploadDatas = {
        action: "upload_error_srt_file",
        error_type_0: "",
        error_type_1: "",
        error_type_2: "",
        error_type_3: "",
        error_type_4: "",
        error_type_5: "",
        error_type_6: "",
        error_type_7: "",
        rows: "",
        chars: "",
        duration: "",
        chars_total: "",
      };
      if (list.includes("error_type_0")) {
        uploadDatas.error_type_0 = "1";
      } else {
        uploadDatas.error_type_0 = "0";
      }
      if (list.includes("error_type_1")) {
        uploadDatas.error_type_1 = "1";
      } else {
        uploadDatas.error_type_1 = "0";
      }
      if (list.includes("error_type_2")) {
        uploadDatas.error_type_2 = "1";
      } else {
        uploadDatas.error_type_2 = "0";
      }
      if (list.includes("error_type_3")) {
        uploadDatas.error_type_3 = "1";
      } else {
        uploadDatas.error_type_3 = "0";
      }
      if (list.includes("error_type_4")) {
        this.checkboxList.map((i) => {
          if (i.id == "error_type_4") uploadDatas.rows = i.input;
        });
        uploadDatas.error_type_4 = "1";
      } else {
        uploadDatas.rows = "2";
        uploadDatas.error_type_4 = "0";
      }
      if (list.includes("error_type_5")) {
        this.checkboxList.map((i) => {
          if (i.id == "error_type_5") uploadDatas.chars = i.input;
        });
        uploadDatas.error_type_5 = "1";
      } else {
        uploadDatas.chars = "35";
        uploadDatas.error_type_5 = "0";
      }
      if (list.includes("error_type_6")) {
        this.checkboxList.map((i) => {
          if (i.id == "error_type_6") uploadDatas.duration = i.input;
        });
        uploadDatas.error_type_6 = "1";
      } else {
        uploadDatas.duration = "0";
        uploadDatas.error_type_6 = "0";
      }
      if (list.includes("error_type_7")) {
        this.checkboxList.map((i) => {
          if (i.id == "error_type_7") uploadDatas.chars_total = i.input;
        });
        uploadDatas.error_type_7 = "1";
      } else {
        uploadDatas.chars_total = "70";
        uploadDatas.error_type_7 = "0";
      }
      return uploadDatas;
    },
    /**
     *
     * @param {*点击工具箱每一项} item
     */
    clicktoolse(item) {
      if (item.id === "16") {
        let pathInfo = this.$router.resolve({
          path: "/HoldAll/HiventyCheck",
        });
        window.open(pathInfo.href, "_blank");
        return;
        // this.showTitle = true;
        // //查询语言
        // this.get_language();
        // this.form16 = {
        //   select1: "", //Source language
        //   select2: "", //Target language
        //   select3: "", //术语表
        //   input: 16,
        //   input1: "",
        //   uploadinput1: "",
        //   source_path: "",
        //   uploadinput2: "",
        //   trans_path: "",
        // };
      }
      if (item.id === "10") {
        let pathInfo = this.$router.resolve({
          path: "/ContainerHome/Team/HoldAll/chat_AI",
        });
        window.open(pathInfo.href, "_blank");
        return;
      }
      this.showfooter = item.id !== "5";
      this.dialogtitle = item.name;
      this.dialogVisible = true;
      this.showTitle = false;
      this.TOOLSETID = item.id;
      if (["8", "9"].includes(item)) {
        this.initData();
      }
    },
    // words页面 中AddWord 按钮
    AddWordsClick() {
      this.innerVisible = true;
    },
    /**
     * 内层弹窗内部 确定按钮
     */
    AddWords() {},
    /**
     * 内层弹窗 关闭按钮
     */
    closeinnerVisible() {
      this.innerVisible = false;
      this.innerVisibledia = {
        select: "0",
        input: "",
      };
    },
    /**
     *
     * @param {*上传之前} file
     */
    beforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isLt100M = file.size / 1024 / 1024 < 100;
      const extension = testmsg === "srt";
      if (!extension) {
        this.$messages("warning", this.$t("toolset.uploadwarningmessage3"));
        return false;
      } else if (!isLt100M) {
        this.$messages("warning", this.$t("toolset.uploadwarningmessage2"));
        return false;
      } else {
        return true;
      }
    },
    /**
     * 点击 上传
     */
    merge_updateNewfile() {
      this.formatmessage = true;
      this.sizemessage = true;
    },
    /**
     * 超出最大上传数量
     * @param filelist
     */
    merge_uploadExceed(filelist) {
      this.$messages(
        "warning",
        this.$t("PersonalEndLanguage.warningmessage11")
      );
    },
    /**
     * 批量上传 上传之前
     * @param file
     * @returns {boolean}
     */
    merge_beforeUpload(file) {
      if (!file) return;
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isLt100M = file.size / 1024 / 1024 < 100;
      const extension = testmsg.toLowerCase() === "srt";
      if (!extension) {
        if (this.formatmessage) {
          this.$messages("warning", this.$t("toolset.uploadwarningmessage3"));
          this.formatmessage = false;
        }
        return false;
      } else if (!isLt100M) {
        if (this.sizemessage) {
          this.$messages("warning", this.$t("toolset.uploadwarningmessage2"));
          this.sizemessage = false;
        }
        return false;
      } else {
        file.rows_count = 0;
        file.savename = "";
        file.min_time = "";
        file.max_time = "";
        file.filename = "";
        file.percentage = 0;
        this.clickOn(file);
        this.fileList.sort((a, b) => {
          let nameA = a.name.toLowerCase();
          let nameB = b.name.toLowerCase();
          return nameA.localeCompare(nameB);
        });
        return true;
      }
    },
    /**
     *  删除 对应 上传的 文件
     * @param e
     * @param item
     * @constructor
     */
    UploadListDeleteClick(e, item) {
      this.fileList.map((i, index) => {
        if (i.name == item.name) {
          this.fileList.splice(index, 1);
        }
      });
      e.stopPropagation();
    },
    /**
     * 清空 批量上传 的文件
     */
    clearFileList() {
      this.fileList = [];
    },
    /**
     * 判断是否 存在相同名称 文件
     * @param obj
     */
    clickOn(obj) {
      if (this.fileList.length > 0) {
        //find方法查询是否存在相同的数据
        let isAdd = this.fileList.find((item, index) => {
          if (item.name == obj.name) {
            //存在相同的数据调用vue的$set方法替换value值
            // this.$set(item, "value", obj.val);
            this.fileList.splice(index, 1, obj);
            //数组中的元素在测试条件时返回 true 时, find()返回符合条件的元素
            return true;
          }
          //如果不存在find方法会返回一个undefined
        });
        console.log(isAdd);
        // 判断isAdd是否为undefined
        if (typeof isAdd == "undefined") {
          //当isAdd为undefined添加新的对象到数组中
          this.fileList.push(obj);
        }
      } else {
        this.fileList.push(obj);
      }
    },
    /**
     * 批量上传 上传进度
     * @param file
     */
    merge_uploadProgress(file) {
      // console.log(file);
      // // if (file.percent > 0) {
      // //   this.percentage = Number(file.percent.toFixed());
      // this.loading = true;
      // this.fileList.forEach((i) => {
      //   if (i.name == file.name) {
      //     console.log(file.loaded);
      //     i.percentage += file.loaded;
      //   }
      // });
      // }
    },
    merge_uploadSuccess(response) {
      if (response.result == "success") {
        this.fileList.forEach((i) => {
          if (i.name == response.info.filename) {
            i.rows_count = response.info.rows_count;
            i.savename = response.info.savename;
            i.min_time = response.info.min_time;
            i.max_time = response.info.max_time;
            i.filename = response.info.filename;
            // console.log(i);
            this.$forceUpdate();
            // this.$set(i, index, i);
          }
        });
        // this.percentage = 0;
        // this.loading = false;
      } else if (response.result == "LOGIN_FAILED") {
        this.$messages("warning", this.$t("CommonName.warningmessage4"));
        this.$router.push("/Login");
        this.$Cookie.remove("token_code");
        sessionStorage.clear();
      } else {
        this.percentage = 0;
        this.loading = false;
        this.$messages("error", response.msg);
      }
    },
    /**
     *
     * @param {*上传之前} file
     */
    beforeUploadZip(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isLt100M = file.size / 1024 / 1024 < 100;
      const extension = testmsg === "zip";
      if (!extension) {
        this.$messages("warning", this.$t("toolset.uploadwarningmessage1"));
        return false;
      } else if (!isLt100M) {
        this.$messages("warning", this.$t("toolset.uploadwarningmessage2"));
        return false;
      } else {
        return true;
      }
    },
    /**
     *
     * @param {*上传之前} file
     */
    beforeUploadExcel(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isLt100M = file.size / 1024 / 1024 < 100;
      const extension = testmsg === "xlsx";
      if (!extension) {
        this.$messages(
          "warning",
          this.$t("TermBaseManagement.warningmessage1")
        );
        return false;
      } else if (!isLt100M) {
        this.$messages("warning", this.$t("toolset.uploadwarningmessage2"));
        return false;
      } else {
        return true;
      }
    },
    // 上传 状态
    uploadChange(file, fileList) {
      if (!file) return;
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isLt100M = file.size / 1024 / 1024 < 100;
      let extension;
      if (
        ["0", "3", "4", "6", "11", "12", "13", "14", "17"].includes(
          this.TOOLSETID
        )
      ) {
        extension = testmsg === "zip";
      } else if (["1", "7"].includes(this.TOOLSETID)) {
        extension = testmsg === "srt";
      } else {
        extension = testmsg === "xlsx";
      }
      if (!extension) {
        if (
          ["0", "3", "4", "6", "11", "12", "13", "14", "17"].includes(
            this.TOOLSETID
          )
        ) {
          this.$messages("warning", this.$t("toolset.uploadwarningmessage1"));
          this.form.uploadinput1 = "";
          return false;
        } else if (["1", "7"].includes(this.TOOLSETID)) {
          this.$messages("warning", this.$t("toolset.uploadwarningmessage3"));
          this.form1.uploadinput2 = "";
          return false;
        } else {
          this.$messages(
            "warning",
            this.$t("TermBaseManagement.warningmessage1")
          );
          return false;
        }
      } else if (!isLt100M) {
        this.$messages("warning", this.$t("toolset.uploadwarningmessage2"));
        if (
          ["0", "2", "3", "6", "11", "12", "13", "14", "17"].includes(
            this.TOOLSETID
          )
        ) {
          this.form.uploadinput1 = "";
        } else if (this.TOOLSETID === "4") {
          this.form.uploadinput1 = "";
        } else if (["7", "1"].includes(this.TOOLSETID)) {
          this.form1.uploadinput2 = "";
        }
        return false;
      } else {
        if (
          ["0", "2", "3", "6", "11", "12", "13", "14", "17"].includes(
            this.TOOLSETID
          )
        ) {
          this.form.uploadinput1 = file.name;
          return true;
        } else if (this.TOOLSETID === "4") {
          this.form.uploadinput1 = file.name;
          return true;
        } else if (["7", "1"].includes(this.TOOLSETID)) {
          this.form1.uploadinput2 = file.name;
          return true;
        }
      }
    },
    /**
     *
     * @param {*上传过程中} file
     */
    uploadProgress(file) {
      // console.log(file);
      // showLoading();
      if (file.percent > 0) {
        this.percentage = Number(file.percent.toFixed());
        this.loading = true;
      }
    },
    /**
     *
     * @param {*上传成功时} response
     */
    uploadSuccess(response) {
      if (response.result == "success") {
        if (this.TOOLSETID == "16") {
          this.percentage = 0;
          this.loading = false;
          this.form16.source_path = response.path;
          this.form16.uploadinput1 = response.show_name;
          return;
        }
        if (this.TOOLSETID == "18") {
          this.percentage = 0;
          this.loading = false;
          this.form18.excel_path = response.path;
          this.form18.uploadinput2 = response.show_name;
          return;
        }
        if (this.clickCombineDocuments === true) {
          this.form8.tableData = response.data;
          this.form8.rows_count = response.rows_count;
          this.form8.word_count = response.word_count;
          this.form8.uploadinput = response.info.name;
          this.form8.savename = response.info.savename;
          this.CombineDocumentsInfo = response.info;
          this.form1.uploadinput1 = response.info.name;
          this.percentage = 0;
          this.loading = false;
          this.clickCombineDocuments = false;
          return;
        }
        setTimeout(() => {
          this.initData();
        });
        this.percentage = 0;
        this.loading = false;
        this.dialogVisible = false;
        if (
          ["0", "3", "4", "1", "6", "7", "11", "12", "13", "14", "17"].includes(
            this.TOOLSETID
          )
        ) {
          window.open(response.url, "_blank");
          // window.location.href = response.url;
        }
      } else if (response.result == "LOGIN_FAILED") {
        this.$messages("warning", this.$t("CommonName.warningmessage4"));
        this.$router.push("/Login");
        this.$Cookie.remove("token_code");
        sessionStorage.clear();
      } else {
        this.percentage = 0;
        this.loading = false;
        this.$messages("error", response.msg);
      }
    },
    /**
     *
     * @param {*上传成功时} response
     */
    uploadSuccess1(response) {
      if (response.result == "success") {
        if (this.TOOLSETID == "16") {
          this.percentage = 0;
          this.loading = false;
          this.form16.trans_path = response.path;
          this.form16.uploadinput2 = response.show_name;
          return;
        }
        if (this.TOOLSETID == "18") {
          this.percentage = 0;
          this.loading = false;
          this.form18.srt_path = response.path;
          this.form18.uploadinput1 = response.show_name;
          return;
        }
      } else if (response.result == "LOGIN_FAILED") {
        this.$messages("warning", this.$t("CommonName.warningmessage4"));
        this.$router.push("/Login");
        this.$Cookie.remove("token_code");
        sessionStorage.clear();
      } else {
        this.percentage = 0;
        this.loading = false;
        this.$messages("error", response.msg);
      }
    },
  },
  mounted() {
    this.checkboxList = [
      {
        id: "error_type_0",
        label: this.$t("toolset.Crossovertime"),
        showInput: false,
      },
      {
        id: "error_type_1",
        label: this.$t("toolset.ContainsEnglish"),
        showInput: false,
      },
      {
        id: "error_type_2",
        label: this.$t("toolset.Containsnumbers"),
        showInput: false,
      },
      {
        id: "error_type_3",
        label: this.$t("toolset.Emptysgement"),
        showInput: false,
      },
      {
        id: "error_type_4",
        label: this.$t("toolset.Morethanrows"),
        showInput: true,
        input: "2",
        disabled: true,
      },
      {
        id: "error_type_5",
        label: this.$t("toolset.Morethancharssinglelength"),
        showInput: true,
        input: "35",
        disabled: true,
      },
      {
        id: "error_type_6",
        label: this.$t("toolset.Durationlessthan"),
        showInput: true,
        input: "0",
        disabled: true,
      },
      {
        id: "error_type_7",
        label: this.$t("toolset.Morethancharstotallength"),
        showInput: true,
        input: "70",
        disabled: true,
      },
      // {
      //   id: "error_type_8",
      //   label: this.$t("toolset.Morethancharstotallength"),
      //   showInput: true,
      //   input: "70",
      //   disabled: true,
      // },
    ];
    this.toolsetList = [
      {
        id: "0",
        name: this.$t("toolset.toolsetList.name0"),
        icon: "SegmentsSorting",
      },
      {
        id: "1",
        name: this.$t("toolset.toolsetList.name1"),
        icon: "CombineDocuments",
      },
      // {
      //   id: "2",
      //   name: this.$t("toolset.toolsetList.name2"),
      //   icon: "ExcelToSrtFile",
      // },
      {
        id: "3",
        name: this.$t("toolset.toolsetList.name3"),
        icon: "UTF-8",
      },
      {
        id: "4",
        name: this.$t("toolset.toolsetList.name4"),
        icon: "ErrorCheck",
      },
      // {
      //   id: "5",
      //   name: this.$t("toolset.toolsetList.name5"),
      //   icon: "Words",
      // },
      {
        id: "6",
        name: this.$t("toolset.toolsetList.name6"),
        icon: "ExchangeOrder",
      },
      {
        id: "7",
        name: this.$t("toolset.toolsetList.name7"),
        icon: "MergingBilingualFiles",
      },
      {
        id: "8",
        name: this.$t("toolset.toolsetList.name8"),
        icon: "split_SRT",
      },
      {
        id: "9",
        name: this.$t("toolset.toolsetList.name9"),
        icon: "merge_SRT",
      },
      {
        id: "10",
        name: this.$t("toolset.toolsetList.name10"),
        icon: "chat_AI",
      },
      {
        id: "11",
        name: this.$t("toolset.toolsetList.name11"),
        icon: "SRT_ASS",
      },
      {
        id: "12",
        name: this.$t("toolset.toolsetList.name12"),
        icon: "ExcelToSrtFile",
      },
      {
        id: "13",
        name: this.$t("toolset.toolsetList.name13"),
        icon: "ExcelToSrtFile",
      },
      {
        id: "14",
        name: this.$t("toolset.toolsetList.name14"),
        icon: "ExcelToSrtFile",
      },
      {
        id: "15",
        name: this.$t("toolset.toolsetList.name15"),
        icon: "CombineDocuments",
      },
      {
        id: "16",
        name: this.$t("toolset.toolsetList.name16"),
        icon: "ErrorCheck",
      },
      {
        id: "17",
        name: this.$t("toolset.toolsetList.name17"),
        icon: "ExcelToSrtFile",
      },
      {
        id: "18",
        name: this.$t("toolset.toolsetList.name18"),
        icon: "ExcelToSrtFile",
      },
    ];
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/dialogStyle.scss";

.titleStyle {
  margin: 24px 0;
}

.mainCenterbtm {
  margin: 0 12px;
  display: flex;
  flex-wrap: wrap;

  .toolseAlldiv {
    width: 30%;
    background-color: #494b5c;
    height: 80px;
    border: 1px solid;
    border-color: #5a5b63;
    border-radius: 4px;
    margin: 0 12px;
    color: #ffffff;
    margin-bottom: 24px;
    cursor: pointer;

    .toolsediv {
      height: 80px;
      display: flex;
      align-items: center;

      p {
        font-size: 18px;
      }

      .icon {
        width: 48px;
        height: 48px;
        margin: 0 20px;
      }
    }

    &:hover {
      background: #54576e;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
    }
  }
}

.dialogContent {
  margin: 24px;

  .content {
    p {
      color: #adb0bc;
      font-size: 14px;
      margin-bottom: 0;
    }

    .uploadp {
      display: flex;
      align-items: center;
      margin-bottom: 6px;

      .icon {
        cursor: pointer;
        margin-left: 6px;
        color: #adb0bc;
      }
    }

    .srtUnput {
      ::v-deep .el-input__inner {
        width: 755px !important;
      }

      ::v-deep .el-input-group__append,
      .el-input-group__prepend {
        width: 84px;
        background: none;
        border: 1px solid #7d7f8b;
        border-left: none;
        text-align: center;
        padding: 0;
      }

      .icon {
        margin-right: 6px;
      }
    }
    .AverageClass {
      display: flex;
      width: 100%;
      justify-content: space-between;
      p {
        width: 400px;
      }
      div {
        width: 400px;
        ::v-deep .el-input__inner {
          width: 400px !important;
        }
      }
    }
  }

  .toolsetListname1 {
    p {
      color: #adb0bc;
      font-size: 14px;
      margin-bottom: 0px;
    }

    .uploadp {
      margin-top: 16px;
    }

    .content_btm {
      .radioClass {
        margin-top: 6px;
      }
    }

    .content_btm_1 {
      .radioClass {
        margin-top: 6px;
      }

      .el-radio.is-bordered {
        width: 420px;
      }
    }
  }

  .name5form5 {
    .content_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .tableborderClass {
      margin-top: 16px;

      .deleteIcon {
        font-size: 18px;
      }
    }
  }

  .name4form4 {
    .top_p {
      color: #adb0bc;
      font-size: 14px;
    }

    .content {
      margin-top: 16px;
    }

    .checkboxClass {
      margin-top: 6px;

      .el-checkbox.is-bordered {
        width: 840px;
        height: 40px;
      }

      .el-button + .el-button,
      .el-checkbox.is-bordered + .el-checkbox.is-bordered {
        margin-left: 0;
      }

      .checkboxinputdiv {
        width: 800px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .checkboxinput {
          width: 380px;
          height: 38px;
          line-height: 38px;
          background-color: #454758;

          ::v-deep .el-input__inner {
            width: 256px;
          }
        }
      }
    }
  }

  .inputNumber {
    ::v-deep .el-input-number {
      width: 150px !important;

      .el-input__inner {
        text-align: left !important;
      }
    }

    ::v-deep .el-input-number.is-controls-right .el-input-number__increase {
      border-bottom: none !important;
    }

    ::v-deep .el-input-number__increase {
      border-left: none !important;
    }

    ::v-deep .el-input-number.is-controls-right .el-input-number__decrease {
      border-left: none !important;
    }

    ::v-deep .el-input-number__decrease,
    ::v-deep .el-input-number__increase {
      background: none !important;
      color: #adb0bc !important;
      width: auto !important;
      margin-right: 10px !important;
      font-size: 16px !important;
    }

    ::v-deep .el-input-number.is-controls-right .el-input-number__decrease,
    ::v-deep .el-input-number.is-controls-right .el-input-number__increase {
      line-height: 16px !important;
    }

    ::v-deep .el-input-number__increase {
      top: 4px !important;
    }

    ::v-deep .el-input-number__decrease {
      bottom: 4px !important;
    }
  }

  .name8form8 {
    .title_p {
      color: #adb0bc;
      font-size: 14px;
      margin-bottom: 6px;
    }

    .name8form8_top {
      display: flex;

      .name8form8_top_left {
        width: 25%;

        .el-radio-group {
          height: 70px;
          width: fit-content;
          display: flex;
          flex-direction: column !important;
          justify-content: space-between;
        }

        .el-radio.is-bordered {
          width: 100px;
        }
      }

      .name8form8_top_center {
        width: 25%;

        ::v-deep .el-input__inner {
          width: 150px !important;
        }
      }

      .name8form8_top_right {
        width: 50%;

        div {
          p {
            color: #ffffff;
          }
        }
      }
    }

    .name8form8_center {
      margin: 24px 0 24px 0;
    }
  }

  .name9form9 {
    .top_div {
      width: 100%;
      height: 300px;
      display: flex;
      justify-content: space-between;
    }

    .top_div_left {
      width: 90%;
      height: 100%;

      .upload-demo {
        height: 100%;
        width: 100%;
      }

      ::v-deep .el-upload {
        width: 100% !important;
        height: 100% !important;
      }

      ::v-deep .el-upload-dragger {
        width: 100% !important;
        height: 100% !important;
        background-color: inherit !important;
      }

      .uploaddiv {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .el-icon-plus {
          font-size: 15.66px;
          color: #46adff;
        }

        .topp {
          color: #46adff;
          font-size: 14px;
          margin-top: 11.9px;
        }

        .botp {
          color: #787e83;
          font-size: 10px;
          margin-top: 8px;
          text-align: center;
        }

        .filelist_div {
          width: 100%;
          height: 100%;

          .upload_table_div {
            .widthClass {
              width: 240px;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            height: 250px;
            width: 100%;

            .videoDatanamediv_header {
              display: flex;
              padding-top: 15px;

              p {
                color: #adb0bc;
                font-size: 12px;
                width: 240px;
                text-align: left;
              }
            }

            .videoDatanamediv_body_list {
              display: flex;
              flex-direction: column;
              align-items: center;
              overflow-y: auto;
              height: calc(100% - 34px);

              .videoDatanamediv_body {
                display: flex;
                align-items: center;
                width: 820px;
                height: 48px;
                background: #474e61;
                border-radius: 4px;
                margin-bottom: 3px;

                //.svgdivs {
                //margin-left: 17px
                //}

                .left_div {
                  @extend .widthClass;

                  .body_p {
                    color: #ffffff;
                    font-size: 14px;
                    width: 220px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }

                .center_input {
                  @extend .widthClass;
                  margin-left: 20px;

                  ::v-deep .el-input__inner {
                    width: 170px !important;
                  }

                  //::v-deep .el-input__suffix {
                  //  right: 40px !important;
                  //  top: 1px;
                  //}
                  ::v-deep .el-input-group__append,
                  .el-input-group__prepend {
                    // right: 10px;
                    width: 70px;
                    background: #393c4e;
                    border: 1px solid #7d7f8b;
                    border-left: none;
                    text-align: center;
                    padding: 0;
                  }
                }

                .right_select {
                  @extend .widthClass;
                  margin-left: 20px;

                  .selectclass {
                    ::v-deep .el-input__inner {
                      width: 240px !important;
                    }
                  }
                }

                .last_delete {
                  width: 7%;

                  .icon {
                    width: 18px;
                    height: 18px;
                  }
                }
              }
            }
          }

          .p_div {
            display: flex;
            justify-content: center;
            align-items: center;
            height: calc(100% - 250px);

            p {
              margin-left: 10px;
            }
          }
        }
      }
    }

    .btm_div {
      display: flex;
      margin-top: 15px;

      .btm_div_left {
        width: 50%;

        .el-radio-group {
          height: 40px;
          width: fit-content;
          display: flex;
          flex-direction: column !important;
          justify-content: space-between;
        }

        .radioClass {
          margin-top: 6px;
        }
      }

      .btm_div_right {
        width: 50%;
        display: flex;
        align-items: center;

        p {
          color: #ffffff;
          font-size: 14px;
          width: 250px;
        }

        ::v-deep .el-input__inner {
          width: 150px !important;
        }
      }
    }
  }

  .name11form11 {
    ::v-deep .el-input__inner {
      width: 100%;
    }
  }

  .name10form10 {
    .name10form10_top {
      .title_p {
        color: #adb0bc;
        font-size: 14px;
        margin-bottom: 6px;
      }

      ::v-deep .el-textarea__inner {
        background: #424657 !important;
        width: 100% !important;
        height: 200px !important;
        overflow-y: auto !important;
        border: 1px solid !important;
        border-color: #6d6f7d !important;
        border-radius: 4px 0 0 4px !important;
        padding: 11px 9px !important;

        &:focus {
          color: #ffffff !important;
        }
      }

      margin-bottom: 10px;
    }
  }

  // .name11form11 {
  // }
}

.innerVisiblecentent {
  margin: 24px;

  .content_top_1_input {
    ::v-deep .el-input__inner {
      width: 840px !important;
    }
  }
}

.content_top_1 {
  p {
    color: #adb0bc;
    font-size: 14px;
    margin-bottom: 6px;
  }
}

.selectclass {
  ::v-deep .el-input__inner {
    width: 220px !important;
  }

  ::v-deep .el-input__icon {
    line-height: 32px;
  }
}
.selectclass16 {
  ::v-deep .el-input__inner {
    width: 840px !important;
  }

  ::v-deep .el-input__icon {
    line-height: 32px;
  }
}
.slottitle {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin-left: 24px;
  }

  .el-button {
    margin-right: 75px;
  }
}
</style>
