<template>
  <div class="container_div">
    <el-tabs v-model="activeName" @tab-click="handleTabClick">
      <!--      转写-->
      <el-tab-pane
        :label="$t('ProjectDetails.Transcription')"
        name="1"
        v-if="['1', '3', '5', '6', '7'].includes(jobtype)"
      ></el-tab-pane>
      <!--      校轴-->
      <el-tab-pane
        :label="$t('ProjectDetails.TranscriptionQC')"
        name="5"
        v-if="['6', '7'].includes(jobtype)"
      ></el-tab-pane>
      <!--      翻译-->
      <el-tab-pane
        :label="$t('ProjectDetails.Translation')"
        name="2"
        v-if="['2', '3', '4', '5', '7'].includes(jobtype)"
      ></el-tab-pane>
      <!--      校对-->
      <el-tab-pane
        :label="$t('ProjectDetails.Review')"
        name="3"
        v-if="['4', '5', '7'].includes(jobtype)"
      ></el-tab-pane>
    </el-tabs>
    <div
      class="main_btm_div"
      v-if="
        (activeName === '1' ||
          activeName === '2' ||
          activeName === '3' ||
          activeName === '5') &&
        mainData.job_info
      "
    >
      <!--     字幕信息 -->
      <p class="title_p">{{ $t("toolset.srtText5") }}</p>
      <div class="main_btm_div_topDiv">
        <p>{{ $t("SplitTask.name") + mainData.job_info.line }}</p>
        <p>{{ $t("SplitTask.name1") + mainData.job_info.duration + "min" }}</p>
        <p class="overflowEllipsis">
          {{ $t("SplitTask.name2") + mainData.job_info.job_name }}
        </p>
      </div>
      <div class="main_btm_div_centerDiv">
        <div class="first_div">
          <!--          拆分方式-->
          <p class="title_p">{{ $t("SplitTask.name3") }}</p>
          <div class="radioClass">
            <el-radio-group
              v-model="newArr[activeName].radio"
              :disabled="
                newArr[activeName].tableData.length > 0 &&
                newArr[activeName].tableData.some((i) => i.job_split_id > 0)
              "
            >
              <!--              行数-->
              <el-radio border label="1">{{
                $t("toolset.srtText11")
              }}</el-radio>
              <!--              时长-->
              <el-radio border label="2">{{ $t("MyFiles.duration") }}</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div>
          <!--          相等部分时长-->
          <p class="title_p">{{ $t("toolset.srtText4") }}</p>
          <div style="display: flex">
            <div class="inputNumber">
              <el-input-number
                v-model="newArr[activeName].input"
                controls-position="right"
                :min="2"
                :max="mainData.job_info.line"
                :precision="0"
                @blur="inputNumber_blur"
                :disabled="
                  newArr[activeName].tableData.length > 0 &&
                  newArr[activeName].tableData.some((i) => i.job_split_id > 0)
                "
              ></el-input-number>
            </div>
            <div style="margin-left: 24px">
              <el-button
                type="primary"
                plain
                class="setpadding16"
                @click="
                  splitClick(newArr[activeName].radio, newArr[activeName].input)
                "
                :disabled="
                  newArr[activeName].tableData.length > 0 &&
                  newArr[activeName].tableData.some((i) => i.job_split_id > 0)
                "
              >
                <div class="svgbtn">
                  <svg-icon icon-class="Split" class="icon"></svg-icon>
                  <!--                  拆分-->
                  <p>{{ $t("ProjectDetails.dropdownList.name9") }}</p>
                </div>
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="Dashed"></div>
      <div
        :class="
          newArr[activeName].tableData.length === 0 ? 'tablebtmborder' : ''
        "
        class="main_btm_div_btmDiv tableborderClass tableClass borderTableStyle"
      >
        <!--        预览-->
        <p class="title_p">{{ $t("toolset.srtText9") }}</p>
        <el-table
          :row-style="{
            height: 52 + 'px',
            background: '#454758',
            color: '#ffffff',
          }"
          :cell-style="{ padding: 0 + 'px' }"
          :header-cell-style="{
            height: 32 + 'px',
            padding: '0px',
            color: '#adb0bc',
            background: '#454758',
          }"
          :data="newArr[activeName].tableData"
          tooltip-effect="dark"
          style="width: 100%"
          max-height="200px"
          border
        >
          <!--       行数   -->
          <el-table-column :label="$t('toolset.srtText11')" width="90">
            <template slot-scope="scope">
              <el-input v-model="scope.row.line" :disabled="true"></el-input>
            </template>
          </el-table-column>
          <!--          时长（分钟）-->
          <el-table-column
            :label="$t('MyFiles.duration') + $t('PersonalCenter.unit')"
            width="114"
          >
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.duration_minute"
                :disabled="true"
              ></el-input>
            </template>
          </el-table-column>
          <!--     任务名称     -->
          <el-table-column :label="$t('CommonName.TaskName')" width="241">
            <template slot-scope="scope">
              <el-input v-model="scope.row.job_name"></el-input>
            </template>
          </el-table-column>
          <!--         任务执行人 -->
          <el-table-column
            :label="$t('ProjectManagement.Waterprint1')"
            width="241"
          >
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.member_id"
                filterable
                class="selectClass"
                @change="selectChange(scope.row)"
                clearable
              >
                <el-option
                  v-for="item in selectOption"
                  :key="item.member_id"
                  :label="item.nickname"
                  :value="item.member_id"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <!--          操作-->
          <el-table-column :label="$t('CommonName.operation')">
            <template slot-scope="scope">
              <div class="operate_div" v-if="scope.row.job_split_id > 0">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('ProjectDetails.dropdownList.name9')"
                  placement="top"
                  :visible-arrow="false"
                  :enterable="false"
                >
                  <el-button
                    type="text"
                    @click="splitJob(scope.row.job_split_id)"
                  >
                    <svg-icon icon-class="Split" class="icon"></svg-icon>
                  </el-button>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('ProjectDetails.text1')"
                  placement="top"
                  :visible-arrow="false"
                  :enterable="false"
                >
                  <el-button
                    type="text"
                    @click="mergeJob(scope.$index, scope.row.job_split_id)"
                  >
                    <svg-icon
                      icon-class="xiangxiahebin"
                      class="icon"
                    ></svg-icon>
                  </el-button>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('ProjectDetails.dropdownBatchList.name24')"
                  placement="top"
                  :visible-arrow="false"
                  :enterable="false"
                  v-if="
                    newArr[activeName].tableData.length === 1 &&
                    scope.row.member_id == ''
                  "
                >
                  <!-- 删除拆分任务，最后一个拆分任务且无指定人员时显示 -->
                  <el-button
                    type="text"
                    @click="deleteClick(scope.row.job_split_id)"
                  >
                    <svg-icon icon-class="delete" class="icon"></svg-icon>
                  </el-button>
                </el-tooltip>
                <!--                <el-button-->
                <!--                  type="text"-->
                <!--                  v-if="scope.row.job_split_id === 0"-->
                <!--                  @click="deleteClick(scope.$index)"-->
                <!--                >-->
                <!--                  <svg-icon icon-class="delete" class="icon"></svg-icon>-->
                <!--                </el-button>-->
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import {
  get_member_list,
  get_split_job,
  get_split_job_info,
  merge_job,
  split_job,
  update_split_job,
} from "@/utils/https";
import { hideLoading, showLoading } from "@/utils/loading";

export default {
  name: "SplitTask.vue",
  props: {
    jobid: {
      type: String,
      default: "",
    },
    projectid: {
      type: String,
      default: "",
    },
    jobtype: {
      type: String,
      default: "",
    },
  },
  computed: {},
  data() {
    return {
      activeName: "1",
      mainData: {},
      selectOption: [],
      sttData: {
        radio: "1",
        input: 2,
        tableData: [],
      },
      traData: {
        radio: "1",
        input: 2,
        tableData: [],
      },
      revData: {
        radio: "1",
        input: 2,
        tableData: [],
      },
      stqData: {
        radio: "1",
        input: 2,
        tableData: [],
      },
      newArr: [],
    };
  },
  methods: {
    /**
     *切换 流程
     * @param val
     */
    handleTabClick(val) {
      // console.log(this.activeName);
    },
    /**
     * 点击拆分
     * @param
     * @constructor
     */
    splitClick(input, radio) {
      this.get_split_job(input, radio);
    },
    // inputNumber_input(currentValue) {
    //   console.log(currentValue);
    //   this.newArr[this.activeName].input = String(
    //     this.newArr[this.activeName].input
    //   ).replace(/^(0+)|[^\d]+/g, "");
    // },
    inputNumber_blur() {
      if (!this.newArr[this.activeName].input) {
        this.newArr[this.activeName].input = 2;
      }
      // // 判断输入的值是否为正整数
      // const reg = /^[1-9]\d*$/;
      // if (!reg.test(this.newArr[this.activeName].input)) {
      //   // 不是正整数，则将输入框的值设为1
      //   this.newArr[this.activeName].input = 2;
      // }
    },
    /**
     * 选择任务执行人
     * @param row
     */
    selectChange(row) {
      // console.log(row, "11111");
      if (!row.nickname) {
        row.nickname = this.selectOption.find(
          (i) => i.member_id === row.member_id
        ).nickname;
      }
    },
    /**
     * 拆分表格 某项
     * @param id
     */
    splitJob(id) {
      showLoading(this.$t("CommonName.loadingmessage"));
      this.split_job(id);
    },
    /**
     * 合并单行-向下
     * @param id
     */
    mergeJob(index, id) {
      let arr = this.newArr[this.activeName].tableData;
      if (id === arr[arr.length - 1].job_split_id) {
        return;
      }
      showLoading(this.$t("CommonName.loadingmessage"));
      this.merge_job(id, arr[index + 1].job_split_id);
    },
    /**
     * 删除拆分任务
     * @param index
     */
    deleteClick(id) {
      this.$confirm(
        this.$t("CommonName.deleteMessage1"),
        this.$t("CommonName.deleteMessageTitle"),
        {
          confirmButtonText: this.$t("CommonName.deleteMessagebutton"),
          cancelButtonText: this.$t("CommonName.deleteMessageClosebutton"),
          closeOnClickModal: false,
          cancelButtonClass: "closeButton",
          // confirmButtonClass: "confirmButton",
        }
      )
        .then(() => {
          showLoading(this.$t("CommonName.loadingmessage"));
          // this.$emit("splitDeleteMethod", this.jobid);
          this.delete_job(id);
        })
        .catch(() => {});

      // this.newArr[this.activeName].tableData.splice(index, 1);
    },
    //   ========================================请求========================================
    /**
     * 获取拆分任务详情
     * @param
     */
    get_split_job_info() {
      get_split_job_info({
        action: "get_split_job_info",
        job_id: this.jobid,
        project_id: this.projectid,
      }).then((res) => {
        if (!res) {
          hideLoading();
          return;
        }
        if (res.split_list) {
          this.sttData.tableData = res.split_list["1"];
          this.traData.tableData = res.split_list["2"];
          this.revData.tableData = res.split_list["3"];
          this.stqData.tableData = res.split_list["5"];
          this.sttData.input = res.split_list["1"]
            ? res.split_list["1"].length
            : 2;
          this.traData.input = res.split_list["2"]
            ? res.split_list["2"].length
            : 2;
          this.revData.input = res.split_list["3"]
            ? res.split_list["3"].length
            : 2;
          this.stqData.input = res.split_list["5"]
            ? res.split_list["5"].length
            : 2;
        }
        this.sttData.radio = res.job_info.workflow_1
          ? res.job_info.workflow_1
          : "1";

        this.traData.radio = res.job_info.workflow_2
          ? res.job_info.workflow_2
          : "1";

        this.revData.radio = res.job_info.workflow_3
          ? res.job_info.workflow_3
          : "1";
        this.stqData.radio = res.job_info.workflow_5
          ? res.job_info.workflow_5
          : "1";

        this.mainData = res;
        hideLoading();
      });
    },
    /**
     * 获取拆分结果
     * @param radio
     * @param input
     */
    get_split_job(radio, input) {
      get_split_job({
        action: "get_split_job",
        job_id: this.jobid,
        project_id: this.projectid,
        split_mode: radio,
        split_count: input,
        workflow: this.activeName,
      }).then((res) => {
        if (!res) {
          return;
        }
        // console.log(res);
        this.newArr[this.activeName].tableData = res.list;
      });
    },
    /**
     *
     * @param {*获取成员列表信息} data
     */
    get_member_list(data = 2) {
      get_member_list({
        action: "get_member_list",
        team_user_status: data,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.selectOption = res.list;
      });
    },
    /**
     * 拆分单行
     * @param id
     */
    split_job(id) {
      split_job({
        action: "split_job",
        job_id: this.jobid,
        job_split_id: id,
      }).then((res) => {
        if (!res) {
          hideLoading();
          return;
        }
        this.get_split_job_info();
      });
    },

    /**
     * 合并单行-向下
     * @param data
     */
    merge_job(id, nextid) {
      merge_job({
        action: "merge_job",
        job_id: this.jobid,
        job_split_id: id,
        njob_split_id: nextid,
      }).then((res) => {
        if (!res) {
          hideLoading();
          return;
        }
        this.get_split_job_info();
      });
    },
    /**
     * 删除拆分任务
     * @param data
     */
    delete_job(id) {
      merge_job({
        action: "delete_split_job",
        job_id: this.jobid,
        job_split_id: id,
        workflow: this.activeName,
      }).then((res) => {
        if (!res) {
          hideLoading();
          return;
        }
        this.$emit("splitDeleteMethod", this.jobid);
        // this.newArr[this.activeName].tableData = [];
        // this.get_split_job_info();
      });
    },
  },
  mounted() {
    hideLoading();
    showLoading(this.$t("CommonName.loadingmessage"));
    this.newArr = [
      "",
      this.sttData,
      this.traData,
      this.revData,
      "",
      this.stqData,
    ];
    if (["1", "3", "5", "6", "7"].includes(this.jobtype)) {
      this.activeName = "1";
    } else if (["2", "3", "4", "5"].includes(this.jobtype)) {
      this.activeName = "2";
    }
    this.get_split_job_info();
    this.get_member_list();
  },
};
</script>

<style scoped lang="scss">
.container_div {
  margin: 0 24px 24px 24px;

  ::v-deep .el-tabs__nav-wrap::after {
    background-color: #515464 !important;
  }

  ::v-deep .el-tabs__item {
    color: #adb0bc;

    &.is-active {
      color: #409eff;
    }
  }

  .main_btm_div {
    p {
      font-size: 14px;
    }

    .title_p {
      color: #adb0bc;
      margin-bottom: 4px;
    }

    .mainClass {
      color: #adb0bc;
      font-size: 14px;
      display: flex;
      margin-bottom: 20px;
    }

    .main_btm_div_topDiv {
      @extend .mainClass;

      p:nth-child(1) {
        margin-right: 50px;
      }

      p:nth-child(2) {
        margin-right: 50px;
      }

      p:nth-child(3) {
        width: 400px;
      }
    }

    .main_btm_div_centerDiv {
      @extend .mainClass;

      .radioClass .el-radio.is-bordered {
        width: 120px;
      }

      .inputNumber {
        ::v-deep .el-input-number {
          width: 120px !important;
          line-height: 32px;

          .el-input__inner {
            width: 120px !important;
            text-align: left !important;
          }
        }

        ::v-deep .el-input-number.is-controls-right .el-input-number__increase {
          border-bottom: none !important;
        }

        ::v-deep .el-input-number__increase {
          border-left: none !important;
        }

        ::v-deep .el-input-number.is-controls-right .el-input-number__decrease {
          border-left: none !important;
        }

        ::v-deep .el-input-number__decrease,
        ::v-deep .el-input-number__increase {
          background: none !important;
          color: #adb0bc !important;
          width: auto !important;
          margin-right: 10px !important;
          font-size: 16px !important;
        }

        ::v-deep .el-input-number.is-controls-right .el-input-number__decrease,
        ::v-deep .el-input-number.is-controls-right .el-input-number__increase {
          line-height: 16px !important;
        }
      }

      .first_div {
        width: 240px;
        margin-right: 24px;
      }
    }
    .Dashed {
      border: 1px dashed #515464;
      margin-bottom: 11px;
    }
    .main_btm_div_btmDiv {
      .selectClass {
        width: 100%;
      }
      ::v-deep .el-input__inner {
        width: 100% !important;
      }
      .operate_div {
        display: flex;
        justify-content: space-around;
        .icon {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
</style>
