import { render, staticRenderFns } from "./ProjectInformation.vue?vue&type=template&id=b7628fb4&scoped=true&"
import script from "./ProjectInformation.vue?vue&type=script&lang=js&"
export * from "./ProjectInformation.vue?vue&type=script&lang=js&"
import style0 from "./ProjectInformation.vue?vue&type=style&index=0&id=b7628fb4&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7628fb4",
  null
  
)

export default component.exports