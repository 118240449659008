<!--
 * @Description: 我的文件
 * @version: 
 * @Author: Carful
 * @Date: 2022-10-25 16:58:26
 * @LastEditors: Carful
 * @LastEditTime: 2022-12-29 16:29:28
-->
<template>
  <div class="contentdiv">
    <div class="titlediv">
      <p>
        <svg-icon icon-class="MyFiles" class="svgClass"></svg-icon
        ><span class="spanClass">{{ $t("MyFiles.MyFilesTitle") }}</span>
      </p>
      <el-button type="primary" class="setpadding16" @click="addFolder">
        <div class="svgbtn">
          <svg-icon icon-class="NewFolder" class="icon"></svg-icon>
          <p>{{ $t("MyFiles.CreateFolder") }}</p>
        </div>
      </el-button>
    </div>
    <MyFilesTableVue
      :table-data="tableData"
      ref="MyFilesTableVue"
      :gofolder="2"
    ></MyFilesTableVue>
    <DialogComVue
      :dialogVisible="dialogVisible"
      @determineClick="determineClick"
      @changeDialogVisible="changeDialogVisible"
      :width="'888px'"
      :top="'10%'"
      :title="dialogtitle"
      v-if="dialogVisible"
    >
      <div class="addFolder langselectclass">
        <p>{{ $t("MyFiles.FolderName") }}</p>
        <el-input
          v-model="input"
          maxlength="50"
          :placeholder="$t('MyFiles.CreateFolder1')"
        ></el-input>
      </div>
    </DialogComVue>
  </div>
</template>
<script>
import MyFilesTableVue from "./MyFilesTable.vue";
import DialogComVue from "@/components/DialogCom.vue";
import { get_job_list, create_folder } from "@/utils/https";
import { timestampToTime } from "@/api/setTime";
import { showLoading, hideLoading } from "@/utils/loading";
export default {
  components: {
    MyFilesTableVue,
    DialogComVue,
  },
  data() {
    return {
      tableData: [],
      dialogtitle: "",
      dialogVisible: false,
      input: "",
      time1: "",
      time2: "",
    };
  },

  created() {
    //   $emit子组件调用父组件的方法并传递数据
    this.$emit("header", true, true, true, true);
    this.time2 = timestampToTime(new Date());
    this.time1 = new Date();
    this.time1.setTime(this.time1.getTime() - 3600 * 1000 * 24 * 90);
    // console.log(this.time1, this.time2);
    this.time1 = timestampToTime(this.time1);
  },
  methods: {
    /**
     * 添加新文件夹按钮
     */
    addFolder() {
      this.dialogtitle = this.$t("MyFiles.CreateFolder");
      this.dialogVisible = true;
    },
    /**
     * 确定按钮
     */
    determineClick() {
      if (this.input.trim() == "") {
        this.$messages("warning", this.$t("MyFiles.inputFolderTips"));
        return;
      }
      create_folder({
        action: "create_folder",
        name: this.input,
      }).then((res) => {
        if (res) {
          this.$messages("success", this.$t("MyFiles.inputFolderTips1"));
          this.dialogVisible = false;
          this.input = "";
          this.$refs.MyFilesTableVue.get_job_list();
          this.$refs.MyFilesTableVue.clearMultipleSelection();
        }
      });
    },
    /**
     *
     * @param {关闭弹窗按钮} val
     */
    changeDialogVisible(val) {
      this.dialogVisible = val;
      this.input = "";
    },
    /**
     * 获取数据
     */
    get_job_list() {
      return get_job_list({
        action: "get_job_list",
        name: "",
        folder_id: "",
        start_time: this.time1,
        end_time: this.time2,
        sort_type: "desc",
        sort_name: "create_time",
      }).then((res) => {
        if (res) {
          // console.log(res.list);
          this.tableData = res.list;
          hideLoading();
        }
      });
    },
  },
  mounted() {
    hideLoading();
    showLoading(this.$t("MyFiles.loadingTips"));
    // this.get_job_list();
  },
};
</script>
<style scoped lang="scss">
.titlediv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .setpadding16 {
    margin-right: 24px;
  }
  .spanClass {
    margin-left: 8px;
  }
}
.addFolder {
  margin: 24px;
  p {
    color: #adb0bc;
    font-size: 14px;
    margin-bottom: 6px;
  }
}
.svgbtn {
  p {
    font-size: 14px;
  }
}
</style>
