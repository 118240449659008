/*
 * @Description:
 * @version:
 * @Author: Carful
 * @Date: 2022-10-19 14:16:23
 * @LastEditors: Carful
 * @LastEditTime: 2023-07-13 16:45:26
 */
module.exports = {
  // 项目管理
  ProjectManagement: {
    Replace3: "更换执行人为...",
    Replace1: "当前任务执行人",
    Replace2: "更换后任务执行人",
    Media: "多媒体项目",
    Media1: "配音项目",
    Media2: "项目类型",
    createTask: "创建项目",
    createTask1: "创建配音项目",
    projectmanagement: "项目",
    formItemLabel: "复制已有项目属性",
    projectName: "项目名称",
    projectNameplaceholder: "请输入项目名称",
    Numberoftasks: "任务数",
    TOTALMEDIALENGTH: "总时长",
    BasicSetting: "全局设置",
    SelectDateTime: "选择日期时间",
    Deadline: "截止时间",
    Completedon: "实际完成时间",
    Waterprint: "水印信息",
    Waterprint1: "任务执行人",
    TaskURL: "任务链接",
    ReportURL: "任务报告链接",
    CopyTaskURL: "复制任务详情",
    CopyTaskURL1: "查看详情",
    TaskInformation: "任务详情",
    Downloadsubtitles: "下载字幕",
    Replacevideofile: "更换源文件",
    ImportSRTname: "导入字幕",
    SRTFileName: "字幕文件名称",
    Workflow: "任务流程",
    Status: "任务状态",
    refuse: "打回",
    MainASREngine: "转写引擎",
    MediaLength: "文件时长",
    MediaSegments: "文件句段数",
    SourceWordcount: "原文字数",
    Createdon: "创建时间",
    Deliveredon: "交付时间",
    TempTM: "翻译记忆库",
    EditTM: "校审记忆库",
    ProofUrl: "审阅链接",
    ProofUrl1: "GPT翻译报告",
    EditItem: "编辑项目",
    DeleteItem: "删除项目",
    CREATED: "创建时间",
    TimeCodeMode: "时间位数设置",
    TimeSettings: "Time Settings",
    OtherSettings: "Other Settings",
    Basicinformation: "基础信息",
    haveinhand: "进行中",
    Delivered: "已交付",
    notSet: "未设置",
    STTname: "转写",
    TRAname: "翻译",
    REVname: "校审",
    changeVideo1:
      "支持的音频文件格式：aac, ac3, ape, flac, m4a, mp3, ogg, wav, wma",
    changeVideo2: "支持的视频文件格式：mp4 (H264), webm",
    changeVideo3: "文件大小限制：2GB",
    changeVideo4: "支持的文件格式：.zip",
    changeVideo5: "确保视频文件和字幕文件一致",
    changeVideo6: "压缩包不能包含文件夹",
    changeVideo7: "仅支持上传zip文件",
    Importoptions: "导入选项",
    Replace: "替换",
    Source: " 原文",
    Target: "译文",
    Bilingual: "双语",
    Bilingual1: "双语-原文在上",
    Bilingual2: "双语-译文在上",
    TextFormat: "文本格式",
    SourceFirst: "原文在上",
    TargetFirst: "译文在上",
    SubtitleFile: "字幕文件",
    UploadSubtitleFile: "上传文件",
    SelectTaskinProject: "选择任务",
    SelectSubtitleFileinTask: "选择文件",
    DownloadSubtitleFiles: "下载字幕文件",
    SourceTXT: "原文 .txt",
    SourceDOCX: "原文 .docx",
    SourceSRT: "原文 .srt",
    SourceChangeSRT: "有修改的原文 .srt",
    TargetTXT: "译文 .txt",
    TargetTTML: "译文 .ttml",
    TargetSRT: "译文 .srt",
    BilingualSRT1: "双语-原文在上 .srt",
    BilingualSRT2: "双语-译文在上 .srt",
    Comment: "备注",
    CreateOn: "创建时间",
    Changetms: "修改TM",
    AllowTimeCodesOverlap: "允许时间轴重叠",
    MachineTranslation: "机器翻译",
    tai: "泰语字符计算忽略元音符号、声调符号等非占位符号",
    Allowreviewertoeditoriginalsubtitlefile: "允许校审修改原文及时间信息",
    Allowtranslationtomodify: "允许翻译修改原文及时间信息",
    Termquery: "术语查询",
    Managementtermfile: "选择术语表",
    query: "查询",
    translation: "译文",
    Currentprojectglossary: "当前项目术语表",
    Filename: "文件名称",
    Numberofterms: "术语数量",
    confirm: "确认",
    remove: "移除",
    addTerms: "从术语库添加术语表",
    Colour: "颜色",
    CommentName: "备注名称",
    Processused: "所用流程",
    AddNewComment: "添加新的备注",
    STT: "转写",
    TRA: "翻译",
    REV: "校审",
    Proof: "审阅",
    successmessage1: "创建项目成功",
    successmessage2: "更新项目信息成功",
    successmessage3: "关闭成功",
    warningmessage1: "只允许上传 .srt格式文件",
    warningmessage2: "只允许上传 .zip格式文件",
    warningmessage3: "此操作将关闭该项目, 是否继续?",
    warningmessage4: "请选择任务",
    warningmessage5: "该状态下，无法查看详情",
    warningmessage6: "两个临时记忆库不能选择相同项",
    ExcelToContent: "导入Excel内容",
    ProjectSettings: "项目设置",
    renameJob: "重命名",
    Createdby: "创建人",
    DownloadExcelTemplate: "下载模板（.xlsx）",
    STT1: "STT",
    TRA1: "TRA",
    REV1: "REV",
    Update: "更新",
    dropdownList: {
      name1: "共享",
      name2: "项目信息",
      name3: "重命名",
      name4: "删除",
    },
    Sharetitle: "项目共享",
    Sharetitle1: "已共享人员",
    Sharetitle2: "未共享人员",
    Shares: "共享人员",
    successmessage4: "更新项目共享信息成功",
    DownloadSelectedTaskFiles: "下载所有任务字幕文件",
    LocalizationUrl: "WiiTube 视频信息",
    WiiTubeVideoID: "YouTube 视频ID",
    ReplaceOssMode: "更改",
    ReplaceOssModeTitle: "更改存储位置",
    CreateFolder: "创建文件夹",
    FolderName: "文件夹名称",
    RenameFolder: "重命名文件夹",
    FolderNameTip: "请输入文件夹名称",
    FolderNameTip1: "创建文件夹成功",
    FolderNameTip2: "重命名文件夹成功",
    MoveToFile: "移动",
    MoveToFileTitle: "移动至",
    AllProject: "全部项目",
    FolderDeleteTip: "文件夹中存在项目，无法删除",
    FolderDeleteTip1: "是否确定删除此文件夹？",
    FolderMoveTip: "移动所选项成功（同名的文件夹不会移动）",
    FolderMoveTip1: "所选文件夹已被删除。",
    CheckInfo: "查看",
    JobMoveTip: "移动所选项成功",
    ReportURL1: "翻译质量报告链接",
    exportName: "批量项目数据导出",
    warningmessage7: "请选择项目",
    dubSetting: "配音设置",
    autoClone: "允许自动克隆",
    STTUrl1: "转写提交对比报告",
    ModificationTime: "最后修改时间",
    TermCategory: "术语类别",
    TermCategoryName: "类别名称",
    addTermCategory: "新增类别",
    STQname: "校轴",
    STQ1: "STQ",
    STQUrl1: "校轴提交对比报告",
    HuaSRT2: "画面字 .srt",
    captionsMove: "禁止拖拽字幕",
  },
  // 通用
  CommonName: {
    loockUptext: "*查找内容前/后带空格",
    Replacetext: "*替换内容前/后带空格",
    Prev: "上一个",
    Nexts: "下一个",
    videoWarning: "视频出现错误，已重新为您建立链接。",
    Next: "下一步",
    Sure: "保存",
    OK: "确定",
    Cancel: "取消",
    Previous: "上一步",
    Taskworkflow: {
      name: "任务流程",
      label0: "转写",
      label1: "转写 > 翻译",
      label2: "翻译",
      label3: "翻译 > 校审",
      label4: "转写 > 翻译 > 校审",
      label5: "转写 > 校轴",
      label6: "转写 > 校轴 > 翻译 > 校审",
    },
    PreprocessOptions: {
      name: "选项",
      label0: "自动语音识别",
      label1: "手动上传源语言字幕文件 .srt/.ass/.vtt",
      label2: "手动上传双语字幕文件 .srt/.ass/.vtt",
      label3: "手动分别上传源/目标语言字幕文件 .srt/.ass/.vtt",
      label4: "硬字幕识别",
      label5: "字幕对齐",
    },
    Orderoftextsinsubtitlefile: {
      name: "双语字幕文件中语言顺序",
      label0: "目标语言在上",
      label1: "源语言在上",
    },
    Sourcelanguage: "源语言",
    Targetlanguage: "目标语言",
    Selectproject: "选择项目",
    Selectmediafileinproject: "选择项目下音视频文件",
    Speechtranscriptiondeadline: "转写截止时间",
    Subtitletranslationdeadline: "翻译截止时间",
    Subtitlereviewdeadline: "校审截止时间",
    MainTMoptional: "主记忆库（可选）",
    TempTMToptional: "翻译记忆库（可选）",
    optional: "（可选）",
    TempTMEoptional: "校审记忆库（可选）",
    selectMessage: "存在未选择项，请选择",
    selectMessage1: "源语言与目标语言不能相同",
    selectMessage2: "新任务名称不能为空",
    Upload: "上传",
    ASREngine: {
      name: "ASR Engine",
      label0: "Microsoft",
      label1: "Tencent",
      label2: "Alibaba",
      label3: "Bytedance",
    },
    title2: "步骤 2/2 - 任务信息",
    status: "状态",
    operation: "操作",
    sourcelanguagetable: "源语言",
    targetlanguagetable: "目标语言",
    duration: "时长",
    Numberofsentencesegments: "句段数",
    Numberofwords: "字数",
    screen: "筛选 :",
    determine: "确定",
    search: "搜索",
    delete: "删除",
    import: "导入",
    export: "导出",
    merge: "合并",
    download: "下载",
    remarks: "备注",
    TaskName: "任务名称",
    taskname: "任务名称",
    taskType: "任务类型",
    Pleaseselect: "请选择",
    Copysucceeded: "复制成功",
    warningmessage1: "存在未选择语言",
    warningmessage2: "源语言目标语言不能选相同语言",
    warningmessage3: "请输入名称",
    warningmessage4: "token已失效,请重新登陆",
    warningmessage5: "请上传文件",
    warningmessage6: "文件已上传完毕，正在解析中... ",
    successmessage1: "创建成功",
    successmessage2: "删除成功",
    successmessage3: "编辑成功",
    successmessage4: "导入成功",
    successmessage5: "上传成功",
    successmessage6: "修改成功",
    loadingmessage: "加载中...",
    noData: "暂无数据",
    statusName1: "转写",
    statusName2: "翻译",
    statusName3: "校审",
    statusName4: "处理中",
    statusName5: "已交付",
    statusName6: "已关闭",
    deleteMessage: "此操作将永久删除该文件, 是否继续?",
    deleteMessage1: "此操作将永久删除该任务, 是否继续?",
    deleteMessage2: "此操作将永久删除该术语, 是否继续?",
    deleteMessageTitle: "提示",
    deleteMessagebutton: "确定",
    deleteMessageClosebutton: "取消",
    Completed: "已完成",
    SelectAllItem: "全选",
    goTo: "跳至",
    NewJobName: "新任务名",
    WorkflowNode: "任务流程",
    replace1: "Replace",
    replace2: "With",
    lookupText1: "原文",
    lookupText2: "译文",
    deleteMessage3: "此操作将永久删除该术语库, 是否继续?",
    OnlineHelp: "在线客服",
    Messages: "站内信息",
    uploadTermTip1: "已上传 ",
    uploadTermTip2: " 条术语，成功导入 ",
    uploadTermTip3: " 条术语。",
    commentsDelete: "删除备注",
    closeMessage: "此操作将关闭所选任务, 是否继续?",
    tokenMessage: "token已失效，请重新登陆",
    revertMessage: "此操作将还原该任务数据, 是否继续?",
    statusName10: "声纹定制配音",
    statusName11: "视频克隆配音",
    create: "创建",
    AItext: "AI翻译中...",
    info: "详情",
    deleteMessage4: "当前项目中将不再使用此术语库, 是否继续?",
    selectMessage3:
      "选择自动语音识别、手动上传双语或目标语言字幕文件时，无法多选目标语言",
    SaveAndClose: "保存并关闭",
    Apply: "应用",
    Pleaseselect1: "请选择执行人",
    Pleaseselect2: "请选择文件夹",
    Pleaseinput1: "请输入新建文件夹名称",
    AITText: "自动AIT",
    content1: "初始内容",
    content2: "新内容",
    statusName12: "校轴",
    STQDeadline: "校轴截止时间",
    deleteMessage5: "此操作将清除任务中的画面字, 是否继续?",
    GlossaryCount: "术语库数量",
    fontFamilyText1: "TitilliumWeb-SemiBold",
    fontFamilyText2: "思源宋体",
    fontFamilyText3: "思源黑体",
    fontFamilyText4: "Roboto-Regular",
    OcrMode: {
      name: "硬字幕识别设置",
      label0: "仅识别原文",
      label1: "识别原文与译文",
    },
  },
  // 团队 媒体操作
  TeamOperation: {
    Submit: "提交",
    PleaseCompletebefore: "请在此时间内完成",
    FullScreen: "全屏",
    text: "校审进行中，点击刷新获取最新报告",
    text1: "校审已完成",
    text2: "句段修改数量：",
    text3: " 修改率：",
    text4: "%",
    text5: "校轴进行中，点击刷新获取最新报告",
    text6: "校轴已完成",
  },
  // 创建任务
  ExternalCreateTask: {
    title1: "步骤 1/2 - 创建任务",
    title2: "步骤 1/3 - 创建任务",
    title3: "步骤 2/3 - 任务信息",
    title4: "步骤 3/3 - 硬字幕识别",
    title5: "步骤 1/4 - 创建任务",
    title6: "步骤 2/4 - 任务信息",
    title7: "步骤 3/4 - 硬字幕识别（原文）",
    title8: "步骤 4/4 - 硬字幕识别（译文）",
    Sourceofmediafile: {
      name: "多媒体文件选择",
      label0: "上传新文件",
      label1: "选择已有文件",
      label2: "本地加载文件",
    },
    TimeCodeMode: "Time Code Mode",
    UploadMediaFile: "上传音视频文件",
    FileStoragelocation: "OSS 模式",
    UploadFile: "上传字幕文件 .srt/.ass/.vtt",
    UploadSourceFile: "上传源语言字幕文件 .srt/.ass/.vtt",
    UploadTargetFile: "上传目标语言字幕文件 .srt/.ass/.vtt",
    Taskprocessor: "转写任务处理人",
    Translator: "翻译任务处理人",
    ProofreadTaskHandler: "校审任务处理人",
    warningmessage1: "只允许上传 .srt/.ass/.vtt格式文件",
    warningmessage2:
      "只允许上传 .aac/.ac3./.ape/.flac/.m4a/.mp3/.ogg/.wav/.wma/.mp4/.webm格式文件",
    warningmessage3: "上传文件大小应小于等于1.5GB",
    warningmessage4: "创建中...",
    warningmessage5: "存在未选择的时间",
    warningmessage6: "存在未上传的文件",
    successmessage1: "创建成功",
    sortText: "任务默认排序",
    sortText1: "任务名称正序",
    sortText2: "任务名称倒序",
    UploadFile1: "上传字幕文件 .txt",
    warningmessage7: "只允许上传 .txt格式文件",
    termText1: "术语库",
    termText2: "选择已有术语库",
    termText3: "新建术语库",
    termText4: "术语库名称",
    termText5: "术语库语言",
    warningmessage8: "术语库名称与术语库语言不能为空",
    subShowText: "字幕显示方式",
    subShowText1: "单句段显示",
    subShowText2: "多句段显示",
    STQprocessor: "校轴任务处理人",
  },
  // 导入任务
  ImportTask: {
    Selecttaskmode: {
      name: "Select Task Mode",
      label0: "Optional Tasks",
      label1: "All Tasks",
    },
    title1: "STEP 1/2 - Import Tasks",
    selectMessages: "源语言与目标语言不能相同",
    successmessage1: "导入成功",
  },
  // 批量创建
  BatchCreationTask: {
    title1: "步骤 1/2 - 批量创建任务",
    OSSMode: "OSS 模式",
    UploadMediaZipFile: "上传批量文件压缩包（.zip）",
  },
  // 批量创建 硬字幕识别任务
  BatchCreateHardSubtitleRecognitionTasks: {
    title1: "步骤 1/3 - 批量创建硬字幕识别任务",
    uploadFiles: "",
  },
  // 团队 项目详情
  ProjectDetails: {
    buttonList: {
      name1: "Import Task",
      name2: "创建任务",
      name3: "批量创建",
      name4: "项目信息",
      name5: "术语管理",
      name6: "字幕设置",
      name7: "Batch Assign",
      name8: "批量创建硬字幕识别任务",
      name9: "创建声纹定制配音任务",
      name10: "创建视频克隆配音任务",
      name11: "定制主播-声纹",
      name12: "定制主播-克隆",
      name13: "项目主播列表",
      name14: "定制主播",
      name15: "借调任务",
      name16: "借调字幕任务",
      name17: "借调配音任务",
      name18: "项目角色",
      name19: "声纹定制配音项目角色",
      name20: "视频克隆配音项目角色",
      name21: "批量指派/换人",
      name22: "批量换人",
      name23: "字幕样式设置",
      name24: "字幕规则设置",
    },
    dropdownList: {
      name1: "导出Excel文件",
      name2: "导入Excel文件",
      name3: "生成波形",
      name4: "克隆任务",
      name5: "删除任务",
      name6: "指派/更改人员",
      name7: "重命名",
      name8: "导入 AIT 文件",
      name9: "拆分",
    },
    topButton: {
      name: "添加角色",
      name2: "一键克隆",
      name3: "一键配音",
      name4: "合并角色",
      name5: "锁定/解锁",
      name6: "删除角色",
      name7: "一键配音还原",
    },
    dropdownBatchList: {
      name1: "下载所有任务字幕文件 .docx",
      name2: "下载所有任务源语言字幕文件 .srt",
      name3: "下载所有任务目标语言字幕文件 .srt",
      name4: "下载所有任务源语言字幕文件 .ttml",
      name5: "导出所有任务链接信息 .xlsx",
      name6: "导出所有任务文件内容信息 .xlsx",
      name7: "下载所有任务字幕文件",
      name8: "Run AIT",
      name9: "关闭任务",
      name10: "Clean up AIT",
      name11: "下载所有任务双语字幕文件-原文在上 .srt",
      name12: "下载所有任务双语字幕文件-译文在上 .srt",
      name13: "Stop AIT",
      name14: "批量打回",
      name15: "批量打开指派任务（校审）",
      name16: "Run MT",
      name17: "批量打开校审任务",
      name18: "批量打开指派任务（翻译）",
      name19: "批量打开指派任务（转写）",
      name20: "批量打开转写任务",
      name21: "批量打开翻译任务",
      name22: "移动任务",
      name23: "批量修改任务名称",
      name24: "删除任务",
      name25: "下载所有任务校轴报告 .xlsx",
      name26: "下载所有任务校审报告 .xlsx",
      name27: "批量打开校轴任务",
      name28: "导出软字幕错误检查报告 .xlsx",
    },
    dropExportBatchList: {
      name1: "导出所有任务文件内容信息 .xlsx（仅原文译文）",
      name2: "导出所有任务文件内容信息 .xlsx",
    },
    ViewLog: "查看修改日志",
    EditTM: "编辑记忆库",
    CloseProject: "关闭项目",
    DeleteTask: "删除任务",
    Operationprocess: "操作流程",
    closingdate: "截止日期",
    Uploadanewglossary: "创建术语表",
    AssignChangeTaskHandler: "指派/更改任务处理人",
    Transcription: "转写",
    Translation: "翻译",
    Review: "校审",
    Pending: "处理中...",
    Delivered: "已交付",
    UploadUpdateglossary: "更新术语表",
    GenerateWaveform: "波形生成中",
    GenerateWaveform1: "波形已生成",
    revivification: "应用此版本",
    version: "版本",
    versionCreateOn: "版本创建时间",
    versiontext1: "替换操作之前的版本",
    versiontext2: "合并操作之前的版本",
    versiontext3: "导入Excel文件操作之前的版本",
    repulseMessage: "是否确认要打回选中的任务到上一流程",
    repulseMessage1: "选中的任务流程不同，是否仍要打回所有选中任务到上一流程",
    text1: "合并下一个任务",
    borrowText1: "字幕项目",
    borrowText2: "配音台词",
    borrowText3: "借调任务目标语言 SRT",
    borrowText4: "上传 SRT 文件",
    borrowText5: "声纹定制",
    borrowText6: "视频克隆",
    borrowText7: "请上传配音的 SRT 文件",
    borrowText8: "国际轨（非必填）",
    borrowText9: "干声音频（非必填）",
    borrowText10: "借调字幕任务",
    borrowText11: "借调配音任务",
    downloadText: "下载音轨/合成视频",
    downloadText1: "配音音轨",
    downloadText2: "角色音轨",
    downloadText3: "混音音轨",
    downloadText4: "合成视频",
    downloadText5: "未生成",
    downloadText6: "生成中",
    downloadText7: "已生成",
    dubbingText: "全局配音",
    downloadTips: "有正在生成中的任务，请稍候下载",
    downloadTips1: "正在生成所需音轨，请稍候下载",
    dubbingText1: "上传背景音频/人声音频",
    dubbingText2:
      "支持上传Zip格式文件，无文件夹，背景音频命名规则：**_no_vocals.mp3/wav，人声音频命名规则：**_vocals.mp3/wav，文件名前缀需与任务名前缀保持一致",
    borrowText12: "借调任务源语言 SRT",
    dubbingText3: "视频预览",
    dubbingText4: "任务列表中没有任务，无法打开字幕样式设置",
    dubbingText5: "压制视频",
    dubbingText6: "项目字幕样式设置",
    labelText: "修改方式",
    labelText0: "追加到任务名前",
    labelText1: "替换",
    labelText2: "追加内容",
    labelText3: "请输入被替换内容",
    dubbingText7: "审阅任务",
    TranscriptionQC: "校轴",
    text2: "设置术语",
    text3: "移除画面字",
    versiontext5: "画面字去除前版本",
  },
  // name6字幕设置
  CaptionSettings: {
    tab1: "字幕规则",
    tab2: "禁用字符",
    tab3: "项目水印",
    tab4: "设置备注",
    tab5: "GPT 设置",
    tab7: "ASR设置",
    tab8: "字幕区域",
    tab9: "SRT设置",
    asrText: "开启/关闭ASR选项，开启后请在列表内选择需要的ASR语言",
    srtText: "导出文件名前缀信息",
    srtText1: "导出文件名后缀信息",
    srtText2: "左侧缩进",
    srtText3: "右侧缩进",
    srtText4: "上方缩进",
    srtText5: "下方缩进",
    srtText6:
      "我想请你作为字幕译员帮从**翻译到**。翻译后目标译文务必加上正确的标点符号，不要遗漏标点特别注意逗号后面首字母要小写（即便换行也要小写），不要漏翻句子",
    Waterprint: {
      WaterprintSTT: "转写环节",
      WaterprintTRA: "翻译环节",
      WaterprintREV: "校审环节",
      WaterprintSTQ: "校轴环节",
    },
    Minimumdurationofsubtitlesperline: "时间轴最小时长",
    Maximumdurationperlineofwords: "时间轴最大时长",
    MinigapTime: "时间轴最小间隔",
    Allowequalsto0: "允许无间隔",
    Maximumwordslimitperlineofsubtitles: "每行字幕最大字数限制",
    languages: "语种",
    MaximumWords: "最大字符数",
    Readrate: "读速",
    nothing: "无",
    ChineseCharacters: "中文字符",
    EnglishCharacters: "英文字符",
    CustomForbiddenCharacters: "自定义禁用字符",
    Useregextocustomize:
      "一个禁用字符为一行，例如：" + "\n" + "&" + "\n" + "#" + "\n" + "@",
    Glossariestabs2Title: "如果字幕存在以下字符，系统会提示警告",
    MiniDuration: "Mini Duration",
    srtText7: "导出原文前缀信息",
    srtText8: "导出原文后缀信息",
    srtText9: "导出译文前缀信息",
    srtText10: "导出译文后缀信息",
    srtText11: "导出单语增加前后缀信息",
    srtText12: "导出双语增加前后缀信息",
  },
  //  团队 成员管理
  MemberManagement: {
    title: "成员管理",
    headerbtn1: "邀请记录",
    headerbtn2: "邀请新成员",
    tableBtn1: "终止合作",
    tableBtn2: "团队记录",
    tableBtn3: "个人记录",
    Numberoftasksinvolved: "参与任务数",
    Joiningtime: "加入时间",
    Nickname: "昵称",
    email: "邮箱",
    accountnumber: "账号 ID",
    accountnumberinput: "请准确输入所添加成员的 ID",
    successmessage1: "已成功将对方邀请至本团队", //邀请中，等待对方接受
    Inviting: "邀请中",
    Incooperation: "合作中",
    Rejected: "已拒绝",
    Terminated: "已终止",
    deleteMessage: "您确定与用户",
    deleteMessage1: "终止合作？",
    invitationMessage1: "邀请账号不存在",
    invitationMessage2: "邀请账号不能为本人",
    invitationMessage3: "重复邀请",
    AllProject: "所有项目",
  },
  // 团队 任务记录
  MemberDetails: {
    time1: "请选择起始日期",
    time2: "请选择结束日期",
    exportData: "导出数据",
    details: "的任务记录",
    details1: "的个人记录",
    taskcontent: "任务内容",
    createTime: "创建时间",
    Submissiontime: "提交时间",
    datetime1: "开始日期",
    datetime2: "至",
    datetime3: "结束日期",
    datetime4: "消耗数量/单位",
    datetime5: "文件长度/单位",
    individual: "个",
  },
  // 邀请记录
  InvitationRecord: {
    title: "邀请记录",
    Invitationtime: "邀请时间",
    AccountID: "账号 ID",
    AccountEmail: "账号邮箱",
    Accountnickname: "账号昵称",
  },

  // TM 管理
  TMManagement: {
    title: "翻译记忆库",
    headerbtn: "新建翻译记忆库",
    TMType: "记忆库类型",
    TMName: "记忆库名称",
    detailedinformation: "详细信息",
    Search: "查询",
    placeholder: "输入查询文本",
    tootipcontent: "查询结果仅显示匹配度高于75%句子",
    tabletitle1: "源语言",
    tabletitle2: "目标语言",
    TMdetailedinformation: "记忆库详细信息",
    edit: "编辑",
    TMName1: "记忆库名称",
    TMKey: "记忆库秘钥",
    LanguagePair: "语言对信息",
    Segments: "句段数量",
    CreatedDate: "创建时间",
    Comments: "备注",
    MainTM: "主记忆库",
    TemporaryTM: "临时记忆库",
    ImportTM: "导入记忆库文件",
    Merge: "合并",
    Uploadfile: "上传文件",
    tootip1: "请选择小于100MB的双列Excel文件上传.",
    Addnewtranslationunits: "添加为新翻译单元",
    Overwriteexistingtranslationunits: "覆盖现有翻译单元",
    Leaveexistingtranslationunitsunchanged: "保留现有翻译单元",
    mergetabletitle: "选择要合并的记忆库",
    Mergeoptions: "合并选项",
    statusName1: "正常",
    statusName2: "导入中...",
    statusName3: "导出中...",
    statusName4: "合并中...",
    warningmessage1: "请输入记忆库名称",
    warningmessage2: "源语言目标语言不能相同",
    successmessage1: "创建成功",
    successmessage2: "导出成功",
    successmessage3: "导入成功",
    successmessage4: "合并成功",
    warningmessage3: "未输入内容",

    warningmessage4: "只允许上传 .xlsx格式",
    select: "请选择...",
    importTMTypeTitle: "如果目标句段不同：",
    MergeTitle: "合并记忆库",
  },
  // 术语库管理
  TermBaseManagement: {
    title: "术语库",
    NewGlossary: "新建术语库",
    GlossaryName: "术语库名称",
    Importtermfile: "导入术语文件",
    Uploadtermfile: "上传术语表（.xlsx）",
    DownloadGlossaryTemplate: "下载术语表模板 (.xlsx)",
    ImportGlossary: "导入术语表",
    Quicklyaddterms: "快速添加术语",
    Operationrecord: "操作记录",
    Operationrecord1: "的操作记录",
    Sourceinput: "输入原文术语以搜索",
    Targetinput: "输入译文术语以搜索",
    Editglossaryinformation: "编辑术语库信息",
    dialogSourceinput: "输入要添加术语的原文",
    dialogTargetinput: "输入要添加术语的译文",
    OperationTime: "操作时间",
    Operator: "操作人",
    Operationcontent: "操作内容",
    Editglossary: "编辑术语",
    warningmessage1: "只允许上传 .xlsx格式文件",
    warningmessage2: "编辑成功",
    warningmessage3: "存在未填项",
    warningmessage4: "备注名不能相同",
    successmessage1: "添加成功",
    updateTermRadio1: "当源术语相同时，更新为最新译文",
    updateTermRadio2: "先清空所有术语后，导入最新术语表",
    warningmessage5: "请填写正确的语言",
    warningmessage6: "首行没语言不能填写",
    warningmessage7: "不能在首行粘贴多行内容",
    warningmessage8: "粘贴内容不能有空值",
    warningmessage9: "不能修改首行前三列内容",
    warningmessage10: "此工作表无法删除",
    warningmessage11: "该术语已存在，在第",
    warningmessage12: "行",
    warningmessage13: "该语言已存在，在第",
    warningmessage14: "列",
    warningmessage15: "粘贴的内容中不能有重复值",
  },
  // 团队 工具箱
  toolset: {
    title: "工具箱",
    toolsetList: {
      name0: "时间轴排序",
      name1: "Combine Documents",
      name2: ".xlsx -> .srt转换器",
      name3: ".srt -> UTF-8转换器",
      name4: "全局检查",
      name5: "Words",
      name6: "调换语言顺序",
      name7: "合成双语字幕",
      name8: "srt拆分",
      name9: "srt合并",
      name10: "万事通",
      name11: ".srt转 .ass",
      name12: "Excel To .srt File",
      name13: "To .srt Excel",
      name14: "Bilingual .srt To Excel",
      name15: "视频转换",
      name16: "Hiventy项目检查",
      name17: "To .srt Excel（画面字特别版）",
      name18: "导入Excel、SRT 并导出SRT",
    },

    name15Text: "请输入通知邮箱",
    name15Text1: "转换成功",
    name0dialogtitle: "Upload ZIP File",
    name0dialogtooltip: "请将SRT文件压缩后上传",
    Importoptions: "导入操作",
    SourceSRT: "Source SRT",
    BilingualSRT: "Bilingual .srt",
    Uploadsourcefile: "Upload Source File",
    Uploadreplacefile: "Upload Replace File",
    UploadExcelFile: "上传 Excel 文件",
    Uploadfilezip: "Upload File .zip",
    Uploadfilesrt: "Upload File .zip",
    Uploadtargetfile: "Upload Target file",
    AddWords: "Add Words",
    Word: "Word",
    Errortype: "Error Type",
    Crossovertime: "Crossover Time",
    ContainsEnglish: "Contains English",
    Containsnumbers: "Contains Numbers",
    Emptysgement: "Empty Sgement",
    Morethanrows: "More Than Rows",
    Morethancharssinglelength: "More Than Chars (single length)",
    Durationlessthan: "Duration Less Than",
    Morethancharstotallength: "More Than Chars (total length)",
    uploadwarningmessage1: "只能上传.zip格式",
    uploadwarningmessage3: "只能上传.srt格式",
    uploadwarningmessage2: "上传文件大小不能超过100MB",
    uploadwarningmessage4: "请上传文件",
    confirm: "确定",
    Importoptions1: "文件内容",
    srtText: "行",
    srtText1: "字符",
    srtText2: "时间",
    srtText3: "分割选项",
    srtText4: "相等部分的数量",
    srtText5: "字幕信息",
    srtText6: "行数 : ",
    srtText7: "字符数 : ",
    srtText8: "上传文件",
    srtText9: "预览",
    srtText10: "文件名",
    srtText11: "行数",
    srtText12: "起始时间",
    srtText13: "结束时间",
    srtText14: "清空",
    srtText15: "文件已包含正确的时间码",
    srtText16: "添加上一个文件的结束时间",
    srtText17: "在各个文件后添加毫秒",
    ASS: "ASS模板",
    ASS1: "请输入ASS模板",
    ASS2: "ASS模板内容",
    Excel: "Upload Excel File",
    name16Text: "源语言",
    name16Text1: "目标语言",
    name16Text2: "术语表",
    name16Text3: "译文字幕单句字符数上限",
    name16Text4: "译文符号检查（可选）",
    name16Text5: "分享检查工具",
    name16Text6: "译文字幕单句字符数上限不能为空",
    name16Text7: "译文符号检查",
    name16Text8: "上传源语言文件",
    name16Text9: "上传目标语言文件",
    name16Text10: "分享链接已复制成功",
    name16Text11: "只支持zip格式",
    name16Text12: "点击选择或拖动文件到这里",
    name16Text13: "文件大小",
    name16Text14: "检查",
  },
  AppointedTask: {
    Taskprocessor: "转写任务处理人",
  },
  //音频转写
  audio: {
    ShowSpeakers: "显示说话人",
    ShowTimecode: "显示时间码",
    Download: "下载",
    popper_speakers_tip1: "将 ",
    popper_speakers_tip2: " 修改为",
    popper_speakers_tip3: "请输入说话人姓名",
    popper_speakers_tip4: "命名为",
    DoubleSpeed: "倍速",
    confirm: "确定",
    VideoBackText: "后退五秒",
    VideoForwardText: "前进五秒",
    AllUpdate: "全部修改",
    speakersInputTip: "说话人姓名不能为空",
    jobNameInputTip: "请输入内容",
    NoSegmentedTip: "无分段纯文稿",
    jobNameInputTip1: "修改任务名称失败",
    setJobRoleTip1: "添加角色失败",
    still_loading: "正在处理中，请稍后",
    downloadText: "下载预览",
    downloadText1: "内容预览",
    cancel: "取消",
    revocation: "撤销",
    revivification: "还原",
  },
  //AI配音
  dubbing: {
    input_placeholder: "请输入内容",
    help_center: "帮助中心",
    create_new_file: "创建新文件",
    number: "数字",
    tts_number_tip: "请选中数字后设置其读法",
    annotation: "多音字",
    tts_annotation_tip: "请选中单个文字后设置其发音",
    alias: "别名",
    tts_alias_tip: "请选中一段文字后更改其发音",
    continuity: "连读",
    tts_continuity_tip: "请选中一段文字后设置连读",
    pause: "停顿",
    tts_pause_tip: "请先放置光标位置",
    intonation: "音效",
    tts_anchor: "多人配音",
    tts_anchor_tip: "请选中一段文字后更换其配音主播",
    background_music: "背景音乐",
    system_prefabrication: "系统预制",
    volume: "音量",
    confirm: "确定",
    auto_upload: "自定义上传",
    audition: "试听",
    continue: "继续",
    suspend: "暂停",
    reset: "重置",
    generating: "生成中",
    number_of_words: "字符数：",
    price: "价格：",
    save_draft: "保存草稿",
    confirm_dubbing: "确认配音",
    download_audio: "下载音频",
    download_subtitles: "下载字幕",
    bgm_tip: "请选择一项背景音乐",
    add_to: "添加",
    please_select: "请选择",
    tts_number_tip1: "请选中数字",
    tts_pause_tip1: "不要选中文字",
    tts_number_cardinal: "按数字发音(.)",
    tts_number_digits: "按数字发音(,)",
    tts_number_telephone: "按电话号码常用方式发音",
    tts_number_date: "按日期发音",
    tts_number_time: "按时间发音",
    tts_number_currency: "按金额发音",
    selected_content: "已选内容",
    alias_replacement: "别名替换",
    replace_single: "替换单个",
    replace_all: "替换全部",
    replace_tip: "不能输入空内容",
    auto: "自定义",
    still_confirmation: "还在确认中",
    download_subtitle_tip: "请先确认配音后再下载字幕",
    download_subtitle_tip1: "正在配音中请稍后再下载字幕",
    download_audio_tip: "请先确认配音后再下载音频",
    download_audio_tip1: "正在配音中请稍后再下载音频",
    download_audio_tip2: "下载失败，权限错误",
    download_audio_tip3: "下载失败",
    pause_input_tip: "自定义内容不能为空",
    try_play_tip: "无法在编辑文本时播放音频，请在编辑完成后重新试听",
    try_play_tip1: "试听失败",
    try_play_tip2: "试听时请勿点击编辑区，否则会停止试听。",
    try_play_tip3: "试听失败，请重试。",
    paste_tip: "选中文字时无法粘贴",
    revocation: "撤销",
    revivification: "还原",
    createDubFile: "新建配音文件",
    currentFree: "限免",
    uploadText1: "点击上传背景音乐",
    clearText: "清除",
    styleDefault: "默认",
    styleAge: "角色风格",
    styleMood: "说话风格",
    myCollection: "主播收藏",
    roleCollection: "角色收藏",
    readingSpeed: "朗读速度",
    anchorTone: "主播语调",
    anchorVolume: "主播音量",
    save: "保存",
    wordOfAuditions: "剩余字符数：",
    numberOfAuditions: "剩余试听次数：",
    todayDubbingNum: "免费配音次数（1000字符以内）：",
    totalDownloads: "总下载次数：",
    numberOfTimes: "次",
    title1: "提示",
    ToBuy: "去购买",
    dialogContent: "您剩余的试听次数不足，请购买后重试",
    dialogContent1: "您剩余的配音次数不足，请购买后重试",
    dubbingTip: "选中内容错误",
    dubbingTip1: "不支持交叉选中",
    pause_input_tip1: "字数已超过规定数量，编辑失败",
    pause_input_tip2: "不支持在标签内粘贴内容或按回车键",
    try_play_tip4: "当前播放音频失效,请重新试听",
    upload_bgm_tip: "请上传音频文件",
    upload_bgm_tip1: "上传音频文件应小于20M",
    try_play_tip5: "试听失败，请输入内容后再进行试听。",
    try_play_tip6: "确认配音失败，请输入内容后再进行确认配音。",
    prev_confirm_time: "上次确认时间：",
    notice_title: "使用须知",
    notice_go_on: "继续",
    notice_buy: "购卡优惠",
    noticeContent1: "在您开始配音之前，请仔细阅读如下内容：",
    noticeContent2:
      "1、WiiMedia 的 AI 文本配音服务按字符数计费，每千字符收费 2 元（购买字符卡更优惠），不足五百字符按半价收费，超过五百但不足一千字符按一千字符收费。",
    noticeContent3:
      "2、费用包括选择普通/高级配音师、外语及小语种配音师、全部精调功能、免费音效、免费背景音乐、确认配音及下载。",
    noticeContent4:
      "3、未购买字符卡的用户，单个项目可试听 20 次，单次试听限 200 个字符，您可以将光标放至试听截断的最后一个字之后，继续试听后续内容。",
    confirm_dubbing_tip: "您的字符余额已不足 ",
    confirm_dubbing_tip1: "，请续购字符卡。",
    empty_text: "暂无数据",
    dubText: "保存失败",
    dubText1: "请填写数字",
    dubText2: "停顿时间最大支持5000ms",
    dubText3: "您的文本内容已经编辑，是否要保存文件后新建文件吗？",
    dubText4: "提示",
    dubText5: "不保存",
    dubText6: "系统可能不会保存您所做的更改。",
    dubText7: "不支持交叉选中",
    payText: "AI 文本配音",
    payText1: "文本配音·字符卡 70,000字符",
    payText2: "字符数",
    payText3: "AI 文本配音",
    anchorsText: "主播设置",
    anchorsText3: "语种",
    anchorsText4: "请选择语言",
    All: "全部",
    anchorsText5: "性别",
    anchorsText6: "请选择性别",
    anchorsText7: "昵称",
    anchorsText8: "输入昵称进行搜索",
    anchorsText9: "男声",
    anchorsText10: "女声",
    anchorsText11: "童声（男）",
    anchorsText12: "童声（女）",
    setAnchorstitle1: "中文（普通话）",
    setAnchorstitle2: "中文（方言）",
    setAnchorstitle3: "英文",
    setAnchorstitle4: "常用外语",
    setAnchorstitle5: "其他外语",
    bgm_text1: "史诗",
    bgm_text2: "恐怖",
    bgm_text3: "欢快",
    bgm_text4: "浪漫",
    sound_text1: "乌鸦", //取自表dict_sound, 数字对应sound_id
    sound_text2: "剑刃击打声",
    sound_text3: "噪音",
    sound_text4: "射击",
    sound_text5: "心脏跳动",
    sound_text6: "悬疑",
    sound_text7: "手机振动",
    sound_text8: "拍手",
    sound_text9: "拔剑",
    sound_text10: "拨号不通",
    sound_text11: "挥剑声",
    sound_text12: "挥剑声2",
    sound_text13: "敲门",
    sound_text14: "普通键盘打字",
    sound_text15: "水龙头",
    sound_text16: "滴水",
    sound_text17: "火柴点火",
    sound_text18: "火箭筒",
    sound_text19: "炮击",
    sound_text20: "爆炸",
    sound_text21: "猫叫",
    sound_text22: "翻书",
    sound_text23: "蝉叫声",
    sound_text24: "走楼梯",
    sound_text25: "跑步",
    sound_text26: "钟表指针",
    sound_text27: "钟表滴答",
    sound_text28: "钢琴重音",
    sound_text29: "飞镖",
    sound_text30: "鬼笑",
  },
  comic: {
    close: "关闭",
  },
  message: {
    refuseTip: "已拒绝加入该团队，将在2秒后刷新该页面",
    refuseTitle: "您是否确认拒绝加入该团队？",
    agreeTip: "已同意加入该团队，将在2秒后刷新该页面",
    agreeTitle: "您是否确认加入该团队？",
    title1: "提示",
    refuse: "拒绝",
    cancel: "取消",
    accept: "接受",
    renew: "续费",
    confirm: "确定",
    title: "消息中心",
  },
  // 媒体 操作页 转写环节
  OverwriteOperation: {
    teamTips: "打开在线术语库",
    ocr: "在线ocr",
    ErrorTypes: "错误类型",
    switchText: "筛选未校审句段",
    switchText1: "开启后仅显示未校审句段",
    switchText2: "有",
    switchText3: "句段未确认，是否确认提交",
    switchText4: "内容一致",
    switchText5: "内容不一致",
    task: "任务",
    moreConcise: "更简洁",
    moreConcise_tooltip:
      "在含义不发生变化的前提下，将目前编辑框中的英文文本修改得更简洁、字符数更少",
    MoreColloquial: "更口语",
    MoreColloquial_tooltip:
      "在含义不发生变化的前提下，将目前编辑框中的英文文本修改得更接近英文口语表达，避免使用过于书面的词汇，避免使用长难句",
    MoreWritten: "更书面",
    MoreWritten_tooltip:
      "在含义不发生变化的前提下，将目前编辑框中的英文文本修改得更书面、更正式，避免使用过于口语化的词组和表达",
    TimeRange: "时间轴",
    Duration: "时长",
    Subtitle: "字幕",
    Char: "字符",
    Speed: "读速",
    Segment: "句段",
    TransSegment: "翻译句段数",
    ReviewSegment: "校审句段数",
    TMAndTB: "记忆库&术语库",
    TMSearch: "记忆库搜索",
    FindAndReplace: "查找&替换",
    Find: "查找",
    Warnings: "警告",
    VerifyOptions: "全局检查项",
    Verify: "全局检查",
    EmptySegment: "空句段",
    SubtitleDuration: "时间轴时长",
    CharacterLimitation: "字符数限制",
    ReadingSpeed: "读速",
    SpellingAndGrammarCheck: "拼写和语法",
    Forbiddencharacter: "禁用字符",
    TimeCodesOverlap: "时间轴重叠",
    MiniGap: "时间轴最小间隔",
    DoubleBlank: "双空格",
    StyleConsistency: "样式一致性",
    StyleAccuracy: "样式正确性",
    MissingNumber: "数字不匹配",
    InconsistentTranslation: "译文不一致",
    TermCheck: "术语检查",
    tooltipContent: "播放当前句",
    tooltipContent1: "转写当前句",
    tooltipContent2: "插入新句段",
    tooltipContent3: "合并句段",
    tooltipContent4: "拆分当前句",
    tooltipContent5: "删除",
    tooltipContent6: "转至下一备注",
    tooltipContent7: "转至具体句段",
    tooltipContent9: "导入字幕块",
    tooltipContent10: "调整时间轴最小间隔",
    tooltipContent11: "机器翻译",
    tooltipContent12: "清空译文",
    tooltipContent13: "将所选句段插入翻译记忆库",
    tooltipContent14: "恢复译文",
    tooltipContent15: "打开多媒体文件",
    tooltipContent16: "AI 润色",
    tooltipContent17: "识别当前句",
    Type: "类型",
    Play: "播放",
    Source: "源语言",
    Target: "目标语言",
    OK: "确认",
    Current: "当前句段",
    All: "所有句段",
    ZoomInTimecode: "Zoom In Timecode",
    ZoomOutTimecode: "Zoom Out Timecode",
    ZoomInAmplitude: "Zoom In Amplitude",
    ZoomOutAmplitude: "Zoom Out Amplitude",
    AdjustMiniGap: "调整时间轴最小间隔",
    SourceSubtitle: "显示源语言字幕",
    TargetSubtitle: "显示目标语言字幕",
    BilingualSubtitle: "显示双语字幕",
    HideSubtitle: "隐藏字幕",
    Displaysubtitle: "显示字幕",
    AjustTimecodes: "平移时间轴",
    UndoAdjustment: "撤销时间轴更新",
    Help: "帮助",
    Backward: "向前平移",
    Forward: "向后平移",
    DownloadTargetSubtitleFile: "下载目标语言字幕文件 .docx",
    DownloadSourceSubtitleFile: "下载源语言字幕文件 .docx",
    DownloadSubtitleFile: "下载目标语言字幕文件 .docx",
    DownloadSubtitleFileexcel: "下载目标语言字幕文件 .xlsx",
    TimetoAdd: "添加时长",
    Shiftfrom: "从时间点平移",
    Alllines: "所有行",
    Selectedlinesonly: "仅选中行",
    Alllinesbeforeselectedline: "选中行及其前所有行",
    Alllinesafterselectedline: "选中行及其后所有行",
    Alllinesafterselectedline1: "选中行及其后所有行",
    lookup: "查找",
    placeholder: "输入句段号/时间点",
    placeholder1: "请输入内容",
    Matchcase: "大小写匹配",
    Wholewordmatching: "整词匹配",
    Replacewith: "替换为",
    Command: "命令",
    Shortcut: "快捷键",
    PlayPause: "播放&暂停",
    GotoPreviousSegment: "转至上一句段",
    GotoNextSegment: "转至下一句段",
    LookPlayback: "播放当前句",
    Delete: "删除",
    MT: "机器翻译",
    CheckTarget: "全局检查",
    Searchsource: "搜索原文",
    Searchsource1: "搜索团队成员",
    closebtn: "查看错误",
    closebtn1: "直接提交",
    TargetCheck: "仅检查译文",
    SourceCheck: "仅检查原文",
    CheckOptions: "Check Options",
    Checklist: "Checklist",
    warningmessage1: "请输入正确格式时间",
    warningmessage2: "最末尾句段，不能进行合并操作",
    warningmessage3: "确定要删除这条备注吗？",
    warningmessage4: "请输入时间",
    warningmessage5: "请输入内容",
    warningmessage6: "确定要提交这个任务吗？",
    warningmessage7: "请选择句段",
    warningmessage8: "确定要撤销对时间轴的更新吗？",
    warningmessage9: "句段不存在",
    warningmessage10: "波形加载失败",
    warningmessage11: "发现潜在错误，请在提交前进行必要的修正",
    warningmessage12: "确定要提交这个任务吗？",
    warningmessage13: "存在译文为空，无法提交该任务",
    warningmessage14: "无数据",
    warningmessage15: "请输入具体句段号",
    warningmessage16: "存在原文为空，无法提交该任务",
    warningmessage17: "确定要还原数据到上一个平移时间操作前吗？",
    warningmessage18: "确定要还原数据到上一个MiniGap操作前吗？",
    warningmessage19: "确定要还原数据到上一个导入字幕块操作前吗？",
    warningmessage20: "请输入具体时间",
    warningmessage21: "所查找的句段不存在",
    findTips1: "查找内容不能为空",
    findTips2: "句段数据错误",
    findTips3: "已完成查找，将重新开始查找",
    findTips4: "无匹配内容",
    successmessage1: "批量插入成功",
    Confirmed: "校审通过",
    Confirmedtips: "句段已完成校审，译文质量合格",
    Preference: "偏好性",
    Preferencetips:
      "译文没有实质性错误，但改后译文的语言表达更为合适，建议参考、学习",
    SG: "规范性",
    SGtips:
      "译文未遵守对应的风格指南（Style Guide/SG）或客户要求；专有名词未使用术语表规定的译文；译文中包含错字、别字、拼写错误、标点符号错误、字母大小写错误、语法（时态、性数）等违背目标语言使用规范性的错误",
    Accuracy: "准确性",
    Accuracytips:
      "译文对原文的含义理解有误；译文未能传达出原文的实际含义；译文存在漏译或多译",
    Language: "通顺性",
    Languagetips:
      "译文语言拗口、不通顺、有歧义、逻辑/修辞/词语搭配不当，或者不符合目标语言的使用习惯",
    Typo: "笔误",
    Typotips:
      "译文中包含错字、别字、拼写错误、标点符号错误、字母大小写错误、语法（时态、性数）错误",
    Other: "其他",
    Othertips: "译文包含其他类错误",
    Withcomments: "带备注",
    SetErrorSeverity: "译文质量评分",
    SetErrorSeverity1: "添加备注",
    SetErrorSeverity2: "此处可输入备注信息",
    proofurlitem: "无性别",
    proofurlitem1: "无",
    proofurlitem2: "男",
    proofurlitem3: "女",
    proofurlitem4: "设置性别",
    DownloadSubtitleSrtFile: "下载目标语言字幕文件 .srt",
    warningmessage22: "批量合并最多支持选择十个句段",
    title_p1: "转写中",
    title_p2: "翻译中",
    title_p3: "校审中",
    title_p4: "校轴中",
    verif_text1: "您正在打开", //您正在打开 xxx 分享的任务，请登录或输入密码查看
    verif_text2: "分享的任务，请",
    verif_text3: "或输入密码查看",
    verif_text4: "请输入密码",
    verif_text5: "登录",
    verif_text6: "任务流程密码",
    verif_text7: "任务流程密码验证成功",
    edit_comment: "校审评语",
    submit_task: "提交任务",
    is_tm: "未确认句段翻译内容是否添加到TM",
    tooltipContent18: "快捷添加术语",
    tooltipContent19: "时间段硬字幕识别",
    ocrLoadingMessage: "字幕识别中...",
    successmessage2: "快速添加术语成功",
    warningmessage23:
      "存在未确认句段，无法提交。请先完成所有句段的校审和确认，或者关闭未校审句段筛选 ",
    Category: "类别",
    transInfo: "翻译人员信息",
    fontSize: "字体大小",
    letterSpacing: "文字间距",
    tooltipContent20: "Mini Duration",
    UI_text: "布局",
    UI_text1: "经典",
    UI_text2: "创意",
    UI_text3: "布局转换",
    hua_text: "仅提取画面字内容",
    warningmessage25: "批量ASR转写最多支持选择十个句段",
    warningmessage26: "批量ASR转写时不允许句段处于编辑状态",
    STQErrorType1: "校轴通过",
    STQErrorType1_desc: "已完成时间轴校审，质量合格",
    STQErrorType2: "时间轴",
    STQErrorType2_desc:
      "包括但不限于时间轴冗余、遗漏、与视频声音不同步、不符合要求的读速、时长、时间轴重叠",
    STQErrorType3: "文本",
    STQErrorType3_desc:
      "包括但不限于多字、少字、错别字、拼写错误、语法错误、与视频语音不符",
    STQErrorType4: "样式",
    STQErrorType4_desc:
      "包括但不限于与风格指南和客户的要求不符、标点符号的使用不符合风格指南的要求或者语言的使用习惯、多空格、少空格",
    STQErrorType5: "其他",
    STQErrorType5_desc: "其他类错误",
    newSource: "新原文",
    STQSegment: "校轴句段数",
    switchText6: "筛选未校轴句段",
    warningmessage27: "请选择开始和结束时间点",
    STTInfo: "转写人员信息",
    tooltipContent21: "术语备注",
    termSendTip: "将术语发送至译文",
    warningmessage24: "原文和译文不能为空",
    successmessage3: "编辑术语成功",
    warningmessage28: "开始时间与上句结束时间有交叉",
    warningmessage29: "结束时间与下句开始时间有交叉",
    warningmessage30: "句段时间超出拆分任务边界",
    warningmessage31: "参数非法",
    UI_text4: "波形",
    warningmessage32: "字幕块的字数超过最大限制值，无法导入",
    switchText7: "开启安全线",
    switchText8: "关闭安全线",
  },
  name: {
    Projects: "项目",
    Members: "团队",
    TranslationMemory: "翻译记忆库",
    Glossary: "术语库",
    Tools: "工具箱",
  },
  // 视频配音操作页
  VideoDubbing: {
    marginView: "余量查看",
    residueText1: "剩余配音时长：",
    residueText2: "剩余试听次数：",
    residueText3: "剩余配音次数：",
    residueText4: "分",
    residueText5: "次",
    dubText: "全局配音",
    dubText1: "生成音频",
    download: "下载",
    downloadText1: "最新台词 .srt",
    downloadText2: "角色台词 .excel",
    downloadText_3: "批量替换SRT",
    downloadText_4: "批量导出错误句段信息",
    downloadText_5: "批量字幕对齐",
    downloadText_6: "批量生成配音文件",
    downloadText_7: "批量生成混音文件",
    roleText0: "设置全部角色音频块语速",
    roleText1: "设置此角色音频块语速",
    roleText2: "语速",
    speed_tip1: "角色语速单次调整最小为当前值的0.5倍",
    cancel: "取消",
    apply: "应用",
    roleText3: "匹配此角色台词",
    roleText4: "配音此角色全部台词",
    roleText5: "删除此角色",
    roleText6: "收藏此角色",
    roleText7: "添加角色",
    roleText8: "已建角色",
    roleText9: "收起",
    roleText10: "角色名称不能为空",
    HideSubtitle: "隐藏字幕",
    Displaysubtitle: "显示字幕",
    SourceSubtitle: "显示源语言字幕",
    TargetSubtitle: "显示配音语言字幕",
    BilingualSubtitle: "显示双语字幕",
    SingleDubbing: "当前句配音",
    speed_tip: "语速异常（单句语速小于0.8或大于1.3）",
    loadingSingleDubbing: "单句配音中...",
    loadingDelete: "删除中...",
    videoTimeAlignment: "字幕对齐中...",
    noticeContent1: "在您开始配音之前，请仔细阅读如下内容：",
    noticeContent2:
      "1、WiiMedia 的 AI 视频配音服务按分钟数计费，每分钟收费2元（购买时长卡优惠），不足一分钟按一分钟计费。",
    noticeContent3:
      "2、费用包括选择普通/高级配音师、外语及小语种功能，全部精调功能、确认配音及下载。",
    noticeContent4: "3、未购买时长卡的用户，单个项目可免费试听 20 次",
    dialogContent: "您剩余的试听/单句配音次数不足，请购买后重试",
    Segment: "句段",
    tooltipContent3: "合并句段",
    tooltipContent4: "拆分当前句",
    tooltipContent5: "删除当前句",
    tooltipContent6: "角色复用",
    tooltipContent7: "说话人识别",
    warningmessage2: "最末尾句段，不能进行合并操作",
    loadingmessage: "加载中...",
    warningmessage3: "最多可以创建50个角色",
    segmentText: "时间轴",
    segmentText1: "时长",
    segmentText2: "角色：",
    segmentText3: "台词",
    segmentText4: "读速",
    toolText1:
      "自动对轨：自动调整音频块读速，使不同轨道音频块尽可能的在同一轨道内。",
    toolText2:
      "向左对齐：选中多个连续相邻音频块后，最左边音频块位置不变，其余音频块自动左移使间隔变为0",
    toolText3:
      "向右对齐：选中多个连续相邻音频块后，最右边音频块位置不变，其余音频块自动右移使间隔变为0",
    toolText4: "字幕对齐：自动调整句段开始结束时间与音频块时间保持一致。",
    toolText5: "删除选中音频块",
    toolText6: "帮助",
    toolText7: "撤销",
    toolText8: "操作记录",
    speakerText: "说话人识别",
    speakerText1:
      "识别说话人功能仅支持视频为中文场景，其他语言暂不支持，识别后会新增角色，并会替换句段内角色信息。",
    speakerText2:
      "请输入1-10之间的人数指定识别，0表示自动识别人数，输入准确的人数将会提高识别的准确性。",
    dubText2: "开始试听，请勿点击页面，否则将停止试听",
    dubText3: "您的时长余额已不足 ",
    dubText4: " 分钟，请续购时长卡。",
    dubText5: "句段尚未选择角色，无法配音",
    dubText6: "请确认是否对已关联角色的句段进行配音？",
    comfirmTips1: "提示",
    comfirmTips2: "确定",
    comfirmTips3: "取消",
    loadingDubbing: "配音中...",
    comfirmTips4: "请确认是否删除选中音频块？",
    dubText7: "请选择至少两个音频块",
    dubText8: "向左",
    dubText9: "向右",
    dubText10: "请确认是否对选中项进行", // 向左/向右
    dubText11: "对齐？",
    dubText12:
      "当前无法使用单句配音，您可在全局配音后，针对单句文字修改，进行单句重配；或单句更换主播、单句特殊调节时使用该功能。",
    dubText13: "请选择句段",
    dubText14: "句段内容为空，无法配音",
    dubText15: "该句段没有设置角色，无法配音",
    dubText16: "请选择同一音轨的音频块",
    dubText17: "请选择相邻音频块",
    comfirmTips5: "合并/拆分会导致已经生成的音频块被删除,是否继续执行操作?",
    comfirmTips6: "删除角色后，该角色所配音字幕需重新选择角色，是否确认删除？",
    comfirmTips7: "请确认是否对已关联该角色的句段进行配音？",
    dubText18: "该角色尚未关联句段，无法配音",
    dubText19: "已成功更新 ",
    dubText20: " 条句段，页面即将刷新",
    dubText21: "自动识别人数填写错误",
    dubText22: "该句段没有设置角色，无法试听",
    loadingDubbing1: "配音中，请稍后...",
    loadingDubbing2: "语速调整中，请稍后...",
    loadingDubbing3: "生成配音文件中，请稍后...",
    loadingDubbing4: "自动对轨中，请稍后...",
    loadingDubbing5: "文件解析中，请稍后...",
    loadingDubbing6: "说话人识别中，请稍后...",
    loadingDubbing7: "视频获取中，请稍后...",
    roleText11: "无角色",
    roleText12: "当前句精调",
    roleText13: "添加角色",
    anchorsText: "角色设置",
    anchorsText1: "输入角色名",
    anchorsText2: "另存为新角色",
    anchorsText3: "语种",
    anchorsText4: "请选择语言",
    All: "全部",
    anchorsText5: "性别",
    anchorsText6: "请选择性别",
    anchorsText7: "昵称",
    anchorsText8: "输入昵称进行搜索",
    anchorsText9: "男声",
    anchorsText10: "女声",
    anchorsText11: "童声（男）",
    anchorsText12: "童声（女）",
    segmentText5: "新语速",
    segmentText6: "全部字幕",
    segmentText7: "该角色字幕",
    segmentText8: "无角色字幕",
    segmentText9: "匹配台词",
    segmentText10: "请输入该角色文本台词，将按单行内容与句段进行匹配",
    segmentText11: "仅匹配无角色台词",
    payText: "本次购买",
    payText1: "优惠购卡",
    payText2: "产品",
    payText3: "AI 视频配音",
    payText4: "视频配音·时长卡 60分钟",
    payText5: "100元",
    payText6: "时长数",
    payText7: "分钟",
    payText8: "优惠",
    payText9: "",
    // payText9: "企业账户 9 折",
    payText10: "无",
    payText11: "实付金额",
    payText12: "有效期",
    payText13: "个月",
    payText14: "支付方式",
    payText15: "微信扫码支付",
    payText16: "支付宝扫码支付",
    payText17: "元",
    comfirmTips8: "请确认是否将音频块数据恢复至上一次操作前？",
    segmentText12: "操作",
    segmentText13: "操作时间",
    segmentText14: "拖动音频块",
    segmentText15: "拖拽音频块两侧",
    dubText23: "没有可撤销的操作",
    comfirmTips9: "该操作会将您的字幕时间向音频块对齐，是否确认执行？",
    dubText24: "全局语速",
    speed_tip2: "全局语速单次调整最小为当前值的0.5倍",
    downloadText3: "国际音轨",
    downloadText4: "配音音轨",
    downloadText5: "混音音轨",
    downloadText6: "合成视频(配音)",
    dubText25: "收藏角色成功",
    toolText9: "自动对轨",
    tooltipContent10: "单句播放",
    tooltipContent11: "当前句试听",
    dubText26: "该句段没有设置角色，无法进行角色精调",
    dubText27: "角色",
    tooltipContent12: "AI翻译",
    tooltipContent13: "手拍角色",
    tooltipContent14: "快捷键",
    tooltipContent15: "请选择角色",
    tooltipContent16:
      "视频播放时，手拍快捷键以设置该句角色，同时视频将自动开始播放下一句。",
    tooltipContent20: "或多选句段后，手拍快捷键为多选句段设置同一角色",
    tooltipContent23: "建议将视频播放速度设置为x0.5",
    segmentText16: "源语言",
    segmentText17: "配音语言",
    dubText28: "需要配音台词处于编辑状态",
    comfirmTips10:
      "修改配音台词内容会导致已经生成的音频块被删除,是否继续执行操作?",
    comfirmTips11: "删除选中句段会导致已经生成的音频块被删除,是否继续执行操作?",
    dubText29: "视频原音",
    dubText30: "该任务暂不支持下载国际音轨",
    loadingDubbing8: "生成混音文件中，请稍后...",
    loadingDubbing9: "生成合成视频文件中，请稍后...",
    tooltipContent17: "仅支持5分钟以内视频",
    tooltipContent8:
      "1. 本功能上传信息为4列Excel格式，依次为开始时间、结束时间、角色名称与主播ID",
    tooltipContent9:
      "2. 根据表格中开始、结束时间与所有句段开始、结束时间进行匹配（精确到小数点后两位）",
    tooltipContent18:
      "3. 若时间一致（精确到小数点后两位）则将表格中的角色匹配到该句段",
    tooltipContent19: "4. 若不一致则不匹配",
    dubText31: "存在尚未配音的台词，是否继续执行操作?",
    loadingDubbing10: "分离音频中，请稍后...",
    toolText10:
      " 自动对轨：将自动调整音频块语速使不同轨道文件尽可能的合并到同一轨道下，默认选中所有需要调整的音频块，您可以根据您的实际需求来选择是否取消音频块的调整，取消选中后，不会调整该音频块",
    comfirmTips12: "本次配音为全局配音，",
    comfirmTips13: "扣除配音时长",
    comfirmTips14: "分，是否确认配音",
    comfirmTips15: "本次配音为角色配音，",
    tooltipContent21: "转写当前句",
    tooltipContent22: "插入新句段",
    warningmessage1: "所查找的句段不存在",
    warningmessage4: "请输入正确格式时间",
    warningmessage5: "句段不在筛选结果内",
    tooltipContent24: "转至具体句段",
    tooltipContent25: "请输入具体句段号",
    tooltipContent26: "请输入具体时间",
    dubText32: "此功能在当前模式下无法使用",
    dubText33: "此功能在筛选时无法使用",
    dubText34: "全屏",
    warningmessage6: "句段内容不能为空",
    comfirmTips16: "检测出表格中以下角色与现有角色名称相同",
    comfirmTips17: "单击继续则将覆盖现有角色。",
    continue: "继续",
    ZoomInTimecode: "缩小波形",
    ZoomOutTimecode: "放大波形",
    warningmessage7: "尚未配音无法导出该文件",
    loadingDubbing11: "生成角色音轨文件中，请稍后...",
    downloadText7: "角色音轨",
    UploadExcelFile: "上传 Excel 文件",
    UploadOnlineRole: "导入在线任务角色",
    dubText35: "任务",
    dubText36: "请选择任务",
    tooltipContent27: "锁定/解锁当前句",
    dubText37: "句段内容为空，无法试听",
    loadingDubbing12: "自动预翻译中，请稍后...",
    tooltipContent28: "将已分配角色匹配到同视频不同语言台词本", //new
    dubText38: "更换主播",
    dubText39: "自动对轨",
    dubText40: "字幕对齐",
    dubText41: "下载",
    loadingDubbing13: "视频压制中，请稍后...",
    dubText42: "开始试听",
    downloadText9: "最新原文 .srt",
    loadingDubbing14: "生成配音波形中，请稍后...",
  },
  VideoDubbingClone: {
    dubText1: "定制主播",
    dubText2: "主播名称",
    dubText3: "请输入主播名称",
    fileText: "上传一段您想要定制主播的原音音频素材",
    fileText1: "支持wav格式，音频时长1-10min，文件大小≤50MB",
    dubText4: "背景音分离",
    warningmessage1: "上传音频时长应为1-10分钟",
    warningmessage2: "只能上传.wav格式",
    warningmessage3: "请上传文件",
    warningmessage4: "该角色尚未进行配音",
    dubText5: "我的收藏",
    dubText6: "添加角色",
    dubText7: "角色设置",
    dubText8: "克隆",
    dubText9: "修改此主播名称",
    comfirmTips1: "请确认是否从我的收藏中移除此主播？",
    dubText10: "从干净的样本录音中克隆语音",
    dubText11:
      " 1. 音频文件需要满足长度为1-10分钟的 .wav格式，文件大小不超过50MB。",
    dubText12:
      "2. 音频质量比长度更重要，尽量选择干净无背景噪音的音频，嘈杂的样本可能会导致定制声音异常。",
    dubText13: "解除与主播的绑定",
    dubText14: "收藏主播",
    dubText15: "选择角色",
    dubText16: "已选主播数量",
    warningmessage5: "该句段对应角色尚未克隆，无法试听和单句配音",
    dubText17: "该主播名称在我的收藏内重复，请重新命名",
    dubText18: "3.定制后主播自动进入我的收藏列表, 列表最多收藏20个主播",
    warningmessage6: "我的收藏列表最多可收藏20个主播",
    dubText19: "删除此主播",
    dubText20: "添加到主播",
    warningmessage7: "只能上传不大于50MB的 .wav文件",
    dubText21: "您的时长余额已不足，请续购时长卡。 ",
    comfirmTips2: "本次配音为视频克隆配音-全局配音，<br>",
    comfirmTips3: "扣除配音时长",
    comfirmTips4: "分，是否确认配音",
    comfirmTips5: "本次配音为视频克隆配音-单角色配音，<br>",
    comfirmTips6: "分，是否确认配音",
    dubText22: "角色名称",
    dubText23: "新手指导",
    dubText24: "执行手拍角色操作",
    dubText_25: "主播",
    dubText_26: "句段数（时长/min）",
    dubText_27: "克隆角色所在任务",
    dubText_28: "状态",
    dubText_29: "配音成功",
    dubText_30: "克隆成功",
    dubText_31: "是否确认配音",
    dubText_32: "是否确认删除角色",
    dubText_33: "存在句段数为0的角色,无法进行克隆",
    dubText_34: "是否确认执行批量字幕对齐",
    dubText_35: "是否确认执行批量生成配音文件",
    dubText_36: "是否确认执行批量生成混音文件",
    dubText_37: "是否确认执行全局配音",
    driverText1:
      "新的视频开始，点击“添加角色”为视频内的角色起个名字。建议只为主要角色命名，配角可用“男配”、“女配”命名。",
    driverText2:
      "如已收藏了满意的主播，也可以点击此按钮一键选择我的收藏里的一个或多个主播并自动创建角色。默认，角色名与主播名称相同，可按需修改角色名。",
    driverText3:
      "也可以通过此功能自行上传无背景噪音的单人音频文件，定制该声纹主播，定制后自动进入我的收藏列表，可长期使用。",
    driverText4:
      "为每一句台词匹配正确的角色是克隆的基本要素。点击此按钮设置快捷键。快捷键为数字键1至6，即最多支持为6个角色设置快捷键。",
    driverText5:
      "播放视频，例如，当前播放句段角色对应快捷键1，则按下数字1，WiiMedia自动将快捷键1对应的角色匹配到该句段，同时自动跳转下一句段开始播放，再次手拍当前播放句段角色对应的快捷键数字，以此类推。",
    driverText6:
      "角色匹配完毕后，点击“全局配音”，WiiMedia自动克隆角色口音或是根据已选择的主播进行全部台词配音。完成后，可根据具体需求调整音频块语速或位置，也可以通过拆分、合并或编辑台词内容来获得更佳配音效果。",
    driverText7:
      "如对选用的主播配音效果不满意，可点到我的收藏中更换其它定制的主播或AI主播。",
    driverText8:
      "全局配音后，为避免音频块重叠，音频块会出现在不同的轨道上。根据实际需求可微调单个音频块，然后点击“自动对轨”将自动调整音频块读速，使不同轨道音频块尽可能的在同一轨道内。",
    driverText9:
      "音频块调整完毕后，如需压制字幕，此功能将自动调整每句台词的开始和结束时间，使其与对应音频块的开始和结束时间保持一致。",
    driverText10: "WiiMedia提供多种类物料下载，按需下载，祝一切顺利！",
    driverText11:
      "新的视频开始，点击“添加角色”为视频内的角色起个名字。建议只为主要角色命名，配角可用“男配”、“女配”命名。然后点击角色旁边灰色按钮为该角色选择主播。",
    driverText12:
      "如已收藏了满意的主播，也可以点击此按钮一键选择我的收藏里的一个或多个主播并自动创建角色。默认，角色名与主播名称相同，可按需修改角色名。",
    driverText13:
      "为丰富主播库，可以通过此功能自行上传无背景噪音的单人音频文件，定制该声纹主播，定制后自动进入我的收藏列表，可长期使用。",
    driverText14:
      "为每一句台词匹配正确的角色是克隆的基本要素。点击此按钮设置快捷键。快捷键为数字键1至6，即最多支持为6个角色设置快捷键。",
    driverText15:
      "播放视频，例如，当前播放句段角色对应快捷键1，则按下数字1，WiiMedia自动将快捷键1对应的角色匹配到该句段，同时自动跳转下一句段开始播放，再次手拍当前播放句段角色对应的快捷键数字，以此类推。",
    driverText16:
      "角色匹配完毕后，点击“全局配音”，WiiMedia自动克隆角色口音或是根据已选择的主播进行全部台词配音。完成后，可根据具体需求调整音频块语速或位置，也可以通过拆分、合并或编辑台词内容来获得更佳配音效果。",
    driverText17:
      "如对选用的主播配音效果不满意，可点到我的收藏中更换其它定制的主播或AI主播。",
    driverText18:
      "全局配音后，为避免音频块重叠，音频块会出现在不同的轨道上。根据实际需求可微调单个音频块，然后点击“自动对轨”将自动调整音频块读速，使不同轨道音频块尽可能的在同一轨道内。",
    driverText19:
      "音频块调整完毕后，如需压制字幕，此功能将自动调整每句台词的开始和结束时间，使其与对应音频块的开始和结束时间保持一致。",
    driverText20: "WiiMedia提供多种类物料下载，按需下载，祝一切顺利！",
    driverText21:
      "新的视频开始，点击“添加角色”为视频内的角色起个名字。建议只为主要角色命名，配角可用“男配”、“女配”命名，同时选择适合的AI主播。",
    driverText22:
      "为每一句台词匹配正确的角色是配音的基本要素。点击此按钮设置快捷键。快捷键为数字键1至6，即最多支持为6个角色设置快捷键",
    driverText23:
      "播放视频，例如，当前播放句段角色对应快捷键1，则按下数字1，WiiMedia自动将快捷键1对应的角色匹配到该句段，同时自动跳转下一句段开始播放，再次手拍当前播放句段角色对应的快捷键数字，以此类推。",
    driverText24:
      "角色匹配完毕后，点击“全局配音”，WiiMedia根据已选择的主播进行全部台词配音。完成后，可根据具体需求调整音频块语速或位置，也可以通过拆分、合并或编辑台词内容来获得更佳配音效果。",
    driverText25: "如对选用的主播配音效果不满意，可点击此处更换其它AI主播。",
    driverText26:
      "全局配音后，为避免音频块重叠，音频块会出现在不同的轨道上。根据实际需求可微调单个音频块，然后点击“自动对轨”将自动调整音频块读速，使不同轨道音频块尽可能的在同一轨道内。",
    driverText27:
      "音频块调整完毕后，如需压制字幕，此功能将自动调整每句台词的开始和结束时间，使其与对应音频块的开始和结束时间保持一致。",
    driverText28: "WiiMedia提供多种类物料下载，按需下载，祝一切顺利！",
    dubText25: "试听",
    tooltipContent1:
      "检查：有角色无音频块句段、音频块与角色或主播来源不匹配句段",
    dubText26: "筛选错误句段",
    warningmessage8: "当前角色尚未选择主播，无法配音。",
    warningmessage9: "以下角色尚未选择主播，无法配音。",
    dubText27: "锁定",
    dubText28: "未锁",
    warningmessage10: "当前句已锁定，无法配音",
    warningmessage11: "句段已锁定，无法合并",
    warningmessage12: "下一句段已锁定，无法合并",
    warningmessage13: "句段已锁定，无法执行手拍操作",
    warningmessage14: "句段已锁定，无法删除",
    dubText29: "项目主播",
    tabName: "声纹定制主播列表",
    tabName1: "视频克隆配音主播列表",
    dubText30: "创建角色",
    dubText31: "保存角色",
    dubText32: "配音时长",
    warningmessage15: "可用克隆角色创建数量或保存主播数量不足，无法配音",
    warningmessage16: "可用克隆角色创建数量或保存主播数量不足，无法定制",
    dubText33: "AI主播",
    dubText34: "您的时长余额已不足，请联系任务指派人。",
    dubText35: "您剩余的试听/单句配音次数不足，请联系任务指派人。",
    dubText36: "完成",
    dubText37: "修改名称",
    dubText38: "项目角色",
    warningmessage17: "单次最多可选10条句段进行配音",
    dubText39: "选中句段配音",
    warningmessage18: "请首先选择文件",
    roleText_gender: "性别",
    roleText_age: "年龄",
    roleText_position: "定位",
    roleText_character: "性格",
    roleText_gender_1: "女性",
    roleText_gender_2: "男性",
    roleText_age_1: "低幼",
    roleText_age_2: "儿童",
    roleText_age_3: "年轻",
    roleText_age_4: "中年",
    roleText_age_5: "老年",
    roleText_position_1: "主角",
    roleText_position_2: "配角",
    roleText_position_3: "反派",
    roleText_position_4: "喜剧",
    roleText_position_5: "龙套",
    roleText_position_10: "其他",
    roleText_character_1: "内向",
    roleText_character_2: "外向",
    roleText_character_3: "幽默",
    roleText_character_4: "严肃",
    roleText_character_5: "坚强",
    roleText_character_6: "脆弱",
    roleText_character_10: "其他",
    dubText40: "角色标签",
    warningmessage19: "角色标签每一项都需选择",
    dubText41: "未设置",
    warningmessage20: "已复制该句段音频块内容",
    warningmessage21: "是否将已复制音频块粘贴到该句段下？",
    warningmessage22: "句段内容为空，无法粘贴",
    warningmessage23: "该句段无角色，无法粘贴",
    warningmessage24: "复制与粘贴句段内容不一致，无法粘贴",
    warningmessage25: "复制与粘贴句段角色不一致，无法粘贴",
    warningmessage26: "复制与粘贴句段是同一个句段，无法粘贴",
    warningmessage27: "该句段无关联音频块，复制失败",
    warningmessage28: "粘贴板无复制句段，无法粘贴",
    loadingPasteDubbing: "粘贴中...",
    dubText42: "字幕样式设置",
    dubText43: "局部涂抹",
    dubText44: "请按住鼠标左键，在视频中框选需要涂抹的区域",
    dubText45: "起始时间点",
    dubText46: "结束时间点",
    dubText47: "保存",
    dubText48: "移除",
    dubText49: "显示字幕设置",
    dubText50: "隐藏字幕设置",
    dubText51: "字幕样式设置",
    dubText52: "字体",
    dubText53: "重置样式",
    dubText54: "字符大小",
    dubText55: "字符间距",
    dubText56: "加粗",
    dubText57: "倾斜",
    dubText58: "下划线",
    dubText59: "向左对齐",
    dubText60: "居中对齐",
    dubText61: "向右对齐",
    dubText62: "字幕颜色",
    dubText63: "字幕背景",
    dubText64: "字幕对齐",
    dubText65: "字幕描边",
    dubText66: "字幕投影",
    dubText67: "单行字幕相对多行字幕底端对齐",
    dubText68: "单行字幕相对多行字幕顶端对齐",
    dubText69: "预览涂抹视频",
    dubText70: "合成视频(配音&字幕)",
    dubText71: "涂抹中",
    dubText72: "所有字幕（顶部/底部）将会按您选择的高度统一对齐",
    warningmessage29: "起始时间与结束时间不能为空",
    dubText73: "备份名称",
    dubText74:
      '该操作会将选中角色进行配音，同时对选中角色关联的任务当前配音数据进行备份。稍后，当配音完成时，可通过 "一键配音还原" 功能进行配音数据恢复。 注：若任务中的配音数据改变，则备份中的该任务配音数据将移除，此任务无法进行数据恢复；项目下同类型的任务只能同时存在一条备份数据。',
    dubText75: "所关联的任务",
    warningmessage30: "请输入备份名称",
    dubText76: "该操作会将此备份关联的任务配音数据进行还原，是否继续？",
    dubText77: "配音数据还原成功",
    dubText78: "调整音频块音量大小",
    dubText79: "音频增益值",
    dubText80: "请填写整数，增益值范围为正负20",
    warningmessage31: "请先选中音频块",
    warningmessage32: "请正确填写增益数",
    loadingVolumeGain: "音量增益处理中...",
    dubText_38: "是否确认进行一键克隆",
    dubText81: "有",
    dubText82: "无",
    dubText83: "合成视频(字幕)",
    dubText84:
      "收藏文件：选中音频块后点击收藏，切换音频块或句段后会清除收藏文件，仅可收藏一个文件",
    dubText85: "试听收藏文件",
    dubText86: "取出收藏文件",
    daubText1: "涂抹设置",
    daubText2: "撤销",
    daubText3: "导入SRT",
    daubText4: "取消选中",
    dubText87: "背景音开启/关闭",
    dubText88: "审阅模式",
    dubText89:
      "此页面为审阅模式，该模式下对句段进行操作时，不会删除对应的音频块。",
    dubText90: "生成配音波形",
  },
  Help: {
    Spacebar: "空格键",
    SpacebarVal: "播放&暂停",
    Gotolast: "转至上一句段（非编辑状态）",
    Gotonext: "转至上一句段（非编辑状态）",
    SaveNext: "保存并跳转一下句段（编辑状态）",
    Playcurrent: "播放当前句",
    Deleteselected: "删除选中句段",
    MachineTranslation: "机器翻译",
    Copycurrenttimestamp: "复制播放时间到平移时间轴",
    Splitsegment: "切分句段（编辑状态）",
    Creattimecodes: "长按J快捷添加句段（播放状态）",
    Creattimecodes1: "按下记录开始时间，抬起记录结束时间并生成句段",
    Setstarttime: "设置时间轴开始时间（选中句段状态）",
    Setendtime: "设置时间轴结束时间（选中句段状态）",
    helpText: "左键",
    helpText1: "多选音频块",
    helpText2: "按住Shift同时点击左键来多选音频块",
    helpText3: "删除选中音频块",
    helpText4: "向左对齐选中音频块",
    helpText5: "向右对齐选中音频块",
    helpText6: "时间线左右滚动",
    helpText7: "滚轮上下",
    helpText8: "撤销",
    helpText9: "按住Shift同时点击左键来多选句段",
    helpText10: "音频块",
    helpText11: "音频块关联句段不在句段筛选结果内",
    helpText12: "音频块关联句段已进行精调",
    helpText13: "音频块与关联句段中的角色不一致",
    helpText15: "音频块与关联句段中的角色或主播来源不一致",
    helpText14: "音频块默认颜色",
    helpText16: "复制音频块",
    helpText17: "粘贴音频块",
    helpText18: "开启原音音轨",
    helpText19: "开启配音音轨",
    helpText20: "音频块关联句段内容不一致",
  },
  ViewReport: {
    ViewReport: "查看报告",
    ViewReportInfo: "报告详情",
    SOURCE: "原文",
    Allsentencesegments: "全部句段",
    TRANSLATORSTARGET: "翻译译文",
    REVIEWERSTARGET: "校审译文",
  },
  supplement: {
    STT: "转写",
    TRA: "翻译",
    REV: "校审",
    STQ: "校轴",
    PROOF: "PROOF",
    Type: "类型",
    Description: "描述",
  },
  TranslationLanguageChange: {
    sourcelanguage: "原始语言",
    title: "标题",
    description: "说明",
    translation: "译文",
    successMessage: "提交成功",
  },
  SubtitleStyle: {
    style: "样式",
    italic: "斜体",
    Italic: "斜体",
    underline: "下划线",
    Underline: "下划线",
    Bold: "加粗",
    musicnotation: "音乐符号",
    bracket: "括号",
    MusicSign: "小音乐符号",
    MusicSign1: "大音乐符号",
    MusicSignwithSpace: "小音乐符号带空格",
    MusicSignwithSpace1: "大音乐符号带空格",
    Fullangleparentheses: "全角圆括号",
    Halfangleparentheses: "半角圆括号",
    squarebrackets: "方括号",
    SmallMusicSymbol: "小音乐符号",
    SmallMusicSymbolspace: "小音乐符号 + 空格",
    Bigmusicsymbols: "大音乐符号",
    Bigmusicsymbolsspace: "大音乐符号 + 空格",
    Subtitleposition: "字幕位置",
    togglecase: "大小写转换",
    togglecase1: "全部大写",
    togglecase2: "首字母大写",
    ClearStyle: "清除样式",
    Up: "上",
    Down: "下",
    Left: "左",
    Right: "右",
    centre: "中",
    middle: "中",
    text: "激活译文",
    text1: "设置错误类型",
  },
  // 个人部分 语言切换
  PersonalEndLanguage: {
    vip: "仅对VIP客户开放定制服务",
    moreLangth: "，最多选择 10 个文件",
    moreLangth1: "，最多选择 10 个文件",
    moreLangth2: "，最多选择 30 个文件",
    staging: "工作台",
    myfile: "我的文件",
    openingTime: "片头时间点",
    endingTime: "片尾时间点",
    timeMessage: "输入的时间不能超出视频时长",
    timeMessage1: "请输入正确时间",
    ViewExamples: "查看示例",
    ViewExamples1: "错误示例",
    ViewExamples2: "正确示例",
    ViewExamples3: "框边缘过于贴近文字，影响识别效果",
    ViewExamples4: "框与文字间留有一定距离，同时避免框选字幕以外文字。",
    ViewExamples5: "设置识别范围",
    ViewExamples6: "显示源视频",
    ViewExamples7:
      "您可以手动选择要识别的片头时间点和片尾时间，选择后将仅对所选时间范围内进行硬字幕识别。",
    PersonalCenter: "个人中心",
    PurchaseServices: "购买服务",
    removeVIP: "开通企业 VIP",
    Feedback: "意见反馈",
    Logoutoflogin: "退出登录",
    buy: "购买",
    enterpriseVip: "企业 VIP",
    Claimgiftpackage: "领取本月免费礼包",
    GiftPackCodeRedemption: "礼包码兑换",
    Giftpackagecode: "礼包码",
    Allservices: "全部服务",
    Hardsubtitlerecognition: "硬字幕识别",
    Hardsubtitlerecognitionillustrate: "AI提取视频内嵌字幕</br>时间轴精确到帧",
    Subtitlealignment: "字幕对齐",
    Subtitlealignmentillustrate: "导入视频/台词文字</br>AI自动输出字幕文件",
    Videodubbing: "视频AI配音",
    Videodubbingillustrate: "海量AI主播</br>转写/翻译/混音一站式服务", //2.18新增
    Videotranscription: "视频AI字幕",
    Videotranscriptionillustrate: "字幕转写/识别</br>在线AI/人工翻译",
    Videotranslation: "视频翻译",
    Videotranslationillustrate: "AI 语音转写</br>AI/人工字幕翻译",
    subtitling: "字幕翻译",
    subtitlingillustrate: "导入视频+字幕文件</br>AI/人工字幕翻译",
    Audiotranscription: "音频AI字幕",
    Audiotranscriptionillustrate: "语音转写</br>在线AI/人工翻译",
    ComicQuickFlip: "漫画快翻",
    ComicQuickFlipillustrate: "上传漫画图片</br>一键识别、翻译输出成品",
    VideoCloningDubbing: "视频克隆配音",
    VideoCloningDubbingillustrate:
      "口音克隆/声纹定制</br>转写/翻译/混音一站式服务",
    VideoCloningDubbingsmall: "声纹定制配音",
    VideoCloningDubbingsmallillustrate:
      "自定义声纹主播</br>转写/翻译/混音一站式服务",
    Textdubbing: "文本AI配音",
    Textdubbingillustrate: "输入文字</br>AI智能合成语音", //2.18新增
    AIdubbing: "AI 配音",
    fileAddress: "YouTube视频地址",
    fileAddress1: "请输入视频地址",
    fileAddress2: "非法地址，请重新输入",
    fileAddress3: "本地视频",
    AIdubbingillustrate: "AI 文本配音</br>AI 视频配音",
    Comingsoonstaytuned: "即将上线，敬请期待",
    RecentFiles: "最近文件",
    Processing: "处理中 ",
    Viewmore: "查看更多",
    NoData: "暂无数据",
    uploadvideo: "上传视频",
    uploadvideo1: "zip上传",
    Reupload: "重新上传",
    uploadImage: "上传图片",
    DragVideo: "点击选择或拖动视频到这里",
    DragVideo1: "点击选择或拖动文件到这里",
    DragVideo2: "点击选择或拖动音频到这里",
    SupportsMP4formatvideo: "支持mp4格式，视频",
    fileText: " 支持mp3、wav、aac格式，音频时长 ≤",
    fileText1: "支持 jpg, jpeg, png, zip 格式，",
    fileText2: "单张宽高尺寸不超过4001*10001，文件大小不超过",
    Transcriptionmethod: "转写方式",
    Transcriptionmethod1: "AI 自动语音转写",
    Transcriptionmethod2: "上传非精准字幕文件",
    Transcriptionmethod3: "视频语言",
    Transcriptionmethod4: "音频语言",
    Transcriptionmethod5: "文字朝向",
    Transcriptionmethod6: "横向",
    Transcriptionmethod7: "竖向",
    Transcriptionmethod8: "需要翻译字幕",
    Freegiftpackage: "领取成功",
    Freegiftpackage1: "感谢使用 WiiMedia，您的每月免费礼包已到账。",
    Freegiftpackage2: "有效期至 ",
    Freegiftpackage3: "每月免费礼包",
    Freegiftpackage4: "译制功能包（10 分钟）",
    Freegiftpackage5:
      "可用功能：音/视频转写、机翻、字幕调整、听译、切轴、调轴、硬字幕识别、字幕对齐、字幕/音频下载功能。",
    Freegiftpackage6: "文本配音包（10 次试听）",
    Freegiftpackage7:
      "可用功能：使用基础及高级（限免）AI 配音师，全部文本精调功能。",
    Freegiftpackage8: "视频配音包（10次试听）",
    Freegiftpackage9:
      "可用功能：使用基础及高级（限免）AI 配音师，全部视频精调功能。",
    Freegiftpackage10: "漫画译制包（10 张）",
    Freegiftpackage11:
      "漫画定制 OCR，画笔、图片批量处理、自动错误检查、专业 CAT 翻译工具。",
    Freegiftpackage12: "开始体验",
    duration: "时长",
    minute: "分钟",
    uploadFile: "上传文件",
    uploadFile1: "（.srt, .ass）",
    uploadFile2: "（.srt）",
    uploadFilep: "未上传字幕文件将自动语音转写",
    upload: "上传",
    SRTLanguage: "SRT语言",
    PleaseselectSRTlanguage: "请选择 .srt语言",
    PleaseselectSRTlanguage1: "请选择配音语言",
    batchupload: "批量上传",
    videoTitle: "视频标题",
    Youtubevideo: "YouTube视频",
    Youtubevideo1: "批量添加YouTube视频",
    Youtubevideo2: "最多添加5个视频",
    Youtubevideo3: "请输入正确地址",
    youtubeTitle: "任务名称",
    youtubeTitle1: "YouTube视频地址",
    youtubeTitle2: "未填写任务名称",
    youtubeTitle3: "未填写YouTube视频地址",
    SRTfile: "上传配音文件 .srt",
    SRTLanguage1: "选择配音语言",
    Selectuploadedvideo: "选择已上传视频",
    gobuy: "去购买",
    Teammode: "团队模式",
    messages1:
      "单次上传视频数量不超过10个，支持mp4格式，视频 ≤1GB，时长 ≤ 120分钟",
    messages2: "您账号下字幕译制包剩余时长不足，无法创建任务",
    messages3: "试听次数不足无法创建任务",
    messages4: "配音次数不足无法创建任务",
    messages5: "动漫张数不足无法创建任务",
    messages6: "可用空间不足无法创建任务",
    warningmessage: "请上传文件",
    warningmessage1: "请选择视频语言",
    warningmessage2: "请上传 .srt文件",
    warningmessage3: "存在未选择语言",
    warningmessage4: "源语言与目标语言不能选相同选项",
    warningmessage5: "请选择音频语言",
    warningmessage6: "选择AI配音类型",
    warningmessage7: "只能上传 .mp4格式",
    warningmessage8: "单次上传视频大小应小于等于1GB",
    warningmessage9: "单次上传视频时长应小于120分钟",
    warningmessage10: "剩余空间不足,请重新选择文件",
    warningmessage11: "超出最大上传限制",
    warningmessage12: "只能上传 .mp3、.wav、.aac格式",
    warningmessage13: "上传音频时长应小于",
    warningmessage14: "只能上传 .jpg、.jpeg、.png、.zip格式",
    warningmessage15: "上传压缩包大小应小于",
    warningmessage16: "上传图片文件大小应小于50M",
    warningmessage17: "单张宽高尺寸不超过4001*10001",
    warningmessage18: "上传视频大小应小于等于",
    warningmessage19: "上传视频时长应小于",
    warningmessage20: "只能上传 .txt文件",
    warningmessage21: "只能上传 .srt文件",
    warningmessage22: "只能上传 .srt和 .ass文件",
    warningmessage23: "上传的srt文件时长大于视频时长,请更换并重新上传",
    warningmessage24: "只能上传 .jpg、.jpeg、.png格式",
    warningmessage25: "传文件大小应小于2M",
    warningmessage26: "意见已提交",
    warningmessage27: "请输入您的建议或意见",
    warningmessage28: "请正确输入礼包码",
    warningmessage29: "请输入礼包码",
    warningmessage30: "请选择目标语言",
    warningmessage31: "请选择视频源语言",
    warningmessage32: "上传对齐字幕文本",
    warningmessage33: "当前仅支持 .txt 格式",
    warningmessage34: "请选择源语言",
    warningmessage35:
      "请在视频中框选出字幕范围，并保证每一句字幕都在识别框中，上下边框不要紧贴文字，两边留有一定的空白距离有助于识别更精准",
    warningmessage36: "文件名称过长",
    warningmessage37: "请上传txt文件",
    warningmessage38:
      " 1. 字幕内容需要分行处理，系统会按行内容与视频进行匹配 \n" +
      " 2. 请确保字幕内容与视频完全对应，不要出现错误内容或字幕顺序颠倒的情况",
    warningmessage39: "请选择配音语言",
    errormessage: "创建失败",
    errormessage1: "文件解析出现错误,请重新上传",
    successMessage: "创建成功",
    successMessage1: "退出成功",
    loadingMessage: "文件已上传完毕，正在解析中... ",
    inputMessage: "请输入您的建议或意见，我们将根据您的反馈不断优化服务。",
    VideoDubbingText: "配音语言",
    VideoDubbingText2: "上传配音 .srt文件",
    VideoDubbingText3: "无配音 .srt文件",
    VideoDubbingText4: "上传srt文件",
    VideoDubbingText5: "有源语言 .srt文件",
    VideoDubbingText6: "上传字幕文件 .srt",
    VideoDubbingText7: "WiiMedia会自动执行AI翻译",
    VideoDubbingText8: "无源语言 .srt文件",
    VideoDubbingText9: "语音转写",
    VideoDubbingText10: "硬字幕识别",
    VideoDubbingText11: "配音方式",
    VideoDubbingText12: "单角色配音",
    VideoDubbingText13: "男女配音",
    VideoDubbingText14: "统自动识别说话人男女并创建角色，同时对应到句段",
    VideoDubbingText15: "多角色配音",
    VideoDubbingText16: "角色数量",
    VideoDubbingText17:
      "系统自动识别说话人并创建角色，同时对应到句段，输入正确的角色数量有助于识别结果更精准",
    VideoDubbingText18: "配音语言与源语言不能相同",
    VideoDubbingText19: "系统自动创建默认角色并对应到句段",
    youtubeText: "添加", //2.18新增
    youtubeText1:
      "仅支持上传双列Excel文件，第一列为任务名称，第二列为YouTube视频地址，从第二行开始读取，单次最大读取5行", //2.18新增
    youtubeText2: "上传Excel", //2.18新增
    youtubeText3: "视频地址样例：", //2.18新增
    VideoDubbingText20: "硬字幕涂抹",
    VideoDubbingText21: "硬字幕压制",
    VideoDubbingText22: "人声音轨，≤200MB（可选）",
    VideoDubbingText23: "国际音轨，≤200MB（可选）",
    warningmessage40: "只能上传 .wav 格式",
    warningmessage41: "该功能仅支持五分钟以内的视频文件",
    warningmessage42: "视频字幕涂抹剩余时长不足",
    warningmessage43: "文件大小应小于200M",
    warningmessage44: "人声音轨时长与视频时长不一致",
    warningmessage45: "国际音轨时长与视频时长不一致",
    warningmessage46: "任务名称不能为空",
    AssignText1: "保存设置",
    AssignText2: "新建文件夹",
    warningmessage47: "新建文件夹名称不能为空",
    warningmessage48: "未选择更换后任务执行人会将清空以下任务的当前执行人",
  },
  // 购买服务
  PurchaseServices: {
    DeepExperiencePackage: "深度体验包",
    Translationfunctionpackage: "译制功能包",
    Translationfunctionpackageexplain: "",
    // Translationfunctionpackageexplain: "开通企业VIP，享受9折优惠>>",
    TranslationfunctionpackageMessage:
      "购买后您将获得特权：音/视频转写、机翻、字幕调整、听译、切轴、调轴、硬字幕识别、字幕对齐、字幕下载",
    periodofvalidity: "有效期",
    Months: "个月",
    makeanappointment: "约",
    yuanminute: "元/分钟",
    buynow: "立即购买",
    AIdubbingpackage: "AI 视频配音包",
    AIdubbingpackageexplain:
      "购买后您将获得特权：适用于视频AI配音和声纹定制配音的所有功能",
    AIdubbingpackageexplain1: "1，一分钟视频消耗一分钟时长卡。",
    AIdubbingpackageexplain2:
      "2，服务范围包括视频字幕语音转写/硬字幕识别、AI自动翻译、音频合成等，无重复收费。",
    AIdubbingpackageexplain3:
      "**声纹定制配音，每一个账号可以免费收藏20个声纹定制主播，超出部分，收费标准为1个声纹定制主播收取100元。",
    AIdubbingpackageexplain4: "**视频克隆配音模块目前仅对VIP客户开放定制服务，",
    AIdubbingpackageexplain5: "联系客服",
    AIdubbingpackageexplain6: "获取更多支持。",
    TextdubbingCharactercards: "文本配音·字符卡",
    Validityperiod: "（有效期36个月）",
    Validityperiod1: "70,000字符",
    Validityperiod2: "约1.4元/千字符",
    ValidityperiodTitle: "文本配音·字符卡 70,000字符",
    Videodubbingdurationcard: "视频配音·时长卡",
    Videodubbingdurationcard1: "60分钟",
    Videodubbingdurationcard2: "约1.6元/分钟",
    Videodubbingdurationcard3: "视频配音·时长卡 60分钟",
    break: "折",
    ComicFlipBag: "AI 文本配音包",
    ComicFlipBag1:
      "购买后您将获得特权：使用基础及高级（限免）AI配音师，全部文本精调功能",
    average: "平均",
    only: "仅",
    Yuanpiece: "元/张",
    illustrate: "说明：",
    illustrate1: "1、付费功能包仅供同一账户一个设备使用",
    illustrate2: "2、个人版与企业VIP用户使用以上功能均需要购买付费功能包",
    illustrate3: "3、深度体验包每个账户终身只可购买一次",
    illustrate4: "4、付费功能包均自购买之日生效，并有不同有效期，超期失效",
    illustrate5: "5、如您有开票需求及其他疑问，可联系客服：",
    illustrate6:
      "1、一个账号仅限一台设备登录（企业VIP用户分配任务给多个独立账户，非一账户多设备登录）",
    illustrate7:
      "2、个人用户与企业VIP用户在部分功能上有区别，但是在使用译制功能、AI配音功能时，仍需购买对应的付费包。",
    illustrate8: "3、企业VIP购买后立即生效，有效期自购买之日起算，为期1年。",
    illustrate9: "4、如您有业务定制需求、开票需求及其他疑问，可联系客服：",
    email: "邮箱",
    phone: "电话",
    product: "产品",
    preferential: "优惠",
    Enterpriseaccountdiscount: "",
    // Enterpriseaccountdiscount: "企业账户 9 折",
    nothave: "无",
    Paidamount: "实付金额",
    Paymentmethod: "支付方式",
    WeChatscanningpayment: "微信扫码支付",
    Alipaycodescanningpayment: "支付宝扫码支付",
    yuan: "元",
    payment: "支付",
    titlep1: "5 小时 译制功能包",
    titlep2: "20 小时 译制功能包",
    titlep3: "100 小时 译制功能包",
    titlep4: "文本配音·字符卡",
    titlep7: "5 小时  视频配音·时长卡",
    titlep8: "20 小时  视频配音·时长卡",
    titlep9: "100 小时  视频配音·时长卡",
    Personal: "个人版",
    EnterpriseVIPEdition: "企业 VIP 版",
    Noannualfee: "无年费",
    year: "年",
    activatenow: "立即开通",
    durationcard: "购买时长卡折扣",
    Nodiscount: "无折扣",
    unlimited: "无限制",
    subtitledownload: "字幕下载",
    audiodownload: "音频下载",
    videoDownload: "视频下载",
    Cloudstoragespace: "云储存空间",
    Cloudstoragespace1: "3G（30天）",
    Cloudstoragespace2: "（过期仅删除音视频文件，不删除项目数据）",
    Cloudstoragespace3: "50G（180天）",
    Exclusivecustomerservice: "专属客服",
    TeamFunctionality: "团队功能",
    Glossary: "术语库",
    TranslationMemoryLibrary: "翻译记忆库",
    Automaticerrorchecking: "自动错误检查",
    Recommendedusagescenarios: "推荐使用场景",
    Recommendedusagescenarios1:
      "适合个人译员使用，注册每月可免费领取部分使用时长，可享基础云储存空间，进一步体验可购买9.9深度体验包。 如长期使用，可按需购买字幕译制包、AI 配音包及漫画快翻包。",
    Recommendedusagescenarios2:
      "适合有团队管理需求、专业语言资产管理功能需求及大批内容储备的企业用户。<br />企业VIP用户除了享有个人版一切权益之外，还提供更大的储存空间、专属客服以及企业专属功能。",
    EnterprisePLUSEdition: "企业 PLUS 版",
    Ondemandcustomization: "按需定制",
    Contactimmediately: "立即联络",
    Contactimmediately1:
      " 如企业VIP提供的功能仍未能满足您的需求，或您有其他定制化需求，可以联系我们，来为您定制专属解决方案。",
    Contactyou: "我们会尽快联系您",
    name: "姓名",
    entername: "请输入您的姓名",
    corporatename: "公司名称",
    entercorporatename: "请输入您的公司名称",
    Emails: "电子邮箱",
    enteremail: "请输入邮箱地址",
    enterthecorrectemail: "请输入正确的邮箱地址",
    enteryouremail: "请输入您的电子邮箱",
    Othercontactinformation: "其他联系方式",
    PhoneWeChatQQ: "电话/微信/QQ...",
    titlep10: "5 小时  视频涂抹·时长卡",
    titlep11: "20 小时  视频涂抹·时长卡",
    titlep12: "100 小时  视频涂抹·时长卡",
    AIDuabPackage: "视频涂抹功能包",
    AIDuabPackageExplain: "购买后您将获得特权：适用于视频字幕涂抹功能",
  },
  // 我的文件
  MyFiles: {
    MyFilesTitle: "我的文件",
    CreateFolder: "新建文件夹",
    CreateFolder1: "输入文件夹名称",
    FolderName: "文件夹名称",
    FolderType: "文件类型",
    inputFolderTips: "请输入文件夹名称",
    inputFolderTips1: "新建文件夹成功",
    loadingTips: "加载中...",
    defaultFolder: "默认文件夹",
    datetime1: "开始日期",
    datetime2: "至",
    datetime3: "结束日期",
    inputFolderTips2: "请输入文件或文件夹名称",
    search: "查询",
    spaceUsage: "空间用量",
    fileSave: "文件保存",
    day: "天",
    fileSaveTips1: "1.文件到期后不删除任务，只清除文件。",
    fileSaveTips2: "2.开通企业VIP后，文件保存时间延长为180天。",
    moveTo: "移动至…",
    moveTo1: "移动至",
    delete: "删除",
    name: "名称",
    filesNum: "文件数量",
    attribute: "团队",
    createTime: "创建时间",
    updateTime: "更新时间",
    operation: "",
    fileDetails: "文件详细信息",
    service: "类型",
    team: "团队",
    sourceLanguage: "源语言",
    targetLanguage: "目标语言",
    SRTLanguage: ".srt语言",
    duration: "时长",
    segments: "句段数",
    wordCount: "字数",
    mediaDuration: "媒体时长",
    deadline: "截止日期",
    imgCount: "图片数量",
    comments: "备注",
    newName: "新名称",
    newNameTips: "请输入新名称",
    searchTips: "暂无数据",
    details: "详细信息",
    rename: "重命名",
    renameFolder: "文件夹重命名",
    my: "我的",
    jobType1: "视频转写",
    jobType2: "视频翻译",
    jobType3: "字幕翻译",
    jobType4: "音频转写",
    jobType5: "漫画快翻",
    jobType6: "AI配音-文本",
    jobType7: "硬字幕识别",
    jobType8: "字幕对齐",
    jobType9: "AI配音-视频",
    jobType10: "声纹定制配音",
    jobType11: "视频克隆配音",
    jobType101: "团队 - 转写",
    jobType102: "团队 - 翻译",
    jobType103: "团队 - 校审",
    jobType106: "团队 - 校轴",
    jobType104: "团队 - 声纹定制配音",
    jobType105: "团队 - 视频克隆配音",
    teamJob1: "团队 - 进行中",
    teamJob2: "团队 - 已完成",
    teamJob3: "未完成，当前进度",
    moveToTips: "请选择要移入的文件夹",
    moveToTips1: "移动成功",
    jobStatus1: "正在处理中，请稍后",
    jobStatus2: "正在识别中...",
    renameFiles: "文件重命名",
    deleteTips1: "请确认是否删除此文件夹？",
    deleteTips2: "请确认是否删除此文件？",
    comfirmTips1: "提示",
    comfirmTips2: "确定",
    comfirmTips3: "取消",
    deleteTips3: "删除成功",
    moveToTips2: "文件夹不能移动",
    deleteTips4: "文件夹内有包含文件，无法删除",
    deleteTips5: "文件有团队任务，无法删除",
    deleteTips6: "文件有pending状态，无法删除",
    deleteTips7: "请确认是否删除选中项？",
    renameTips: "新名称不能为空",
    renameTip1: "文件夹重命名成功",
    renameTip2: "文件重命名成功",
    All: "全部",
    download: "下载",
    downloadTitle: "AI配音",
    downloadTips: "批量下载仅支持AI视频配音任务",
    downloadLoading: "导出中...",
    downloadTips1: "文件下载失败",
    downloadTips2: "所选任务无可下载文件",
    DUBLanguage: "配音语言",
    Projecttask:
      "仅支持被指派任务（校审流程，需同一语言对，同一项目下，未拆分）",
    Projecttask1: "仅支持校审流程",
    Projecttask2:
      "仅支持被指派任务（翻译流程，需同一语言对，同一项目下，未拆分）",
    Projecttask3:
      "仅支持被指派任务（转写流程，需同一语言对，同一项目下，未拆分）",
    Projecttask4: "仅支持转写流程",
    Projecttask5: "仅支持翻译流程",
    Projecttask6: "仅支持同一语言对",
    Projecttask7: "仅支持同一语言",
    Projecttask8: "仅支持校轴流程",
  },
  //个人中心
  PersonalCenter: {
    unit: "（分钟）",
    unit1: "（张）",
    unit2: "（字符）",
    unit3: "（小时）",
    ID: "ID：",
    title: "个人中心",
    loadingTips: "加载中...",
    teamInformation: "所在团队信息",
    corporateVIP: "企业 VIP",
    expirationTime: "到期时间：",
    email: "邮箱：",
    mobilephone: "手机号：",
    Nickname: "昵称：",
    unbound: "未绑定",
    binding: "绑定",
    bound: "已绑定",
    unbind: "解除绑定",
    replace: "更换",
    wechat: "微信账户：",
    setUp: "设置",
    changePassword: "更改密码",
    accountCancellation: "注销账号",
    giftText: "领取本月免费礼包",
    giftText1: "本月免费礼包已领取",
    giftText2: "购买记录",
    giftText3: "服务明细",
    giftText4: "译制功能包",
    remainingDuration: "剩余时长",
    giftText5:
      "可用功能：音/视频转写、机翻、字幕调整、听译、切轴、调轴、硬字幕识别、字幕对齐、字幕/音频下载",
    hour: "小时",
    giftText6: "购买更多",
    giftText7: "AI 文本配音包",
    giftText8: "剩余字符数",
    giftText9: "试听次数",
    giftText10: "免费配音次数（1000字符以内）",
    giftText11: "次",
    giftText12: "总下载次数",
    giftText13: "无限制",
    giftText14: "可用功能：使用基础及高级（限免）AI配音师，全部文本精调功能",
    giftText15: "AI 视频配音包",
    minute: "分钟",
    char: "字符",
    giftText16: "免费配音次数（5分钟以内）",
    giftText17: "漫画快翻包",
    giftText18: "剩余图片数量",
    giftText19: "张",
    giftText20:
      "可用功能：漫画定制OCR，画笔、图片批量处理、自动错误检查、专业CAT翻译工具",
    giftText21: "空间用量",
    giftText22: "如有疑问，请联系客服",
    giftText23: "绑定手机号",
    giftText24: "绑定新手机号",
    phoneNumber: "手机号",
    tips1: "请输入手机号",
    phoneText: "验证码",
    phoneText1: "短信验证码",
    phoneText2: "发送验证码",
    second: "秒",
    oldPassword: "旧密码",
    oldPasswordText: "请输入旧密码",
    newPassword: "新密码",
    newPasswordText: "请输入新密码",
    confirmPassword: "确认密码",
    confirmPasswordText: "请再次输入新密码",
    PasswordText: "请输入8-20位密码",
    PasswordText1: "请再次输入密码",
    PasswordText2: "两次输入密码不一致!",
    giftText25: "验证身份信息",
    email1: "邮箱",
    giftText26: "请输入验证码",
    team: "团队",
    joinedOn: "加入时间",
    wechatText: "请用微信扫描",
    wechatText1: "正在加载",
    wechatText2: "二维码失效",
    wechatText3: "重新加载",
    wechatText4: "扫码并关注 「Wiitrans 我译网」完成绑定",
    phoneText3: "手机号不能为空",
    phoneText4: "请输入正确手机号",
    uploadText: "只能上传.jpg、.jpeg、.png格式",
    uploadText1: "上传文件大小应小于2M",
    uploadText2: "token已失效，请重新登陆",
    copyText: "复制成功",
    comfirmText: "注销账号将清空您账号上的所有内容，是否确认注销？",
    comfirmTips1: "提示",
    comfirmTips2: "确定",
    comfirmTips3: "取消",
    comfirmTips4: "注销成功",
    teamTips: "暂未加入任何团队",
    phoneText5: "绑定手机号成功",
    PasswordText3: "修改密码成功,请重新登陆",
    phoneText6: "验证成功，绑定新手机号",
    phoneText7: "请输入电话号",
    phoneText8: "验证码已发送",
    wechatText5: "该微信已经绑定其他账号了，请先解除绑定",
    wechatText6: "请确认是否解除绑定微信？",
    PurchaseRecordText: "购买记录",
    PurchaseRecordText1: "购买时间",
    PurchaseRecordText2: "购买产品",
    PurchaseRecordText3: "支付方式",
    PurchaseRecordText4: "花费金额",
    ServiceDetailsText: "服务明细",
    ServiceDetailsText1: "时间",
    ServiceDetailsText2: "内容",
    ServiceDetailsText3: "来源 ",
    giftText27: "视频涂抹功能包",
    giftText28: "可用功能：视频字幕涂抹",
    TurnOnMessage: "开启消息提醒",
    TurnOffMessage: "关闭消息提醒",
    exportInfo: "导出个人信息副本",
    CancellationTips:
      "请填写注销理由，在15天内未登录自动注销（缓冲期），若登录的话取消注销",
    CancellationTips1: "请填写注销理由",
    personInfo: "个人信息",
    otherInfo: "第三方共享清单",
  },
  // Login
  Login: {
    text1: "输入邮箱，立即体验",
    text2: "注册",
    text3: "已有账号登录",
    text4: "输入邮箱，验证身份",
    confirm: "确定",
    text5: "验证邮件已发送，请您登录邮箱完成验证",
    wechatText: "微信扫码登录",
    wechatText1: "WiiMedia 开放平台",
    wechatText2: "正在加载",
    wechatText3: "二维码失效",
    wechatText4: "重新加载",
    wechatText5: "微信扫码关注公众号即可登录",
    wechatText6:
      "关注公众号可实时接收项目进度，获悉优惠活动、功能更新及使用教程",
    wechatText7: "首次使用微信登录需绑定 WiiMedia 或 Wiitrans 账号，",
    wechatText8: "之后扫码即可直接登录",
    wechatText9: "绑定已有账号",
    wechatText10: "注册新账号",
    wechatText11: "返回",
    email: "邮箱",
    password: "密码",
    binding: "绑定",
    text6: "WiiMedia 账号登录",
    text7: "注册账号",
    text8: "忘记密码？",
    login: "登录",
    text9: "Wiitrans 账号登录",
    mediaText: "注册 WiiMedia 账号",
    nickname: "昵称",
    nicknameText: "请输入昵称",
    nicknameText1: "昵称唯一，确认注册后无法修改",
    passwordText: "请输入密码",
    passwordText1: "密码可包含字母、数字、下划线；区分大小写；长度为 8-20 位",
    passwordText2: "请再次输入密码",
    text10: "已阅读并同意：",
    text11: "隐私政策",
    text12: "产品服务协议",
    passwordText3: "设置新密码",
    passwordText4: "确认",
    passwordText5: "设置完成",
    passwordText6: "新密码设置完成！请牢记您的新密码",
    passwordText7: "返回登录",
    wechatText12: "请用微信扫描",
    wechatText13: "扫码并关注 「Wiitrans 我译网」完成绑定",
    wechatText14: "绑定 WiiMedia 账号",
    passwordText8: "两次输入密码不一致!",
    nicknameText2: "昵称最长不能超过50个字",
    nicknameText3: "该昵称已存在,请更换昵称",
    text13: "请输入邮箱",
    text14: "请输入正确邮箱",
    text15: "激活邮件已发送到您的注册邮箱，请尽快登录邮箱激活。",
    passwordText9: "输入密码只支持数字，字母(大小写)，下划线",
    passwordText10: "请输入8-20位密码",
    passwordText11: "登录前请先阅读并同意 服务协议、隐私政策",
    text16: "、",
  },
  SplitTask: {
    name: "行数：",
    name1: "总时长：",
    name2: "任务名称：",
    name3: "拆分方式",
    name4: "进度",
    name5: "请先进行拆分",
  },
  CustomizedAnchor: {
    name1: "音频来源",
    name2: "选择已有任务角色",
    name3: "添加更多",
    name4: "定制成功",
  },
  //主播风格
  VoiceStyle: {
    text1: "撒娇",
    text2: "诗歌 - 阅读",
    text3: "严肃",
    text4: "助理",
    text5: "客服",
    text6: "害怕",
    text7: "平静",
    text8: "开心",
    text9: "悲伤",
    text10: "抒情",
    text11: "新闻",
    text12: "温柔",
    text13: "生气",
    text14: "聊天",
    text15: "不满",
    text16: "沮丧",
    text17: "旁白、放松",
    text18: "新闻 - 休闲",
    text19: "旁白 - 专业",
    text20: "旁白 - 放松",
    text21: "新闻 - 正式",
    text22: "广告 - 欢快",
    text23: "尴尬",
    text24: "沮丧",
    text25: "女青年",
    text26: "男青年",
    text27: "男老年",
    text28: "女中年",
    text29: "女孩",
    text30: "女老年",
    text31: "羡慕",
    text32: "男孩",
    text33: "男中年",
    text34: "纪录片旁白",
    text35: "窃窃私语",
    text36: "有希望",
    text37: "大喊大叫",
    text38: "友好",
    text39: "不友好",
    text40: "兴奋",
    text41: "同理心",
    text42: "英语(美国)",
    text43: "英语(英国)",
    text44: "东北话",
    text45: "河南话",
    text46: "陕西话",
    text47: "冀鲁官话",
    text48: "台湾普通话",
    text49: "吴语",
    text50: "粤语-简体",
    text51: "粤语-繁体",
    text52: "西南官话",
    text53: "日语(日本)",
    text54: "韩语(韩国)",
    text55: "德语(德国)",
    text56: "法语(法国)",
    text57: "法语(加拿大)",
    text58: "法语(比利时)",
    text59: "法语(瑞士)",
    text60: "意大利语(意大利)",
    text61: "俄语(俄罗斯)",
    text62: "西班牙语(墨西哥)",
    text63: "西班牙语(西班牙)",
    text64: "葡萄牙语(巴西)",
    text65: "葡萄牙语(葡萄牙)",
    text66: "泰语(泰国)",
    text67: "越南语(越南)",
    text68: "印尼语(印度尼西亚)",
    text69: "丹麦语(丹麦)",
    text70: "乌克兰语(乌克兰)",
    text71: "乌兹别克语(乌兹别克斯坦)",
    text72: "乌尔都语(印度)",
    text73: "乌尔都语(巴基斯坦)",
    text74: "书面挪威语(挪威)",
    text75: "保加利亚语(保加利亚)",
    text76: "僧伽罗语(斯里兰卡)",
    text77: "克罗地亚语(克罗地亚)",
    text78: "冰岛语(冰岛)",
    text79: "加利西亚语(加利西亚语)",
    text80: "匈牙利语(匈牙利)",
    text81: "印地语(印度)",
    text82: "哈萨克语(哈萨克斯坦)",
    text83: "土耳其语(土耳其)",
    text84: "塞尔维亚语(塞尔维亚)",
    text85: "孟加拉语(孟加拉)",
    text86: "希伯来语(以色列)",
    text87: "希腊语(希腊)",
    text88: "拉脱维亚语(拉脱维亚)",
    text89: "捷克语(捷克)",
    text90: "斯洛伐克语(斯洛伐克)",
    text91: "斯洛文尼亚语(斯洛文尼亚)",
    text92: "波兰语(波兰)",
    text93: "波斯尼亚语(波斯尼亚)",
    text94: "瑞典语(瑞典)",
    text95: "缅甸语(缅甸)",
    text96: "罗马尼亚语(罗马尼亚)",
    text97: "老挝语(老挝) ",
    text98: "芬兰语(芬兰)",
    text99: "荷兰语(荷兰)",
    text100: "他加禄语(菲律宾)",
    text101: "阿拉伯语(沙特阿拉伯)",
    text102: "马来语(马来西亚)",
    text103: "马耳他语(马耳他)",
    text104: "高棉语(柬埔寨)",
    text105: "西班牙语(美国)",
    text106: "阿拉伯语",
    text107: "中文(香港)",
    text108: "多情感",
    text109: "多场景",
    text110: "多年龄",
    text111: "角色扮演",
    text112: "童声",
    text113: "成熟知性",
    text114: "轻松",
    text115: "专业",
    text116: "软萌童声",
    text117: "儿童音",
    text118: "萝莉女声",
    text119: "治愈童声",
    text120: "抖音热门",
    text121: "影视解说",
    text122: "纪录片",
    text123: "有声书",
    text124: "儿童读物",
    text125: "文学",
    text126: "电商",
    text127: "广告",
    text128: "游戏",
    text129: "短视频",
    text130: "故事",
    text131: "童音",
  },
  //通用语言（取自数据库表dict_language，与language_id对应）
  GlobalLanguage: {
    lang1: "英语",
    lang2: "简体中文",
    lang3: "法语",
    lang4: "印地语",
    lang5: "日语",
    lang6: "高棉语",
    lang7: "马来语",
    lang8: "葡萄牙语（巴西）",
    lang9: "葡萄牙语（欧洲）",
    lang10: "罗马尼亚语",
    lang11: "泰语",
    lang12: "越南语",
    lang13: "台湾繁体",
    lang14: "德语",
    lang15: "西班牙语（拉美）",
    lang16: "俄语",
    lang17: "韩语",
    lang18: "意大利语",
    lang19: "印尼语",
    lang20: "阿拉伯语",
    lang21: "缅甸语",
    lang22: "老挝语",
    lang23: "希伯来语",
    lang24: "西班牙语（欧洲）",
    lang25: "香港繁体",
    lang26: "他加禄语",
    lang27: "法语（加拿大）",
  },
  // 个人媒体操作页
  VideoDetails: {
    buttonName: "全局AI翻译",
    buttonName2: "字幕规则",
    buttonName3: "下载",
    dialogText: "字幕规则设置",
    dialogText1: "每行字幕最短持续时间",
    dialogText2: "每行字幕最长持续时间",
    dialogText3: "时间轴最小间隔",
    dialogText4: "允许无间隔",
    dialogText5: "允许时间轴重叠",
    dialogText6: "每行字幕最大字数限制",
    dialogText7: "翻译成功",
    dialogText8: "最短持续时间应小于最长持续时间",
    dialogText9: "设置成功",
  },
};
