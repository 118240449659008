/*
 * @Description:
 * @version:
 * @Author: Carful
 * @Date: 2022-10-19 14:05:23
 * @LastEditors: Carful
 * @LastEditTime: 2023-07-05 17:17:59
 */
import Vue from "vue";
import VueRouter from "vue-router";
import Cookies from "js-cookie";
// import ContainerHome from "../views/Home/ContainerHome.vue";
import WorkbenchMain from "../views/PersonalMain/WorkbenchMain/WorkbenchMain.vue";
import MyFiles from "../views/PersonalMain/MyFiles/MyFiles.vue";
import VideoDetails from "../views/PersonalMain/VideoDetails/VideoDetails.vue";
import TeamHome from "../views/TeamMain/TeamHome.vue";
import ProjectManagement from "../views/TeamMain/ProjectManagement/ProjectManagement.vue";
import ProjectDetails from "../views/TeamMain/ProjectManagement/ProjectDetails.vue";
import CartoonProject from "../views/TeamMain/CartoonProject.vue";
import MemberManagement from "../views/TeamMain/MemberManagement/MemberManagement.vue";
import TMManagement from "../views/TeamMain/TMManagement/TMManagement.vue";
import TermBaseManagement from "../views/TeamMain/TermBaseManagement/TermBaseManagement.vue";
import HoldAll from "../views/TeamMain/HoldAll/HoldAll";
import DefaultFolder from "../views/PersonalMain/MyFiles/DefaultFolder.vue";
import MemberDetails from "../views/TeamMain/MemberManagement/MemberDetails.vue";
import InvitationRecord from "../views/TeamMain/MemberManagement/InvitationRecord.vue";
import TMManagementDetails from "../views/TeamMain/TMManagement/TMManagementDetails.vue";
import TermBaseManagementDetails from "../views/TeamMain/TermBaseManagement/TermBaseManagementDetails.vue";
import TermBaseManagementOperationrecord from "../views/TeamMain/TermBaseManagement/TermBaseManagementOperationrecord.vue";
import LuckySheet from "../views/TeamMain/TermBaseManagement/LuckySheet.vue";
import PersonalCenter from "../views/Home/Header/PersonalCenter.vue";
import MessageCenter from "../views/Home/Header/MessageCenter.vue";
import PurchaseServices from "../views/Home/Header/PurchaseServices.vue";
import EnterprisesOpenedVIP from "../views/Home/Header/MemberServices/EnterprisesOpenedVIP.vue";
import PurchaseServicesIndex from "../views/Home/Header/MemberServices/PurchaseServicesIndex.vue";
import ServiceDetails from "../views/Home/Header/ServiceDetails.vue";
import PurchaseRecord from "../views/Home/Header/PurchaseRecord.vue";
import MemberInvitation from "../views/TeamMain/MemberManagement/MemberInvitation.vue";

Vue.use(VueRouter);
// 解决 vue-router 升级导致的 Uncaught(in promise)
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/ContainerHome",
    name: "ContainerHome",
    component: () => import("@/views/Home/ContainerHome.vue"),
    meta: {
      keepalive: false,
    },
    children: [
      {
        path: "/ContainerHome/PersonalCenter",
        name: "PersonalCenter",
        component: PersonalCenter, //个人中心
        meta: {
          keepalive: false,
        },
      },
      {
        path: "/ContainerHome/ServiceDetails",
        name: "ServiceDetails",
        component: ServiceDetails, //服务明细
        meta: {
          keepalive: false,
        },
      },
      {
        path: "/ContainerHome/PurchaseRecord",
        name: "PurchaseRecord",
        component: PurchaseRecord, //购买记录
        meta: {
          keepalive: false,
        },
      },
      {
        path: "/ContainerHome/MessageCenter",
        name: "MessageCenter",
        component: MessageCenter, //消息中心
        meta: {
          keepalive: false,
        },
      },
      {
        path: "/ContainerHome/PurchaseServices",
        name: "PurchaseServices",
        component: PurchaseServices, //会员服务
        meta: {
          keepalive: false,
        },
      },

      {
        path: "/ContainerHome/WorkbenchMain",
        name: "WorkbenchMain",
        component: WorkbenchMain, //工作台
        meta: {
          keepalive: false,
        },
      },
      {
        path: "/ContainerHome/MyFiles",
        name: "MyFiles",
        component: MyFiles, //我的文件
        meta: {
          keepalive: false,
        },
      },
      {
        path: "/ContainerHome/MyFiles/DefaultFolder",
        name: "DefaultFolder",
        component: DefaultFolder, //我的文件 默认文件夹
        meta: {
          keepalive: false,
        },
      },

      {
        path: "/ContainerHome/Team/MemberManagement/MemberDetails",
        name: "MemberDetails",
        component: MemberDetails, //任务记录
        meta: {
          keepalive: false,
        },
      },
      {
        path: "/ContainerHome/Team/MemberManagement/PersonalRecords",
        name: "PersonalRecords",
        component: () =>
          import("@/views/TeamMain/MemberManagement/PersonalRecords.vue"), //任务记录
        meta: {
          keepalive: false,
        },
      },
      {
        path: "/ContainerHome/Team/MemberManagement/InvitationRecord",
        name: "InvitationRecord",
        component: InvitationRecord, //团队 成员管理 邀请记录
        meta: {
          keepalive: false,
        },
      },
      {
        path: "/ContainerHome/Team/TermBaseManagement/TermBaseManagementOperationrecord",
        name: "TermBaseManagementOperationrecord",
        component: TermBaseManagementOperationrecord, //团队 术语库管理 操作记录
        meta: {
          keepalive: false,
        },
      },
      {
        path: "/ContainerHome/Team/HoldAll/chat_AI", //万事通
        name: "chat_AI",
        component: () => import("@/views/TeamMain/HoldAll/know_it_all.vue"),
        meta: {
          keepalive: true,
        },
      },
      {
        path: "/ContainerHome/Team/HoldAll/share_check", //分享检查工具页
        name: "share_check",
        component: () => import("@/views/TeamMain/HoldAll/share_check.vue"),
        meta: {
          keepalive: true,
        },
      },
      {
        path: "/ContainerHome/Team",
        name: "Team",
        component: TeamHome,
        children: [
          {
            path: "/ContainerHome/Team/ProjectManagement", //项目管理
            name: "ProjectManagement",
            component: ProjectManagement,
            meta: {
              keepalive: false,
            },
          },
          {
            path: "/ContainerHome/Team/ProjectManagement/ProjectDetails",
            name: "ProjectDetails",
            component: ProjectDetails, //项目管理详情
            meta: {
              keepalive: false,
            },
          },

          {
            path: "/ContainerHome/Team/ProjectManagement/VideoDubbingProjectDetails",
            name: "VideoDubbingProjectDetails",
            component: () =>
              import(
                "@/views/TeamMain/ProjectManagement/VideoDubbingProjectDetails.vue"
              ), //视频配音 团队 端
            meta: {
              keepalive: false,
            },
          },
          {
            path: "/ContainerHome/Team/CartoonProject", //漫画管理
            name: "CartoonProject",
            component: CartoonProject,
            meta: {
              keepalive: false,
            },
          },
          {
            path: "/ContainerHome/Team/MemberManagement", //成员管理
            name: "MemberManagement",
            component: MemberManagement,
            meta: {
              keepalive: false,
            },
          },
          {
            path: "/ContainerHome/Team/TMManagement", //TM管理
            name: "TMManagement",
            component: TMManagement,
            meta: {
              keepalive: false,
            },
          },
          {
            path: "/ContainerHome/Team/TMManagement/TMManagementDetails", //TM管理详情
            name: "TMManagementDetails",
            component: TMManagementDetails,
            meta: {
              keepalive: false,
            },
          },
          {
            path: "/ContainerHome/Team/TermBaseManagement", //术语库管理
            name: "TermBaseManagement",
            component: TermBaseManagement,
            meta: {
              keepalive: false,
            },
          },
          {
            // TermBaseManagementDetails
            path: "/ContainerHome/Team/TermBaseManagement/TermBaseManagementDetails", //术语库管理
            name: "TermBaseManagementDetails", //术语库管理详情
            component: TermBaseManagementDetails,
            meta: {
              keepalive: false,
            },
          },
          {
            path: "/ContainerHome/Team/HoldAll", //工具箱
            name: "HoldAll",
            component: HoldAll,
            meta: {
              keepalive: false,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/Login",
    name: "Login",
    component: () => import("@/views/Login/Login.vue"),
  },
  {
    path: "/MemberInvitation", //成员 邀请 页面
    name: "MemberInvitation",
    component: MemberInvitation,
    meta: {
      keepalive: false,
    },
  },
  {
    path: "/ContainerHome/OverwriteOperation",
    name: "OverwriteOperation", //媒体 操作页 转写环节 团队
    component: () =>
      import("@/views/TeamMain/MediaOperation/OverwriteOperation.vue"),
    meta: {
      keepalive: false,
    },
  },
  {
    path: "/ContainerHome/OverwriteOperationLocal",
    name: "OverwriteOperationLocal", //媒体 操作页 转写环节 团队 本地
    component: () =>
      import(
        "@/views/TeamMain/MediaOperation/LocalMediaOperationsPage/OverwriteOperation_local.vue"
      ),
    meta: {
      keepalive: false,
    },
  },
  {
    path: "/ContainerHome/OverwriteOperationNew",
    name: "OverwriteOperationNew", //媒体 操作页 STQ环节 团队 新
    component: () =>
      import("@/views/TeamMain/MediaOperation/OverwriteOperation.vue"),
    meta: {
      keepalive: false,
    },
  },
  {
    path: "/ContainerHome/TranslationOperation",
    name: "TranslationOperation", //媒体 操作页 翻译环节 团队
    component: () =>
      import("@/views/TeamMain/MediaOperation/ProofingOperation.vue"),
    meta: {
      keepalive: false,
    },
  },
  {
    path: "/ContainerHome/TranslationOperationLocal",
    name: "TranslationOperationLocal", //媒体 操作页 翻译环节 团队 本地
    component: () =>
      import(
        "@/views/TeamMain/MediaOperation/LocalMediaOperationsPage/TranslationOperation_local.vue"
      ),
    meta: {
      keepalive: false,
    },
  },
  {
    path: "/ContainerHome/TranslationLanguageChange",
    name: "TranslationLanguageChange", //翻译环节  语言切换
    component: () =>
      import(
        "@/views/TeamMain/MediaOperation/TranslationLanguageChange/TranslationLanguageChange.vue"
      ),
    meta: {
      keepalive: false,
    },
  },
  {
    path: "/ContainerHome/TranslationReport",
    name: "TranslationReport", //媒体 操作页 翻译环节 翻译提交后可查看的一个报告页面 团队
    component: () =>
      import(
        "@/views/TeamMain/MediaOperation/TranslationReport/TranslationReport.vue"
      ),
    meta: {
      keepalive: false,
    },
  },
  {
    path: "/ContainerHome/TranscriptionReport",
    name: "TranscriptionReport", //媒体 操作页 翻译环节 翻译提交后可查看的一个报告页面 团队
    component: () =>
      import(
        "@/views/TeamMain/MediaOperation/TranslationReport/TranslationReport.vue"
      ),
    meta: {
      keepalive: false,
    },
  },
  {
    path: "/ContainerHome/SubmitReport",
    name: "SubmitReport", //提交报告
    component: () =>
      import(
        "@/views/TeamMain/MediaOperation/TranslationReport/SubmitReport.vue"
      ),
    meta: {
      keepalive: false,
    },
  },
  {
    path: "/ContainerHome/SubmitReport1",
    name: "SubmitReport1", //转写提交对比报告
    component: () =>
      import(
        "@/views/TeamMain/MediaOperation/TranslationReport/SubmitReport1.vue"
      ),
    meta: {
      keepalive: false,
    },
  },
  {
    path: "/ContainerHome/ProofingOperationLocal",
    name: "ProofingOperationLocal", //媒体 操作页 校对环节 团队 本地
    component: () =>
      import(
        "@/views/TeamMain/MediaOperation/LocalMediaOperationsPage/ProofingOperation_local.vue"
      ),
    meta: {
      keepalive: false,
    },
  },

  {
    path: "/ContainerHome/ProofingOperation",
    name: "ProofingOperation", //媒体 操作页 校对环节 团队
    component: () =>
      import("@/views/TeamMain/MediaOperation/ProofingOperation.vue"),
    meta: {
      keepalive: false,
    },
  },

  {
    path: "/ContainerHome/BatchProofreading",
    name: "BatchProofreading", //媒体 操作页 批量 校对环节 团队
    component: () =>
      import("@/views/TeamMain/MediaOperation/BatchProofreading.vue"),
    meta: {
      keepalive: false,
    },
  },
  {
    path: "/ContainerHome/BatchTranslation",
    name: "BatchTranslation", //媒体 操作页 批量 翻译环节 团队
    component: () =>
      import("@/views/TeamMain/MediaOperation/BatchProofreading.vue"),
    meta: {
      keepalive: false,
    },
  },
  {
    path: "/ContainerHome/BatchTranscription",
    name: "BatchTranscription", //媒体 操作页 批量 转写环节 团队
    component: () =>
      import("@/views/TeamMain/MediaOperation/BatchTranscription.vue"),
    meta: {
      keepalive: false,
    },
  },
  {
    path: "/ContainerHome/BatchTranscriptionQC",
    name: "BatchTranscriptionQC", //媒体 操作页 批量 转写环节 团队
    component: () =>
      import("@/views/TeamMain/MediaOperation/BatchTranscription.vue"),
    meta: {
      keepalive: false,
    },
  },
  {
    path: "/ContainerHome/ProofUrlLocal",
    name: "ProofUrlLocal", //媒体 操作页 Proof 团队 本地
    component: () =>
      import(
        "@/views/TeamMain/MediaOperation/LocalMediaOperationsPage/ProofUrl_local.vue"
      ),
    meta: {
      keepalive: false,
    },
  },
  {
    path: "/ContainerHome/ProofUrl",
    name: "ProofUrl", //媒体 操作页 Proof 团队
    component: () => import("@/views/TeamMain/MediaOperation/ProofUrl.vue"),
    meta: {
      keepalive: false,
    },
  },
  {
    path: "/ContainerHome/TextAreaDemo",
    name: "TextAreaDemo", //demo 临时作用
    component: () => import("@/views/TeamMain/MediaOperation/TextAreaDemo.vue"),
    meta: {
      keepalive: false,
    },
  },
  {
    path: "/ContainerHome/YoutubeVideoOperation",
    name: "YoutubeVideoOperation", //youtube 视频项目
    component: () =>
      import(
        "@/views/TeamMain/MediaOperation/YoutubeVideoOperation/YoutubeVideoOperation.vue"
      ),
    meta: {
      keepalive: false,
    },
  },
  {
    path: "/ContainerHome/VideoDetails",
    name: "VideoDetails", //波形详情 个人
    component: VideoDetails,
    meta: {
      keepalive: false,
    },
  },
  {
    path: "/ContainerHome/VideoDubbing",
    name: "VideoDubbing", //波形详情 个人
    component: () =>
      import(
        "@/views/PersonalMain/VideoDetails/VideoDubbing/VideoDubbing_new.vue"
      ),
    meta: {
      keepalive: false,
    },
  },
  //克隆视频配音
  {
    path: "/ContainerHome/VideoDubbingClone",
    name: "VideoDubbingClone",
    component: () =>
      import(
        "@/views/PersonalMain/VideoDetails/VideoDubbingClone/VideoDubbing.vue"
      ),
    meta: {
      keepalive: false,
    },
  },
  //克隆视频配音 音频块虚拟滚动
  {
    path: "/ContainerHome/VideoDubbingClone1",
    name: "VideoDubbingClone1",
    component: () =>
      import(
        "@/views/PersonalMain/VideoDetails/VideoDubbingClone/VideoDubbing - 音频块虚拟滚动.vue"
      ),
    meta: {
      keepalive: false,
    },
  },
  {
    path: "/TeamMain/DubbingProof",
    name: "DubbingProof", //配音 proof 团队
    component: () =>
      import("@/views/TeamMain/VideoDubbingClone/VideoDubbing.vue"),
    meta: {
      keepalive: false,
    },
  },
  {
    path: "/ContainerHome/BatchDubbingProof",
    name: "BatchDubbingProof", //配音 批量proof 团队
    component: () =>
      import("@/views/TeamMain/MediaOperation/BatchDubbingProof.vue"),
    meta: {
      keepalive: false,
    },
  },
  //克隆视频配音（团队）
  {
    path: "/TeamMain/VideoDubbingClone",
    name: "VideoDubbingClone",
    component: () =>
      import("@/views/TeamMain/VideoDubbingClone/VideoDubbing.vue"),
    meta: {
      keepalive: false,
    },
  },
  {
    path: "/demo",
    name: "demo", //媒体 操作页 校对环节 团队 本地
    component: () => import("@/views/demo1.vue"),
    meta: {
      keepalive: false,
    },
  },
  {
    path: "/DubbingInfo",
    name: "DubbingInfo",
    component: () => import("@/views/Dubbing/DubbingInfo.vue"),
  },
  {
    path: "/AudioInfo",
    name: "AudioInfo",
    component: () => import("@/views/Audio/AudioInfo.vue"),
  },
  {
    path: "/Comic/Preview",
    name: "ComicPreview",
    component: () => import("@/views/Comic/Preview.vue"),
  },
  //团队 术语库管理 操作
  {
    path: "/ContainerHome/Team/TermBaseManagement/LuckySheet",
    name: "LuckySheet",
    component: LuckySheet, //团队 术语库管理 操作
    meta: {
      keepalive: false,
    },
  },
  //Hiventy项目检查
  {
    path: "/HoldAll/HiventyCheck",
    name: "HiventyCheck",
    component: () => import("@/views/TeamMain/HoldAll/HiventyCheck.vue"),
    meta: {
      keepalive: false,
    },
  },
  { path: "*", redirect: "/Login" },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
// 声明全局前置守卫，控制后台主页的访问权限z`
router.beforeEach((to, from, next) => {
  if (to.path === "/Login") {
    ///ContainerHome
    //如果已经登录了就跳转到首页
    const token = Cookies.get("token_code");
    // console.log("token", token);
    if (token !== null && token !== "" && token !== undefined) {
      next("/ContainerHome/WorkbenchMain");
    } else {
      next();
    }
  } else if (
    to.path === "/MemberInvitation" ||
    to.path === "/ContainerHome/OverwriteOperation" ||
    to.path === "/ContainerHome/OverwriteOperationNew" ||
    to.path === "/ContainerHome/TranslationOperation" ||
    to.path === "/ContainerHome/ProofingOperation" ||
    to.path === "/ContainerHome/ProofUrl" ||
    to.path === "/ContainerHome/YoutubeVideoOperation" //测试
  ) {
    next();
  } else {
    // console.log(Cookies.get("token_code"));
    const token = Cookies.get("token_code");
    // const token = sessionStorage.getItem("token");
    if (token == null || token == "") {
      next("/Login");
    } else {
      // console.log(22222, to.path);
      next();
    }
  }
});
export default router;
