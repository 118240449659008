<!--
 * @Description: 项目管理
 * @version: 
 * @Author: Carful
 * @Date: 2022-11-07 09:57:02
 * @LastEditors: Carful
 * @LastEditTime: 2023-02-27 17:27:24
-->
<template>
  <div class="mainCenter">
    <div class="mainCenterTop titleHeader">
      <div class="mainHeader_left">
        <div class="signoutClass" v-if="folder_pid != 0" @click="backClick">
          <svg-icon icon-class="Aright" class="icon"></svg-icon>
        </div>
        <div class="titleClass">
          <div class="line1"></div>
          <el-breadcrumb separator="/" class="breadcrumb1">
            <el-breadcrumb-item :class="0 == folder_pid ? 'current' : ''">
              <template v-if="0 != folder_pid">
                <a @click="breadcrumbClick(0)">{{
                  $t("ProjectManagement.AllProject")
                }}</a>
              </template>
              <template v-else
                >{{ $t("ProjectManagement.AllProject") }}
              </template>
            </el-breadcrumb-item>
            <el-breadcrumb-item
              v-for="item in Breadcrumb"
              :key="item.id"
              :class="parseInt(item.id) == folder_pid ? 'current' : ''"
            >
              <template v-if="parseInt(item.id) != folder_pid">
                <a @click="breadcrumbClick(item.id)">{{ item.label }}</a>
              </template>
              <template v-else>{{ item.label }}</template>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <div class="titleOption">
        <!--        创建文件夹-->
        <el-button type="text" @click="createFolder">
          <div class="svgbtn">
            <svg-icon icon-class="NewFolder" class="icon"></svg-icon>
            <p>{{ $t("ProjectManagement.CreateFolder") }}</p>
          </div>
        </el-button>
        <!--        创建项目-->
        <el-button type="text" @click="createTask">
          <div class="svgbtn">
            <svg-icon icon-class="create" class="icon"></svg-icon>
            <p>{{ $t("ProjectManagement.createTask") }}</p>
          </div>
        </el-button>
        <!--        创建配音项目-->
        <el-button type="text" @click="createDubbingTask" v-if="role == 3">
          <div class="svgbtn">
            <svg-icon icon-class="create" class="icon"></svg-icon>
            <p>{{ $t("ProjectManagement.createTask1") }}</p>
          </div>
        </el-button>
        <!--        移动-->
        <el-button type="text" @click="moveToFile">
          <div class="svgbtn">
            <svg-icon icon-class="ShiftIn" class="icon"></svg-icon>
            <p>{{ $t("ProjectManagement.MoveToFile") }}</p>
          </div>
        </el-button>
        <!--        导出-->
        <el-button type="text" @click="exportToFile" v-if="role == 3">
          <div class="svgbtn">
            <svg-icon icon-class="export" class="icon"></svg-icon>
            <p>{{ $t("CommonName.export") }}</p>
          </div>
        </el-button>
      </div>
    </div>
    <!-- 筛选 -->
    <div class="contentCenter_tags screenStyle">
      <p>{{ $t("CommonName.screen") }}</p>
      <el-tag
        v-for="tag in tags"
        :key="tag.name"
        closable
        :type="tag.type"
        @close="handleClose(tag)"
      >
        <p
          class="screenp"
          :id="'tag' + tag.id"
          @mouseover="onShowNameTipsMouseenter(tag)"
        >
          <el-tooltip
            class="item"
            effect="dark"
            :content="tag.name"
            placement="top-start"
            :disabled="!tag.showdropdown"
          >
            <span>{{ tag.name }}</span>
          </el-tooltip>
        </p>
      </el-tag>
    </div>
    <div class="mainCenterbtm tableClass">
      <el-table
        :row-style="{
          height: 48 + 'px',
          background: '#393c4e',
          color: '#cbd7e8',
        }"
        :cell-style="{ padding: 0 + 'px' }"
        :header-cell-style="{
          height: 48 + 'px',
          padding: '0px',
          color: '#adb0bc',
          background: '#393c4e',
        }"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :max-height="tableHeight"
        :default-sort="defaultSort"
        @sort-change="sortChange"
        :row-key="getRowKey"
        @selection-change="handleSelectionChange"
      >
        <div slot="empty" class="noDataDiv">
          <svg-icon icon-class="NoData" class="noDataicon"></svg-icon>
          <p>{{ $t("CommonName.noData") }}</p>
        </div>
        <el-table-column
          type="selection"
          width="65"
          align="center"
          :selectable="selectable"
          :reserve-selection="true"
        ></el-table-column>
        <el-table-column min-width="220" show-overflow-tooltip>
          <template slot="header">
            <div class="tableheaderdiv">
              <!-- 项目名称 -->
              <p class="menuP">{{ $t("ProjectManagement.projectName") }}</p>
              <div class="icondiv">
                <el-popover
                  placement="bottom-end"
                  width="278"
                  v-model="visible"
                  :visible-arrow="false"
                >
                  <div class="popoverDiv">
                    <el-input
                      v-model="popoverInput"
                      :placeholder="$t('CommonName.search')"
                      maxlength="100"
                      @keyup.enter.native="searchdetermineClick"
                    ></el-input>
                    <el-button
                      type="primary"
                      class="setpadding16"
                      @click="searchdetermineClick"
                      >{{ $t("ProjectManagement.query") }}
                    </el-button>
                  </div>
                  <svg-icon
                    icon-class="Filter"
                    class="iconsize"
                    slot="reference"
                  ></svg-icon>
                </el-popover>
                <div class="tableheader"></div>
              </div>
            </div>
          </template>
          <template slot-scope="scope">
            <div
              class="menuscopediv overflowEllipsis"
              style="cursor: pointer"
              @click="tovideoDetails(scope.row)"
            >
              <div style="display: flex; width: 100%">
                <svg-icon
                  :icon-class="
                    scope.row.is_folder == '1' ? 'Folder' : 'project2'
                  "
                  class="icon"
                  :class="
                    scope.row.is_folder == '1'
                      ? 'svgiconclass'
                      : 'svgiconclass1'
                  "
                ></svg-icon>
                <p
                  style="width: calc(100% - 23px)"
                  class="overflowEllipsis"
                  :class="scope.row.is_folder == '1' ? 'folderp' : 'filep'"
                  v-text="scope.row.name"
                ></p>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 状态 -->
        <el-table-column prop="statusName" width="120">
          <template slot="header">
            <div class="tableheaderdiv">
              <p>{{ $t("CommonName.status") }}</p>
              <div class="icondiv">
                <el-dropdown
                  @command="statuscommand"
                  trigger="click"
                  placement="bottom-end"
                >
                  <span class="el-dropdown-link">
                    <svg-icon icon-class="Filter" class="iconsize"></svg-icon>
                  </span>
                  <el-dropdown-menu
                    slot="dropdown"
                    class="tabledropdowndiv noshowtriangle"
                  >
                    <el-dropdown-item
                      v-for="item in statusdropdownList"
                      :key="item.id"
                      :command="{ name: item.name, value: item.id }"
                    >
                      <span>{{ item.name }}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <div class="tableheader"></div>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 项目类型 -->
        <el-table-column prop="project_name" width="120">
          <template slot="header">
            <div class="tableheaderdiv">
              <p>{{ $t("ProjectManagement.Media2") }}</p>
              <div class="icondiv">
                <el-dropdown
                  @command="projecctcommand"
                  trigger="click"
                  placement="bottom-end"
                >
                  <span class="el-dropdown-link">
                    <svg-icon icon-class="Filter" class="iconsize"></svg-icon>
                  </span>
                  <el-dropdown-menu
                    slot="dropdown"
                    class="tabledropdowndiv noshowtriangle"
                  >
                    <el-dropdown-item
                      v-for="item in ProjectdropdownList"
                      :key="item.id"
                      :command="{ name: item.name, value: item.id }"
                    >
                      <span>{{ item.name }}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <div class="tableheader"></div>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 任务数 -->
        <el-table-column prop="total_files" width="130">
          <template slot="header">
            <div class="tableheaderdiv">
              <p>{{ $t("ProjectManagement.Numberoftasks") }}</p>
              <div class="icondiv">
                <div class="tableheader"></div>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- TOTALMEDIALENGTH -->
        <el-table-column prop="minute_duration" width="120">
          <template slot="header">
            <div class="tableheaderdiv">
              <p>{{ $t("ProjectManagement.TOTALMEDIALENGTH") }}</p>
              <div class="icondiv">
                <div class="tableheader"></div>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 创建人 -->
        <el-table-column prop="create_by" width="180">
          <template slot="header">
            <div class="tableheaderdiv">
              <p>{{ $t("ProjectManagement.Createdby") }}</p>
              <div class="icondiv">
                <div class="tableheader"></div>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 创建时间 -->
        <el-table-column
          prop="create_time"
          sortable="custom"
          :sort-orders="['ascending', 'descending']"
          width="180"
        >
          <template slot="header">
            <span>{{ $t("MemberDetails.createTime") }}</span>
            <span class="tableheaderspan"></span>
          </template>
        </el-table-column>
        <!-- 备注 -->
        <el-table-column prop="comments" show-overflow-tooltip min-width="180">
          <template slot="header">
            <span>{{ $t("CommonName.remarks") }}</span>
            <span class="tableheaderspan"></span>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column width="40">
          <template slot-scope="scope">
            <div class="operationClass">
              <el-dropdown @command="commandClicks" trigger="click">
                <span class="el-dropdown-link">
                  <svg-icon
                    icon-class="MoreOperations"
                    style="color: #cbd7e8"
                  ></svg-icon>
                </span>
                <el-dropdown-menu slot="dropdown" class="tabledropdowndiv">
                  <el-dropdown-item
                    v-for="item in scope.row.is_folder == '1'
                      ? dropdownList1
                      : dropdownList"
                    :key="item.id"
                    :command="{ command: item.id, row: scope.row }"
                    v-show="
                      !(
                        item.id == '1' &&
                        (nickname != scope.row.create_by || role != 3)
                      ) || scope.row.is_folder == '1'
                    "
                  >
                    <svg-icon
                      :icon-class="item.icon"
                      style="margin-right: 8px; font-size: 18px"
                    ></svg-icon>
                    <span>{{ item.name }}</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div
        style="margin-top: 20px; float: right; margin-right: 20px"
        v-if="tableData.length > 0"
      >
        <PaginationComVue
          :tableData="[]"
          :pageSize="pageSize"
          :currentPage="currentPage"
          :total="tableTotal"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        ></PaginationComVue>
      </div>
      <DialogComVue
        :dialogVisible="dialogVisible"
        @determineClick="determineClick"
        @changeDialogVisible="changeDialogVisible"
        :footerbtnName="$t('CommonName.Sure')"
        :closefooterbtnName="$t('CommonName.Cancel')"
        :width="'888px'"
        :top="'5%'"
        :title="dialogtitle"
        v-if="dialogVisible"
      >
        <el-form
          ref="form"
          :model="form"
          label-position="top"
          class="formStyleClass dialogformStyleClass"
          @keydown.enter.prevent
          v-if="dialogtitle === $t('ProjectManagement.createTask')"
        >
          <!-- 项目名称 -->
          <el-form-item :label="$t('ProjectManagement.projectName')">
            <el-input
              v-model="form.project_name"
              :maxlength="100"
              :placeholder="$t('ProjectManagement.projectNameplaceholder')"
            ></el-input>
          </el-form-item>
          <!-- 基础设置 -->
          <el-form-item :label="$t('ProjectManagement.BasicSetting')">
            <el-checkbox-group v-model="form.type_mode">
              <div class="checkboxClass" style="justify-content: space-between">
                <div>
                  <el-checkbox :label="1" name="type" border
                    >{{ $t("ProjectManagement.AllowTimeCodesOverlap") }}
                  </el-checkbox>
                  <el-checkbox
                    :label="3"
                    name="type"
                    border
                    style="margin-top: 6px"
                    >{{ $t("ProjectManagement.MachineTranslation") }}
                  </el-checkbox>
                </div>
                <div>
                  <el-checkbox :label="2" name="type" border
                    >{{
                      $t(
                        "ProjectManagement.Allowreviewertoeditoriginalsubtitlefile"
                      )
                    }}
                  </el-checkbox>
                  <el-checkbox
                    :label="4"
                    name="type"
                    border
                    style="margin-top: 6px"
                    >{{ $t("ProjectManagement.Allowtranslationtomodify") }}
                  </el-checkbox>
                </div>
              </div>
              <div class="checkboxClass">
                <el-checkbox
                  :label="5"
                  name="type"
                  border
                  style="margin-top: 6px; width: 100%"
                  >{{ $t("ProjectManagement.tai") }}
                </el-checkbox>
              </div>
              <div class="checkboxClass">
                <el-checkbox
                  :label="10"
                  name="type"
                  border
                  style="margin-top: 6px; width: 100%"
                  >{{ $t("ProjectManagement.captionsMove") }}
                </el-checkbox>
              </div>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item
            :label="$t('ExternalCreateTask.TimeCodeMode')"
            v-show="role == 3"
          >
            <div class="radioClass">
              <el-radio-group v-model="form.dig">
                <el-radio border label="3">00:00:00.000</el-radio>
                <el-radio border label="2">00:00:00.00</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
          <!--          项目名称 顺序-->
          <el-form-item :label="$t('ExternalCreateTask.sortText')">
            <div class="radioClass">
              <el-radio-group v-model="form.sort">
                <el-radio border label="0"
                  >{{ $t("ExternalCreateTask.sortText1") }}
                </el-radio>
                <el-radio border label="1"
                  >{{ $t("ExternalCreateTask.sortText2") }}
                </el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item :label="$t('ProjectManagement.formItemLabel')">
            <div class="contentClass">
              <el-select
                v-model="form.select"
                :placeholder="$t('CommonName.Pleaseselect')"
                filterable
              >
                <el-option
                  v-for="item in selectList"
                  :key="item.project_id"
                  :label="item.project_name"
                  :value="item.project_id"
                >
                </el-option>
              </el-select>
              <div class="contentClass_btmdiv" v-show="form.select">
                <el-checkbox-group v-model="form.checkList">
                  <el-checkbox label="1"
                    >{{ $t("CaptionSettings.tab1") }}
                  </el-checkbox>
                  <el-checkbox label="2"
                    >{{ $t("CaptionSettings.tab2") }}
                  </el-checkbox>
                  <el-checkbox label="3"
                    >{{ $t("CaptionSettings.tab3") }}
                  </el-checkbox>
                  <el-checkbox label="4"
                    >{{ $t("CaptionSettings.tab4") }}
                  </el-checkbox>
                  <el-checkbox label="5">{{ "AI Translation" }} </el-checkbox>
                  <el-checkbox label="6"
                    >{{ $t("CaptionSettings.tab8") }}
                  </el-checkbox>
                  <el-checkbox label="7"
                    >{{ $t("CaptionSettings.tab7") }}
                  </el-checkbox>
                  <el-checkbox label="8"
                    >{{ $t("CaptionSettings.tab9") }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
          </el-form-item>
          <!-- 术语表 -->
          <el-form-item :label="$t('ExternalCreateTask.termText1')">
            <div class="radioClass">
              <el-radio-group v-model="form.term_mode">
                <el-radio border label="2"
                  >{{ $t("ExternalCreateTask.termText2") }}
                </el-radio>
                <el-radio border label="1"
                  >{{ $t("ExternalCreateTask.termText3") }}
                </el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
          <!-- 选择已有术语库 -->
          <el-form-item
            :label="$t('ExternalCreateTask.termText2')"
            v-show="form.term_mode == '2'"
          >
            <div class="contentClass">
              <el-select
                v-model="form.term_id"
                :placeholder="$t('CommonName.Pleaseselect')"
                filterable
              >
                <el-option
                  v-for="item in selectTermList"
                  :key="item.term_file_id"
                  :label="item.name"
                  :value="item.term_file_id"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <!-- 术语名称 -->
          <el-form-item
            :label="$t('ExternalCreateTask.termText4')"
            v-show="form.term_mode == '1'"
          >
            <el-input v-model="form.term_name" :maxlength="100"></el-input>
          </el-form-item>
          <!-- 术语语言 -->
          <el-form-item
            :label="$t('ExternalCreateTask.termText5')"
            v-show="form.term_mode == '1'"
          >
            <div class="contentClass">
              <el-select
                v-model="form.term_lang"
                :placeholder="$t('TMManagement.select')"
                filterable
              >
                <el-option
                  v-for="item in selectLangList"
                  :key="item.language_id"
                  :label="item.cname"
                  :value="item.language_id"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <!-- 术语目标语言 -->
          <el-form-item
            :label="$t('CommonName.Targetlanguage')"
            v-show="form.term_mode == '1'"
          >
            <div class="contentClass">
              <el-select
                v-model="form.tlangs"
                multiple
                :placeholder="$t('TMManagement.select')"
                filterable
              >
                <el-option
                  v-for="item in selectLangList"
                  :key="item.language_id"
                  :label="item.cname"
                  :value="item.language_id"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <!-- 备注 -->
          <el-form-item :label="$t('CommonName.remarks')">
            <el-input
              class="textAreaClassHeight"
              type="textarea"
              v-model="form.comments"
              :maxlength="300"
              resize="none"
            ></el-input>
          </el-form-item>
        </el-form>
        <div
          v-if="dialogtitle === $t('ProjectManagement.createTask1')"
          class="dubbingTask dialogformStyleClass"
        >
          <!-- 项目名称 -->
          <p>{{ $t("ProjectManagement.projectName") }}</p>
          <el-input
            v-model="dubbingform.taskName"
            :maxlength="100"
            :placeholder="$t('ProjectManagement.projectNameplaceholder')"
          ></el-input>
          <!-- 备注 -->
          <p style="margin-top: 24px">{{ $t("CommonName.remarks") }}</p>
          <el-input
            class="textAreaClassHeight"
            type="textarea"
            v-model="dubbingform.comments"
            :maxlength="300"
            resize="none"
          ></el-input>
        </div>
      </DialogComVue>
      <DialogComVue
        :dialogVisible="dialogShareVisible"
        @determineClick="determineShareClick"
        @changeDialogVisible="changeShareVisible"
        :footerbtnName="$t('CommonName.Sure')"
        :closefooterbtnName="$t('CommonName.Cancel')"
        :width="'888px'"
        :top="'10%'"
        :title="dialogSharetitle"
        v-if="dialogShareVisible"
      >
        <div class="shareBody" v-if="false">
          <div
            class="tableClass borderTableStyle shareBodyDiv"
            :class="tableSharedData.length == 0 ? 'tablebtmborder' : ''"
          >
            <el-table
              :row-style="{
                height: 40 + 'px',
                background: '#393c4e',
                color: '#cbd7e8',
              }"
              :cell-style="{ padding: 0 + 'px' }"
              :header-cell-style="{
                height: 32 + 'px',
                padding: '0px',
                color: '#adb0bc',
                background: '#393c4e',
              }"
              :data="tableSharedData"
              tooltip-effect="dark"
              style="width: 100%; overflow-y: auto; border-right: 1px solid"
              max-height="273px"
              @selection-change="handleSharedSelection"
              border
            >
              <el-table-column type="selection" width="39"></el-table-column>
              <el-table-column
                prop="nickname"
                :label="$t('ProjectManagement.Sharetitle1')"
              >
              </el-table-column>
            </el-table>
          </div>
          <div
            class="tableClass borderTableStyle shareBodyDiv"
            :class="tableShareData.length == 0 ? 'tablebtmborder' : ''"
          >
            <el-table
              :row-style="{
                height: 40 + 'px',
                background: '#393c4e',
                color: '#cbd7e8',
              }"
              :cell-style="{ padding: 0 + 'px' }"
              :header-cell-style="{
                height: 32 + 'px',
                padding: '0px',
                color: '#adb0bc',
                background: '#393c4e',
              }"
              :data="tableShareData"
              tooltip-effect="dark"
              style="width: 100%; overflow-y: auto; border-right: 1px solid"
              max-height="273px"
              @selection-change="handleShareSelection"
              border
            >
              <el-table-column type="selection" width="39"></el-table-column>
              <el-table-column
                prop="nickname"
                :label="$t('ProjectManagement.Sharetitle2')"
              >
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="shareBody">
          <el-transfer
            v-model="Shareds"
            :titles="[
              $t('ProjectManagement.Sharetitle2'),
              $t('ProjectManagement.Sharetitle1'),
            ]"
            :props="{
              key: 'user_id',
              label: 'nickname',
            }"
            :data="tableShareData"
          >
          </el-transfer>
        </div>
      </DialogComVue>
      <DialogComVue
        :dialogVisible="dialogProVisible"
        @changeDialogVisible="changeDialogProVisible"
        :footerbtnName="$t('CommonName.Sure')"
        :closefooterbtnName="$t('CommonName.Cancel')"
        :width="'888px'"
        :top="'3%'"
        :title="$t('ProjectDetails.buttonList.name4')"
        :showfooter="false"
        v-if="dialogProVisible"
      >
        <ProjectInformation
          :project_id="commandRow.id"
          :user_id="user_id"
          :commandRow="commandRow"
          @proInfohandleClick="proInfohandleClick"
        ></ProjectInformation>
      </DialogComVue>
      <DialogComVue
        :dialogVisible="dialogFolderVisible"
        @determineClick="determineFolderClick"
        @changeDialogVisible="changeDialogFolderVisible"
        :footerbtnName="$t('CommonName.Sure')"
        :closefooterbtnName="$t('CommonName.Cancel')"
        :width="'888px'"
        :top="'10%'"
        :title="
          update_folder_id == 0
            ? $t('ProjectManagement.CreateFolder')
            : $t('ProjectManagement.RenameFolder')
        "
        v-if="dialogFolderVisible"
      >
        <div class="addFolder langselectclass">
          <p>{{ $t("ProjectManagement.FolderName") }}</p>
          <el-input
            v-model="FolderName"
            maxlength="100"
            :placeholder="$t('ProjectManagement.FolderNameTip')"
          ></el-input>
        </div>
      </DialogComVue>
    </div>
    <DialogComVue
      :dialogVisible="dialogMoveVisible"
      @determineClick="determineMoveClick"
      @changeDialogVisible="changeDialogMoveVisible"
      :footerbtnName="$t('CommonName.OK')"
      :closefooterbtnName="$t('CommonName.Cancel')"
      :width="'650px'"
      :top="'5%'"
      :title="$t('ProjectManagement.MoveToFileTitle')"
      v-if="dialogMoveVisible"
    >
      <div class="moveFolder langselectclass">
        <div
          class="allProject"
          :class="selectAllProject ? 'active' : ''"
          @click="selectAllProject = true"
        >
          <svg-icon icon-class="project2" class="icon svgiconclass1"></svg-icon>
          <p>{{ $t("ProjectManagement.AllProject") }}</p>
        </div>
        <el-tree
          ref="tree"
          :data="moveFolderList"
          :props="defaultProps"
          @node-click="handleNodeClick"
          class="move_tree"
          empty-text=""
        >
          <span class="custom-tree-node" slot-scope="{ node }">
            <svg-icon
              :icon-class="node.expanded ? 'file_open' : 'Folder'"
              class="icon svgiconclass"
            ></svg-icon>
            <span class="folderp">{{ node.label }}</span>
            <!-- <el-button type="text" size="mini" @click="() => append(node)">
              Append
            </el-button> -->
          </span>
        </el-tree>
      </div>
    </DialogComVue>
    <DialogComVue
      :dialogVisible="dialogExportVisible"
      @determineClick="determineExportClick"
      @changeDialogVisible="changeDialogExportVisible"
      :footerbtnName="$t('CommonName.OK')"
      :closefooterbtnName="$t('CommonName.Cancel')"
      :width="'650px'"
      :top="'5%'"
      :title="$t('CommonName.export')"
      v-if="dialogExportVisible"
    >
      <div class="moveFolder langselectclass">
        <el-tree
          ref="ExportTree"
          :data="exportFolderList"
          :props="defaultProps"
          show-checkbox
          :check-on-click-node="false"
          empty-text=""
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <svg-icon
              :icon-class="
                data.type == 0
                  ? node.expanded
                    ? 'file_open'
                    : 'Folder'
                  : 'project2'
              "
              class="icon svgiconclass"
              :class="data.type == 1 ? 'svgiconclass1' : ''"
            ></svg-icon>
            <span class="folderp">{{ node.label }}</span>
            <!-- <el-button type="text" size="mini" @click="a(data)">
              Append
            </el-button> -->
          </span>
        </el-tree>
      </div>
    </DialogComVue>
  </div>
</template>
<script>
import PaginationComVue from "@/components/PaginationCom.vue";
import DialogComVue from "@/components/DialogCom.vue";
import ProjectInformation from "./Dialogs/ProjectInformation.vue";
import {
  onShowNameTipsMouseenter,
  addAndreplaceTags,
} from "@/assets/js/Publicmethods";
import {
  get_project_list,
  create_project,
  get_project_share,
  project_folder_api,
  get_template_project_list,
  create_media_project,
  export_project_jobs_info,
  get_language,
  get_usable_termfile_list,
} from "@/utils/https";
import { showLoading, hideLoading } from "@/utils/loading";
import { filter } from "jszip";

export default {
  components: {
    PaginationComVue,
    DialogComVue,
    ProjectInformation, //项目信息
  },
  data() {
    return {
      dubbingform: {
        taskName: "",
        comments: "",
      },
      info: {},
      popoverInput: "",
      visible: false,
      tags: [],
      statusdropdownList: [
        {
          id: "10",
          name: this.$t("ProjectManagement.haveinhand"),
        },
        {
          id: "20",
          name: this.$t("ProjectManagement.Delivered"),
        },
      ],
      ProjectdropdownList: [
        {
          id: "0",
          name: this.$t("ProjectManagement.Media"),
        },
        {
          id: "1",
          name: this.$t("ProjectManagement.Media1"),
        },
      ],
      dialogtitle: "",
      dialogVisible: false,
      form: {
        project_name: "",
        type_mode: ["2"],
        dig: "3",
        sort: "0",
        comments: "",
        select: "",
        checkList: ["1", "2", "3", "4", "5", "6", "7", "8"],
        term_mode: "2",
        term_id: "",
        term_name: "",
        term_lang: "",
        tlangs: [],
      },
      selectList: [], //创建任务 复制其他任务属性
      tableData: [],
      older: "create_time",
      sort: "desc",
      pageSize: 20,
      currentPage: 1,
      tableHeight: 0,
      dropdownList: [
        {
          name: this.$t("ProjectManagement.dropdownList.name2"),
          icon: "Details",
          id: "2", // 项目信息
        },
        {
          name: this.$t("ProjectManagement.dropdownList.name1"),
          icon: "Share",
          id: "1", // 共享
        },
      ],
      dropdownList1: [
        {
          name: this.$t("ProjectManagement.dropdownList.name3"),
          icon: "Edit",
          id: "3", // 重命名
        },
        {
          name: this.$t("ProjectManagement.dropdownList.name4"),
          icon: "delete",
          id: "4", // 删除
        },
      ],
      dialogShareVisible: false,
      dialogSharetitle: "",
      tableShareData: [],
      tableSharedData: [],
      ShareSelection: [], //未共享列表多选项
      SharedSelection: [], //已共享列表多选项
      defaultSort: { prop: "create_time", order: "descending" },
      nickname: "",
      user_id: "",
      role: 1,
      dialogProVisible: false,
      Shareds: [],
      dialogFolderVisible: false,
      FolderName: "",
      folder_pid: 0,
      tableTotal: 0,
      update_folder_id: 0,
      multipleSelection: [],
      moveFolderList: [],
      dialogMoveVisible: false,
      defaultProps: {
        children: "children",
        label: "label",
      },
      selectAllProject: false,
      move_folder_id: "0", //移动文件夹id
      Breadcrumb: [], //面包屑
      exportFolderList: [],
      dialogExportVisible: false,
      selectTermList: [],
      selectLangList: [],
    };
  },
  methods: {
    /**
     * 获取语言接口
     */
    get_language() {
      return get_language({
        action: "get_language",
        lang:
          sessionStorage.getItem("lang") == "cn" ||
          sessionStorage.getItem("lang") == null
            ? 1
            : 2,
      }).then((res) => {
        if (res) {
          this.selectLangList = res.list;
        }
      });
    },
    /**
     *
     * @param {*获取项目可添加术语列表信息} id
     */
    get_usable_termfile_list() {
      get_usable_termfile_list({
        action: "get_usable_termfile_list",
        project_id: 0,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.selectTermList = res.list;
      });
    },
    /**
     * 创建多媒体项目
     * @param id
     */
    create_media_project() {
      create_media_project({
        action: "create_media_project",
        project_name: this.dubbingform.taskName,
        folder_pid: this.folder_pid,
        comments: this.dubbingform.comments,
      }).then((res) => {
        if (!res) {
          return;
        }
        sessionStorage.setItem("menuTags", "");
        this.currentPage = 1;
        this.older = "create_time";
        this.defaultSort = { prop: "create_time", order: "descending" };
        this.sort = "desc";
        this.tags = [];

        this.get_project_list().then(() => {
          // console.log(res);
          this.$messages(
            "success",
            this.$t("ProjectManagement.successmessage1")
          );

          this.dialogVisible = false;
        });
      });
    },
    /**
     * 获取 列表数据
     */
    get_project_list() {
      let data = { action: "get_project_list" };
      if (this.older != "" && this.sort != "") {
        data["older"] = this.older;
        data["sort"] = this.sort;
      }
      data["folder_pid"] = this.folder_pid;
      data["pageSize"] = this.pageSize;
      data["currentPage"] = this.currentPage;
      this.tags.forEach((item, index) => {
        data[item.key] = item.value;
      });
      return get_project_list(data).then((res) => {
        if (res) {
          // console.log(res);
          res.list.forEach((i) => {
            if (i.pstatus == "10") {
              i.statusName = this.$t("ProjectManagement.haveinhand");
            } else if (i.pstatus == "20") {
              i.statusName = this.$t("ProjectManagement.Delivered");
            } else {
              i.statusName = "--";
            }
            i.project_name =
              i.project_type === "0"
                ? this.$t("ProjectManagement.Media")
                : i.project_type === "1"
                ? this.$t("ProjectManagement.Media1")
                : "--";
          });
          // console.log(page);
          this.tableTotal = parseInt(res.list_count);
          this.tableData = res.list;
          this.Breadcrumb = res.Breadcrumb;
          this.$refs.multipleTable.clearSelection();
          hideLoading();
        }
      });
    },
    /**
     * 获取项目模板列表
     * @returns {*}
     */
    get_template_project_list() {
      return get_template_project_list({
        action: "get_template_project_list",
      }).then((res) => {
        if (res) {
          return res;
        } else {
          hideLoading();
        }
      });
    },
    /**
     * 表头 项目名称 筛选
     */
    searchdetermineClick() {
      if (this.popoverInput) {
        this.tags = addAndreplaceTags(
          this.$t("ProjectManagement.projectName"),
          this.popoverInput,
          this.tags,
          "project_name",
          this.popoverInput
        );
        this.currentPage = 1;
        this.handleMenuTags();
      }
      this.visible = false;
      this.popoverInput = "";
      this.get_project_list();
    },
    /**
     *
     * @param {*状态筛选下拉框} command
     */
    statuscommand(command) {
      this.tags = addAndreplaceTags(
        this.$t("CommonName.status"),
        command.name,
        this.tags,
        "status",
        command.value
      );
      this.currentPage = 1;
      this.handleMenuTags();
      this.get_project_list();
    },
    /**
     * 项目类型 筛选
     * @param command
     */
    projecctcommand(command) {
      this.tags = addAndreplaceTags(
        this.$t("ProjectManagement.Media2"),
        command.name,
        this.tags,
        "project_type",
        command.value
      );
      this.currentPage = 1;
      this.handleMenuTags();
      this.get_project_list();
    },
    /**
     *
     * @param {*筛选 tab 删除} tag
     */
    handleClose(tag) {
      this.tags.splice(this.tags.indexOf(tag), 1);
      this.currentPage = 1;
      this.handleMenuTags();
      this.get_project_list();
    },
    /**
     *
     * @param {超出溢出 显示} i
     */
    onShowNameTipsMouseenter(i) {
      onShowNameTipsMouseenter(i);
    },
    /**
     *
     * @param {*弹窗关闭} val
     */
    changeDialogVisible(val) {
      this.dialogVisible = val;
      this.form = {
        project_name: "",
        type_mode: [],
        dig: "3",
        sort: "0",
        comments: "",
        select: "",
        checkList: ["1", "2", "3", "4", "5", "6", "7", "8"],
        term_mode: "2",
        term_id: "",
        term_name: "",
        term_lang: "",
      };
    },
    /**
     *
     * @param {*弹窗关闭} val
     */
    changeDialogProVisible(val) {
      this.dialogProVisible = val;
    },
    /**
     * 弹窗 确定按钮
     */
    determineClick() {
      if (this.dialogtitle === this.$t("ProjectManagement.createTask1")) {
        if (!this.dubbingform.taskName.trim()) {
          this.$messages("warning", this.$t("CommonName.warningmessage3"));
          return;
        }
        this.create_media_project();
        return;
      }
      // this.dialogVisible = false;
      // 创建任务 跳转任务详情
      // this.$router.push({
      //   name: "ProjectDetails",
      //   params: { id: "1" },
      // });
      // console.log(this.form);
      if (!this.form.project_name.trim()) {
        this.$messages("warning", this.$t("CommonName.warningmessage3"));
        return;
      }
      if (this.form.term_mode == "1") {
        if (!this.form.term_name.trim() || this.form.term_lang == "") {
          this.$messages(
            "warning",
            this.$t("ExternalCreateTask.warningmessage8")
          );
          return;
        }
        if (
          this.form.tlangs.length > 0 &&
          this.form.tlangs.includes(this.form.term_lang)
        ) {
          this.$messages("warning", this.$t("CommonName.selectMessage1"));
          return;
        }
      }
      showLoading(this.$t("CommonName.loadingmessage"));
      create_project({
        action: "create_project",
        project_name: this.form.project_name.trim(),
        type_mode: this.form.type_mode, //1是否允许时间轴交叉，2是否允许校对流程修改原文及时间轴，3MT，4是否允许翻译流程修改原文及时间轴 5.是否泰语去掉 元音等字符
        dig: this.form.dig,
        sort_mode: this.form.sort,
        comments: this.form.comments,
        folder_pid: this.folder_pid,
        template_project_id: this.form.select, //模板项目id 不使模板项目时传
        template_mode: this.form.select ? this.form.checkList : [], //1:字幕规则,2:屏蔽字符,3: 水印,4:设置备注,5:AI Translation,6:字幕区域,7:ASR设置,8:SRT设置
        th_lang_mode: this.form.type_mode.includes(5) ? "1" : "0",
        term_file_mode: this.form.term_mode,
        term_file_id: this.form.term_id,
        term_name: this.form.term_name,
        term_lang: this.form.term_lang,
        tlangs: this.form.tlangs.join(","),
        captions_move: this.form.type_mode.includes(10) ? "1" : "0",
      }).then((res) => {
        if (res) {
          sessionStorage.setItem("menuTags", "");
          this.currentPage = 1;
          this.older = "create_time";
          this.defaultSort = { prop: "create_time", order: "descending" };
          this.sort = "desc";
          this.tags = [];

          this.get_project_list().then(() => {
            // console.log(res);
            this.$messages(
              "success",
              this.$t("ProjectManagement.successmessage1")
            );

            this.dialogVisible = false;
          });
        }
      });
    },
    /**
     *
     * @param {点击表格 项目名称 进入项目详情页面} row
     */
    tovideoDetails(row) {
      if (row.is_folder === "1") {
        this.folder_pid = row.id;

        sessionStorage.setItem("menuTags", "");
        this.currentPage = 1;
        this.older = "create_time";
        this.defaultSort.prop = this.older;
        this.sort = "desc";
        this.defaultSort.order = "descending";
        this.tags = [];
        this.$refs.multipleTable.sort(this.older, this.defaultSort.order);

        this.$router.push({
          name: "ProjectManagement",
          query: {
            id: row.id,
          },
        });
        // this.$router.go(0);
      } else {
        let name =
          row.project_type === "0"
            ? "ProjectDetails"
            : "VideoDubbingProjectDetails";
        this.$router.push({
          name,
          query: {
            id: row.id,
            fid: this.folder_pid,
          },
        });
      }
    },
    /**
     * 创建项目
     */
    createTask() {
      showLoading(this.$t("CommonName.loadingmessage"));
      this.get_template_project_list().then((res) => {
        this.get_language();
        this.get_usable_termfile_list();
        this.selectList = res.list;
        this.form = {
          project_name: "",
          type_mode: [],
          dig: "3",
          sort: "0",
          comments: "",
          select: "",
          checkList: ["1", "2", "3", "4", "5", "6", "7", "8"],
          term_mode: "2",
          term_id: "",
          term_name: "",
          term_lang: "",
          tlangs: [],
        };
        this.dialogtitle = this.$t("ProjectManagement.createTask");
        this.dialogVisible = true;
        hideLoading();
      });
    },
    /**
     * 创建 配音项目
     */
    createDubbingTask() {
      this.dubbingform = {
        taskName: "",
        comments: "",
      };
      this.dialogtitle = this.$t("ProjectManagement.createTask1");
      this.dialogVisible = true;
    },
    /**
     * 分页器
     * @param {} val 页数
     */
    handleSizeChange(val) {
      this.pageSize = val;
      this.handleMenuTags();
      this.get_project_list();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.handleMenuTags();
      this.get_project_list();
    },
    sortChange(column) {
      // console.log(column);
      showLoading(this.$t("CommonName.loadingmessage"));
      if (column.order == "ascending") {
        this.older = column.prop;
        this.sort = "asc";
      } else {
        this.older = column.prop;
        this.sort = "desc";
      }
      this.currentPage = 1;
      this.handleMenuTags();
      this.get_project_list();
    },
    /**
     *
     * @param {*表格 操作} row
     * row.command
     */
    commandClicks(row) {
      this.commandRow = row.row;
      switch (row.command) {
        //共享
        case "1":
          this.dialogSharetitle = this.$t("ProjectManagement.Sharetitle");
          this.showfooter = true;
          this.dialogShareVisible = true;
          this.getShareTableData(this.commandRow.id);
          break;
        //项目信息
        case "2":
          // if (row.row.project_type === "0") {
          this.dialogProVisible = true;
          this.Shareds = [];
          // } else {
          //   console.log(11111);
          // }
          // console.log(row.row);
          break;
        //重命名
        case "3":
          this.update_folder_id = this.commandRow.id;
          this.dialogFolderVisible = true;
          this.FolderName = this.commandRow.name;
          break;
        //删除
        case "4":
          this.handleFolderDelete(this.commandRow);
          break;
        default:
          break;
      }
    },
    /**
     *
     * @param {*获取共享列表} val
     */
    getShareTableData(project_id) {
      get_project_share({
        action: "get_project_share_ulist",
        project_id: project_id,
      }).then((res) => {
        if (!res) {
          return;
        }
        let data = [];
        res.list.forEach((item, index) => {
          data.push(item.user_id);
        });
        this.Shareds = data;
      });
      get_project_share({
        action: "get_project_share_unlist",
        project_id: project_id,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.tableShareData = res.list;
      });
    },
    /**
     *
     * @param {*弹窗关闭} val
     */
    changeShareVisible(val) {
      this.dialogShareVisible = val;
    },
    /**
     * 弹窗 确定按钮
     */
    determineShareClick() {
      get_project_share({
        action: "set_project_share_user",
        project_id: this.commandRow.id,
        ouser_ids: this.Shareds,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.$messages("success", this.$t("ProjectManagement.successmessage4"));
        this.dialogShareVisible = false;
      });
      // let ouser_ids_shared = [];
      // this.SharedSelection.forEach((item) => {
      //   ouser_ids_shared.push(item.user_id);
      // });
      // let ouser_ids_share = [];
      // this.ShareSelection.forEach((item) => {
      //   ouser_ids_share.push(item.user_id);
      // });
      // console.log(ouser_ids_share);
      // if (ouser_ids_share.length > 0) {
      //   get_project_share({
      //     action: "set_project_share_user",
      //     project_id: this.commandRow.id,
      //     ouser_ids: ouser_ids_share,
      //   }).then((res) => {
      //     if (!res) {
      //       return;
      //     }
      //     this.getShareTableData(this.commandRow.id);
      //   });
      // }
      // if (ouser_ids_shared.length > 0) {
      //   get_project_share({
      //     action: "remove_project_share_user",
      //     project_id: this.commandRow.id,
      //     ouser_ids: ouser_ids_shared,
      //   }).then((res) => {
      //     if (!res) {
      //       return;
      //     }
      //     this.getShareTableData(this.commandRow.id);
      //   });
      // }
    },
    handleSharedSelection(val) {
      this.SharedSelection = val;
    },
    handleShareSelection(val) {
      this.ShareSelection = val;
    },
    handleMenuTags() {
      let menuTags = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        older: this.older,
        sort: this.sort,
        tags: this.tags,
      };
      sessionStorage.setItem("menuTags", JSON.stringify(menuTags));
    },
    proInfohandleClick() {
      this.get_project_list();
    },
    createFolder() {
      this.update_folder_id = 0;
      this.dialogFolderVisible = true;
      this.FolderName = "";
    },
    changeDialogFolderVisible(val) {
      this.dialogFolderVisible = false;
    },
    determineFolderClick() {
      if (this.FolderName.trim() == "") {
        this.$messages("warning", this.$t("ProjectManagement.FolderNameTip"));
        return false;
      }
      if (this.update_folder_id != 0) {
        project_folder_api({
          action: "rename_folder",
          folder_pid: this.folder_pid,
          folder_id: this.update_folder_id,
          folder_name: this.FolderName.trim(),
        }).then((res) => {
          if (!res) {
            return;
          }
          this.dialogFolderVisible = false;
          this.$messages(
            "success",
            this.$t("ProjectManagement.FolderNameTip2")
          );
          this.get_project_list();
        });
      } else {
        project_folder_api({
          action: "create_folder",
          folder_pid: this.folder_pid,
          folder_name: this.FolderName,
        }).then((res) => {
          if (!res) {
            return;
          }
          this.dialogFolderVisible = false;
          this.$messages(
            "success",
            this.$t("ProjectManagement.FolderNameTip1")
          );
          this.get_project_list();
        });
      }
    },
    backClick() {
      sessionStorage.setItem("menuTags", "");
      this.currentPage = 1;
      this.older = "create_time";
      this.defaultSort.prop = this.older;
      this.sort = "desc";
      this.defaultSort.order = "descending";
      this.tags = [];
      let pid = this.folder_pid;
      const index = this.Breadcrumb.findIndex(
        (item) => parseInt(item.id) == pid
      );
      let id = 0;
      if (index > 0) {
        id = this.Breadcrumb[index - 1]["id"];
      }
      this.folder_pid = id;

      this.$router.push({
        name: "ProjectManagement",
        query: { id },
      });
      setTimeout(() => {
        this.$refs.multipleTable.sort(this.older, this.defaultSort.order);
      }, 10);

      // this.$router.go(0);
    },
    handleSelectionChange(val) {
      // console.log(val);
      this.multipleSelection = val;
    },
    handleFolderDelete(data) {
      let that = this;
      let next = true;
      if (data.total_files > 0) {
        that.$messages("warning", this.$t("ProjectManagement.FolderDeleteTip"));
        next = false;
        return false;
      }
      if (next) {
        // console.log(jobids);
        // console.log(folderids);
        let title = "";
        title = this.$t("ProjectManagement.FolderDeleteTip1");
        this.$confirm(title, this.$t("CommonName.deleteMessageTitle"), {
          confirmButtonText: this.$t("CommonName.deleteMessagebutton"),
          cancelButtonText: this.$t("CommonName.deleteMessageClosebutton"),
          cancelButtonClass: "el-button--info",
          iconClass: "none",
          closeOnClickModal: false,
          distinguishCancelAndClose: true,
          closeOnPressEscape: false,
          type: "warning",
        })
          .then(() => {
            showLoading(this.$t("CommonName.loadingmessage"));

            project_folder_api({
              action: "delete_folder",
              folder_id: data.id,
            }).then((res) => {
              if (res) {
                this.get_project_list().then((res) => {
                  this.$messages(
                    "success",
                    this.$t("CommonName.successmessage2")
                  );
                });
              }
            });
          })
          .catch((action) => {
            if (action === "cancel") {
              console.log("cancel");
            }
          });
      }
    },
    exportToFile() {
      showLoading(this.$t("CommonName.loadingmessage"));
      project_folder_api({
        action: "get_export_folder",
        folder_id: this.folder_pid,
      }).then((res) => {
        hideLoading();
        if (!res) {
          return;
        }
        this.exportFolderList = res.list;
        this.dialogExportVisible = true;
      });
    },
    determineExportClick() {
      let allcheckeds = this.$refs.ExportTree.getCheckedNodes();
      let pcheckeds = [];
      let pids = [];
      if (allcheckeds.length > 0) {
        pcheckeds = allcheckeds.filter((i) => i.type == 1);
      }
      // console.log("pcheckeds", pcheckeds);
      if (pcheckeds.length > 0) {
        pcheckeds.forEach((item) => {
          pids.push(item.project_id);
        });
        showLoading(this.$t("CommonName.loadingmessage"));
        export_project_jobs_info({
          action: "export_project_jobs_info",
          project_ids: pids,
        }).then((res) => {
          hideLoading();
          console.log("res", res);
          window.open(res.path, "_blank");
          // const blob = new Blob([res], {
          //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
          // });
          // let downloadElement = document.createElement("a");
          // let href = window.URL.createObjectURL(blob); //创建下载的链接
          // downloadElement.href = href;
          // downloadElement.download =
          //   this.$t("ProjectManagement.exportName") + `.xlsx`;
          // document.body.appendChild(downloadElement);
          // downloadElement.click(); //点击下载
          // document.body.removeChild(downloadElement); //下载完成移除元素
          // window.URL.revokeObjectURL(href);
        });
      } else {
        this.$messages("warning", this.$t("ProjectManagement.warningmessage7"));
      }
    },
    changeDialogExportVisible(val) {
      this.dialogExportVisible = false;
    },
    moveToFile() {
      if (this.multipleSelection.length > 0) {
        showLoading(this.$t("CommonName.loadingmessage"));
        let folder_ids = [];
        this.multipleSelection.forEach((item) => {
          if (item.is_folder == "1") {
            folder_ids.push(item.id);
          }
        });
        project_folder_api({
          action: "get_move_folder",
          folder_ids,
        }).then((res) => {
          hideLoading();
          if (!res) {
            return;
          }
          this.moveFolderList = res.list;
          this.dialogMoveVisible = true;
        });
      }
    },
    determineMoveClick() {
      let project_ids = [];
      let folder_ids = [];
      if (this.multipleSelection.length > 0) {
        this.multipleSelection.forEach((item) => {
          if (item.is_folder == "1") {
            folder_ids.push(item.id);
          } else {
            project_ids.push(item.id);
          }
        });
        if (this.selectAllProject) {
          this.move_folder_id = "0";
          //移动到根目录 也就是folder_id为0
        }
        project_folder_api({
          action: "move_job_folder",
          project_ids,
          folder_ids,
          folder_id: this.move_folder_id,
        }).then((res) => {
          if (!res) {
            return;
          }
          if (res.error > 0) {
            this.$messages(
              "error",
              this.$t("ProjectManagement.FolderMoveTip1")
            );
          } else {
            this.$messages(
              "success",
              this.$t("ProjectManagement.FolderMoveTip")
            );
            this.dialogMoveVisible = false;
            this.get_project_list();
          }
        });
      }
    },
    changeDialogMoveVisible(val) {
      this.dialogMoveVisible = false;
    },
    handleNodeClick(val) {
      this.selectAllProject = false;
      this.move_folder_id = val.id;
    },
    getRowKey(row) {
      return row.id;
    },
    breadcrumbClick(folder_id) {
      this.folder_pid = folder_id;
      this.$router.push({
        name: "ProjectManagement",
        query: { id: folder_id },
      });
      sessionStorage.setItem("menuTags", "");
      this.currentPage = 1;
      this.older = "create_time";
      this.defaultSort.prop = this.older;
      this.sort = "desc";
      this.defaultSort.order = "descending";
      this.tags = [];
      this.$refs.multipleTable.sort(this.older, this.defaultSort.order);

      // this.$router.go(0);
    },
    selectable(row, index) {
      if (row.owner == 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.folder_pid = this.$route.query.id ? this.$route.query.id : 0;
  },
  mounted() {
    hideLoading();
    showLoading(this.$t("CommonName.loadingmessage"));
    //将储存的搜索条件，赋值
    if (
      sessionStorage.getItem("menuIndex") != null &&
      sessionStorage.getItem("menuIndex") ==
        "/ContainerHome/Team/ProjectManagement"
    ) {
      if (
        sessionStorage.getItem("menuTags") != "" &&
        sessionStorage.getItem("menuTags") != null
      ) {
        let menuTags = JSON.parse(sessionStorage.getItem("menuTags"));
        if (menuTags.currentPage != undefined) {
          this.currentPage = menuTags.currentPage;
        }
        if (menuTags.pageSize != undefined) {
          this.pageSize = menuTags.pageSize;
        }
        if (menuTags.older != undefined) {
          this.older = menuTags.older;
          this.defaultSort.prop = this.older;
        }
        if (menuTags.sort != undefined) {
          this.sort = menuTags.sort;
          this.defaultSort.order =
            this.sort == "asc" ? "ascending" : "descending";
        }
        if (menuTags.tags != undefined) {
          this.tags = menuTags.tags;
        }
      }
    }
    let info = JSON.parse(sessionStorage.getItem("info"));
    this.nickname = info.nickname;
    this.user_id = info.user_id;
    this.role = info.role;
    this.get_project_list();
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - 300;
    });
    window.onresize = () => {
      this.tableHeight = window.innerHeight - 300;
    };
  },
};
</script>
<style lang="scss" scoped>
.mainCenter {
  height: calc(100% - 38px);
  width: 100%;
  box-sizing: border-box;
}

.mainCenterTop {
  // float: right;

  margin: 14px 24px 14px 18px;

  .titleOption {
    .svgbtn {
      margin-right: 14px;
    }
  }

  .mainHeader_left {
    display: flex;
    width: calc(100% - 700px);

    .signoutClass {
      width: 22px;
      height: 22px;
      border: 1px solid;
      border-color: #757784;
      border-radius: 2px;
      line-height: 22px;
      margin-right: 24px;
      cursor: pointer;

      .icon {
        font-size: 16px;
      }
    }

    .titleClass {
      display: flex;
      align-items: center;
      text-align: left;
      width: calc(100% - 48px);
      justify-content: space-between;

      .overflowp {
        width: 350px;
      }

      .line1 {
        width: 4px;
        height: 16px;
        background-color: #46adff;
        border-radius: 2px;
      }

      .breadcrumb1 {
        width: calc(100% - 12px);

        ::v-deep .el-breadcrumb__item .el-breadcrumb__inner {
          color: #adb0bc;
        }

        ::v-deep .el-breadcrumb__item.current .el-breadcrumb__inner {
          color: #fff;
        }

        ::v-deep .el-breadcrumb__inner a {
          color: #adb0bc;

          &:hover {
            color: #46adff;
          }
        }
      }

      p {
        font-weight: 700;
        color: #ffffff;
        font-size: 16px;
        margin-left: 8px;
      }
    }

    .dropdowndiv {
      display: flex;
      align-items: center;
      margin-left: 40px;

      .dropdownbutton {
        p {
          color: #46adff;
          font-size: 14px;
        }
      }
    }
  }
}

.mainCenterbtm {
  height: calc(100% - 76px);

  .operationClass {
    cursor: pointer;
  }

  .menuscopediv {
    // padding-left: 14px;
    color: #46adff;
    display: flex;
    align-content: center;

    .folderp {
      color: #cbd7e8;
      font-size: 14px;
      white-space: pre;
    }

    .filep {
      color: #46adff;
      font-size: 14px;
    }

    .svgiconclass {
      color: #f5df86;
      width: 18px !important;
      height: 23px !important;
      margin-right: 5px;
    }

    .svgiconclass1 {
      width: 18px !important;
      height: 23px !important;
      color: #46adff;
      margin-right: 5px;
    }
  }

  .tableheaderdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .menuP {
      padding-left: 0px;
    }

    .icondiv {
      display: flex;
      align-items: center;

      .iconsize {
        font-size: 16px;
      }

      .tableheader {
        margin-left: 6px;
        width: 1px;
        height: 12px;
        background-color: #757784;
      }
    }
  }

  .tableheaderspan {
    float: right;
    display: block;
    width: 1px;
    height: 12px;
    background-color: #757784;
    margin-top: 10px;
  }

  // ::v-deep .el-checkbox__inner {
  //   background: none !important;
  //   line-height: 0;
  // }
  // ::v-deep .el-checkbox .el-checkbox__inner:hover {
  //   border-color: #ffffff;
  // }
  // ::v-deep .el-checkbox__input.is-disabled .el-checkbox__inner {
  //   background-color: #edf2fc !important;
  //   border-color: #7d7f8b;
  // }
  // ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  //   border: 1px solid #409eff !important;
  //   background-color: #409eff !important;
  // }
  // ::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  //   border: 1px solid #409eff !important;
  //   background-color: #409eff !important;
  // }
  // ::v-deep .el-checkbox__input .el-checkbox__inner {
  //   width: 14px;
  //   height: 14px;
  //   background-color: #393c4e !important;
  //   border: 1px solid;
  //   border-color: #7d7f8b;
  //   border-radius: 2px;
  // }
}

.dialogformStyleClass {
  padding: 0 24px 24px 24px;

  .contentClass {
    width: 100%;

    ::v-deep .el-input__inner {
      width: 400px !important;
    }
  }

  .contentClass_btmdiv {
    margin-top: 16px;

    ::v-deep .el-checkbox__label {
      color: #fff !important;
    }
  }
}

.shareBody {
  margin: 24px;
  display: flex;
  justify-content: center;

  p {
    color: #adb0bc;
    font-size: 14px;
    margin-bottom: 5px;
  }

  .shareBodyDiv {
    width: 46%;
  }

  ::v-deep .el-transfer-panel {
    color: #adb0bc;
    background-color: #393c4e;
    border: 1px solid #7d7f8b;
    width: 260px;
  }

  ::v-deep .el-transfer-panel__item.el-checkbox {
    color: #adb0bc;
  }

  ::v-deep .el-transfer-panel__header {
    background-color: #393c4e !important;
    border-bottom: 1px solid #7d7f8b;
    color: #adb0bc;

    .el-checkbox .el-checkbox__label {
      color: #adb0bc;
      font-size: 15px;
    }
  }

  ::v-deep .el-transfer__buttons {
    padding: 0 45px;

    .el-button {
      padding: 0 20px;
      margin-bottom: 0;
    }
  }
}

.addFolder {
  margin: 24px;

  p {
    color: #adb0bc;
    font-size: 14px;
    margin-bottom: 6px;
  }
}

.moveFolder {
  margin: 24px;
  height: 300px;
  overflow: auto;
  border: 1px solid #7d7f8b;
  border-radius: 2px;
  padding: 16px;

  .allProject {
    display: flex;
    height: 27px;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: #484a5a;
    }

    .svgiconclass1 {
      color: #cbd7e8;
      width: 18px !important;
      height: 21px !important;
      margin-right: 5px;
    }

    p {
      color: #cbd7e8;
      margin-bottom: 0px;
    }
  }

  .allProject.active {
    background-color: #46adff; //背景色
  }

  p {
    color: #adb0bc;
    font-size: 14px;
    margin-bottom: 6px;
  }

  ::v-deep .el-tree {
    background-color: #393c4e;

    .el-tree-node:focus > .el-tree-node__content {
      background-color: #46adff; //背景色
    }

    .el-tree-node__content {
      &:hover {
        background: #484a5a;
      }
    }
  }

  .custom-tree-node {
    display: flex;
    align-items: center;
  }

  .folderp {
    color: #cbd7e8;
    font-size: 14px;
    white-space: pre;
  }

  .svgiconclass {
    color: #f5df86;
    width: 18px !important;
    height: 21px !important;
    margin-right: 5px;
  }
  .svgiconclass1 {
    color: #46adff;
    width: 18px !important;
    height: 21px !important;
    margin-right: 5px;
  }
}

.dubbingTask {
  margin: 24px;

  p {
    color: #adb0bc;
    font-size: 14px;
    margin-bottom: 5px;
  }
}
::v-deep .el-dialog--center .el-dialog__body {
  padding: 0px !important;
  max-height: 90vh;
  overflow-y: auto;
}
</style>
